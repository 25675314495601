import _Button from "@mui/material/Button";
var __jsx = React.createElement;
import React from 'react';
import { SharedComponentsTestIds } from '../Goals.model';
export const ButtonComponent = function (onClick, text) {
  let fullWidth = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
  let disabled = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : false;
  return __jsx(_Button, {
    "data-cy": SharedComponentsTestIds.buttonComponent,
    disabled: disabled,
    fullWidth: fullWidth,
    onClick: onClick,
    sx: {
      mt: 6,
      px: 6,
      py: 3
    }
  }, text);
};