import _Alert from "@mui/material/Alert";
import _Link from "@mui/material/Link";
var __jsx = React.createElement;
import React from 'react';
import { AdaAssistantLink, CallbackLink, ExternalLink } from '@yieldstreet/ui-kit';
import size from "lodash/size";
// import { Link } from 'sharedComponents/Links/Links';
import NotificationMessage from 'sharedComponents/NotificationMessage/NotificationMessage';
import { NotificationWrapper } from '../CompleteInvestment.style';
const renderTimerExpiredError = offeringInfo => {
  var _offeringInfo$note, _offeringInfo$note2;
  return __jsx(NotificationWrapper, null, __jsx(NotificationMessage, {
    title: "Investment request expired",
    textList: [__jsx(React.Fragment, null, "Your investment request has expired due to inactivity. If you would like to invest in", ' ', offeringInfo === null || offeringInfo === void 0 ? void 0 : (_offeringInfo$note = offeringInfo.note) === null || _offeringInfo$note === void 0 ? void 0 : _offeringInfo$note.title, ", please", ' ', __jsx(_Link, {
      to: `/offering/${offeringInfo === null || offeringInfo === void 0 ? void 0 : (_offeringInfo$note2 = offeringInfo.note) === null || _offeringInfo$note2 === void 0 ? void 0 : _offeringInfo$note2.urlHash}/`
    }, "click here"), " to start a new investment.")]
  }));
};
const renderBulkTimerExpiredError = link => {
  return __jsx(NotificationWrapper, null, __jsx(NotificationMessage, {
    title: "Investment request expired",
    textList: [__jsx(React.Fragment, null, "Your investment request has expired due to inactivity. If you would like to submit your request, please ", __jsx(_Link, {
      to: link
    }, "retry again"), ".")]
  }));
};
const renderPromotionGateError = promoError => {
  return __jsx(_Alert, {
    severity: "error",
    title: "Sorry, there was a problem with this promotion.",
    "data-cy": "promotion-error"
  }, promoError);
};
const renderPaymentInfoError = () => {
  return __jsx(_Alert, {
    severity: "error",
    title: "Sorry, we were unable to retrieve payment methods for your investment request.",
    "data-cy": "investor-request-error"
  }, "Please select your investor account and try again.");
};
const renderSubmitInvestmentError = () => {
  return __jsx(_Alert, {
    severity: "error",
    title: "Your investment could not be processed at this time."
  }, __jsx(React.Fragment, null, "Please try again, or contact us at ", __jsx(_Link, {
    href: "tel:8449435378"
  }, "844-943-5378"), " or", ' ', __jsx(AdaAssistantLink, null, "investments@yieldstreet.com")));
};
const renderInvestmentErrorCodeError = (investmentErrorCode, investmentErrorMessage, userState, amount) => {
  let title = 'Error';
  let errorMessage = '';
  switch (investmentErrorCode) {
    case 'noteUrlHash':
      if (investmentErrorMessage.match(/sorry/gi)) {
        title = 'This Offering Has Closed';
        errorMessage = [`${userState.displayName}, we're sorry your investment request of $${new Intl.NumberFormat(('en-IN', {
          style: 'currency',
          currency: 'USD'
        })).format(amount)} cannot be accepted.`, 'This offering is oversubscribed, and the waitlist has been closed.'];
      } else {
        title = 'Not found';
        errorMessage = [investmentErrorMessage];
      }
      break;
    case 'paymentMethod':
      errorMessage = ['Subscription not set for this investment. Please select a payment method and bank account before confirming.'];
      break;
    case 'database':
      errorMessage = [__jsx(React.Fragment, null, "Could not create investment request. Please", ' ', __jsx(CallbackLink, {
        id: "investment-try-again",
        callback: () => window.location.reload()
      }, "try again"), ' ', "and contact our support if the problem persists.")];
      break;
    case 'resubscription':
      title = 'Investment Request Notice';
      errorMessage = [__jsx(React.Fragment, null, "We\u2019re glad you want to submit an additional Investment Request. However, currently we are only accepting one Short Term Notes resubscription request at a time. If you have any questions or concerns please email us at", ' ', __jsx(AdaAssistantLink, null, "investments@yieldstreet.com"))];
      break;
    case 'noInvestorAccount':
      title = 'Yieldstreet Wallet is Required, and Missing';
      errorMessage = [__jsx(React.Fragment, null, "To invest in this offering, please complete your Yieldstreet Wallet account setup. Take the next step ", __jsx(ExternalLink, {
        href: "/portfolio"
      }, "here"), ".")];
      break;
    default:
      errorMessage = [investmentErrorMessage || 'Sorry, we were not able to create your investment request. Please return to the Offering page to submit a new request.'];
      break;
  }
  return __jsx(NotificationWrapper, null, __jsx(NotificationMessage, {
    title: title,
    textList: errorMessage
  }));
};
const ErrorHandler = _ref => {
  let {
    promoError,
    offeringDetails,
    userState,
    amount,
    timerExpired,
    paymentInfoError,
    submitInvestmentError,
    investmentErrorCode,
    investmentErrorMessage,
    timerExpiredLink
  } = _ref;
  if (timerExpired || investmentErrorCode !== null && investmentErrorCode !== void 0 && investmentErrorCode.expired) {
    if (size(offeringDetails) === 1) {
      return renderTimerExpiredError(Object.values(offeringDetails)[0]);
    } else {
      return renderBulkTimerExpiredError(timerExpiredLink);
    }
  }
  if (promoError) {
    return renderPromotionGateError(promoError);
  }
  if (paymentInfoError) {
    return renderPaymentInfoError();
  }
  if (submitInvestmentError) {
    return renderSubmitInvestmentError();
  }
  if (investmentErrorCode) {
    return renderInvestmentErrorCodeError(investmentErrorCode, investmentErrorMessage, userState, amount);
  }
  return null;
};
export default ErrorHandler;