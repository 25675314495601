import _Link from "@mui/material/Link";
var __jsx = React.createElement;
import React from 'react';
import { createYupObjectSchema } from '@yieldstreet/tool-kit';
import { User2FAMethod } from '@yieldstreet/platform-kit';
export const RegisterFormSchema = isMaskedPhone => createYupObjectSchema({
  phone: {
    validationType: isMaskedPhone ? 'default-string' : 'phone'
  }
});
export const registerTitle = 'Protect your account with two-factor authentication';
export const twoFATitle = {
  [User2FAMethod.TEXT_MESSAGE]: 'SMS authentication setup',
  [User2FAMethod.AUTHY_APP]: 'Authy authenticator setup',
  [User2FAMethod.OTP_APP]: 'Authenticator setup'
};
export const authenticationMethod = {
  [User2FAMethod.TEXT_MESSAGE]: 'We will text a verification code to the number below.',
  [User2FAMethod.AUTHY_APP]: 'You’ll receive a text with a link to download the Authy app. If you already have the app, the link will open it.',
  [User2FAMethod.OTP_APP]: __jsx(React.Fragment, null, "Scan the QR code using a supported authenticator app, such as", ' ', __jsx(_Link, {
    href: "https://support.google.com/accounts/answer/1066447?hl=en&co=GENIE.Platform%3DiOS&oco=0",
    target: "_blank"
  }, "Google Authenticator"), ",", ' ', __jsx(_Link, {
    href: "https://www.microsoft.com/en-us/security/mobile-authenticator-app",
    target: "_blank"
  }, "Microsoft Authenticator"), ", or any other compatible app.")
};
export const getTwoFAOptions = availableMethods => {
  const methodList = {
    [User2FAMethod.AUTHY_APP]: {
      label: 'Authy authenticator setup',
      value: User2FAMethod.AUTHY_APP,
      description: 'You’ll receive a text with a link to download the Authy app. If you already have the app, the link will open it.'
    },
    [User2FAMethod.TEXT_MESSAGE]: {
      label: 'Text/SMS',
      value: User2FAMethod.TEXT_MESSAGE,
      description: 'Receive verification codes via text message'
    },
    [User2FAMethod.OTP_APP]: {
      label: 'Authenticator',
      value: User2FAMethod.OTP_APP,
      description: 'Use an authentication app of your choice to receive authentication codes'
    }
  };
  return availableMethods.map(method => methodList[method]);
};
export const getMethodText = method => {
  if (!method) {
    return {
      title: registerTitle,
      titleDescription: 'Which method would you like to use?'
    };
  }
  return {
    title: twoFATitle[method],
    titleDescription: authenticationMethod[method]
  };
};