import _extends from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/extends.js";
import _objectSpread from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
var __jsx = React.createElement;
import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { getIRAFundingInstructions, useFlags } from '@yieldstreet/tool-kit';
import { skipToken } from '@reduxjs/toolkit/query';
import { PaymentMethodType, investmentRequestOfferingsApi, investmentApi, offeringApi, investorAccountsApi, InvestmentRequestStatus, InvestorAccountProvider, additionalFundingSourceTypes, LegalEntityType, Cadence } from '@yieldstreet/platform-kit';
import { getCapitalCallsCalculation, getAdditionalFundsNeeded, isFortyActOrRegAPlus, useRolloverSheet } from '@yieldstreet/tool-kit';
import { Notification } from '@yieldstreet/ui-kit';
import isEmpty from "lodash/isEmpty";
import forEach from "lodash/forEach";
import find from "lodash/find";
import size from "lodash/size";
import map from "lodash/map";
import isNil from "lodash/isNil";
import sum from "lodash/sum";
import * as Yup from 'yup';
import { ADVICE_RECOMMENDATIONS_PATH } from 'pages/advice';
import FormikEffect from 'utils/FormikEffect';
import { setSegmentTrack } from 'utils/thirdPartyCode';
import { useGetRollover } from '../../hooks/useGetRollover';
import { COMPLETE_INVESTMENT_STATUS, COMPLETE_INVESTMENT_STATUS_CODE } from './CompleteInvestment.model';
import { SContentBox } from './CompleteInvestment.style';
import DistributionReinvestment from './DistributionReinvestment';
import ErrorHandler from './ErrorHandler';
import Header from './Header';
import { useInvestmentPaymentMethods } from './hooks/useInvestmentPaymentMethods';
import { InvestorAccountSelection } from './InvestorAccountSelection';
import { useUserState } from 'utils/hooks';
import { RolloverSheet } from './RolloverSheet/RolloverSheet';
import { CompleteInvestmentInner } from './CompleteInvestmentInner';
import { UpfrontCommitmentBreakdown } from './UpfrontCommitmentBreakdown/';

/**
 * @param investments {Array<{ urlHash: string, amount: string | number (unparsed)}>}
 * @param investmentType { null | "DISCRETIONARY" | "NON_DISCRETIONARY"}
 * @param formikProps
 */
const CompleteInvestment = _ref => {
  var _paymentInfo$walletBa, _selectedAccount$prov, _offeringDetails$Obje, _offeringDetails$Obje2, _offeringDetails$Obje3, _offeringDetails$Obje4, _investorAccounts$fin;
  let {
    isRolloverV2,
    rollover,
    promoState,
    investments,
    investmentType,
    formikProps,
    setInvestorName,
    setTotalFundedNowAmount,
    setInvestmentAmountsByUrlHash,
    setAccountEligibility,
    setIsDisabled,
    disabledReason,
    setDisabledReason,
    setStatus,
    status,
    updateValidation,
    upfrontFundingDetails
  } = _ref;
  const {
    pizzaTrackerSpvMonthlyCadenceCopy
  } = useFlags({
    pizzaTrackerSpvMonthlyCadenceCopy: false
  });
  const {
    values: {
      investorAccountId,
      paymentMethod,
      primaryPaymentDetails
    }
  } = formikProps;
  const {
    isFreeInvestmentPromo,
    promoKey,
    promoChecked,
    promoError
  } = promoState || {};
  const promoWait = promoState && !promoChecked;
  const {
    0: investmentError,
    1: setInvestmentError
  } = useState(null);
  const {
    0: additionalFundsPaymentDetails,
    1: setAdditionalFundsPaymentDetails
  } = useState(null);
  const {
    0: hasCreatedInvestmentRequest,
    1: setHasCreatedInvestmentRequest
  } = useState(false);
  const userState = useUserState();
  const loggedIn = userState.loggedIn;
  const isWirePayment = (primaryPaymentDetails === null || primaryPaymentDetails === void 0 ? void 0 : primaryPaymentDetails.paymentMethod) === PaymentMethodType.WIRE;
  const isACHPayment = (primaryPaymentDetails === null || primaryPaymentDetails === void 0 ? void 0 : primaryPaymentDetails.paymentMethod) === PaymentMethodType.ACH;

  // This data is retrieved from marketplace. It might not be accurate, since we have multple entry points: marketplace & recommendations.
  const {
    marketplaceFilterUsed,
    timerExpired
  } = useSelector(_ref2 => {
    let {
      investmentRequest,
      expirationTimer
    } = _ref2;
    return {
      marketplaceFilterUsed: investmentRequest.marketplaceFilterUsed,
      timerExpired: expirationTimer.expired
    };
  });
  const {
    noteUrlHashes,
    totalCommittedAmount
  } = useMemo(() => {
    const noteUrlHashes = [];
    let totalCommittedAmount = 0;
    forEach(investments, _ref3 => {
      let {
        urlHash,
        amount
      } = _ref3;
      noteUrlHashes.push(urlHash);
      totalCommittedAmount += amount;
    });
    setSegmentTrack({
      event: 'Checkout Started',
      properties: {
        urlHashes: noteUrlHashes,
        totalCommittedAmount
      }
    });
    return {
      noteUrlHashes,
      totalCommittedAmount
    };
  }, [investments]);

  /**
   * Get Offering Details
   */
  const {
    data: offeringDetails,
    isSuccess: isOfferingSuccess,
    supportAdditionalPaymentMethods,
    hasCapitalCallDeals,
    capitalCallUrlHashes
  } = offeringApi.useDetailsQuery(noteUrlHashes, {
    selectFromResult: _ref4 => {
      let {
        data,
        isSuccess
      } = _ref4;
      const capitalCallUrlHashes = [];
      let supportAdditionalPaymentMethods = true;
      forEach(data, (x, key) => {
        var _x$note, _x$note2;
        if (isFortyActOrRegAPlus((_x$note = x.note) === null || _x$note === void 0 ? void 0 : _x$note.fundType)) {
          supportAdditionalPaymentMethods = false;
        }
        if ((_x$note2 = x.note) !== null && _x$note2 !== void 0 && _x$note2.capitalCallEnabled) {
          capitalCallUrlHashes.push(key);
        }
      });
      return {
        data,
        isSuccess,
        supportAdditionalPaymentMethods,
        capitalCallUrlHashes,
        hasCapitalCallDeals: capitalCallUrlHashes.length > 0
      };
    }
  });
  const {
    totalAmount,
    capitalCallDealAmounts
  } = useMemo(() => {
    const amounts = [];
    const capitalCallDealAmounts = {};
    const investmentAmountsByUrlHash = {};
    forEach(investments, _ref5 => {
      let {
        urlHash,
        amount
      } = _ref5;
      if (hasCapitalCallDeals) {
        var _offeringDetails$urlH;
        const calculation = getCapitalCallsCalculation({
          committedAmount: amount,
          capitalDrawnPercentage: offeringDetails === null || offeringDetails === void 0 ? void 0 : (_offeringDetails$urlH = offeringDetails[urlHash]) === null || _offeringDetails$urlH === void 0 ? void 0 : _offeringDetails$urlH.note.capitalDrawnPercentage
        });
        const fundedNow = (calculation === null || calculation === void 0 ? void 0 : calculation.fundedNow) || 0;
        amounts.push(fundedNow);
        capitalCallDealAmounts[urlHash] = fundedNow;
        investmentAmountsByUrlHash[urlHash] = fundedNow;
      } else {
        amounts.push(amount);
        investmentAmountsByUrlHash[urlHash] = amount;
      }
    });
    const total = sum(amounts);
    setTotalFundedNowAmount && setTotalFundedNowAmount(total);
    setInvestmentAmountsByUrlHash && setInvestmentAmountsByUrlHash(investmentAmountsByUrlHash);
    return {
      totalAmount: total,
      capitalCallDealAmounts
    };
  }, [investments, setTotalFundedNowAmount, setInvestmentAmountsByUrlHash, hasCapitalCallDeals, offeringDetails]);
  const {
    maxRolloverAmount,
    needsAdditionalFunds: rolloverNeedsAdditionalFunds,
    rolloverData: rolloverV2Data,
    rolloverDataIsUninitialized: rolloverV2DataIsUninitialized,
    rolloverDataIsLoading: rolloverV2DataIsLoading,
    rolloverOffering
  } = useRolloverSheet({
    amount: totalAmount,
    urlHash: rollover === null || rollover === void 0 ? void 0 : rollover.urlHash,
    investorId: rollover === null || rollover === void 0 ? void 0 : rollover.investorId
  });

  /**
   * Retrieve Rollover data by investor account
   */
  const {
    rolloverInvestmentIds
  } = useGetRollover(investorAccountId);

  /**
   * Accept Rollover
   */

  const acceptRollover = useMemo(() => {
    // If >1 offering, then we do not support rollover
    if (size(offeringDetails) > 1) return false;
    return !!find(offeringDetails, x => !!x.note.acceptRollover);
  }, [offeringDetails]);
  const paymentMethodsRolloverConfig = useMemo(() => {
    if (!acceptRollover) return {};
    if (isRolloverV2) {
      return {
        rolloverAmountAvailable: maxRolloverAmount,
        rolloverSourceUrlHash: rollover === null || rollover === void 0 ? void 0 : rollover.urlHash
      };
    }
    return {
      // rolloverInvestmentIds: filteredRolloverInvestmentIds,
    };
  }, [acceptRollover, isRolloverV2, maxRolloverAmount, rollover]);
  const {
    paymentInfo,
    paymentInfoLoading,
    paymentInfoError,
    primaryPaymentMethods,
    additionalFundsPaymentMethods,
    firstPaymentMethod,
    firstAdditionalFundsPaymentMethod,
    hasPaymentMethodChoice,
    hasAdditionalFundsPaymentMethodChoice
  } = useInvestmentPaymentMethods(investorAccountId, totalAmount, noteUrlHashes, supportAdditionalPaymentMethods, paymentMethodsRolloverConfig);
  const missingAmount = paymentInfo === null || paymentInfo === void 0 ? void 0 : (_paymentInfo$walletBa = paymentInfo.walletBalance) === null || _paymentInfo$walletBa === void 0 ? void 0 : _paymentInfo$walletBa.missingAmount;

  /**
   * Set accept rollover
   */
  useEffect(() => {
    const {
      values
    } = formikProps;
    if ((values === null || values === void 0 ? void 0 : values.acceptRollover) !== acceptRollover) {
      formikProps.setFieldValue('acceptRollover', acceptRollover);
    }
  }, [formikProps, acceptRollover]);

  /**
   * Set Formik Props BankAccountId
   */
  useEffect(() => {
    let bankAccountId = null;
    if (missingAmount > 0) {
      bankAccountId = (additionalFundsPaymentDetails === null || additionalFundsPaymentDetails === void 0 ? void 0 : additionalFundsPaymentDetails.bankAccountId) || null;
    } else {
      bankAccountId = (primaryPaymentDetails === null || primaryPaymentDetails === void 0 ? void 0 : primaryPaymentDetails.bankAccountId) || null;
    }
    if (formikProps.values.bankAccountId !== bankAccountId) {
      formikProps.setFieldValue('bankAccountId', bankAccountId);
    }
  }, [primaryPaymentDetails, missingAmount, formikProps, additionalFundsPaymentDetails]);
  const [getBulkEligibility, {
    data: eligibilityResult,
    isSuccess: isEligibilitySuccess
  }] = investmentApi.useBulkInvestmentEligibilityMutation();
  useEffect(() => {
    var _eligibilityResult$el;
    const investorEligibility = eligibilityResult === null || eligibilityResult === void 0 ? void 0 : (_eligibilityResult$el = eligibilityResult.eligibility) === null || _eligibilityResult$el === void 0 ? void 0 : _eligibilityResult$el.investorData;
    const accountEligibility = investorEligibility === null || investorEligibility === void 0 ? void 0 : investorEligibility.find(investorAccount => (investorAccount === null || investorAccount === void 0 ? void 0 : investorAccount.investorAccountId) === investorAccountId);
    if (setAccountEligibility && accountEligibility) setAccountEligibility(accountEligibility);
  }, [eligibilityResult, investorAccountId, setAccountEligibility]);

  /**
   * Get eligible investor accounts array.
   */
  const investorAccounts = useMemo(() => {
    var _eligibilityResult$el2;
    return (eligibilityResult === null || eligibilityResult === void 0 ? void 0 : (_eligibilityResult$el2 = eligibilityResult.eligibility) === null || _eligibilityResult$el2 === void 0 ? void 0 : _eligibilityResult$el2.investorData) || [];
  }, [eligibilityResult]);

  /**
   *  Bulk Eligibility
   *
   */
  useEffect(() => {
    let rolloverInvestmentIdsForRequest = [];
    if (isRolloverV2) {
      var _rolloverV2Data$total;
      if (rolloverV2DataIsUninitialized || rolloverV2DataIsLoading) {
        return;
      }
      rolloverInvestmentIdsForRequest = rolloverV2Data === null || rolloverV2Data === void 0 ? void 0 : (_rolloverV2Data$total = rolloverV2Data.totals) === null || _rolloverV2Data$total === void 0 ? void 0 : _rolloverV2Data$total.investmentIds;
    }
    const investmentEligibilityConfig = {
      type: investmentType,
      investments,
      rolloverInvestmentIds: acceptRollover ? rolloverInvestmentIdsForRequest : [],
      rolloverAmountAvailable: maxRolloverAmount,
      rolloverSourceUrlHash: rolloverOffering === null || rolloverOffering === void 0 ? void 0 : rolloverOffering.urlHash
    };
    getBulkEligibility(investmentEligibilityConfig);
  }, [isRolloverV2, rolloverV2DataIsUninitialized, rolloverV2DataIsLoading, rolloverV2Data, investments, investmentType, getBulkEligibility, rolloverInvestmentIds, acceptRollover]);

  /**
   * Distribuition Reinvestment (drip)
   */
  const {
    distributionReinvestmentConfig,
    dripEnabledPerOffering
  } = useMemo(() => {
    const dripEnabledPerOffering = {};
    forEach(offeringDetails, offering => {
      var _offering$note;
      if (offering !== null && offering !== void 0 && (_offering$note = offering.note) !== null && _offering$note !== void 0 && _offering$note.distributionReinvestment) {
        var _offering$note2, _offering$note3;
        Object.assign(dripEnabledPerOffering, {
          [(_offering$note2 = offering.note) === null || _offering$note2 === void 0 ? void 0 : _offering$note2.urlHash]: (_offering$note3 = offering.note) === null || _offering$note3 === void 0 ? void 0 : _offering$note3.title
        });
      }
    });
    let distributionReinvestmentConfig = skipToken;
    if (investorAccountId) {
      distributionReinvestmentConfig = {
        investorAccountId,
        urlHashes: Object.keys(dripEnabledPerOffering) || []
      };
    }
    return {
      distributionReinvestmentConfig,
      dripEnabledPerOffering
    };
  }, [investorAccountId, offeringDetails]);
  const {
    data: distributionReinvestmentData
  } = investorAccountsApi.useGetDistributionReinvestmentQuery(distributionReinvestmentConfig);
  const additionalRolloverFundsDetails = useMemo(() => {
    var _paymentInfo$walletBa2;
    return getAdditionalFundsNeeded(totalAmount, isRolloverV2 ? maxRolloverAmount : 0, paymentInfo === null || paymentInfo === void 0 ? void 0 : (_paymentInfo$walletBa2 = paymentInfo.walletBalance) === null || _paymentInfo$walletBa2 === void 0 ? void 0 : _paymentInfo$walletBa2.currentBalance);
  }, [isRolloverV2, totalAmount, maxRolloverAmount, paymentInfo]);
  const showWalletBalance = (paymentInfo === null || paymentInfo === void 0 ? void 0 : paymentInfo.showWalletBalance) && (!primaryPaymentDetails || (primaryPaymentDetails === null || primaryPaymentDetails === void 0 ? void 0 : primaryPaymentDetails.paymentMethod) !== PaymentMethodType.WIRE);
  const hasInsufficientFunds = !!missingAmount && (!primaryPaymentDetails || (primaryPaymentDetails === null || primaryPaymentDetails === void 0 ? void 0 : primaryPaymentDetails.paymentMethod) !== PaymentMethodType.WIRE);

  //FIXME: this needs to be cleaned up and moved to the page
  const timerExpiredLink = useCallback(() => {
    if (['DISCRETIONARY', 'NON_DISCRETIONARY'].includes(investmentType)) {
      return ADVICE_RECOMMENDATIONS_PATH;
    }
    return '/offerings/';
  }, [investmentType]);
  const shouldPreSelectAccountForAdditionalDeposit = useMemo(() => firstAdditionalFundsPaymentMethod && isNil(formikProps.values.additionalFundsPaymentMethod) && !hasAdditionalFundsPaymentMethodChoice, [firstAdditionalFundsPaymentMethod, hasAdditionalFundsPaymentMethodChoice, formikProps]);

  /**
   * Setting the Formik Defaults
   */
  useEffect(() => {
    var _investorAccounts$;
    const {
      values
    } = formikProps;
    if (!hasPaymentMethodChoice && firstPaymentMethod && isNil(values.paymentMethod)) {
      formikProps.setFieldValue('paymentMethod', firstPaymentMethod === null || firstPaymentMethod === void 0 ? void 0 : firstPaymentMethod.value);
    }
    if (shouldPreSelectAccountForAdditionalDeposit) {
      formikProps.setFieldValue('additionalFundsPaymentMethod', firstAdditionalFundsPaymentMethod === null || firstAdditionalFundsPaymentMethod === void 0 ? void 0 : firstAdditionalFundsPaymentMethod.value);
    }
    if (isRolloverV2 && rollover !== null && rollover !== void 0 && rollover.investorId) {
      if (isNil(values.investorAccountId) || (values === null || values === void 0 ? void 0 : values.investorAccountId) !== (rollover === null || rollover === void 0 ? void 0 : rollover.investorId)) {
        formikProps.setFieldValue('investorAccountId', rollover === null || rollover === void 0 ? void 0 : rollover.investorId);
      }
    } else if ((investorAccounts === null || investorAccounts === void 0 ? void 0 : investorAccounts.length) === 1 && !((_investorAccounts$ = investorAccounts[0]) !== null && _investorAccounts$ !== void 0 && _investorAccounts$.gateData)) {
      if (isNil(values.investorAccountId)) {
        formikProps.setFieldValue('investorAccountId', investorAccounts[0].investorAccountId);
      }
    }
    if (!!distributionReinvestmentData && formikProps.values.dripDataPerOffering !== distributionReinvestmentData) {
      formikProps.setFieldValue('dripDataPerOffering', distributionReinvestmentData);
    }
  }, [formikProps, firstPaymentMethod, firstAdditionalFundsPaymentMethod, investorAccounts, distributionReinvestmentData, isRolloverV2, rollover, shouldPreSelectAccountForAdditionalDeposit, hasPaymentMethodChoice]);

  // Get The Offering Title
  const {
    offeringTitle,
    isCapitalCall,
    offeringUrlHash
  } = useMemo(() => {
    var _offering$note4, _offering$note5, _offering$note6;
    if (!offeringDetails || size(offeringDetails) > 1) {
      return {
        offeringTitle: null,
        isCapitalCall: null //FIXME we need to check what happends when we have multiple capital call deals.
      };
    }
    const offering = find(offeringDetails);
    return {
      offeringTitle: offering === null || offering === void 0 ? void 0 : (_offering$note4 = offering.note) === null || _offering$note4 === void 0 ? void 0 : _offering$note4.title,
      isCapitalCall: offering === null || offering === void 0 ? void 0 : (_offering$note5 = offering.note) === null || _offering$note5 === void 0 ? void 0 : _offering$note5.capitalCallEnabled,
      offeringUrlHash: offering === null || offering === void 0 ? void 0 : (_offering$note6 = offering.note) === null || _offering$note6 === void 0 ? void 0 : _offering$note6.urlHash
    };
  }, [offeringDetails]);
  const distributionData = useMemo(() => {
    if (!distributionReinvestmentData) {
      return [];
    }
    return map(distributionReinvestmentData, (data, urlHash) => {
      const title = (dripEnabledPerOffering === null || dripEnabledPerOffering === void 0 ? void 0 : dripEnabledPerOffering[urlHash]) || '';
      return {
        noteTitle: title,
        investment: data,
        key: urlHash
      };
    });
  }, [distributionReinvestmentData, dripEnabledPerOffering]);
  const distributionReinvestments = useMemo(() => {
    const {
      values
    } = formikProps;
    if (!distributionReinvestmentData || !(values !== null && values !== void 0 && values.investorAccountId)) {
      return null;
    }
    return __jsx(DistributionReinvestment, {
      formikProps: formikProps,
      data: distributionData
    });
  }, [formikProps, distributionReinvestmentData, distributionData]);

  /**
   * Cleanup rules on investor changed;
   */
  const handleInvestorChange = useCallback(() => {
    formikProps.setFieldValue('paymentMethod', undefined);
  }, [formikProps]);
  const handlePaymentMethodChange = useCallback(selected => {
    if (selected !== primaryPaymentDetails) {
      formikProps.setFieldValue('primaryPaymentDetails', selected);
    }
  }, [primaryPaymentDetails, formikProps]);
  const handleAdditionalFundsPaymentMethodChange = useCallback(selected => {
    if (selected !== additionalFundsPaymentDetails) {
      setAdditionalFundsPaymentDetails(selected);
    }
  }, [additionalFundsPaymentDetails]);

  /**
   * Get investment amount for url hash
   */
  const getInvestmentAmount = useCallback(noteUrlHash => {
    var _investments$find;
    return (_investments$find = investments.find(_ref6 => {
      let {
        urlHash
      } = _ref6;
      return urlHash === noteUrlHash;
    })) === null || _investments$find === void 0 ? void 0 : _investments$find.amount;
  }, [investments]);

  /**
   * Get Pre Investment Request
   */
  const [getPreInvestmentRequest, {
    data: preInvestmentRequestData,
    isSuccess: preInvestmentRequestSuccess,
    isError: preInvestmentRequestError,
    isLoading: preInvestmentRequestLoading
  }] = investmentRequestOfferingsApi.useGetInvestmentRequestMutation();
  useEffect(() => {
    if (!(noteUrlHashes !== null && noteUrlHashes !== void 0 && noteUrlHashes.length)) {
      return skipToken;
    }
    const status = InvestmentRequestStatus.PREQ;
    const requests = [];
    forEach(investments, investment => {
      requests.push({
        noteUrlHash: investment.urlHash,
        status
      });
    });
    getPreInvestmentRequest({
      requests
    });
  }, [noteUrlHashes, investments, getPreInvestmentRequest]);

  /**
   * Create Investment
   */
  const [createInvestment, createInvestmentResult] = investmentRequestOfferingsApi.useBulkCreateMutation();
  useEffect(() => {
    if (!createInvestmentResult) {
      return;
    }
    if (createInvestmentResult.isSuccess) {
      var _createInvestmentResu;
      const investments = {};
      forEach((_createInvestmentResu = createInvestmentResult.data) === null || _createInvestmentResu === void 0 ? void 0 : _createInvestmentResu.results, result => {
        const {
          noteUrlHash
        } = result.investment;
        Object.assign(investments, {
          [noteUrlHash]: _objectSpread(_objectSpread({}, result.investment), {}, {
            amount: getInvestmentAmount(noteUrlHash)
          })
        });
      });
      if (!formikProps.values.investments && size(investments)) {
        formikProps.setFieldValue('investments', investments);
      }
    }
    if (createInvestmentResult.isError) {
      var _createInvestmentResu2, _createInvestmentResu3, _createInvestmentResu4, _createInvestmentResu5, _createInvestmentResu6, _createInvestmentResu7, _createInvestmentResu8, _createInvestmentResu9, _createInvestmentResu10;
      setInvestmentError({
        message: ((_createInvestmentResu2 = createInvestmentResult.error) === null || _createInvestmentResu2 === void 0 ? void 0 : (_createInvestmentResu3 = _createInvestmentResu2.response) === null || _createInvestmentResu3 === void 0 ? void 0 : (_createInvestmentResu4 = _createInvestmentResu3.data) === null || _createInvestmentResu4 === void 0 ? void 0 : _createInvestmentResu4.message) || ((_createInvestmentResu5 = createInvestmentResult.error) === null || _createInvestmentResu5 === void 0 ? void 0 : (_createInvestmentResu6 = _createInvestmentResu5.response) === null || _createInvestmentResu6 === void 0 ? void 0 : (_createInvestmentResu7 = _createInvestmentResu6.data) === null || _createInvestmentResu7 === void 0 ? void 0 : _createInvestmentResu7.error),
        code: (_createInvestmentResu8 = createInvestmentResult.error) === null || _createInvestmentResu8 === void 0 ? void 0 : (_createInvestmentResu9 = _createInvestmentResu8.response) === null || _createInvestmentResu9 === void 0 ? void 0 : (_createInvestmentResu10 = _createInvestmentResu9.data) === null || _createInvestmentResu10 === void 0 ? void 0 : _createInvestmentResu10.code
      });
    }
  }, [createInvestmentResult, getInvestmentAmount, formikProps]);

  /**
   * Create investment request
   */
  useEffect(() => {
    if (promoError || promoWait || !preInvestmentRequestSuccess || hasCreatedInvestmentRequest || !noteUrlHashes || isEmpty(capitalCallDealAmounts) && hasCapitalCallDeals) {
      return;
    }
    if (isRolloverV2 && (rolloverV2DataIsUninitialized || rolloverV2DataIsLoading)) {
      return;
    }
    const requests = [];
    forEach(investments, _ref7 => {
      let {
        urlHash,
        amount
      } = _ref7;
      let capitalCallInvestmentRequest = {};
      if (capitalCallDealAmounts !== null && capitalCallDealAmounts !== void 0 && capitalCallDealAmounts[urlHash]) {
        const fundedNow = capitalCallDealAmounts === null || capitalCallDealAmounts === void 0 ? void 0 : capitalCallDealAmounts[urlHash];
        capitalCallInvestmentRequest = {
          committedAmount: amount,
          amount: fundedNow
        };
      }
      const investmentRequest = _objectSpread({
        promotionKey: promoKey,
        amount
      }, capitalCallInvestmentRequest);
      const customVariables = _objectSpread({
        activeFilter: marketplaceFilterUsed.activeFilter
      }, !isEmpty(marketplaceFilterUsed.searchFilters) && {
        searchFilters: JSON.stringify(marketplaceFilterUsed.searchFilters)
      });
      const rolloverV2Request = {};
      if (isRolloverV2 && rollover && investments.length === 1) {
        var _rolloverV2Data$total2, _rolloverV2Data$total3;
        rolloverV2Request.additionalFundingSources = [{
          type: additionalFundingSourceTypes.ROLLOVER,
          attributes: {
            investmentIds: rolloverV2Data === null || rolloverV2Data === void 0 ? void 0 : (_rolloverV2Data$total2 = rolloverV2Data.totals) === null || _rolloverV2Data$total2 === void 0 ? void 0 : _rolloverV2Data$total2.investmentIds,
            noteUrlHash: rollover === null || rollover === void 0 ? void 0 : rollover.urlHash,
            maturityDate: rolloverV2Data === null || rolloverV2Data === void 0 ? void 0 : rolloverV2Data.maturityDate,
            availableAmount: rolloverV2Data === null || rolloverV2Data === void 0 ? void 0 : (_rolloverV2Data$total3 = rolloverV2Data.totals) === null || _rolloverV2Data$total3 === void 0 ? void 0 : _rolloverV2Data$total3.maxRolloverAmount
          }
        }];
      }
      requests.push(_objectSpread(_objectSpread({}, investmentRequest), {}, {
        investmentId: preInvestmentRequestData === null || preInvestmentRequestData === void 0 ? void 0 : preInvestmentRequestData[urlHash],
        noteUrlHash: urlHash,
        adviceType: investmentType,
        customVariables
      }, rolloverV2Request));
    });
    createInvestment({
      requests
    });
    setHasCreatedInvestmentRequest(true);
  }, [isRolloverV2, rollover, rolloverV2Data, rolloverV2DataIsUninitialized, rolloverV2DataIsLoading, promoError, promoKey, promoWait, hasCreatedInvestmentRequest, preInvestmentRequestData, preInvestmentRequestSuccess, investments, noteUrlHashes, createInvestment, investmentType, capitalCallDealAmounts, hasCapitalCallDeals, marketplaceFilterUsed]);

  /**
   * Determine Complete investment health state
   */
  useEffect(() => {
    var _eligibilityResult$el3;
    /**
     * Handle preInvestmentRequestError
     */
    if (!preInvestmentRequestLoading && preInvestmentRequestError) {
      setStatus(COMPLETE_INVESTMENT_STATUS.ERROR);
      setInvestmentError({
        code: 'pre-invest-error'
      });
      return;
    }
    if (timerExpired) {
      setStatus && setStatus(COMPLETE_INVESTMENT_STATUS.TIMER_EXPIRED);
      return;
    }
    if (!isEligibilitySuccess) {
      setStatus && setStatus(COMPLETE_INVESTMENT_STATUS.LOADING);
      return;
    }
    if (eligibilityResult !== null && eligibilityResult !== void 0 && (_eligibilityResult$el3 = eligibilityResult.eligibility) !== null && _eligibilityResult$el3 !== void 0 && _eligibilityResult$el3.userGate) {
      setStatus && setStatus(COMPLETE_INVESTMENT_STATUS.GATED);
      return;
    }
    setStatus && setStatus(null);
  }, [timerExpired, eligibilityResult, isEligibilitySuccess, setStatus, preInvestmentRequestLoading, preInvestmentRequestError]);
  const showErrorHandler = (status === null || status === void 0 ? void 0 : status.code) === COMPLETE_INVESTMENT_STATUS_CODE.ERROR || promoError;

  /**
   * Selected account by investor account id.
   */
  const selectedAccount = useMemo(() => {
    const investorAccount = investorAccounts === null || investorAccounts === void 0 ? void 0 : investorAccounts.find(account => account.investorAccountId === parseInt(investorAccountId, 10));
    setInvestorName && setInvestorName(investorAccount === null || investorAccount === void 0 ? void 0 : investorAccount.name);
    return investorAccount;
  }, [investorAccountId, investorAccounts, setInvestorName]);
  const singleCapitalCallDetails = useMemo(() => {
    if (size(capitalCallUrlHashes) === 1 && size(investments) === 1) {
      const offering = offeringDetails === null || offeringDetails === void 0 ? void 0 : offeringDetails[capitalCallUrlHashes[0]].note;
      const {
        capitalDrawnPercentage,
        capitalDrawnStrategy
      } = offering;
      return {
        capitalDrawnPercentage,
        capitalDrawnStrategy
      };
    }
    return null;
  }, [capitalCallUrlHashes, investments, offeringDetails]);
  const iraFundingInstructions = useMemo(() => getIRAFundingInstructions(), [selectedAccount]);

  /**
   * Handle Disabled state
   */
  useEffect(() => {
    if (!setIsDisabled) {
      return;
    }
    if (!(createInvestmentResult !== null && createInvestmentResult !== void 0 && createInvestmentResult.isSuccess) || paymentInfoError || paymentInfoLoading) {
      setIsDisabled(true);
      if (createInvestmentResult !== null && createInvestmentResult !== void 0 && createInvestmentResult.isError && !disabledReason) {
        var _createInvestmentResu11, _createInvestmentResu12, _createInvestmentResu13, _failedResults$;
        const creationResults = (createInvestmentResult === null || createInvestmentResult === void 0 ? void 0 : (_createInvestmentResu11 = createInvestmentResult.error) === null || _createInvestmentResu11 === void 0 ? void 0 : (_createInvestmentResu12 = _createInvestmentResu11.response) === null || _createInvestmentResu12 === void 0 ? void 0 : (_createInvestmentResu13 = _createInvestmentResu12.data) === null || _createInvestmentResu13 === void 0 ? void 0 : _createInvestmentResu13.results) || [];
        const failedResults = creationResults.filter(item => !item.success);
        setDisabledReason === null || setDisabledReason === void 0 ? void 0 : setDisabledReason(failedResults === null || failedResults === void 0 ? void 0 : (_failedResults$ = failedResults[0]) === null || _failedResults$ === void 0 ? void 0 : _failedResults$.message);
      }
    } else {
      setIsDisabled(false);
      setDisabledReason === null || setDisabledReason === void 0 ? void 0 : setDisabledReason(null);
    }
  }, [setIsDisabled, paymentInfoError, disabledReason, paymentInfoLoading, setDisabledReason, createInvestmentResult, paymentMethod]);
  if (paymentInfo && primaryPaymentDetails) {
    if (primaryPaymentDetails.processOn.length !== 0) {
      const processOn = Yup.boolean().required('Required field: Payment Date.');
      updateValidation && updateValidation({
        processOn
      });
    }
  }
  const renderErrorHandler = useCallback(() => __jsx(ErrorHandler, {
    promoError: promoError,
    offeringInfo: offeringDetails,
    userState: userState,
    amount: totalAmount,
    timerExpired: timerExpired,
    paymentInfoError: paymentInfoError,
    investmentErrorCode: investmentError === null || investmentError === void 0 ? void 0 : investmentError.code,
    investmentErrorMessage: investmentError === null || investmentError === void 0 ? void 0 : investmentError.message,
    timerExpiredLink: timerExpiredLink()
  }), [promoError, totalAmount, investmentError, offeringDetails, paymentInfoError, timerExpired, userState, timerExpiredLink]);
  if (showErrorHandler) {
    return renderErrorHandler();
  }
  const isExternalIRA = (selectedAccount === null || selectedAccount === void 0 ? void 0 : selectedAccount.retirementAccount) && (selectedAccount === null || selectedAccount === void 0 ? void 0 : (_selectedAccount$prov = selectedAccount.provider) === null || _selectedAccount$prov === void 0 ? void 0 : _selectedAccount$prov.type) !== InvestorAccountProvider.YS;
  const selectedAccountEligible = !(selectedAccount !== null && selectedAccount !== void 0 && selectedAccount.gateData);
  const isYSWallet = !!primaryPaymentDetails && !(isWirePayment || isACHPayment);
  if (!loggedIn && !isOfferingSuccess) {
    return null;
  }
  const isSPVFund = offeringDetails && ((_offeringDetails$Obje = offeringDetails[Object.keys(offeringDetails)[0]]) === null || _offeringDetails$Obje === void 0 ? void 0 : (_offeringDetails$Obje2 = _offeringDetails$Obje.note) === null || _offeringDetails$Obje2 === void 0 ? void 0 : _offeringDetails$Obje2.legalEntityType) === LegalEntityType.FUND;
  const isMonthlyCadence = offeringDetails && ((_offeringDetails$Obje3 = offeringDetails[Object.keys(offeringDetails)[0]]) === null || _offeringDetails$Obje3 === void 0 ? void 0 : (_offeringDetails$Obje4 = _offeringDetails$Obje3.note) === null || _offeringDetails$Obje4 === void 0 ? void 0 : _offeringDetails$Obje4.cadence) === Cadence.MONTHLY;
  const shouldShowAlternativeCopy = pizzaTrackerSpvMonthlyCadenceCopy && isSPVFund && isMonthlyCadence;
  const rolloverInvestorEntityId = (_investorAccounts$fin = investorAccounts.find(investor => investor.investorAccountId.toString() === (rollover === null || rollover === void 0 ? void 0 : rollover.investorId))) === null || _investorAccounts$fin === void 0 ? void 0 : _investorAccounts$fin.investorEntityId.toString();
  return __jsx(React.Fragment, null, __jsx(Header, {
    amount: isCapitalCall ? totalCommittedAmount : totalAmount,
    title: offeringTitle,
    isCapitalCall: isCapitalCall
  }), __jsx(SContentBox, null, upfrontFundingDetails ? __jsx(UpfrontCommitmentBreakdown, {
    upfrontFundingDetails: upfrontFundingDetails
  }) : null, __jsx(FormikEffect, {
    formik: formikProps,
    onChange: _ref8 => {
      let {
        values
      } = _ref8;
      const {
        paymentMethod,
        additionalFundsPaymentMethod
      } = values;
      const primaryDetails = paymentMethod ? primaryPaymentMethods.find(_ref9 => {
        let {
          value
        } = _ref9;
        return paymentMethod === value;
      }) : null;
      handlePaymentMethodChange(primaryDetails);
      const additionalFundsDetails = additionalFundsPaymentMethod ? additionalFundsPaymentMethods.find(_ref10 => {
        let {
          value
        } = _ref10;
        return additionalFundsPaymentMethod === value;
      }) : null;
      handleAdditionalFundsPaymentMethodChange(additionalFundsDetails);
    }
  }), isRolloverV2 && rollover ? __jsx(RolloverSheet, _extends({
    amount: totalAmount,
    investorEntityId: rolloverInvestorEntityId,
    destinationUrlHash: offeringUrlHash
  }, rollover)) : __jsx(InvestorAccountSelection, {
    formikProps: formikProps,
    investorAccounts: investorAccounts,
    onInvestorChange: handleInvestorChange
  }), !isRolloverV2 && paymentInfoLoading && __jsx(Notification, {
    inline: true,
    "data-js-loading-payment-method-notification": true,
    description: "Loading payment methods for your selected investor account..."
  }), __jsx(CompleteInvestmentInner, {
    isRollover: isRolloverV2,
    rolloverNeedsAdditionalFunds: rolloverNeedsAdditionalFunds,
    rolloverAmount: maxRolloverAmount,
    showWalletBalance: showWalletBalance,
    investorAccountId: investorAccountId,
    paymentInfoLoading: paymentInfoLoading,
    paymentInfo: paymentInfo,
    isWirePayment: isWirePayment,
    isACHPayment: isACHPayment,
    promoState: promoState,
    primaryPaymentMethods: primaryPaymentMethods,
    hasPaymentMethodChoice: hasPaymentMethodChoice,
    formikProps: formikProps,
    totalAmount: totalAmount,
    selectedAccountEligible: selectedAccountEligible,
    isExternalIRA: isExternalIRA,
    iraFundingInstructions: iraFundingInstructions,
    isYSWallet: isYSWallet,
    hasInsufficientFunds: hasInsufficientFunds,
    additionalRolloverFundsDetails: additionalRolloverFundsDetails,
    totalCommittedAmount: totalCommittedAmount,
    singleCapitalCallDetails: singleCapitalCallDetails,
    isFreeInvestmentPromo: isFreeInvestmentPromo,
    hasAdditionalFundsPaymentMethodChoice: hasAdditionalFundsPaymentMethodChoice,
    additionalFundsPaymentDetails: additionalFundsPaymentDetails,
    additionalFundsPaymentMethods: additionalFundsPaymentMethods,
    shouldShowAlternativeCopy: shouldShowAlternativeCopy,
    upfrontFundingDetails: upfrontFundingDetails
  }), paymentInfoError && renderErrorHandler(), distributionReinvestments));
};
export default CompleteInvestment;