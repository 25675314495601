import _Box from "@mui/material/Box";
import _Stack from "@mui/material/Stack";
import _Typography from "@mui/material/Typography";
import _extends from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/extends.js";
var __jsx = React.createElement;
import React from 'react';
import Carousel from 'nuka-carousel';
import isEmpty from "lodash/isEmpty";
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { PageGrid, OfferingCard } from '@yieldstreet/ui-kit';
import { latestDealItems } from '../LatestDeals/LatestDeals';
import { items, newProductsConstants, testIds } from './NewInvestorsProducts.model';
import { ItemsWrapper, NewInvestorsProductsWrapper, SCarousel, Subtitle, TextWrapper } from './NewInvestorsProducts.style';
export const NewInvestorsProducts = _ref => {
  let {
    history,
    investorsProducts
  } = _ref;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));
  const handleOfferingsProducts = offeringsProducts => offeringsProducts.map(offering => {
    const url = `/offering/${offering.urlHash}/${offering.SEOTitle}`;
    return __jsx(OfferingCard, _extends({
      className: "offering-cards",
      imageUrl: offering.thumbnailImageUrl,
      key: offering.id,
      offering: offering,
      onClick: () => history.push(url),
      showSummary: true
    }, testIds.offeringsCard.attr));
  });
  return __jsx(PageGrid, _extends({
    config: {
      spacing: 20
    }
  }, testIds.base.attr), __jsx(NewInvestorsProductsWrapper, null, __jsx(TextWrapper, null, __jsx(_Typography, _extends({
    variant: "h4"
  }, testIds.heading.attr), newProductsConstants.heading), __jsx(Subtitle, _extends({
    variant: "body1"
  }, testIds.subtitle.attr), newProductsConstants.subtitle), __jsx(ItemsWrapper, testIds.itemsCarousel.attr, isMobile || isTablet ? __jsx(Carousel, {
    cellSpacing: 10,
    renderBottomCenterControls: null,
    renderCenterLeftControls: null,
    renderCenterRightControls: null,
    slidesToShow: isMobile ? 1.2 : 1.7
  }, latestDealItems(items)) : latestDealItems(items, true))), !isEmpty(investorsProducts) && __jsx(_Box, testIds.offeringsCarousel.attr, isMobile || isTablet ? __jsx(SCarousel, {
    cellSpacing: 5,
    slidesToShow: isMobile ? 1.2 : 1.7,
    renderCenterRightControls: null,
    renderCenterLeftControls: null
  }, handleOfferingsProducts(investorsProducts)) : __jsx(_Stack, {
    direction: "row",
    gap: 5
  }, handleOfferingsProducts(investorsProducts)))));
};