import { createYupObjectSchema } from '@yieldstreet/tool-kit';
export const EDIT_PHONE_MODAL = 'showModalPhoneNumber';
export const modalTitle = 'Edit phone number';
export const phoneInput = 'Phone number';
export const closeButton = 'Close';
export const submitButton = 'Update phone';
export const TEST_IDS = {
  modal: 'edit-phone-modal',
  modalHeader: 'edit-phone-number-header',
  submitButton: 'edit-phone-number-submit-button'
};
export const EditPhoneSchema = createYupObjectSchema({
  phoneNumber: {
    validationType: 'phone'
  }
});