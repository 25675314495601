import { genTestIds } from '@yieldstreet/tool-kit';
export const Copy = {
  title: 'Rollover details',
  investorAccount: 'Investor account:',
  rolloverFrom: 'Rollover from:',
  investmentAmount: 'Total investment amount',
  rolloverAmount: 'Available rollover amount',
  additionalFunds: 'Additional funds required ',
  disclaimer: `*Your rollover to {{note}} will be initiated once we process the final payment from your current investment.`
};
const {
  getTestIds
} = genTestIds(['title', 'investor-account', 'investor-account-value', 'rollover-from', 'rollover-from-value', 'investment-amount', 'investment-amount-value', 'rollover-amount', 'rollover-amount-value', 'additional-funds', 'additional-funds-value', 'disclaimer']);
export const testIds = getTestIds('rollover-payment');