import _objectSpread from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import { getIn } from 'formik';
import { fieldToComponent } from '../fieldToComponent/fieldToComponent.util';
import omit from "lodash/omit";
export function fieldToTextField(props) {
  //https://mui.com/material-ui/react-text-field/#integration-with-3rd-party-input-libraries

  const value = getIn(props.formikProps.values, props.name);
  const filteredProps = omit(props, ['formikProps', 'helperText', 'slotProps']);
  return _objectSpread(_objectSpread({}, fieldToComponent(props)), {}, {
    value,
    onChange: props.formikProps.handleChange
  }, filteredProps);
}