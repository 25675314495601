import _Typography from "@mui/material/Typography";
var __jsx = React.createElement;
import React from 'react';
import { ArticleCard, Col, PageGrid, Row } from '@yieldstreet/ui-kit';
import { RouterLink } from '@yieldstreet/ui-kit/navigation';
import { RESOURCES } from './RecommendedResources.model';
const Card = _ref => {
  let {
    resource
  } = _ref;
  const {
    testId,
    title,
    description,
    ctaLink,
    image,
    mini
  } = resource;
  return __jsx(ArticleCard, {
    testId: testId,
    title: title,
    description: description,
    image: image,
    cta: __jsx(RouterLink, {
      id: testId,
      to: ctaLink
    }, "Continue reading"),
    mini: mini
  });
};
const DefaultView = () => __jsx(Row, null, __jsx(Col, {
  size: {
    desktop: 6,
    tablet: 6
  }
}, __jsx(Card, {
  resource: RESOURCES.EVALUATING_ALTERNATIVE_INVESTMENT_CARD
})), __jsx(Col, {
  size: {
    desktop: 6,
    tablet: 6
  }
}, __jsx(Row, null, __jsx(Col, null, __jsx(Card, {
  resource: RESOURCES.WHY_ALTERNATIVES
})), __jsx(Col, null, __jsx(Card, {
  resource: RESOURCES.ZERO_INTEREST_RATE_MARKET_CARD
})))));
const ExperiencedView = () => __jsx(Row, null, __jsx(Col, {
  size: {
    desktop: 6,
    tablet: 6
  }
}, __jsx(Card, {
  resource: RESOURCES.EVALUATING_COLLATERAL_CARD
})), __jsx(Col, {
  size: {
    desktop: 6,
    tablet: 6
  }
}, __jsx(Row, null, __jsx(Col, null, __jsx(Card, {
  resource: RESOURCES.ZERO_INTEREST_RATE_MARKET_CARD
})), __jsx(Col, null, __jsx(Card, {
  resource: RESOURCES.SOURCING_AND_ORIGINATION_PARTNERS_CARD
})))));
export const RecommendedResources = _ref2 => {
  let {
    userAudiences: {
      altExperienced
    }
  } = _ref2;
  return __jsx(PageGrid, {
    config: {
      spacing: 20
    },
    "data-cy": "module-recommended-resources"
  }, __jsx(Row, null, __jsx(Col, null, __jsx(_Typography, {
    component: "h4",
    variant: "h4",
    "data-cy": "module-title"
  }, "Learn more about alternatives"))), altExperienced ? __jsx(ExperiencedView, null) : __jsx(DefaultView, null));
};