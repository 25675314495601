import { theme2 } from '@yieldstreet/ui-kit';
import { ManagedPortfolioAssetTypeLabels, ManagedPortfolioAssetTypes } from '@yieldstreet/platform-kit';
import { formatPercentageDecimal } from '@yieldstreet/tool-kit';
const colorByAssetClass = {
  [ManagedPortfolioAssetTypes.CASH]: theme2.colors.chart_15,
  [ManagedPortfolioAssetTypes.CREDIT]: theme2.colors.chart_700,
  [ManagedPortfolioAssetTypes.EQUITY]: theme2.colors.chart_800
};
export const getChartConfig = (data, inverse, isMobile) => {
  const assetClasses = data === null || data === void 0 ? void 0 : data.map(item => item[0]);
  const colors = assetClasses === null || assetClasses === void 0 ? void 0 : assetClasses.map(ac => colorByAssetClass[ac]);
  const chartData = data === null || data === void 0 ? void 0 : data.map(item => [ManagedPortfolioAssetTypeLabels[item[0]], formatPercentageDecimal(item[1], 2)]);
  return {
    chart: {
      type: 'pie',
      height: 273,
      spacing: [10, 5, 30, 5],
      backgroundColor: 'transparent',
      style: {
        fontFamily: theme2.typography.primary
      }
    },
    title: {
      text: null
    },
    legend: {
      align: 'center',
      verticalAlign: 'bottom',
      layout: 'horizontal',
      enabled: true,
      squareSymbol: true,
      symbolHeight: 11,
      symbolWidth: 11,
      symbolRadius: 4,
      itemDistance: isMobile ? 8 : 20,
      itemStyle: {
        color: inverse ? theme2.colors.text_label_inverse : theme2.colors.text_label,
        lineHeight: '14px',
        fontWeight: '400'
      },
      itemHoverStyle: {
        color: inverse ? theme2.colors.text_label_inverse : theme2.colors.text_label
      },
      labelFormatter: function () {
        return `${this.name} ${this.y}%`;
      },
      x: 0,
      y: 0,
      navigation: {
        enabled: false
      }
    },
    credits: {
      enabled: false
    },
    tooltip: {},
    plotOptions: {
      series: {
        borderWidth: 0,
        colorByPoint: true,
        type: 'pie',
        size: '100%',
        innerSize: '50%',
        dataLabels: {
          enabled: false
        },
        tooltip: {
          valueSuffix: '%'
        },
        enableMouseTracking: false
      },
      pie: {
        showInLegend: true,
        allowPointSelect: false,
        states: {
          inactive: {
            opacity: 0.8
          }
        },
        point: {
          events: {
            legendItemClick: function (e) {
              e.preventDefault();
            }
          }
        }
      }
    },
    colors,
    series: [{
      name: 'Allocation',
      data: chartData
    }]
  };
};