import _Stack from "@mui/material/Stack";
import _Box from "@mui/material/Box";
import _Skeleton from "@mui/material/Skeleton";
var __jsx = React.createElement;
import React from 'react';
import { NumericFormat } from 'react-number-format';
import { PortfolioCard, PortfolioCardBody, PortfolioCardItem, PortfolioCardLinkWrapper, PortfolioCardTitle } from '../../../../../../portfolio/common/PortfolioCard';
import { LatestDealsListHeadings } from '../../../LatestDealsList.model';
export const LatestDealsListCard = _ref => {
  let {
    data,
    isLoading
  } = _ref;
  const handleLink = data !== null && data !== void 0 && data.noteUrlHash ? `/offering/${data === null || data === void 0 ? void 0 : data.noteUrlHash}` : '';
  return __jsx(PortfolioCardLinkWrapper, {
    to: handleLink
  }, __jsx(PortfolioCard, null, __jsx(_Stack, {
    direction: "row",
    sx: {
      p: 4,
      alignItems: 'center'
    }
  }, isLoading ? __jsx(React.Fragment, null, __jsx(_Skeleton, {
    variant: "rectangular",
    width: 48,
    height: 48,
    sx: {
      mr: 4
    }
  }), __jsx(_Skeleton, {
    variant: "text",
    width: "100%"
  })) : __jsx(React.Fragment, null, __jsx(_Box, {
    component: "img",
    src: data === null || data === void 0 ? void 0 : data.thumbnail,
    sx: {
      borderRadius: 1,
      mr: 4,
      height: 48
    }
  }), __jsx(PortfolioCardTitle, null, data === null || data === void 0 ? void 0 : data.title))), __jsx(PortfolioCardBody, null, __jsx(PortfolioCardItem, {
    isLoading: isLoading,
    label: LatestDealsListHeadings.AssetClass
  }, data === null || data === void 0 ? void 0 : data.assetClass), __jsx(PortfolioCardItem, {
    isLoading: isLoading,
    label: LatestDealsListHeadings.MinimumInvestment
  }, __jsx(NumericFormat, {
    value: data === null || data === void 0 ? void 0 : data.minimumAmount,
    displayType: 'text',
    thousandSeparator: true,
    prefix: '$'
  })), __jsx(PortfolioCardItem, {
    isLoading: isLoading,
    label: LatestDealsListHeadings.Term
  }, data === null || data === void 0 ? void 0 : data.term))));
};