export const TOTAL_STEPS = 5;
export const dontKnowLabel = 'I don’t know yet';
export const testIds = {
  questionsCurrencyTextInput: 'quiz-goals-currency-text-input',
  questionsFormRadioGroupInput: 'quiz-goals-radio-group-input',
  questionsFormCheckboxInput: 'quiz-goals-checkbox-input',
  desktopView: 'goals-module-desktop',
  mobileView: 'goals-module-mobile',
  prevButton: 'goals-question-prev-question-button',
  title: 'goals-question-title'
};