var __jsx = React.createElement;
import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { resetResetPwErrors } from 'store/actions/user';
import UserProfile from './UserProfile';
import { PageLoader } from '@yieldstreet/ui-kit/layouts';
import { userProfileApi, userBeneficiariesApi, userAddressApi, userApi, allToken, twoFactorAuthApi } from '@yieldstreet/platform-kit';
import { useApiErrorMessage } from '@yieldstreet/tool-kit';
import { useModalContext } from '@yieldstreet/ui-kit';
const initialModalState = {
  showModalRemoveBeneficiaries: false,
  showModalBeneficiaries: false
};
const UserProfileContainer = () => {
  var _userProfile$profile2, _userProfile$profile3, _userProfile$profile4;
  const {
    isModalVisible
  } = useModalContext();
  const {
    user
  } = useSelector(state => state);
  const dispatch = useDispatch();
  const {
    0: modals,
    1: setModals
  } = useState(initialModalState);
  const {
    useGetUserStateQuery
  } = userApi;
  const {
    useGetUserProfileQuery,
    useUpdateUserProfileMutation
  } = userProfileApi;
  const {
    useGetUserAddressQuery
  } = userAddressApi;
  const {
    useDeleteUserBeneficiaryMutation
  } = userBeneficiariesApi;
  const {
    data: userProfile,
    isLoading
  } = useGetUserProfileQuery();
  const {
    data: userAddress,
    isLoading: isAddressLoading
  } = useGetUserAddressQuery({
    userId: user.userState.id
  });
  const {
    data: userState,
    isLoading: isUserStateLoading
  } = useGetUserStateQuery(allToken);
  const twoFaStatusState = twoFactorAuthApi.useStatusQuery();
  const {
    data: {
      status: twoFAStatus
    } = {},
    isLoading: twoFAStatusFetching
  } = twoFaStatusState || {};
  const twoFAStatusError = useApiErrorMessage(twoFaStatusState);
  const [updateUserProfile] = useUpdateUserProfileMutation();
  const [deleteUserBeneficiary] = useDeleteUserBeneficiaryMutation();
  const showModal = modal => setModals({
    [modal]: true
  });
  const hideModal = useCallback(modal => {
    setModals({
      [modal]: false
    });
  }, [setModals]);
  const toggleSMS = () => {
    var _userProfile$profile;
    updateUserProfile({
      userId: user.userState.id,
      data: {
        smsEnabled: !((_userProfile$profile = userProfile.profile) !== null && _userProfile$profile !== void 0 && _userProfile$profile.smsEnabled)
      }
    });
  };
  const deleteBeneficiary = id => {
    deleteUserBeneficiary({
      userId: id
    });
  };
  const hasEmailVerified = useMemo(() => {
    var _user$userState;
    return (((_user$userState = user.userState) === null || _user$userState === void 0 ? void 0 : _user$userState.roles) || []).includes('investor.email.verified');
  }, [user.userState]);
  useEffect(() => {
    dispatch(resetResetPwErrors());
  }, [user.resetPwError, dispatch]);
  return (isLoading || isAddressLoading || isUserStateLoading) && !isModalVisible ? __jsx(PageLoader, {
    testId: "user-profile-loader"
  }) : __jsx(UserProfile, {
    userState: userState,
    resetPw: user.resetPw,
    twoFAStatus: twoFAStatus,
    hasPassword: userProfile === null || userProfile === void 0 ? void 0 : userProfile.hasPassword,
    twoFAStatusError: twoFAStatusError,
    twoFAStatusFetching: twoFAStatusFetching,
    primaryBeneficiary: userProfile === null || userProfile === void 0 ? void 0 : userProfile.primaryBeneficiary,
    isSMSToggleOn: userProfile === null || userProfile === void 0 ? void 0 : (_userProfile$profile2 = userProfile.profile) === null || _userProfile$profile2 === void 0 ? void 0 : _userProfile$profile2.smsEnabled,
    contingentBeneficiary: userProfile === null || userProfile === void 0 ? void 0 : userProfile.contingentBeneficiary,
    hasEmailVerified: hasEmailVerified,
    showBeneficiary: userProfile === null || userProfile === void 0 ? void 0 : userProfile.showBeneficiary,
    showOldModal: showModal,
    hideOldModal: hideModal,
    showModalRemoveBeneficiaries: modals.showModalRemoveBeneficiaries,
    showModalBeneficiaries: modals.showModalBeneficiaries,
    toggleSMS: toggleSMS,
    deleteBeneficiary: deleteBeneficiary,
    userAddress: userAddress === null || userAddress === void 0 ? void 0 : userAddress.address,
    userPhone: {
      raw: userProfile === null || userProfile === void 0 ? void 0 : (_userProfile$profile3 = userProfile.profile) === null || _userProfile$profile3 === void 0 ? void 0 : _userProfile$profile3.phoneRaw,
      phone: userProfile === null || userProfile === void 0 ? void 0 : (_userProfile$profile4 = userProfile.profile) === null || _userProfile$profile4 === void 0 ? void 0 : _userProfile$profile4.phone
    }
  });
};
export default UserProfileContainer;