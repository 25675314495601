import _objectSpread from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import _objectWithoutProperties from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/objectWithoutProperties.js";
const _excluded = ["onBlur", "onChange"],
  _excluded2 = ["disabled", "field", "form", "onClose"];
//https://github.com/stackworx/formik-mui/blob/main/packages/formik-mui/src/Select.tsx

import { getIn } from 'formik';
export function fieldToSelect(_ref) {
  let {
      disabled,
      field: {
        onBlur: _onBlur,
        onChange
      },
      form: {
        isSubmitting,
        touched,
        errors,
        setFieldTouched,
        setFieldValue
      },
      onClose
    } = _ref,
    field = _objectWithoutProperties(_ref.field, _excluded),
    props = _objectWithoutProperties(_ref, _excluded2);
  const fieldError = getIn(errors, field.name);
  const showError = getIn(touched, field.name) && !!fieldError;
  return _objectSpread(_objectSpread({
    disabled: disabled !== null && disabled !== void 0 ? disabled : isSubmitting,
    error: showError,
    formError: showError ? fieldError : undefined,
    onBlur: () => {
      // no-op
    },
    onChange: onChange,
    // we must use `onClose` instead of `onChange` to be able to trigger validation when users click outside of the select list.
    onClose: onClose !== null && onClose !== void 0 ? onClose : async e => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const dataset = e.target.dataset;
      if (dataset && dataset.value) {
        // out-of-sync issue since November 2019: https://github.com/formium/formik/issues/2059#issuecomment-890613538
        // without the await, formik validates with the former value
        await setFieldValue(field.name, dataset.value, false);
      }
      setFieldTouched(field.name, true, true);
    }
  }, field), props);
}