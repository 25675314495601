import _extends from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/extends.js";
var __jsx = React.createElement;
import React, { useCallback, useMemo, useEffect } from 'react';
import { getIn } from 'formik';
import { FormikTextInput } from '../FormikTextInput';
import { AddressFields } from './FormikAddressInputGroup.model';
import { useTestIds } from './FormikAddressInputGroup.utils';
import { StateSelection } from './StateSelection/StateSelection';
import { Flex } from './FormikAddressInputGroup.style';
import { FormikAddressInput } from '../FormikAddressInput/FormikAddressInput';
import { getGoogleFieldValue, getAddressOptionLabel } from '../FormikAddressInput/FormikAddressInput.utils';
export const FormikAddressInputGroup = _ref => {
  let {
    apiKey,
    label,
    formikProps,
    namePrefix = '',
    nameMap = {},
    backendError = {},
    allowWorldwide = false,
    disabled = false,
    testId
  } = _ref;
  const testIds = useTestIds({
    testId: testId || namePrefix
  });
  const getFieldName = useCallback(name => `${namePrefix ? `${namePrefix}.` : ''}${nameMap[name] || name}`, [nameMap, namePrefix]);
  const getFieldValue = useCallback(name => getIn(formikProps.values, getFieldName(name)), [getFieldName, formikProps]);
  const setFieldValue = useCallback((name, value) => formikProps.setFieldValue(getFieldName(name), value), [getFieldName, formikProps]);
  const getCity = value => {
    return getGoogleFieldValue(value, 'locality') || getGoogleFieldValue(value, 'sublocality_level_1') || getGoogleFieldValue(value, 'administrative_area_level_2');
  };
  const onFillAutocomplete = useCallback(value => {
    const address = getAddressOptionLabel(value);
    const city = getCity(value);
    const country = getGoogleFieldValue(value, 'country');
    const zip = getGoogleFieldValue(value, 'postal_code');
    const state = getGoogleFieldValue(value, 'administrative_area_level_1');
    setFieldValue(AddressFields.ADDRESS_LINE_1, address);
    setFieldValue(AddressFields.CITY, city);
    setFieldValue(AddressFields.POSTAL_CODE, zip);
    setFieldValue(AddressFields.STATE, state);
    setFieldValue(AddressFields.COUNTRY, country);
  }, [setFieldValue]);
  useEffect(() => {
    if (!allowWorldwide && getFieldValue(AddressFields.COUNTRY) !== 'US') {
      setFieldValue(AddressFields.COUNTRY, 'US');
    }
  }, [getFieldValue, setFieldValue, allowWorldwide]);
  const backendErrors = useMemo(() => {
    const getError = field => {
      var _backendError$getFiel, _backendError$getFiel2;
      return (_backendError$getFiel = backendError === null || backendError === void 0 ? void 0 : (_backendError$getFiel2 = backendError[getFieldName(field)]) === null || _backendError$getFiel2 === void 0 ? void 0 : _backendError$getFiel2[0]) !== null && _backendError$getFiel !== void 0 ? _backendError$getFiel : undefined;
    };
    const address = getError(AddressFields.ADDRESS_LINE_1);
    const address2 = getError(AddressFields.ADDRESS_LINE_2);
    const city = getError(AddressFields.CITY);
    const state = getError(AddressFields.STATE);
    const postalCode = getError(AddressFields.POSTAL_CODE);
    const country = getError(AddressFields.COUNTRY);
    return {
      address,
      address2,
      city,
      state,
      postalCode,
      country
    };
  }, [backendError, getFieldName]);
  return __jsx(React.Fragment, null, __jsx(FormikAddressInput, _extends({
    apiKey: apiKey,
    name: getFieldName(AddressFields.ADDRESS_LINE_1),
    label: label !== null && label !== void 0 ? label : 'Address',
    formikProps: formikProps,
    onChange: value => {
      onFillAutocomplete(value);
    },
    helperText: backendErrors.address,
    sx: {
      mb: 2
    }
  }, testIds[AddressFields.ADDRESS_LINE_1].attr)), __jsx(FormikTextInput, _extends({
    name: getFieldName(AddressFields.ADDRESS_LINE_2),
    label: "Address (cont)",
    placeholder: "Apartment, suite, building, floor, etc. (Optional)",
    formikProps: formikProps,
    helperText: backendErrors.address2,
    fullWidth: true,
    disabled: disabled,
    sx: {
      mb: 3
    }
  }, testIds[AddressFields.ADDRESS_LINE_2].attr)), __jsx(FormikTextInput, _extends({
    name: getFieldName(AddressFields.CITY),
    label: "City",
    formikProps: formikProps,
    fullWidth: true,
    disabled: disabled,
    sx: {
      mb: 3
    }
  }, testIds[AddressFields.CITY].attr)), __jsx(Flex, {
    sx: {
      gap: 3,
      mb: 3
    }
  }, __jsx(StateSelection, {
    country: getFieldValue(AddressFields.COUNTRY),
    formikProps: formikProps,
    formSelectProps: {
      name: getFieldName(AddressFields.STATE),
      helperText: backendErrors.state
    },
    formTextInputProps: {
      name: getFieldName(AddressFields.STATE),
      helperText: backendErrors.state
    },
    testId: testIds[AddressFields.STATE].attr,
    disabled: disabled
  }), __jsx(FormikTextInput, _extends({
    name: getFieldName(AddressFields.POSTAL_CODE),
    label: "Zip code",
    formikProps: formikProps,
    helperText: backendErrors.postalCode
  }, testIds[AddressFields.POSTAL_CODE].attr, {
    fullWidth: true,
    disabled: disabled
  }))), allowWorldwide && __jsx(FormikTextInput, _extends({
    name: getFieldName(AddressFields.COUNTRY),
    label: "Country",
    formikProps: formikProps,
    helperText: backendErrors.country
  }, testIds[AddressFields.COUNTRY].attr, {
    fullWidth: true,
    disabled: disabled
  })));
};