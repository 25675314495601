var __jsx = React.createElement;
import React from 'react';
import { SignupQuizSteps } from '@yieldstreet/platform-kit';
import { SignupInterestedLabels, SignupInterestedOptions } from '@yieldstreet/tool-kit';
import { SignupQuizStep } from '../../SignupQuizStep';
import { stepTestId } from './InterestedStep.model';
export const InterestedStep = _ref => {
  let {
    nextStep,
    saveQuizAnswer,
    getQuizAnswer
  } = _ref;
  const answer = getQuizAnswer(SignupQuizSteps.INTERESTED_ASSET_CLASSES, true);
  return __jsx(SignupQuizStep, {
    answer: answer,
    asChip: true,
    getQuizAnswer: getQuizAnswer,
    multiple: true,
    nextStep: nextStep,
    options: SignupInterestedOptions,
    saveQuizAnswer: saveQuizAnswer,
    stepKey: SignupQuizSteps.INTERESTED_ASSET_CLASSES,
    testId: stepTestId,
    title: SignupInterestedLabels.title
  });
};