import _Alert from "@mui/material/Alert";
import _Link from "@mui/material/Link";
import _Typography from "@mui/material/Typography";
import _objectSpread from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import _objectWithoutProperties from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/objectWithoutProperties.js";
const _excluded = ["primaryPaymentDetails", "investments"];
var __jsx = React.createElement;
import React, { useMemo, useEffect, useState, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { offeringApi, investmentRequestOfferingsApi, AudiencePresetTypes } from '@yieldstreet/platform-kit';
import { PageContainer, PageLoader } from '@yieldstreet/ui-kit/layouts';
import { Formik, Form } from 'formik';
import find from "lodash/find";
import forEach from "lodash/forEach";
import map from "lodash/map";
import * as Yup from 'yup';
import { useAudiences, useGetCheckboxes } from '@yieldstreet/tool-kit';
import { PRE_INVEST_ACCOUNT_SETUP_NEEDED_PATH } from 'pages/preInvestAccountSetup';
import { ADVICE_RECOMMENDATIONS_PATH } from '../advice';
import BulkAcknowledge from './BulkAcknowledge/BulkAcknowledge';
import CompleteInvestment from './CompleteInvestment';
import { COMPLETE_INVESTMENT_VALIDATION_SCHEMA, COMPLETE_INVESTMENT_INITIAL_VALUES, COMPLETE_INVESTMENT_STATUS_CODE, COMPLETE_INVESTMENT_STATUS_META } from './CompleteInvestment.model';
import { ButtonContainer, SubmitButton, CancelButton, RightButton } from './CompleteInvestment.style';
import ErrorHandler from './ErrorHandler';
import Signature from './Signature';
import { SIGNATURE_INITIAL_VALUES, SIGNATURE_VALIDATION_SCHEMA } from './Signature/Signature.model';
import { onCompleteInvestments } from './utils/investmentThirdPartyCode';
const BulkCompleteInvestmentPage = () => {
  const {
    push,
    location: {
      state
    }
  } = useHistory();
  const {
    0: investorName,
    1: setInvestorName
  } = useState(null);
  const {
    0: totalFundedNowAmount,
    1: setTotalFundedNowAmount
  } = useState(0);
  const {
    0: investmentAmountsByUrlHash,
    1: setInvestmentAmountsByUrlHash
  } = useState({});
  const {
    0: isCompleteInvestmentStatus,
    1: setIsCompleteInvestmentStatus
  } = useState(null);
  const {
    audiencesData
  } = useAudiences(AudiencePresetTypes.ANALYTICS);
  if (!(state !== null && state !== void 0 && state.preInvestmentState)) {
    push(ADVICE_RECOMMENDATIONS_PATH);
  }

  /** @see useGetInvestmentEligibility.js utils/investmentEligibility/useGetInvestmentEligibility.js */
  const {
    urlHashes,
    investments,
    investmentType
  } = (state === null || state === void 0 ? void 0 : state.preInvestmentState) || {};

  /**
   * Check if user is gated and redirect to gate handling page
   */

  const isErrorAndGated = (isCompleteInvestmentStatus === null || isCompleteInvestmentStatus === void 0 ? void 0 : isCompleteInvestmentStatus.code) === COMPLETE_INVESTMENT_STATUS_CODE.ERROR && (isCompleteInvestmentStatus === null || isCompleteInvestmentStatus === void 0 ? void 0 : isCompleteInvestmentStatus.meta) === COMPLETE_INVESTMENT_STATUS_META.GATED;
  if (isErrorAndGated) {
    push(PRE_INVEST_ACCOUNT_SETUP_NEEDED_PATH, {
      preInvestmentState: state.preInvestmentState
    });
  }
  const {
    0: submitSignError,
    1: setSubmitSignError
  } = useState(null);
  const {
    0: validation,
    1: setValidation
  } = useState(null);
  const {
    0: completeInvestmentDisabled,
    1: setCompleteInvestmentDisabled
  } = useState(null);
  const {
    0: completeInvestmentDisabledReason,
    1: setCompleteInvestmentDisabledReason
  } = useState(null);
  const {
    data: offeringDetails
  } = offeringApi.useDetailsQuery(urlHashes);
  const isLoading = !offeringDetails;
  const [submitInvestment, {
    data: bulkSignResponse,
    originalArgs
  }] = investmentRequestOfferingsApi.useBulkSignMutation();
  useEffect(() => {
    if (!bulkSignResponse) {
      return;
    }
    if (bulkSignResponse !== null && bulkSignResponse !== void 0 && bulkSignResponse.errors) {
      setSubmitSignError(true);
      return;
    }
    const investmentSubscriptions = map(bulkSignResponse === null || bulkSignResponse === void 0 ? void 0 : bulkSignResponse.results, _ref => {
      let {
        investment = {}
      } = _ref;
      const {
        investmentId,
        noteUrlHash
      } = investment;
      return {
        investmentId,
        noteUrlHash
      };
    });
    push({
      pathname: '/bulk-investment-result',
      state: {
        investmentSubscriptions,
        investorName,
        totalInvestmentAmount: totalFundedNowAmount
      }
    });
  }, [bulkSignResponse, push, investorName, originalArgs, totalFundedNowAmount]);
  const disableSubmit = completeInvestmentDisabled; //FIXME get correct handle
  const investmentRequestLoading = false; //FIXME

  const submit = useCallback(async values => {
    var _audiencesData$audien, _audiencesData$audien2;
    const {
        primaryPaymentDetails,
        investments
      } = values,
      formData = _objectWithoutProperties(values, _excluded);
    const disclaimerEl = document.getElementById('complete-investment-disclaimer');
    const requests = [];
    forEach(investments, (investment, key) => {
      var _values$dripDataPerOf;
      const dripOfferingConfig = (_values$dripDataPerOf = values.dripDataPerOffering) === null || _values$dripDataPerOf === void 0 ? void 0 : _values$dripDataPerOf[key];
      const noteUrlHash = investment === null || investment === void 0 ? void 0 : investment.noteUrlHash;
      const customVariables = _objectSpread(_objectSpread({}, dripOfferingConfig && {
        reInvest: (dripOfferingConfig === null || dripOfferingConfig === void 0 ? void 0 : dripOfferingConfig.configPresent) && (dripOfferingConfig === null || dripOfferingConfig === void 0 ? void 0 : dripOfferingConfig.reInvest)
      }), {}, {
        investmentAmount: investmentAmountsByUrlHash === null || investmentAmountsByUrlHash === void 0 ? void 0 : investmentAmountsByUrlHash[noteUrlHash]
      });
      const investmentRequestDetails = _objectSpread(_objectSpread({}, formData), {}, {
        processOn: values.processOn ? 2 : 0,
        // Checkbox do delay the process by 2 days, or 0 days
        paymentMethod: primaryPaymentDetails.paymentMethod,
        bankAccountId: values === null || values === void 0 ? void 0 : values.bankAccountId,
        disclaimer: disclaimerEl ? disclaimerEl.innerText : '',
        customVariables,
        noteUrlHash,
        investmentId: investment === null || investment === void 0 ? void 0 : investment.id
      });
      requests.push(investmentRequestDetails);
    });
    await submitInvestment({
      requests
    });
    onCompleteInvestments({
      investments,
      context: {
        firstTimeInvestor: audiencesData === null || audiencesData === void 0 ? void 0 : (_audiencesData$audien = audiencesData.audiences) === null || _audiencesData$audien === void 0 ? void 0 : (_audiencesData$audien2 = _audiencesData$audien.FTI_YS) === null || _audiencesData$audien2 === void 0 ? void 0 : _audiencesData$audien2.active
      }
    });
    localStorage.removeItem('preInvestmentPending');
  }, [submitInvestment, investmentAmountsByUrlHash, audiencesData]);
  const {
    externalCheckboxes,
    checkboxValidationSchema,
    generalCheckboxes
  } = useGetCheckboxes(offeringDetails, urlHashes, investmentType, true);
  const {
    validationSchema,
    initialValues
  } = useMemo(() => {
    if (!checkboxValidationSchema) {
      return {
        validationSchema: null,
        initialValues: null
      };
    }
    const validationSchema = _objectSpread(_objectSpread(_objectSpread({}, SIGNATURE_VALIDATION_SCHEMA), COMPLETE_INVESTMENT_VALIDATION_SCHEMA), checkboxValidationSchema === null || checkboxValidationSchema === void 0 ? void 0 : checkboxValidationSchema.validationSchema);
    const initialValues = _objectSpread(_objectSpread(_objectSpread({}, SIGNATURE_INITIAL_VALUES), COMPLETE_INVESTMENT_INITIAL_VALUES), checkboxValidationSchema === null || checkboxValidationSchema === void 0 ? void 0 : checkboxValidationSchema.initialValues);
    return {
      validationSchema,
      initialValues
    };
  }, [checkboxValidationSchema]);
  useEffect(() => {
    if (validationSchema && !validation) {
      setValidation(validationSchema);
    }
  }, [validationSchema, setValidation, validation]);
  const {
    0: page,
    1: setPage
  } = useState(1);
  const title = page === 1 ? 'Complete investment' : 'Acknowledge and sign';
  const handleContinueButton = formikProps => {
    const validationKeys = Object.keys(COMPLETE_INVESTMENT_VALIDATION_SCHEMA);
    const hasErrors = find(validationKeys, x => {
      var _formikProps$errors;
      return formikProps === null || formikProps === void 0 ? void 0 : (_formikProps$errors = formikProps.errors) === null || _formikProps$errors === void 0 ? void 0 : _formikProps$errors[x];
    });
    if (!hasErrors) {
      formikProps.setErrors({});
      setPage(2);
    }
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [page]);
  const handleValidationChange = value => {
    setValidation(_objectSpread(_objectSpread({}, validation), value));
  };
  return __jsx(PageContainer, {
    testId: "bulk-complete-investment-page"
  }, isLoading ? __jsx(PageLoader, null) : __jsx(React.Fragment, null, !isErrorAndGated && __jsx(_Typography, {
    variant: "h3"
  }, title), completeInvestmentDisabledReason && __jsx(_Alert, {
    severity: "error"
  }, __jsx(_Typography, {
    variant: "body1"
  }, completeInvestmentDisabledReason, " Please", ' ', __jsx(_Link, {
    id: "restriction-notification",
    href: "mailto:investments@yieldstreet.com"
  }, "contact our Investor Relations team"), ' ', "if you think this is a mistake.")), __jsx(Formik, {
    initialValues: initialValues,
    validationSchema: Yup.object().shape(validation),
    onSubmit: values => submit(values),
    render: formikProps => {
      return __jsx(Form, null, page === 1 && __jsx(React.Fragment, null, __jsx(CompleteInvestment, {
        investments: investments,
        investmentType: investmentType,
        formikProps: formikProps,
        setIsDisabled: setCompleteInvestmentDisabled,
        disabledReason: completeInvestmentDisabledReason,
        setDisabledReason: setCompleteInvestmentDisabledReason,
        setInvestorName: setInvestorName,
        setTotalFundedNowAmount: setTotalFundedNowAmount,
        setInvestmentAmountsByUrlHash: setInvestmentAmountsByUrlHash,
        setStatus: setIsCompleteInvestmentStatus,
        status: isCompleteInvestmentStatus,
        updateValidation: handleValidationChange
      }), !isErrorAndGated && __jsx(ButtonContainer, null, __jsx(CancelButton, {
        id: "complete-cancelBtn",
        type: "button",
        variant: "outlined",
        "data-cy": "ui-kit-button-container-complete-investment-cancel",
        "data-trackingId": "complete-investment-cancel",
        onClick: () => push(ADVICE_RECOMMENDATIONS_PATH)
      }, "Cancel"), __jsx(RightButton, {
        "data-cy": "ui-kit-button-container-complete-investment-submit",
        id: "complete-continueBtn",
        "data-trackingId": "complete-investment-continue",
        type: "primary",
        disabled: disableSubmit,
        onClick: () => handleContinueButton(formikProps)
      }, "Continue"))), page === 2 && __jsx(React.Fragment, null, __jsx(BulkAcknowledge, {
        formikProps: formikProps,
        offeringDetails: offeringDetails,
        externalCheckboxes: externalCheckboxes,
        generalCheckboxes: generalCheckboxes
      }), __jsx(Signature, {
        formikProps: formikProps
      }), __jsx(ButtonContainer, null, __jsx(CancelButton, {
        "data-cy": "ui-kit-button-container-complete-investment-cancel",
        id: "complete-backBtn",
        type: "button",
        variant: "outlined",
        "data-trackingId": "complete-investment-back",
        onClick: () => setPage(1)
      }, "Back"), __jsx(SubmitButton, {
        "data-cy": "ui-kit-button-container-complete-investment-submit",
        id: "complete-submitBtn",
        "data-trackingId": "complete-investment-submit",
        type: "submit",
        loading: investmentRequestLoading
      }, "Submit investment")), submitSignError && __jsx(ErrorHandler, {
        submitInvestmentError: submitSignError
      })));
    }
  })));
};
export default BulkCompleteInvestmentPage;