var __jsx = React.createElement;
import React from 'react';
import { useResponsiveFriendly } from '@yieldstreet/ui-kit';
import { testIds, mobileViewPort, tabletViewPort } from './OfferingList.model';
import { OfferingListWrapper } from './OfferingList.style';
import { OfferingListBody } from './OfferingListBody/OfferingListBody';
import { OfferingListHeader } from './OfferingListHeader/OfferingListHeader';
export const OfferingList = _ref => {
  let {
    offerings,
    headings,
    config
  } = _ref;
  const deviceViewPort = useResponsiveFriendly();
  if (!(offerings !== null && offerings !== void 0 && offerings.length) || !(headings !== null && headings !== void 0 && headings.length)) {
    return null;
  }
  const isMobile = deviceViewPort === mobileViewPort || deviceViewPort === tabletViewPort;
  return __jsx(OfferingListWrapper, {
    "data-cy": testIds.offeringListItem
  }, !isMobile && __jsx(OfferingListHeader, {
    headings: headings
  }), __jsx(OfferingListBody, {
    isMobile: isMobile,
    offerings: offerings,
    config: config
  }));
};