import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import isEmpty from "lodash/isEmpty";
import uniqBy from "lodash/uniqBy";
import { AccreditationEligibility } from '@yieldstreet/platform-kit';
import { getOfferingTerm, isFortyActFund, isRegAPlus } from '@yieldstreet/tool-kit';
import { getOpenOfferings } from 'store/actions/marketplace';
import marketplaceOfferingDefault from 'assets/images/images/marketplaceOfferingDefault.png';
export const useDashboardData = _ref => {
  let {
    userAudiences
  } = _ref;
  const dispatch = useDispatch();
  const {
    openOfferings
  } = useSelector(_ref2 => {
    let {
      marketplace
    } = _ref2;
    return {
      openOfferings: marketplace.openOfferings
    };
  });
  useEffect(() => {
    if (!isEmpty(userAudiences)) {
      const accreditation = userAudiences !== null && userAudiences !== void 0 && userAudiences.allNAI ? {
        accreditation: AccreditationEligibility.NONA
      } : {};
      dispatch(getOpenOfferings(accreditation));
    }
  }, [dispatch, userAudiences]);
  const uniqInvestmentsByProductType = uniqBy(openOfferings, 'productType');
  const uniqInvestmentsByAssetClass = uniqBy(openOfferings, 'assetClass');
  const investorsProducts = [];
  const ysPrismFund = openOfferings.find(offering => isFortyActFund(offering.fundType));
  if (ysPrismFund) {
    investorsProducts.push(ysPrismFund);
  }
  const growthIncome = openOfferings.find(offering => isRegAPlus(offering.fundType));
  if (growthIncome) {
    investorsProducts.push(growthIncome);
  }
  const activeRecentOfferings = uniqInvestmentsByProductType.filter((_offering, index) => index < 4).map(offering => {
    const {
      title,
      urlHash,
      SEOTitle,
      thumbnailImageUrl,
      assetClassDisplayName,
      interestRate,
      minimumPurchase,
      offeringHero
    } = offering;
    let hasCustomRateString = false;
    const {
      term,
      termSuffix
    } = getOfferingTerm(offering);
    if (offeringHero) {
      const {
        customRateString
      } = offeringHero;
      hasCustomRateString = !!customRateString;
    }
    return {
      id: urlHash,
      title,
      thumbnailImageUrl: thumbnailImageUrl ? thumbnailImageUrl : marketplaceOfferingDefault,
      link: `/offering/${urlHash}/${SEOTitle}`,
      assetClass: assetClassDisplayName,
      targetReturn: hasCustomRateString ? offeringHero === null || offeringHero === void 0 ? void 0 : offeringHero.customRateString : interestRate,
      targetTerm: `${term} ${termSuffix}`,
      minInvestment: minimumPurchase
    };
  });
  return {
    activeRecentOfferings,
    openOfferingsLength: openOfferings.length,
    openAssetClassesLength: uniqInvestmentsByAssetClass.length,
    investorsProducts
  };
};