import { genTestIds } from '@yieldstreet/tool-kit';
const {
  getTestIds
} = genTestIds(['wrapper', 'payment-header', 'total-cash-balance', 'investment-amount-label', 'investment-amount-value', 'deposit-required-amount-label', 'deposit-required-amount-value', 'insufficient-funds-disclaimer', 'free-investment-message']);
export const testIds = getTestIds('wallet-balance');
export const texts = {
  paymentMethodRollover: 'Source for additional funds',
  paymentMethodDefault: 'Payment method',
  walletBalance: 'Wallet balance',
  investmentAmountRollover: 'Additional funds required',
  investmentAmountDefault: 'This investment',
  depositNeeded: 'Deposit needed',
  depositDefault: 'Remaining balance',
  disclaimer: 'I authorize Yieldstreet to debit the account indicated for the amount noted. I will not dispute Yieldstreet debiting my account, so long as the transaction corresponds to the terms in this online form and my agreement with Yieldstreet.'
};