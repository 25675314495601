import _extends from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/extends.js";
import _objectWithoutProperties from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/objectWithoutProperties.js";
const _excluded = ["description", "label", "selected"];
var __jsx = React.createElement;
import React from 'react';
import { Description, SButton } from './OnboardButton.style';
export const OnboardButton = _ref => {
  let {
      description,
      label,
      selected
    } = _ref,
    props = _objectWithoutProperties(_ref, _excluded);
  return __jsx(SButton, _extends({
    "aria-selected": selected,
    description: description,
    selected: selected
  }, props), label, description && __jsx(Description, {
    variant: "body2"
  }, description));
};