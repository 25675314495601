import _extends from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/extends.js";
var __jsx = React.createElement;
import React, { useEffect } from 'react';
import isEmpty from "lodash/isEmpty";
import { NewModal, ModalContainer, useModalContext } from '@yieldstreet/ui-kit';
import { StrategyModalFooter, StrategyModalHeader } from './components';
import { STRATEGY_MODAL, investmentStrategyGraph, testIds } from './StrategyModal.model';
import { GraphImage, GraphWrapper } from './StrategyModal.style';
export const StrategyModal = () => {
  const {
    passProps,
    hideModal
  } = useModalContext();
  const {
    investmentStrategyType
  } = passProps;
  useEffect(() => {
    if (isEmpty(investmentStrategyType)) {
      hideModal();
    }
  }, [investmentStrategyType, hideModal]);
  if (isEmpty(investmentStrategyType)) {
    return null;
  }
  return __jsx(NewModal, {
    modalId: STRATEGY_MODAL
  }, __jsx(ModalContainer, null, __jsx(StrategyModalHeader, {
    investmentStrategyType: investmentStrategyType
  }), __jsx(GraphWrapper, null, __jsx(GraphImage, _extends({}, testIds.image.attr, {
    src: investmentStrategyGraph[investmentStrategyType]
  }))), __jsx(StrategyModalFooter, null)));
};