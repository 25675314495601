import _Alert from "@mui/material/Alert";
import _extends from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/extends.js";
import _Typography from "@mui/material/Typography";
import _AlertTitle from "@mui/material/AlertTitle";
var __jsx = React.createElement;
import React, { useCallback, useEffect, useMemo } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Formik, Form } from 'formik';
import compact from "lodash/compact";
import isEmpty from "lodash/isEmpty";
import flatten from "lodash/flatten";
import { skipToken } from '@reduxjs/toolkit/query';
import { Button, FormCheckboxInput, FormTextInput, Notification, PageContainer, PageLoader, Paragraph } from '@yieldstreet/ui-kit';
import { appActionManager, useApiErrorMessage, usePortfolioEligibilityCheck } from '@yieldstreet/tool-kit';
import { adviceEngineApi } from '@yieldstreet/platform-kit';
import { INVESTOR_ENTITY_ID_PARAM, MANAGED_PORTFOLIO_FUND_FULL_PATH } from '../../ManagedPortfolio.model';
import { AgreementFormConfig, agreementOptions, content, testIds, AgreementFormFields, manualStrategySelectionText, ManualStrategy, mapToManualStrategyOption } from './Agreement.model';
import { AgreementWrapper, AgreementHeadingWrapper, AgreementTitle, AgreementDocumentWrapper, AgreementConfirmWrapper, AgreementInitialsWrapper, AgreementNotificationWrapper } from './Agreement.style';
import { useDiscretionaryFlags } from 'hooks';
export const Agreement = () => {
  const history = useHistory();
  const {
    investorEntityId,
    strategyType
  } = useParams();
  const {
    useGetSuggestionsQuery,
    useAssignStrategyMutation
  } = adviceEngineApi;
  const suggestionsQueryConfig = useMemo(() => investorEntityId ? {
    investorEntityId
  } : skipToken, [investorEntityId]);
  const {
    data: {
      suggestedStrategies = []
    } = {},
    isLoading: isLoadingSuggestions
  } = useGetSuggestionsQuery(suggestionsQueryConfig);
  const [assignStrategy, assignStrategyResult] = useAssignStrategyMutation();
  const {
    isLoading: isLoadingAssignStrategy,
    isSuccess: isSuccessAssignStrategy
  } = assignStrategyResult;
  const isSuggestedStrategySelected = !!(strategyType && suggestedStrategies.includes(strategyType));
  const noRecommendationSuggested = isEmpty(compact(suggestedStrategies));
  const manualStrategyType = noRecommendationSuggested && ManualStrategy.NoRecommendation || !isSuggestedStrategySelected && ManualStrategy.CustomStrategy;
  const assignStrategyErrorMessage = useApiErrorMessage(assignStrategyResult);
  const {
    showManagedOnboarding
  } = useDiscretionaryFlags();
  const legalAgreementOptions = useMemo(() => [...agreementOptions, ...(manualStrategyType ? [mapToManualStrategyOption(manualStrategyType)] : [])], [manualStrategyType]);
  const handleOnSubmit = useCallback(_ref => {
    let {
      agreementLegalInvestorInitials
    } = _ref;
    if (!strategyType || !investorEntityId) {
      return;
    }
    const links = agreementOptions.map(agr => agr.link);
    const strategyRequest = {
      investorEntityId,
      investorInitials: agreementLegalInvestorInitials,
      strategyType,
      agreementsIdentifiers: flatten(links),
      manualStrategySelectionAgreementText: manualStrategyType ? manualStrategySelectionText[manualStrategyType] : ''
    };
    assignStrategy(strategyRequest);
  }, [assignStrategy, investorEntityId, manualStrategyType, strategyType]);
  const {
    action: portfolioEligibilityAction,
    isLoading: isPortfolioEligibilityLoading
  } = usePortfolioEligibilityCheck({
    investorEntityId,
    requiredActions: [],
    config: {
      skipPollChecks: !isSuccessAssignStrategy || !investorEntityId
    }
  });
  const isLoading = isLoadingSuggestions || isLoadingAssignStrategy || isPortfolioEligibilityLoading;
  useEffect(() => {
    if (portfolioEligibilityAction && !isPortfolioEligibilityLoading) {
      appActionManager.callAction(portfolioEligibilityAction.action, portfolioEligibilityAction.params);
    }
  }, [portfolioEligibilityAction, isPortfolioEligibilityLoading]);
  useEffect(() => {
    if (isSuccessAssignStrategy) {
      const nextStepLink = MANAGED_PORTFOLIO_FUND_FULL_PATH.replace(INVESTOR_ENTITY_ID_PARAM, investorEntityId);
      history.replace(nextStepLink);
    }
  }, [isSuccessAssignStrategy, history]);
  const isFormDisabled = !showManagedOnboarding;
  if (isLoading) {
    return __jsx(PageContainer, null, __jsx(PageLoader, {
      testId: testIds.loadingView.id
    }));
  }
  return __jsx(PageContainer, null, __jsx(AgreementWrapper, testIds.dataView.attr, __jsx(AgreementHeadingWrapper, testIds.heading.attr, __jsx(AgreementTitle, null, content.title), __jsx(Paragraph, {
    secondary: true
  }, content.disclosure)), assignStrategyErrorMessage && __jsx(AgreementNotificationWrapper, null, __jsx(Notification, {
    noMargin: true,
    inline: true,
    testId: testIds.notification.id,
    severity: "error",
    title: content.formError.title,
    description: content.formError.description
  })), isFormDisabled && __jsx(_Alert, _extends({
    severity: "error"
  }, testIds.errorNotificationOnboarding.attr, {
    sx: {
      mb: 4
    }
  }), __jsx(_AlertTitle, null, "You are currently unable to deposit funds into YS360 portfolio"), __jsx(_Typography, {
    variant: "body1"
  }, "An issue during your registration has occurred")), __jsx(AgreementDocumentWrapper, null, __jsx(Formik, _extends({}, AgreementFormConfig(!isSuggestedStrategySelected), {
    onSubmit: handleOnSubmit
  }), formikProps => __jsx(Form, null, __jsx(AgreementConfirmWrapper, testIds.options.attr, legalAgreementOptions.map(option => __jsx(FormCheckboxInput, _extends({
    key: option.name
  }, option, {
    disabled: isFormDisabled,
    formikProps: formikProps
  })))), __jsx(AgreementInitialsWrapper, null, __jsx(FormTextInput, {
    marginHeight: 0,
    fullWidth: true,
    disabled: isFormDisabled,
    name: AgreementFormFields.investorInitials,
    label: "Your initials",
    formikProps: formikProps,
    testId: testIds.investorInitials.id
  })), __jsx(Button, _extends({
    disabled: isLoadingAssignStrategy || !formikProps.isValid || isFormDisabled,
    loading: isLoadingAssignStrategy,
    fullWidth: true,
    type: "submit"
  }, testIds.submitButton.attr), "Continue"))))));
};