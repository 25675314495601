import React from "react";
var __jsx = React.createElement;
import { genTestIds } from '@yieldstreet/tool-kit';
import { SExternalLink } from './ManagedPortfolioSelection.style';
export const TEST_ID_BASE = 'managed-portfolio-selection';
const {
  getTestIds
} = genTestIds(['title', 'strategyCard', 'selectButton', 'helpButton', 'featuredStrategy', 'disclaimer']);
export const testIds = getTestIds(TEST_ID_BASE);
export const getTexts = hasSuggestion => {
  const suggestionTexts = {
    title: 'Select your strategy',
    subtitle: 'Choose one of three portfolios providing you with extensive diversification, all in one portfolio.'
  };
  const nonSuggestionTexts = {
    title: 'Based on your responses, we do not believe Managed Portfolios is a good fit',
    subtitle: __jsx(React.Fragment, null, "If you would still like to proceed, you can select one of the following strategies. Alternatively, consider", ' ', __jsx(SExternalLink, {
      href: "/marketplace"
    }, "exploring Yieldstreet"), " for other opportunities that may be a better fit for your investment objectives.")
  };
  return hasSuggestion ? suggestionTexts : nonSuggestionTexts;
};