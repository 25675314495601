var __jsx = React.createElement;
import React, { useEffect, useMemo, useCallback } from 'react';
import { connect } from 'react-redux';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import { offeringApi, portfolioGraphQLApi, investmentRequestApi } from '@yieldstreet/platform-kit';
import { BusinessEvent, eventManager, useFlags } from '@yieldstreet/tool-kit';
import { skipToken } from '@reduxjs/toolkit/query';
import { PRE_INVEST_ACCOUNT_SETUP_NEEDED_PATH } from 'pages/preInvestAccountSetup';
import { submitPreInvestmentRequest, setPreInvestmentRequestDetails, clearPreInvestmentRequestDetails } from 'store/actions/investmentRequest';
import { useGetInvestmentEligibility } from 'utils/investmentEligibility';
import { setSegmentTrack } from 'utils/thirdPartyCode';
import { useUserState } from 'utils/hooks';
import InputInvestment from './InputInvestment';
const InputInvestmentContainer = _ref => {
  let {
    clearPreInvestmentRequestDetails,
    preRequestDetails,
    submitPreInvestmentRequest,
    setPreInvestmentRequestDetails
  } = _ref;
  const userState = useUserState();
  const history = useHistory();
  const location = useLocation();
  const {
    urlHash
  } = useParams();
  const [getInvestmentEligibility] = useGetInvestmentEligibility();
  const {
    gwiCapitalCalls: newCapitalCall
  } = useFlags({
    gwiCapitalCalls: false
  });
  const {
    offering,
    isLoadingError
  } = offeringApi.useDetailsQuery([urlHash], {
    selectFromResult: _ref2 => {
      var _data$urlHash;
      let {
        data,
        isLoading,
        isUninitialized,
        isError
      } = _ref2;
      return {
        offering: data === null || data === void 0 ? void 0 : (_data$urlHash = data[urlHash]) === null || _data$urlHash === void 0 ? void 0 : _data$urlHash.note,
        isOfferingLoading: isLoading || isUninitialized,
        isLoadingError: isError
      };
    }
  });
  const [createCommitment, commitmentResult] = investmentRequestApi.useCreateCommitmentMutation();
  const rollover = useMemo(() => {
    var _location$state, _location$state$rollo, _location$state2, _location$state2$roll, _location$state3;
    if (!(location !== null && location !== void 0 && (_location$state = location.state) !== null && _location$state !== void 0 && (_location$state$rollo = _location$state.rollover) !== null && _location$state$rollo !== void 0 && _location$state$rollo.urlHash) || !(location !== null && location !== void 0 && (_location$state2 = location.state) !== null && _location$state2 !== void 0 && (_location$state2$roll = _location$state2.rollover) !== null && _location$state2$roll !== void 0 && _location$state2$roll.investorId)) {
      return null;
    }
    return location === null || location === void 0 ? void 0 : (_location$state3 = location.state) === null || _location$state3 === void 0 ? void 0 : _location$state3.rollover;
  }, [location]);
  const getRolloverOfferingConfig = useMemo(() => {
    if (!rollover) {
      return skipToken;
    }
    return [rollover.urlHash];
  }, [rollover]);
  const {
    rolloverOffering
  } = offeringApi.useDetailsQuery(getRolloverOfferingConfig, {
    selectFromResult: _ref3 => {
      var _data$rollover$urlHas;
      let {
        data
      } = _ref3;
      return {
        rolloverOffering: data === null || data === void 0 ? void 0 : (_data$rollover$urlHas = data[rollover.urlHash]) === null || _data$rollover$urlHas === void 0 ? void 0 : _data$rollover$urlHas.note
      };
    }
  });
  const getRolloverAmountConfig = useMemo(() => {
    if (!rollover || !(rollover !== null && rollover !== void 0 && rollover.investorId) || !(rollover !== null && rollover !== void 0 && rollover.urlHash)) {
      return skipToken;
    }
    return {
      userId: userState.id,
      investorId: rollover.investorId,
      noteUrlHash: rollover.urlHash
    };
  }, [userState, rollover]);
  const {
    rolloverData,
    isFetchingRolloverData
  } = portfolioGraphQLApi.useGetRolloverAmountQuery(getRolloverAmountConfig, {
    selectFromResult: _ref4 => {
      let {
        data,
        isFetching
      } = _ref4;
      return {
        rolloverData: data === null || data === void 0 ? void 0 : data.maxRolloverAmount,
        isFetchingRolloverData: isFetching
      };
    }
  });
  useEffect(() => {
    // eventually convert this to RTK API
    if (preRequestDetails) {
      clearPreInvestmentRequestDetails();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (commitmentResult.isSuccess) {
      const {
        data: {
          commitment
        }
      } = commitmentResult;
      history.push(`/complete-investment/${urlHash}/${commitment.committedAmount}?commitmentId=${commitment.commitmentId}`);
    }
  }, [commitmentResult, history, urlHash]);
  const attemptToInvest = useCallback(async amount => {
    let rolloverInvestmentIds;
    if (offering !== null && offering !== void 0 && offering.acceptRollover) {
      var _rolloverData$totals, _rolloverData$totals$;
      if (rolloverData !== null && rolloverData !== void 0 && (_rolloverData$totals = rolloverData.totals) !== null && _rolloverData$totals !== void 0 && (_rolloverData$totals$ = _rolloverData$totals.investmentIds) !== null && _rolloverData$totals$ !== void 0 && _rolloverData$totals$.length) {
        var _rolloverData$totals2;
        rolloverInvestmentIds = rolloverData === null || rolloverData === void 0 ? void 0 : (_rolloverData$totals2 = rolloverData.totals) === null || _rolloverData$totals2 === void 0 ? void 0 : _rolloverData$totals2.investmentIds;
      }
    }
    if (newCapitalCall && offering !== null && offering !== void 0 && offering.capitalCallEnabled) {
      createCommitment({
        noteUrlHash: urlHash,
        committedAmount: amount
      });
      return;
    }
    const {
      isEligibleToInvest,
      preInvestmentState
    } = await getInvestmentEligibility({
      investments: [{
        amount,
        urlHash
      }],
      rolloverInvestmentIds
    });
    eventManager.track(BusinessEvent.INVESTOR_INVESTMENT_AMOUNT, {
      amount: parseInt(amount),
      urlHash,
      noteTitle: offering === null || offering === void 0 ? void 0 : offering.title
    });
    if (isEligibleToInvest) {
      history.push(`/complete-investment/${urlHash}/${amount}`, {
        rollover
      });
    } else {
      submitPreInvestmentRequest(urlHash, {
        amount: parseInt(amount)
      });
      setPreInvestmentRequestDetails({
        amount,
        urlHash
      });
      setSegmentTrack({
        event: 'investor.investment.prequeststarted'
      });
      history.push(PRE_INVEST_ACCOUNT_SETUP_NEEDED_PATH, {
        preInvestmentState
      });
    }
  }, [createCommitment, getInvestmentEligibility, history, newCapitalCall, offering, rollover, rolloverData, setPreInvestmentRequestDetails, submitPreInvestmentRequest, urlHash]);
  if (isLoadingError) {
    history.push('/offerings/');
  }
  return __jsx(InputInvestment, {
    history: history,
    offering: offering,
    rolloverOffering: rolloverOffering,
    rollover: rolloverData,
    isFetchingRolloverData: isFetchingRolloverData,
    userState: userState,
    attemptToInvest: attemptToInvest
  });
};
const mapStateToProps = _ref5 => {
  let {
    investmentRequest
  } = _ref5;
  return {
    preRequestDetails: investmentRequest.preRequestDetails
  };
};
const dispatchToProps = {
  submitPreInvestmentRequest,
  setPreInvestmentRequestDetails,
  clearPreInvestmentRequestDetails
};
export default connect(mapStateToProps, dispatchToProps)(InputInvestmentContainer);