import _Box from "@mui/material/Box";
import _Button from "@mui/material/Button";
import _Radio from "@mui/material/Radio";
import _Divider from "@mui/material/Divider";
import _Stack from "@mui/material/Stack";
import _Typography from "@mui/material/Typography";
import _extends from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/extends.js";
import _useMediaQuery from "@mui/material/useMediaQuery";
var __jsx = React.createElement;
import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { useTheme } from '@mui/material/styles';
import { useAccountProgress } from '@yieldstreet/tool-kit';
import { testIds } from './AccountProgressList.model';
import { useUserState } from 'utils/hooks';
export const AccountProgressList = () => {
  const history = useHistory();
  const userState = useUserState();
  const {
    firstName
  } = userState || {};
  const theme = useTheme();
  const isDeviceMedium = _useMediaQuery(theme.breakpoints.up('lg'));
  const {
    accountProgressData
  } = useAccountProgress();
  const handleAction = useCallback(item => {
    if (item !== null && item !== void 0 && item.action && item !== null && item !== void 0 && item.showCta) {
      return history.push(item.action.link);
    }
  }, [history]);
  return __jsx(_Box, _extends({
    sx: {
      borderRadius: 4,
      background: `linear-gradient(75deg, #253034 0.82%, #314A50 27.09%, #315258 71.85%, #446F75 101.33%)`,
      padding: 8
    }
  }, testIds.base.attr), __jsx(_Stack, {
    spacing: 4,
    sx: {
      marginBottom: 6
    }
  }, __jsx(_Typography, _extends({
    color: "text.header.inverse",
    component: "h4",
    variant: "h4"
  }, testIds.welcome.attr), "Welcome to Yieldstreet ", firstName), __jsx(_Typography, _extends({
    color: "text.header.inverse",
    variant: "body2"
  }, testIds.caption.attr), "You\u2019re almost ready to start investing, complete your account setup.")), __jsx(_Box, _extends({
    sx: {
      border: 1,
      borderColor: 'border.extra_strong',
      borderRadius: 4
    }
  }, testIds.stateList.attr), __jsx(_Stack, {
    divider: __jsx(_Divider, {
      sx: {
        borderColor: 'border.extra_strong'
      },
      flexItem: true
    }),
    sx: {
      alignItems: 'flex-start',
      justifyContent: 'center'
    }
  }, accountProgressData.map(item => __jsx(_Stack, {
    key: item.name,
    direction: "row",
    sx: {
      alignItems: 'center',
      padding: theme.spacing(6, 4),
      width: 1
    },
    onClick: () => item.showCta && handleAction(item)
  }, __jsx(_Radio, _extends({}, testIds.stateItem.attr, {
    name: item.name,
    value: item.name,
    color: "default",
    checked: item.isFinished
  })), __jsx(_Stack, {
    direction: "row",
    sx: {
      justifyContent: 'space-between',
      width: 1
    }
  }, __jsx(_Stack, {
    color: item.isFinished ? 'text.paragraphSecondary.inverse' : 'text.paragraphPrimary.inverse'
  }, __jsx(_Typography, {
    variant: "body1",
    sx: {
      fontWeight: 'medium'
    }
  }, item.label), (item === null || item === void 0 ? void 0 : item.caption) && !(item !== null && item !== void 0 && item.isFinished) && __jsx(_Typography, {
    variant: "body2"
  }, item.caption)), item.showCta && __jsx(_Stack, {
    sx: {
      justifyContent: 'center',
      width: '30%',
      alignItems: 'flex-end'
    }
  }, isDeviceMedium ? __jsx(_Button, testIds.stateCta.attr, item.cta) : __jsx(ChevronRightIcon, {
    sx: {
      cursor: 'pointer',
      color: 'background.default'
    }
  }))))))));
};