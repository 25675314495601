var __jsx = React.createElement;
import React from 'react';
import { CSSTransition } from 'react-transition-group';
import { AccountProgressItem } from './AccountProgressItem';
import { Container, ProgressContainer } from './AccountProgress.style';
import { accountTypeSelection, firstInvestment, testIds, verifyIdentity } from './AccountProgress.model';
export const AccountProgress = _ref => {
  let {
    actionLink,
    stepsDone
  } = _ref;
  return __jsx(CSSTransition, {
    in: true,
    classNames: "fade",
    appear: true,
    timeout: 200
  }, __jsx(Container, null, __jsx(ProgressContainer, null, __jsx(AccountProgressItem, {
    completed: stepsDone.ACCOUNT,
    link: '/create-investor-account',
    subtitle: accountTypeSelection.subtitle,
    testId: testIds.accountType.id,
    title: accountTypeSelection.title
  }), __jsx(AccountProgressItem, {
    completed: stepsDone.IDENTITY,
    link: actionLink,
    subtitle: verifyIdentity.subtitle,
    testId: testIds.verifyIdentity.id,
    title: verifyIdentity.title
  }), __jsx(AccountProgressItem, {
    link: stepsDone.READY ? '/offerings' : actionLink,
    subtitle: firstInvestment.subtitle,
    testId: testIds.firstInvestment.id,
    title: firstInvestment.title
  }))));
};