import _Typography from "@mui/material/Typography";
var __jsx = React.createElement;
import React from 'react';
import { PageFlow } from '@yieldstreet/ui-kit';
import { useAuthCheck } from 'utils/auth';
import { Content } from './PrimarySignup.style';
import { PrimarySignupForm } from './PrimarySignupForm';
const PrimarySignup = () => {
  useAuthCheck();
  return __jsx(PageFlow, {
    showLogo: true
  }, __jsx(Content, null, __jsx(_Typography, {
    component: "h1",
    variant: "h5",
    sx: {
      mb: 4
    }
  }, "Create an account to access curated private market alternatives"), __jsx(_Typography, {
    component: "p",
    variant: "body1"
  }, "Start building a portfolio tailored to your objectives."), __jsx(PrimarySignupForm, null)));
};
export default PrimarySignup;