import { mockUserOptions } from './SAMLFakeEtc.mock';
export const initUser = mockUserOptions[0];
export const inputs = [{
  id: 'account_number',
  label: 'Account Number'
}, {
  id: 'contact_name',
  label: 'Contact Name'
}, {
  id: 'contact_id',
  label: 'Contact ID'
}, {
  id: 'email',
  label: 'Email',
  type: 'email'
}, {
  id: 'user_id',
  label: 'YS User Hashed ID (Optional)'
}];