var __jsx = React.createElement;
import React from 'react';
import { ButtonComponent } from '../components/ButtonComponent';
import { DescriptionComponent } from '../components/DescriptionComponent';
import { TitleComponent } from '../components/TitleComponent';
import { ButtonText, QuizContent, TEST_IDS } from '../Goals.model';
import { DashboardDesktopWrapper, DescriptionWrapper, Left, MobileWrapper, QuestionWrapper, Right } from '../Goals.style';
import { QuestionIcon, WhiteCardWrapper } from './ResultsNotFit.style';
import question from './assets/question.svg';
export const ResultsNotFit = _ref => {
  let {
    onClick,
    page,
    showMobileView
  } = _ref;
  const MobileView = __jsx(React.Fragment, null, __jsx(MobileWrapper, {
    "data-cy": TEST_IDS.resultsNotFitMobileWrapper,
    isDashboard: page === 'dashboard'
  }, __jsx(QuestionWrapper, null, TitleComponent(page, QuizContent.resultsNotFit[page].question)), __jsx(DescriptionWrapper, null, DescriptionComponent(QuizContent.resultsNotFit[page].description)), ButtonComponent(onClick, ButtonText.resultsNotFit)));
  const DesktopView = __jsx(DashboardDesktopWrapper, {
    "data-cy": TEST_IDS.resultsNotFitWrapper,
    isDashboard: true
  }, __jsx(Left, {
    state: "default"
  }, __jsx(QuestionWrapper, null, TitleComponent(page, QuizContent.resultsNotFit[page].question)), __jsx(DescriptionWrapper, null, DescriptionComponent(QuizContent.resultsNotFit[page].description), ButtonComponent(onClick, ButtonText.resultsNotFit))), __jsx(Right, null, __jsx(WhiteCardWrapper, {
    clickable: false
  }, __jsx(QuestionIcon, {
    src: question,
    alt: "No offerings",
    "data-cy": TEST_IDS.resultsNotFitIcon
  }))));
  return showMobileView ? MobileView : DesktopView;
};