var __jsx = React.createElement;
import React from 'react';
import { PageGrid } from '@yieldstreet/ui-kit';
import { Communication } from 'sharedComponents/Communication';
export const CommunicationDashboard = _ref => {
  let {
    userAudiences
  } = _ref;
  if (!userAudiences) {
    return null;
  }
  return __jsx(PageGrid, {
    config: {
      spacing: 20
    },
    "data-cy": "module-communication"
  }, __jsx(Communication, {
    location: "empty_portfolio"
  }));
};