import _objectSpread from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import { useMemo } from 'react';
import { skipToken } from '@reduxjs/toolkit/query';
import { paymentMethodsApi } from '@yieldstreet/platform-kit';
import forEach from "lodash/forEach";
const paymentMethodOptions = (paymentInfo, supportAdditionalPaymentMethods) => {
  let paymentMethods = [];
  let primaryPaymentMethods = [];
  let additionalFundsPaymentMethods = [];
  if (paymentInfo) {
    var _paymentInfo$paymentO;
    (_paymentInfo$paymentO = paymentInfo.paymentOptions) === null || _paymentInfo$paymentO === void 0 ? void 0 : _paymentInfo$paymentO.forEach(paymentOption => {
      if (paymentOption.bankAccounts.length) {
        paymentOption.bankAccounts.forEach(bankAcct => {
          const label = paymentOption.type === 'ACH' ? `${bankAcct.displayName} - ${bankAcct.lastFourDigits}` : bankAcct.displayName;
          paymentMethods.push({
            label,
            paymentMethod: paymentOption.type,
            bankAccountId: bankAcct.id,
            processOn: paymentOption.processOn,
            value: paymentOption.type + bankAcct.id,
            additionalFunds: paymentOption.additionalFunds
          });
        });
      } else if (!paymentOption.additionalFunds) {
        paymentMethods.push({
          label: paymentOption.description,
          paymentMethod: paymentOption.type,
          bankAccountId: null,
          processOn: paymentOption.processOn,
          value: paymentOption.type,
          additionalFunds: paymentOption.additionalFunds
        });
      }
    });
  }
  forEach(paymentMethods, method => {
    if (method.additionalFunds) {
      if (supportAdditionalPaymentMethods) {
        additionalFundsPaymentMethods.push(method);
      }
    } else {
      primaryPaymentMethods.push(method);
    }
  });

  // helper consts
  const hasPaymentMethodChoice = primaryPaymentMethods.length > 1;
  const firstPaymentMethod = primaryPaymentMethods[0];
  const hasAdditionalFundsPaymentMethodChoice = additionalFundsPaymentMethods.length > 1;
  const firstAdditionalFundsPaymentMethod = additionalFundsPaymentMethods[0];
  return {
    paymentMethods,
    hasPaymentMethodChoice,
    firstPaymentMethod,
    hasAdditionalFundsPaymentMethodChoice,
    firstAdditionalFundsPaymentMethod,
    primaryPaymentMethods,
    additionalFundsPaymentMethods
  };
};
export const useInvestmentPaymentMethods = (investorAccountId, investmentAmount, noteUrlHashes, supportAdditionalPaymentMethods, rolloverConfig) => {
  const paymentMethodQuery = useMemo(() => {
    if (!investorAccountId || !investmentAmount || !noteUrlHashes) {
      return skipToken;
    }
    return _objectSpread({
      investorAccountId,
      investmentAmount,
      urlHashes: noteUrlHashes
    }, rolloverConfig);
  }, [investorAccountId, investmentAmount, noteUrlHashes, rolloverConfig]);
  const {
    currentData: paymentInfo,
    isFetching: paymentInfoLoading,
    error: paymentInfoError,
    refetch
  } = paymentMethodsApi.usePaymentMethodsQuery(paymentMethodQuery, {
    refetchOnMountOrArgChange: true
  });
  const {
    paymentMethods,
    hasPaymentMethodChoice,
    firstPaymentMethod,
    hasAdditionalFundsPaymentMethodChoice,
    firstAdditionalFundsPaymentMethod,
    primaryPaymentMethods,
    additionalFundsPaymentMethods
  } = useMemo(() => paymentMethodOptions(paymentInfo, supportAdditionalPaymentMethods), [paymentInfo, supportAdditionalPaymentMethods]);
  return {
    // payment info data
    paymentInfo: _objectSpread(_objectSpread({}, paymentInfo), {}, {
      walletBalance: null
    }),
    paymentInfoLoading,
    paymentInfoError,
    // payment methods
    paymentMethods,
    primaryPaymentMethods,
    additionalFundsPaymentMethods,
    // helper consts
    hasPaymentMethodChoice,
    firstPaymentMethod,
    hasAdditionalFundsPaymentMethodChoice,
    firstAdditionalFundsPaymentMethod,
    refetchPaymentMethods: refetch
  };
};
export const InvestmentPaymentMethodsProvider = _ref => {
  let {
    investorAccountId,
    investmentAmount,
    noteUrlHash,
    children
  } = _ref;
  const paymentMethods = useInvestmentPaymentMethods(investorAccountId, investmentAmount, noteUrlHash);
  return children(paymentMethods);
};