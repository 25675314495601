import _extends from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/extends.js";
var __jsx = React.createElement;
import React from 'react';
import { USStatesSelectOptions } from './StateSelection.model';
import { FormikTextInput } from '../../FormikTextInput';
import { FormikSelectInput } from '../../FormikSelectInput';
export const StateSelection = _ref => {
  let {
    disabled = false,
    country,
    formikProps,
    formSelectProps,
    formTextInputProps,
    testId
  } = _ref;
  return country === 'US' ? __jsx(FormikSelectInput, _extends({
    label: "State",
    placeholder: "Select state",
    options: USStatesSelectOptions,
    fullWidth: true,
    disabled: disabled,
    formikProps: formikProps,
    formControl: {
      fullWidth: true
    }
  }, formSelectProps, testId)) : __jsx(FormikTextInput, _extends({
    label: "State",
    fullWidth: true,
    disabled: disabled,
    formikProps: formikProps
  }, formTextInputProps, testId));
};