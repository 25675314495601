var __jsx = React.createElement;
import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { Switch } from 'react-router';
import { useFlags } from '@yieldstreet/tool-kit';
import { withFlagsPrefetch } from '../../utils/flags';
import { LEGACY_SIGNUP_GET_STARTED_PATH, LEGACY_SIGNUP_PATHS, LEGACY_SIGNUP_QUIZ_PATHS, SIGNUP_BASE_PATH, SIGNUP_SCREEN_PATH } from './Signup.model';
import GetStarted from './getStarted/GetStarted';
import SignupQuizContainer from './signupQuiz/SignupQuizContainer';
import NewSignupContainer from './newSignup/NewSignupContainer';
const SignupRoutes = withFlagsPrefetch(() => {
  const {
    hideSignupQuiz: hideQuiz
  } = useFlags({
    hideSignupQuiz: false
  });
  return __jsx(Switch, null, __jsx(Route, {
    path: SIGNUP_SCREEN_PATH,
    render: () => __jsx(NewSignupContainer, null)
  }), __jsx(Route, {
    path: LEGACY_SIGNUP_PATHS
  }, hideQuiz && __jsx(Redirect, {
    to: SIGNUP_BASE_PATH
  }), __jsx(Route, {
    path: LEGACY_SIGNUP_GET_STARTED_PATH,
    render: () => __jsx(GetStarted, null),
    exact: true
  }), __jsx(Route, {
    path: LEGACY_SIGNUP_QUIZ_PATHS,
    render: () => __jsx(SignupQuizContainer, null)
  })));
});
export default SignupRoutes;