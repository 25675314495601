import _Divider from "@mui/material/Divider";
import _Alert from "@mui/material/Alert";
import _Stack from "@mui/material/Stack";
import _Box from "@mui/material/Box";
import _Typography from "@mui/material/Typography";
import _objectSpread from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import React from "react";
var __jsx = React.createElement;
import { useCallback, useRef } from 'react';
import { Formik } from 'formik';
import { LoadingButton } from '@mui/lab';
import { authApi } from '@yieldstreet/platform-kit';
import { RouterLink } from '@yieldstreet/ui-kit/navigation';
import { PageFlow } from '@yieldstreet/ui-kit';
import { FormikTextInput, FormikPasswordInput, FormikCheckboxInput } from '@yieldstreet/ui-kit/inputs';
import { AuthType, BusinessEvent, useApiErrorMessage, useApiEventTracking, useApiFormErrors, createYupObjectSchema } from '@yieldstreet/tool-kit';
import { ReCaptchaDisclaimer } from 'sharedComponents/ReCaptcha';
import { SocialLogin } from 'sharedComponents/SocialLogin';
import { useAnalyticsIds } from 'utils/analytics';
import { useAuthFlow, AUTH_ERRORS } from 'utils/auth';
import { loginTitle, formikNames, TEST_IDS } from '../Login.model';
import { Content, FieldsContainer, FormContainer } from '../Login.style';
const LoginFormSchema = createYupObjectSchema({
  email: {
    validationType: 'email'
  },
  password: {
    validationType: 'default-string'
  }
});
export const LoginCredentialsForm = () => {
  const form = useRef();
  const [passwordLogin, passwordLoginState] = authApi.usePasswordLoginMutation();
  const {
    isLoading,
    data: authResult
  } = passwordLoginState;
  const {
    require2FA
  } = authResult || {};
  const {
    isAuthenticating
  } = useAuthFlow(authResult);
  const {
    anonymousId
  } = useAnalyticsIds();
  const doPasswordLogin = useCallback(credentials => {
    return passwordLogin(_objectSpread({
      anonymousId
    }, credentials));
  }, [passwordLogin, anonymousId]);
  const errorMessage = useApiErrorMessage(passwordLoginState, AUTH_ERRORS.PASSWORD_LOGIN);
  useApiFormErrors(passwordLoginState, form);
  useApiEventTracking(passwordLoginState, BusinessEvent.USER_LOGIN_STARTED, {
    type: AuthType.PASSWORD,
    require2FA
  }, errorMessage);
  return __jsx(PageFlow, {
    showLogo: true
  }, __jsx(Content, {
    "data-cy": TEST_IDS.loginBox
  }, __jsx(_Typography, {
    component: "h1",
    variant: "h4"
  }, loginTitle), __jsx(FormContainer, null, __jsx(Formik, {
    ref: form,
    initialValues: {
      email: '',
      password: '',
      rememberMe: false,
      showPasswordText: false
    },
    validationSchema: LoginFormSchema,
    onSubmit: (values, formikActions) => doPasswordLogin(values).then(() => formikActions.setSubmitting(false))
  }, formikProps => __jsx(FieldsContainer, null, __jsx(FormikTextInput, {
    disabled: false,
    name: "email",
    label: formikNames.email,
    formikProps: formikProps,
    "data-cy": TEST_IDS.emailForm,
    type: "email"
  }), __jsx(FormikPasswordInput, {
    disabled: false,
    name: "password",
    label: formikNames.password,
    formikProps: formikProps,
    "data-cy": TEST_IDS.passwordForm
  }), __jsx(_Stack, {
    direction: "row",
    justifyContent: "space-between"
  }, __jsx(FormikCheckboxInput, {
    disabled: false,
    name: "rememberMe",
    label: formikNames.rememberMe,
    formikProps: formikProps,
    "data-cy": TEST_IDS.rememberMeForm
  }), __jsx(_Box, {
    sx: {
      pb: 3,
      pt: 3
    }
  }, __jsx(RouterLink, {
    to: "/password"
  }, formikNames.forgotPassword))), errorMessage && __jsx(_Alert, {
    severity: "error",
    "data-cy": TEST_IDS.errorNotification
  }, errorMessage), __jsx(LoadingButton, {
    "data-cy": TEST_IDS.submitButton,
    fullWidth: true,
    loading: isLoading || isAuthenticating,
    type: "submit"
  }, formikNames.submitButton))), __jsx("div", null, __jsx(_Divider, {
    component: "div",
    role: "separator",
    sx: {
      mb: 4
    }
  }, __jsx(_Typography, {
    sx: {
      color: 'text.secondary',
      fontSize: 13
    }
  }, "or")), __jsx(SocialLogin, {
    cta: formikNames.socialLogin,
    hideSeperator: true
  }), __jsx(_Typography, {
    component: "p",
    variant: "body1",
    sx: {
      mt: 10,
      textAlign: 'center'
    }
  }, formikNames.withoutAccount, __jsx("br", null), __jsx(RouterLink, {
    to: "/signup-quiz"
  }, formikNames.createAccount)))), __jsx(ReCaptchaDisclaimer, null)));
};