var __jsx = React.createElement;
import React from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { SignupQuizSteps as SignupQuizStepsType } from '@yieldstreet/platform-kit';
import { ExperienceStep } from './ExperienceStep/ExperienceStep';
import { HowStep } from './HowStep/HowStep';
import { InterestedStep } from './InterestedStep/InterestedStep';
import { PortfolioStep } from './PortfolioStep/PortfolioStep';
import { WhyStep } from './WhyStep/WhyStep';
import { AnimationGroup } from './SignupQuizSteps.style';
export const SignupQuizSteps = _ref => {
  let {
    path,
    nextStep,
    saveQuizAnswer,
    getQuizAnswer
  } = _ref;
  const location = useLocation();
  return __jsx(TransitionGroup, {
    component: AnimationGroup
  }, __jsx(CSSTransition, {
    key: location.key,
    classNames: "slide",
    appear: true,
    timeout: 500
  }, __jsx(Switch, {
    location: location
  }, __jsx(Route, {
    strict: true,
    path: `${path}/${SignupQuizStepsType.EXPERIENCE_ALTS}`,
    render: () => __jsx(ExperienceStep, {
      nextStep: nextStep,
      saveQuizAnswer: saveQuizAnswer,
      getQuizAnswer: getQuizAnswer
    })
  }), __jsx(Route, {
    strict: true,
    path: `${path}/${SignupQuizStepsType.INVESTING_REASONS}`,
    render: () => __jsx(WhyStep, {
      nextStep: nextStep,
      saveQuizAnswer: saveQuizAnswer,
      getQuizAnswer: getQuizAnswer
    })
  }), __jsx(Route, {
    strict: true,
    path: `${path}/${SignupQuizStepsType.INTERESTED_ASSET_CLASSES}`,
    render: () => __jsx(InterestedStep, {
      nextStep: nextStep,
      saveQuizAnswer: saveQuizAnswer,
      getQuizAnswer: getQuizAnswer
    })
  }), __jsx(Route, {
    strict: true,
    path: `${path}/${SignupQuizStepsType.PORTFOLIO}`,
    render: () => __jsx(PortfolioStep, {
      nextStep: nextStep,
      saveQuizAnswer: saveQuizAnswer,
      getQuizAnswer: getQuizAnswer
    })
  }), __jsx(Route, {
    strict: true,
    path: `${path}/${SignupQuizStepsType.USUAL_INVESTING_WAY}`,
    render: () => __jsx(HowStep, {
      nextStep: nextStep,
      saveQuizAnswer: saveQuizAnswer,
      getQuizAnswer: getQuizAnswer
    })
  }))));
};