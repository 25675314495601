export const loginTitle = 'Log in';
export const formikNames = {
  email: 'Email',
  password: 'Password',
  rememberMe: 'Remember me',
  forgotPassword: 'Forgot password?',
  submitButton: 'Log in',
  socialLogin: 'Continue',
  withoutAccount: 'Don’t have an account yet?',
  createAccount: 'Create one today'
};
export const TEST_IDS = {
  loginBox: 'login-box',
  emailForm: 'login-email-text-input-form',
  passwordForm: 'login-password-text-input-form',
  rememberMeForm: 'login-remember-me-checkbox-form',
  errorNotification: 'login-error-notification',
  submitButton: 'login-submit-button',
  uiButton: 'ui-kit-button-container'
};