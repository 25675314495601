import _Stack from "@mui/material/Stack";
import _Typography from "@mui/material/Typography";
import _Box from "@mui/material/Box";
var __jsx = React.createElement;
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { hasTieredPricing, interpolate } from '@yieldstreet/tool-kit';
import { PageContainer, PageLoader } from '@yieldstreet/ui-kit/layouts';
import { RouterLink } from '@yieldstreet/ui-kit/navigation';
import Invest from 'sharedComponents/Offering/Invest';
import NotificationMessage from 'sharedComponents/NotificationMessage/NotificationMessage';
import { setTimerRunning } from 'store/actions/expirationTimer';
import { TieredPricingContainer } from 'sharedComponents/TieredPricing';
import { COPY as copy } from './InputInvestment.model';
import { InvestContainer } from './InputInvestment.style';
const InputInvestment = _ref => {
  let {
    history,
    offering,
    rolloverOffering,
    rollover,
    isFetchingRolloverData,
    userState,
    attemptToInvest
  } = _ref;
  const dispatch = useDispatch();
  const timerExpired = useSelector(state => state.expirationTimer.expired);
  const {
    0: value,
    1: setValue
  } = useState();
  useEffect(() => {
    dispatch(setTimerRunning());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const title = useMemo(() => {
    if (offering !== null && offering !== void 0 && offering.capitalCallEnabled) {
      return copy.title.capitalCall;
    }
    if (!!rolloverOffering) {
      return copy.title.rollover;
    }
    return copy.title.default;
  }, [offering, rolloverOffering]);
  const enableTieredPricing = useMemo(() => {
    return hasTieredPricing(offering);
  }, [offering]);
  if (timerExpired) {
    const errorMessage = [interpolate(copy.errorMessage, {
      title: offering === null || offering === void 0 ? void 0 : offering.title,
      link: __jsx(RouterLink, {
        to: `/offering/${offering === null || offering === void 0 ? void 0 : offering.urlHash}/`
      }, copy.errorMessageLink)
    })];
    return __jsx(_Box, null, __jsx(NotificationMessage, {
      dataCy: "input-invest-expiration",
      title: copy.errorMessageTitle,
      textList: errorMessage
    }));
  }
  return __jsx(React.Fragment, null, !offering || isFetchingRolloverData ? __jsx(PageLoader, {
    testId: "input-investment-loader"
  }) : __jsx(_Box, {
    sx: theme => ({
      width: '100%',
      backgroundColor: theme.palette.background.default
    })
  }, __jsx(PageContainer, {
    sx: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    }
  }, __jsx(_Stack, {
    spacing: 4,
    width: "100%",
    maxWidth: "450px",
    alignItems: "flex-start"
  }, __jsx(_Typography, {
    variant: "h2",
    maxWidth: 415
  }, title), __jsx(InvestContainer, null, __jsx(Invest, {
    loggedIn: userState === null || userState === void 0 ? void 0 : userState.loggedIn,
    history: history,
    onInvestClick: attemptToInvest,
    offering: offering,
    rolloverOffering: rolloverOffering,
    rollover: rollover,
    isFetchingRolloverData: isFetchingRolloverData,
    onChangeInput: setValue
  }), enableTieredPricing && __jsx(TieredPricingContainer, {
    currentValue: value
  }))))));
};
export default InputInvestment;