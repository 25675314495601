import _Alert from "@mui/material/Alert";
import _Typography from "@mui/material/Typography";
import _extends from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/extends.js";
var __jsx = React.createElement;
import React, { useEffect } from 'react';
import isEmpty from "lodash/isEmpty";
import { LoadingButton } from '@mui/lab';
import { userApi } from '@yieldstreet/platform-kit';
import { PageFlow } from '@yieldstreet/ui-kit';
import { OnboardCheckboxStack } from '@yieldstreet/ui-kit/inputs';
import { BusinessEvent, useApiErrorMessage, useApiEventTracking, useAuth } from '@yieldstreet/tool-kit';
import { useAuthCompleted, useAuthTracking, AUTH_ERRORS } from 'utils/auth';
import useAccreditationStatus from '../hooks/useAccreditationStatus';
import { accreditationOptions, testIds } from './AccreditationSignup.model';
import { ContentContainer, FormContainer } from './AccreditationSignup.style';
const AccreditationSignup = () => {
  const {
    isAuthLoading,
    isAccreditationSubmitted
  } = useAuth();
  const completeAuth = useAuthCompleted();
  const trackAuth = useAuthTracking();
  const {
    payload,
    isAccredited,
    selectedValues,
    handleChange
  } = useAccreditationStatus();
  const [submitAccreditationStatus, submitAccreditationStatusState] = userApi.useSubmitAccreditationStatusMutation();
  const {
    isLoading,
    isSuccess
  } = submitAccreditationStatusState;
  const isSubmitting = isLoading || isAuthLoading && isSuccess;
  const errorMessage = useApiErrorMessage(submitAccreditationStatusState, AUTH_ERRORS.ACCREDITATION);
  useEffect(() => {
    if (isAccreditationSubmitted) {
      completeAuth();
    }
  }, [isAccreditationSubmitted, completeAuth]);
  useEffect(() => {
    if (isSuccess) {
      trackAuth();
    }
  }, [isSuccess, completeAuth, trackAuth]);
  useApiEventTracking(submitAccreditationStatusState, BusinessEvent.USER_SIGNUP_ACCREDITATION, {
    accredited: isAccredited
  }, errorMessage);
  return __jsx(PageFlow, null, __jsx(ContentContainer, null, __jsx(_Typography, _extends({
    component: "h1",
    fontWeight: 500,
    variant: "h5"
  }, testIds.title.attr), "Are you an accredited investor?"), __jsx(_Typography, _extends({
    component: "p",
    sx: {
      mt: 3,
      mb: 6
    }
  }, testIds.description.attr), "To help us understand which of our investment opportunities is best for you, please provide some information about your accredited status, which we will verify later in the account setup process."), __jsx(FormContainer, null, __jsx(OnboardCheckboxStack, _extends({
    onChange: handleChange,
    options: accreditationOptions,
    slotProps: {
      size: 'medium'
    },
    spacing: 4,
    sx: {
      mb: 2
    },
    values: selectedValues
  }, testIds.questions.attr)), errorMessage && __jsx(_Alert, _extends({
    severity: "error"
  }, testIds.error.attr), errorMessage), __jsx(LoadingButton, _extends({
    disabled: isSubmitting || isEmpty(selectedValues),
    fullWidth: true,
    loading: isSubmitting,
    onClick: () => submitAccreditationStatus(payload)
  }, testIds.submit.attr), "Continue"))));
};
export default AccreditationSignup;