import _Stack from "@mui/material/Stack";
import _Divider from "@mui/material/Divider";
import _Typography from "@mui/material/Typography";
import _Alert from "@mui/material/Alert";
import _extends from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/extends.js";
import _objectSpread from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
var __jsx = React.createElement;
import React, { useCallback, useEffect, useState } from 'react';
import { authApi, LoginResultStatus } from '@yieldstreet/platform-kit';
import { AuthType, BusinessEvent, eventManager, useApiErrorMessage, useApiEventTracking } from '@yieldstreet/tool-kit';
import { useAnalyticsIds } from 'utils/analytics';
import { useAuthRecaptcha, useAuthFlow, useAuthMeta, AUTH_ERRORS } from 'utils/auth';
import useAccreditationStatus from 'pages/signup/newSignup/hooks/useAccreditationStatus';
import { AppleAuth, GoogleAuth } from './methods';
import { testIds } from './SocialLogin.model';
export const SocialLogin = props => {
  const {
    hideSeperator
  } = props;
  const {
    0: authType,
    1: setAuthType
  } = useState(null);
  const [socialLogin, socialLoginState] = authApi.useSocialLoginMutation();
  const {
    isLoading,
    isSuccess,
    data: authResult
  } = socialLoginState;
  const {
    code,
    require2FA
  } = authResult || {};
  const isSignup = code === LoginResultStatus.CREATED;
  const {
    anonymousId
  } = useAnalyticsIds();
  const {
    isAuthenticating
  } = useAuthFlow(authResult);
  const fetchReCaptchaToken = useAuthRecaptcha();
  const getMetaPayload = useAuthMeta();
  const {
    hasAccreditationStatus,
    isAccredited,
    payload: accreditationPayload
  } = useAccreditationStatus();
  const doSocialLogin = useCallback(async _ref => {
    let {
      token,
      authType,
      userDetails = {}
    } = _ref;
    setAuthType(authType);
    const metaPayload = getMetaPayload();
    const recaptchaV3 = (await fetchReCaptchaToken('socialLogin')) || undefined;
    return socialLogin({
      token,
      anonymousId,
      recaptchaV3,
      userDetails,
      authData: _objectSpread(_objectSpread({}, metaPayload), accreditationPayload)
    });
  }, [accreditationPayload, anonymousId, fetchReCaptchaToken, getMetaPayload, socialLogin]);
  const errorMessage = useApiErrorMessage(socialLoginState, AUTH_ERRORS.SOCIAL_LOGIN);
  useApiEventTracking(socialLoginState, isSignup ? BusinessEvent.USER_SIGNUP_STARTED : BusinessEvent.USER_LOGIN_STARTED, {
    type: authType,
    require2FA
  }, errorMessage);
  useEffect(() => {
    if (isSignup && isSuccess && hasAccreditationStatus) {
      // if accreditation status payload was sent with social call it
      // means accreditation was submitted so fire accrediation event
      eventManager.track(BusinessEvent.USER_SIGNUP_ACCREDITATION, {
        accredited: isAccredited
      });
    }
  }, [isSignup, isSuccess, hasAccreditationStatus, isAccredited]);
  return __jsx(_Stack, _extends({
    direction: "column",
    gap: 3
  }, testIds.base.attr), __jsx(GoogleAuth, _extends({}, props, {
    loading: authType === AuthType.GOOGLE && (isLoading || isAuthenticating),
    disabled: isLoading,
    onSuccess: token => doSocialLogin({
      token,
      authType: AuthType.GOOGLE
    })
  })), __jsx(AppleAuth, _extends({}, props, {
    loading: authType === AuthType.APPLE && (isLoading || isAuthenticating),
    disabled: isLoading,
    onSuccess: (token, userDetails) => doSocialLogin({
      token,
      authType: AuthType.APPLE,
      userDetails
    })
  })), errorMessage && __jsx(_Alert, _extends({
    severity: "error"
  }, testIds.error.attr), errorMessage), !hideSeperator && __jsx(_Divider, {
    component: "div",
    role: "separator",
    sx: {
      mb: 1
    }
  }, __jsx(_Typography, {
    sx: {
      color: 'text.secondary',
      fontSize: 13
    }
  }, "or")));
};