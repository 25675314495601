import { createYupObjectSchema } from '@yieldstreet/tool-kit';
export const SET_PASSWORD_MODAL_ID = 'SET_PASSWORD_MODAL_ID';
export const INITIAL_VALUES = {
  password: '',
  passwordConfirm: '',
  reason: '',
  message: '',
  type: ''
};
export const validateMatchingPassword = values => {
  return values.password !== values.passwordConfirm ? {
    passwordConfirm: 'Your passwords must match.'
  } : {};
};
export const SetPasswordSchema = createYupObjectSchema({
  password: {
    validationType: 'password'
  },
  passwordConfirm: {
    validationType: 'default-string'
  }
});