import _extends from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/extends.js";
var __jsx = React.createElement;
import React, { useEffect, useCallback, useMemo, useState } from 'react';
import { Redirect } from 'react-router-dom';
import { isValidSignupStep, useFlags } from '@yieldstreet/tool-kit';
import { PageFlow, StepCounter } from '@yieldstreet/ui-kit';
import { PageLoader } from '@yieldstreet/ui-kit/layouts';
import { SignupQuizSteps as SignupQuizStepsType } from '@yieldstreet/platform-kit';
import { useUserState } from 'utils/hooks';
import MetaData from 'sharedComponents/MetaData';
import { PersonalizationPage } from '../personalizationPage/PersonalizationPage';
import { SignupQuizSteps } from './SignupQuizSteps/SignupQuizSteps';
import { useSignupQuiz } from './hooks/useSignupQuiz';
import { onSubmitSignupQuizStep } from './SignupQuiz.util';
import { testIds } from './SignupQuiz.model';
export const SignupQuiz = _ref => {
  let {
    history,
    location,
    match
  } = _ref;
  const {
    search
  } = location;
  const {
    path
  } = match;
  const {
    accountPersonalization
  } = useFlags({
    accountPersonalization: false
  });
  const {
    currentStep,
    currentStepObj,
    currentStepCount,
    setCurrentStep,
    getQuizAnswer,
    saveQuizAnswer,
    isLoadingAnswers,
    lastAnsweredStep,
    stepFlow
  } = useSignupQuiz();
  const {
    0: loading,
    1: setLoading
  } = useState(true);
  const {
    0: showPreSignupLoader,
    1: setShowPreSignupLoader
  } = useState(false);
  useEffect(() => {
    if (lastAnsweredStep) {
      if (lastAnsweredStep.nextStep && lastAnsweredStep.nextStep !== currentStep) {
        setCurrentStep(lastAnsweredStep.nextStep);
        history.push(`${path}/${lastAnsweredStep.nextStep}${search}`);
      } else if (lastAnsweredStep.redirect && !accountPersonalization) {
        history.push(`/${lastAnsweredStep.redirect}`);
      }
    }
  }, [lastAnsweredStep, history, path, search, currentStep, setCurrentStep, accountPersonalization]);

  // Fix for styled components css issues on server side to be revisited with Style Components V6
  useEffect(() => {
    setLoading(false);
  }, []);
  const redirect = useMemo(() => {
    if (!currentStep && path === '/signup-quiz') {
      return {
        pathname: `/get-started/`,
        search
      };
    }
    if (currentStep === 'signup') {
      return {
        pathname: `/signup/`,
        search
      };
    }
    if (!currentStep || !isValidSignupStep(currentStep)) {
      const firstStep = Object.keys(stepFlow)[0];
      return {
        pathname: `${path}/${firstStep}`,
        search
      };
    }
    return null;
  }, [currentStep, path, search, stepFlow]);
  useEffect(() => {
    if (accountPersonalization && redirect && currentStep === SignupQuizStepsType.SIGNUP) {
      setShowPreSignupLoader(true);
      setTimeout(() => {
        history.push(redirect);
      }, 4000);
    } else if (redirect) {
      if (!currentStep) {
        const firstStep = Object.keys(stepFlow)[0];
        setCurrentStep(firstStep);
      }
      history.push(redirect);
    }
  }, [accountPersonalization, currentStep, history, redirect, setCurrentStep, stepFlow]);
  const nextStepLink = useMemo(() => {
    if (currentStepObj !== null && currentStepObj !== void 0 && currentStepObj.redirect) {
      return `/${currentStepObj.redirect}`;
    }
    return `${path}/${currentStepObj.nextStep}${search}`;
  }, [search, path, currentStepObj]);
  const prevStepLink = useMemo(() => {
    return `${path}/${currentStepObj.prevStep}${search}`;
  }, [search, path, currentStepObj]);
  const nextStep = useCallback(function () {
    let trackingProperties = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    const {
      skip
    } = trackingProperties;
    localStorage.setItem('enterFromLeft', false);
    let newStep = currentStepObj !== null && currentStepObj !== void 0 && currentStepObj.redirect ? currentStepObj.redirect : currentStepObj.nextStep;
    if (!skip) {
      setCurrentStep(newStep);
      if (stepFlow) {
        onSubmitSignupQuizStep(currentStep, trackingProperties);
        if (accountPersonalization && newStep === SignupQuizStepsType.SIGNUP) {
          // prevent immediate redirect to Signup and let effect and loader show
          return;
        }
        return history.push(nextStepLink);
      }
    }
  }, [history, nextStepLink, setCurrentStep, currentStepObj, stepFlow, currentStep, accountPersonalization]);
  const prevStep = useCallback(() => {
    localStorage.setItem('enterFromLeft', true);
    if (currentStep === SignupQuizStepsType.EXPERIENCE) {
      history.push('/get-started');
      return null;
    }
    const newStep = currentStepObj.prevStep;
    setCurrentStep(newStep);
    return history.push(prevStepLink);
  }, [history, prevStepLink, setCurrentStep, currentStepObj, currentStep]);
  const userState = useUserState();
  if (userState.loggedIn) {
    return __jsx(Redirect, {
      to: '/dashboard'
    });
  }
  if (showPreSignupLoader) {
    return __jsx(PersonalizationPage, null);
  }
  if (loading || isLoadingAnswers) {
    return __jsx(PageLoader, null);
  }
  return __jsx(PageFlow, _extends({
    backNavigation: true,
    backNavigationCallback: prevStep,
    counter: __jsx(StepCounter, {
      currentStep: currentStepCount.currentStep,
      maxStep: currentStepCount.maxStep
    })
  }, testIds.pageFlow.attr), __jsx(MetaData, {
    metaData: {
      title: 'Sign Up To Access Alternative Investments - Yieldstreet',
      description: 'Access real estate, legal, marine, art and diversified investment funds. Sign up to Yieldstreet, your online alternative investment platform.  Join today!',
      canonicalUrl: `/signup-quiz/${currentStep}/`,
      url: `/signup-quiz/${currentStep}/`
    }
  }), __jsx(SignupQuizSteps, {
    path: path,
    nextStep: nextStep,
    saveQuizAnswer: saveQuizAnswer,
    getQuizAnswer: getQuizAnswer
  }));
};