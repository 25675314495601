import _Box from "@mui/material/Box";
import _Typography from "@mui/material/Typography";
import _extends from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/extends.js";
var __jsx = React.createElement;
import React, { useMemo } from 'react';
import { useCapitalCallsCalculation } from '@yieldstreet/tool-kit';
import { NumberFormat } from 'sharedComponents/NumberFormat/NumberFormat';
import { testIds, texts } from './WalletBalance.model';
import { Table, TableValue, TableFooter, FreeInvestmentMessage } from './WalletBalance.style';
import { WalletDisclaimer } from './WalletDisclaimer';
const WalletBalance = _ref => {
  let {
    currentBalance,
    missingAmount,
    remainingBalance,
    investmentAmount,
    usingRolloverFunds,
    rolloverAdditionalFundsRequired,
    rolloverTotalFundsRequired,
    hasInsufficientFunds,
    committedAmount,
    capitalDrawnPercentage,
    promoState,
    showDisclaimer = true
  } = _ref;
  const {
    isFreeInvestmentPromo
  } = promoState || {};
  const calculation = useCapitalCallsCalculation(committedAmount, capitalDrawnPercentage);
  const numberFormatter = value => {
    return __jsx(NumberFormat, {
      decimalScale: 2,
      displayType: "text",
      fixedDecimalScale: true,
      prefix: "$",
      thousandSeparator: true,
      value: Math.abs(value)
    });
  };
  const isDepositNeeded = usingRolloverFunds ? !!rolloverAdditionalFundsRequired : !!missingAmount;
  const walletInvestmentAmount = capitalDrawnPercentage ? calculation === null || calculation === void 0 ? void 0 : calculation.fundedNow : investmentAmount;
  const finalInvestmentAmountCalculation = useMemo(() => {
    if (isFreeInvestmentPromo) {
      return 0;
    }
    if (usingRolloverFunds) {
      return rolloverTotalFundsRequired;
    }
    return walletInvestmentAmount;
  }, [isFreeInvestmentPromo, usingRolloverFunds, rolloverTotalFundsRequired, walletInvestmentAmount]);
  const remainingBalanceCalculation = useMemo(() => {
    if (usingRolloverFunds) {
      return finalInvestmentAmountCalculation - currentBalance;
    }
    if (capitalDrawnPercentage) {
      return currentBalance - (calculation === null || calculation === void 0 ? void 0 : calculation.fundedNow);
    }
    return remainingBalance - missingAmount;
  }, [usingRolloverFunds, finalInvestmentAmountCalculation, currentBalance, capitalDrawnPercentage, calculation, remainingBalance, missingAmount]);
  const currentBalanceFormatted = numberFormatter(currentBalance);
  const finalInvestmentFormatted = numberFormatter(finalInvestmentAmountCalculation);
  const remainingBalanceFormatted = numberFormatter(remainingBalanceCalculation);
  return __jsx(_Box, _extends({
    className: "wallet-balance"
  }, testIds.wrapper.attr), __jsx(_Box, null, __jsx(Table, null, __jsx("tbody", null, __jsx("tr", null, __jsx(_Typography, _extends({
    variant: "h4"
  }, testIds.paymentHeader.attr), usingRolloverFunds ? texts.paymentMethodRollover : texts.paymentMethodDefault)), __jsx(React.Fragment, null, __jsx("tr", null, __jsx("td", null, __jsx(_Typography, _extends({
    variant: "body2"
  }, testIds.investmentAmountLabel.attr), usingRolloverFunds ? texts.investmentAmountRollover : texts.investmentAmountDefault)), __jsx("td", null, __jsx(TableValue, _extends({
    variant: "body2",
    id: "wallet-investmentAmount"
  }, testIds.investmentAmountValue.attr), finalInvestmentFormatted))), __jsx("tr", null, __jsx("td", null, __jsx(_Typography, _extends({
    variant: "body2",
    sx: {
      pd: 1
    }
  }, testIds.base.attr), texts.walletBalance)), __jsx("td", null, __jsx(TableValue, _extends({
    variant: "body2",
    id: "wallet-totalCashBalance"
  }, testIds.totalCashBalance.attr), currentBalanceFormatted)))))), __jsx(TableFooter, null, __jsx("tfoot", null, __jsx("tr", null, __jsx("td", null, __jsx(_Typography, _extends({
    variant: "body2",
    fontWeight: "semiBold"
  }, testIds.depositRequiredAmountLabel.attr), isDepositNeeded ? texts.depositNeeded : texts.depositDefault)), __jsx("td", null, __jsx(_Typography, _extends({
    variant: "body2",
    fontWeight: "semiBold",
    sx: {
      textAlign: 'right'
    },
    id: "wallet-depositRequiredAmount"
  }, testIds.depositRequiredAmountValue.attr), remainingBalanceFormatted)))))), isFreeInvestmentPromo && __jsx(FreeInvestmentMessage, testIds.freeInvestmentMessage.attr, __jsx(_Typography, {
    variant: "body1",
    sx: {
      mb: 1
    }
  }, "Payment by Yieldstreet"), __jsx(_Typography, {
    variant: "body2"
  }, "We assure you no amount will be subtracted from your wallet for this investment.")), showDisclaimer && hasInsufficientFunds && !isFreeInvestmentPromo && __jsx(WalletDisclaimer, null));
};
export default WalletBalance;