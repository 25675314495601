import { Label } from '@yieldstreet/ui-kit';
import styled from 'styled-components';
import { OfferingListAlignment, OfferingListAlignmentFirstChild, OfferingListAlignmentLastChild, OfferingListContentRightMixin, OfferingListFlexMixin } from '../OfferingList.style';
export const OfferingListHeaderLabel = styled(Label).withConfig({
  displayName: "OfferingListHeaderstyle__OfferingListHeaderLabel",
  componentId: "sc-1fbkwgt-0"
})(["", " &:first-child{", "}&:last-child{", "}", ";"], OfferingListAlignment, OfferingListAlignmentFirstChild, OfferingListAlignmentLastChild, props => props !== null && props !== void 0 && props.rightAlign ? OfferingListContentRightMixin : '');
export const OfferingListHeaderLabelWrapper = styled.div.withConfig({
  displayName: "OfferingListHeaderstyle__OfferingListHeaderLabelWrapper",
  componentId: "sc-1fbkwgt-1"
})(["", ""], OfferingListFlexMixin);