import { Paragraph } from '@yieldstreet/ui-kit';
import styled from 'styled-components';
export const InvestorAccountsList = styled.div.withConfig({
  displayName: "EditAddressInvestorSyncstyle__InvestorAccountsList",
  componentId: "sc-o9x77w-0"
})(["display:inline-block;width:100%;"]);
export const InvestorAccountItem = styled.div.withConfig({
  displayName: "EditAddressInvestorSyncstyle__InvestorAccountItem",
  componentId: "sc-o9x77w-1"
})(["display:inline-flex;justify-content:space-between;align-items:center;width:100%;padding:", ";margin-bottom:", ";background:", ";"], props => props.theme.spacing.sm, props => props.theme.spacing.s, props => props.theme.colors.background_card);
export const InvestorAccountDetails = styled.div.withConfig({
  displayName: "EditAddressInvestorSyncstyle__InvestorAccountDetails",
  componentId: "sc-o9x77w-2"
})(["display:inline-block;"]);
export const InvestorAccountAction = styled.div.withConfig({
  displayName: "EditAddressInvestorSyncstyle__InvestorAccountAction",
  componentId: "sc-o9x77w-3"
})(["display:inline-block;padding-left:", ";"], props => props.theme.spacing.sm);
export const InvestorAccountStatusIcon = styled.img.withConfig({
  displayName: "EditAddressInvestorSyncstyle__InvestorAccountStatusIcon",
  componentId: "sc-o9x77w-4"
})(["margin-right:", ";"], props => props.theme.spacing.xs);
export const InvestorAccountError = styled(Paragraph).withConfig({
  displayName: "EditAddressInvestorSyncstyle__InvestorAccountError",
  componentId: "sc-o9x77w-5"
})(["margin-top:", ";color:", ";"], props => props.theme.spacing.xs, props => props.theme.colors.text_error_main);