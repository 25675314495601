import _Stack from "@mui/material/Stack";
import _Box from "@mui/material/Box";
import _Typography from "@mui/material/Typography";
var __jsx = React.createElement;
import React from 'react';
import { LoadingLogo, LoadingLogoType, PageFlow } from '@yieldstreet/ui-kit';
import { SPaper, SStack } from './PersonalizationPage.style';
export const PersonalizationPage = () => {
  return __jsx(PageFlow, {
    showLogo: true
  }, __jsx(_Stack, {
    direction: "column",
    flexGrow: 1
  }, __jsx(_Box, {
    sx: {
      m: 'auto'
    }
  }, __jsx(LoadingLogo, {
    size: {
      width: '66px',
      height: '66px'
    },
    type: LoadingLogoType.NO_LOGO
  }), __jsx(SStack, {
    direction: "column"
  }, __jsx(_Typography, {
    component: "h4",
    variant: "h4"
  }, "Tailoring your Yieldstreet experience"), __jsx(_Typography, {
    component: "p",
    variant: "body1"
  }, "This may take a few seconds."))), __jsx(SPaper, {
    elevation: 0
  }, __jsx(_Typography, {
    component: "h6",
    variant: "h6",
    sx: {
      mb: 3
    }
  }, "Did you know...."), __jsx(_Typography, {
    component: "p",
    variant: "body2"
  }, "Yieldstreet works with Wilshire, an asset manager with $1.3T in assets under advisement (AUA), to customize private market portfolios for a range of investor goals and preferences."))));
};