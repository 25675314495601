import { ManagedPortfolioStrategies } from '@yieldstreet/platform-kit';
import { genTestIds } from '@yieldstreet/tool-kit';
import income from './assets/income.png';
import balanced from './assets/balanced.png';
import growth from './assets/growth.png';
const {
  getTestIds
} = genTestIds(['title', 'subtitle', 'image', 'footer']);
export const testIds = getTestIds('strategy-modal');
export const STRATEGY_MODAL = testIds.base.id;
export const investmentStrategyCopy = {
  title: '{{strategy}} strategy historical performance',
  subtitle: 'Simulated past performance of an {{strategy}} portfolio based on its current holdings from October 2020 to March 2024.'
};
export const investmentStrategyGraph = {
  [ManagedPortfolioStrategies.INCOME]: income,
  [ManagedPortfolioStrategies.BALANCED]: balanced,
  [ManagedPortfolioStrategies.GROWTH]: growth
};