import _Typography from "@mui/material/Typography";
import _extends from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/extends.js";
var __jsx = React.createElement;
import React from 'react';
import { CSSTransition } from 'react-transition-group';
import { PageLoader } from '@yieldstreet/ui-kit/layouts';
import { HeroBanner, PageContainer, PageGrid } from '@yieldstreet/ui-kit';
import { AdviceCard } from 'sharedComponents/Advice';
import { Communication } from 'sharedComponents/Communication';
import FreeInvestmentBanner from 'sharedComponents/OfferingPromo/Banners/FreeInvestment/FreeInvestment';
import { REIT_URL_HASH, REIT_FREE_SHARES_PROMO_CODE } from '../../freeShares/FreeShares.model';
import { AccountProgressContainer } from '../components/AccountProgress';
import { CommunicationDashboard } from '../components/CommunicationDashboard';
import { DashboardPromoCards } from '../components/DashboardPromoCards';
import { Goals } from '../components/Goals';
import { LatestDeals } from '../components/LatestDeals';
import { NewInvestorsProducts } from '../components/NewInvestorsProducts';
import { RecommendedResources } from '../components/RecommendedResources';
import { headerDashboard, testIds } from './Dashboard.model';
import { DashboardWrapper, HeroContainer, ModuleContainer } from './Dashboard.style';

/**
 * @deprecated use `Dashboard.tsx` instead.
 * */
export const Dashboard = _ref => {
  let {
    dashboardData,
    hasAdvice,
    history,
    isShowingPortfolioGoals,
    loading,
    userAudiences,
    userState
  } = _ref;
  const {
    accreditedSignUp
  } = userState;
  const {
    activeRecentOfferings,
    openOfferingsLength,
    openAssetClassesLength,
    investorsProducts
  } = dashboardData;
  if (loading) {
    return __jsx(PageLoader, testIds.loader.attr);
  }
  return __jsx(CSSTransition, {
    in: true,
    classNames: "fade",
    appear: true
  }, __jsx(DashboardWrapper, testIds.content.attr, __jsx(HeroContainer, {
    className: "dashboard-hero-container"
  }, __jsx(HeroBanner, {
    testId: `${testIds.firstName.id}`,
    inverse: true,
    title: `${userState.firstName},`,
    sideContent: __jsx(AccountProgressContainer, null)
  }, __jsx(_Typography, _extends({
    component: "h4",
    variant: "h4",
    inverse: true
  }, testIds.heading.attr), headerDashboard.heading), __jsx(_Typography, _extends({
    component: "p",
    variant: "body1",
    inverse: true
  }, testIds.paragraph.attr), headerDashboard.paragraph))), __jsx(Communication, {
    location: "empty_portfolio_hero"
  }), __jsx(ModuleContainer, {
    className: "dashboard-module-container"
  }, __jsx(PageContainer, {
    fluid: true,
    full: true
  }, __jsx(PageGrid, {
    config: {
      spacing: 60
    },
    fluid: true,
    full: true
  }, __jsx(PageGrid, {
    config: {
      spacing: 20
    }
  }, __jsx(FreeInvestmentBanner, {
    urlHash: REIT_URL_HASH,
    promoKey: REIT_FREE_SHARES_PROMO_CODE,
    investmentAsset: "real estate",
    investmentTitle: "Growth & Income REIT"
  })), __jsx(CommunicationDashboard, {
    userAudiences: userAudiences
  }), hasAdvice && __jsx(AdviceCard, {
    contained: true
  }), !accreditedSignUp ? __jsx(NewInvestorsProducts, {
    history: history,
    investorsProducts: investorsProducts
  }) : __jsx(LatestDeals, {
    activeRecentOfferings: activeRecentOfferings,
    openOfferingsLength: openOfferingsLength,
    openAssetClassesLength: openAssetClassesLength
  }), __jsx(DashboardPromoCards, {
    userAudiences: userAudiences
  }), isShowingPortfolioGoals && __jsx(Goals, {
    userAudiences: userAudiences,
    page: "dashboard"
  }), __jsx(RecommendedResources, {
    userAudiences: userAudiences
  }))))));
};