import _extends from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/extends.js";
var __jsx = React.createElement;
import React, { useCallback, useMemo } from 'react';
import { skipToken } from '@reduxjs/toolkit/query';
import { NumericFormat } from 'react-number-format';
import { getDatesForRange, useTenderOfferPageCopy, Interpolation } from '@yieldstreet/tool-kit';
import { noteApi, investmentRequestOfferingsApi, overviewApi } from '@yieldstreet/platform-kit';
import { PageGrid, Row, Col, Heading, PageContainer, SkeletonText, PageLoader } from '@yieldstreet/ui-kit';
import Header from 'pages/completeInvestment/Header';
import { NumberFormat } from 'sharedComponents/NumberFormat/NumberFormat';
import warningIcon from './assets/warning.svg';
import { SLabel, NoteBanner } from './TenderOfferInput.style';
import { testIds } from './TenderOfferInput.model';
import { TenderOfferInputForm } from './TenderOfferInputForm';
import { isNotBasicTenderOfferCheck } from './shared/TenderOffer.utils';
const {
  useGetTenderOfferConfigQuery
} = noteApi;
const {
  useGetInvestorPositionQuery
} = investmentRequestOfferingsApi;
const {
  useGetPortfolioOverviewQuery
} = overviewApi;
const TenderOfferInput = _ref => {
  var _portfolioOverview$in, _portfolioOverview$pr, _portfolioOverview$pr2;
  let {
    history,
    match
  } = _ref;
  const {
    userId,
    pastUrl,
    urlHash,
    investorId
  } = match.params;
  const {
    useGetNoteDocumentsQuery
  } = noteApi;
  const {
    data: noteDocsData,
    isLoading: noteDocsLoading
  } = useGetNoteDocumentsQuery({
    urlHash
  });
  const {
    offering,
    isOfferingLoading,
    copy
  } = useTenderOfferPageCopy({
    urlHash,
    screen: 'input'
  });
  const isNotBasicTenderOffer = useMemo(() => isNotBasicTenderOfferCheck({
    fundType: offering === null || offering === void 0 ? void 0 : offering.fundType,
    legalEntityType: offering === null || offering === void 0 ? void 0 : offering.legalEntityType
  }), [offering]);
  const {
    data: investorPosition = {}
  } = useGetInvestorPositionQuery({
    noteUrlHash: urlHash,
    investorAccountId: investorId
  });
  const portfolioOverviewConfig = useMemo(() => {
    if (isNotBasicTenderOffer) {
      return skipToken;
    }
    const range = getDatesForRange({
      range: 'LIFE_TIME'
    });
    return {
      userId,
      investorAccountId: investorId,
      start: range[0],
      end: range[1],
      noteUrlHash: urlHash,
      includeRepaidPrincipal: true,
      includePrincipalWriteOff: true
    };
  }, [isNotBasicTenderOffer, userId, investorId, urlHash]);
  const {
    data: portfolioOverview = {}
  } = useGetPortfolioOverviewQuery(portfolioOverviewConfig);
  const {
    data: tenderOfferConfig
  } = useGetTenderOfferConfigQuery({
    urlHash
  });
  const {
    amount,
    nav,
    tenderId,
    noteTitle
  } = investorPosition;
  const submitCallback = useCallback(() => {
    history.push(`/liquidity-option/confirmation/${pastUrl}/${urlHash}/${investorId}/${tenderId}`);
  }, [history, pastUrl, urlHash, investorId, tenderId]);
  if (isOfferingLoading || noteDocsLoading) {
    return __jsx(PageLoader, testIds.loading.attr);
  }
  return __jsx(PageContainer, null, __jsx(PageGrid, {
    config: {
      gutter: 26,
      spacing: 26
    }
  }, __jsx(Row, null, __jsx(Col, {
    size: 12
  }, __jsx(Heading, {
    type: 3,
    marginVertical: "sm"
  }, isOfferingLoading ? __jsx(SkeletonText, {
    filled: true,
    active: true,
    lines: 2,
    align: "left"
  }) : copy === null || copy === void 0 ? void 0 : copy.title))), __jsx(Row, null, __jsx(Col, {
    size: 12
  }, __jsx(Header, {
    dataCy: testIds.header.id,
    amountLabel: "Investment balance*",
    title: isNotBasicTenderOffer ? noteTitle : portfolioOverview === null || portfolioOverview === void 0 ? void 0 : (_portfolioOverview$in = portfolioOverview.investmentDetail) === null || _portfolioOverview$in === void 0 ? void 0 : _portfolioOverview$in.title,
    customNumberFormat: __jsx(NumericFormat, {
      value: isNotBasicTenderOffer ? amount : portfolioOverview === null || portfolioOverview === void 0 ? void 0 : (_portfolioOverview$pr = portfolioOverview.principal) === null || _portfolioOverview$pr === void 0 ? void 0 : _portfolioOverview$pr.totalInvested,
      displayType: "text",
      thousandSeparator: true,
      prefix: '$',
      decimalScale: 2
    })
  }))), __jsx(Row, null, __jsx(Col, {
    size: {
      mobile: 12,
      tablet: 7,
      desktop: 7
    }
  }, nav && isNotBasicTenderOffer && __jsx(NoteBanner, null, __jsx("img", {
    src: warningIcon,
    alt: ""
  }), __jsx(SLabel, null, __jsx(Interpolation, {
    template: copy === null || copy === void 0 ? void 0 : copy.investmentBalanceDisclaimer,
    values: {
      amount: __jsx(NumberFormat, _extends({}, testIds.navAmount.attr, {
        value: nav,
        displayType: "text",
        thousandSeparator: true,
        prefix: "$",
        decimalScale: 4,
        fixedDecimalScale: true
      }))
    }
  }))), !isNotBasicTenderOffer && __jsx(NoteBanner, null, __jsx("img", {
    src: warningIcon,
    alt: ""
  }), __jsx(SLabel, null, "* Total investment balance is based on the outstanding principal balance.")))), __jsx(Row, null, __jsx(Col, {
    size: 12
  }, __jsx(TenderOfferInputForm, {
    urlHash: urlHash,
    investorAccountId: investorId,
    tenderId: tenderId,
    nav: nav,
    fundType: offering === null || offering === void 0 ? void 0 : offering.fundType,
    legalEntityType: offering === null || offering === void 0 ? void 0 : offering.legalEntityType,
    liquidationDiscount: tenderOfferConfig === null || tenderOfferConfig === void 0 ? void 0 : tenderOfferConfig.liquidationDiscountPercentageFee,
    desiredAmount: isNotBasicTenderOffer ? amount : portfolioOverview === null || portfolioOverview === void 0 ? void 0 : (_portfolioOverview$pr2 = portfolioOverview.principal) === null || _portfolioOverview$pr2 === void 0 ? void 0 : _portfolioOverview$pr2.totalInvested,
    backUrl: `/liquidity-option/what-to-know/${userId}/${pastUrl}/${urlHash}/${investorId}`,
    onSubmitCallback: submitCallback,
    copy: copy,
    documents: noteDocsData === null || noteDocsData === void 0 ? void 0 : noteDocsData.documents
  })))));
};
export default TenderOfferInput;