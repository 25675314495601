import { goalsQuiz } from '../../Goals.model';
export const isNextButtonDisabled = (values, step) => {
  switch (step) {
    case 0:
      return !values.quizGoalsRadioGroupInput_0;
    case 1:
      return !values.quizGoalsRadioGroupInput_1;
    case 2:
      return !values.quizGoalsRadioGroupInput_2;
    case 3:
      return !values.quizGoalsCurrencyTextInput_3 && !values.quizGoalsCheckboxInput_3;
    case 4:
      return !values.quizGoalsCurrencyTextInput_4 && !values.quizGoalsCheckboxInput_4;
    default:
      return true;
  }
};
export const getInitialFormikProps = getQuizAnswer => ({
  quizGoalsRadioGroupInput_0: goalsQuiz(getQuizAnswer)[0].answer,
  quizGoalsRadioGroupInput_1: parseFloat(goalsQuiz(getQuizAnswer)[1].answer),
  quizGoalsRadioGroupInput_2: parseInt(goalsQuiz(getQuizAnswer)[2].answer, 10),
  quizGoalsCheckboxInput_3: parseInt(goalsQuiz(getQuizAnswer)[3].answer, 10) === 0,
  quizGoalsCurrencyTextInput_3: parseInt(goalsQuiz(getQuizAnswer)[3].answer, 10),
  quizGoalsCurrencyTextInput_4: parseInt(goalsQuiz(getQuizAnswer)[4].answer, 10),
  quizGoalsCheckboxInput_4: parseInt(goalsQuiz(getQuizAnswer)[4].answer, 10) === 0
});