var __jsx = React.createElement;
import React from 'react';
import { NewModal } from '@yieldstreet/ui-kit';
import { EditAddressContainer } from './EditAddressContainer';
import { EDIT_ADDRESS_MODAL } from './EditAddressModal.model';
export const EditAddressModal = _ref => {
  let {
    setSnackbarProps
  } = _ref;
  return __jsx(NewModal, {
    id: EDIT_ADDRESS_MODAL,
    modalId: EDIT_ADDRESS_MODAL
  }, __jsx(EditAddressContainer, {
    setSnackbarProps: setSnackbarProps
  }));
};