var __jsx = React.createElement;
import React from 'react';
import { Label, FormCheckboxInput, FormSelectInput, Paragraph } from '@yieldstreet/ui-kit';
import { CompleteInvestmentCopy as copy, interpolate, useGetACHBusinessDay } from '@yieldstreet/tool-kit';
import styled from 'styled-components';
export const InsufficientFundsForm = _ref => {
  var _formikProps$values;
  let {
    hasAdditionalFundsPaymentMethodChoice,
    additionalFundsPaymentMethods,
    additionalFundsPaymentDetails,
    formikProps,
    isConfirmInvestmentScreen,
    error,
    isACHPayment = false
  } = _ref;
  const addDelay = (_formikProps$values = formikProps.values) !== null && _formikProps$values !== void 0 && _formikProps$values.processOn ? 2 : 0;
  const {
    formattedBusinessDay
  } = useGetACHBusinessDay(new Date(), addDelay);
  return __jsx(React.Fragment, null, __jsx(Wrapper, {
    "data-cy": "additional-funds-method"
  }, __jsx(ContentWrapper, null, __jsx(Content, null, hasAdditionalFundsPaymentMethodChoice ? __jsx(FormWrapper, {
    "data-cy": "additional-funds-dropdown"
  }, __jsx(FormSelectInput, {
    id: "additional-funds-dropdown-input",
    name: "additionalFundsPaymentMethod",
    label: isACHPayment ? 'Source of funds*' : 'Source for additional funds*',
    placeholder: "Select payment method",
    formikProps: formikProps,
    options: additionalFundsPaymentMethods,
    fullWidth: true
  }), error && __jsx(Label, null, __jsx(ErrorLabel, null, "There was an error submitting your funds. Please try again"))) : __jsx(React.Fragment, null, __jsx(SinglePaymentTitle, null, isACHPayment ? 'Source of funds*' : 'Source for additional funds*'), __jsx(SinglePaymentLabel, {
    "data-cy": "additional-funds-single"
  }, additionalFundsPaymentDetails === null || additionalFundsPaymentDetails === void 0 ? void 0 : additionalFundsPaymentDetails.label))), additionalFundsPaymentDetails !== null && additionalFundsPaymentDetails !== void 0 && additionalFundsPaymentDetails.processOn.length && !isConfirmInvestmentScreen ? __jsx(React.Fragment, null, __jsx(Label, null, interpolate(copy.ACH.footnote1, {
    ACHInitiationDate: formattedBusinessDay
  })), __jsx(Label, null, copy.ACH.footnote2), __jsx(Label, null, copy.ACH.footnote3), __jsx(Content, {
    "data-cy": "additional-funds-process-on-checkbox"
  }, __jsx(FormCheckboxInput, {
    name: "processOn",
    label: "Wait 2 days before pulling funds",
    formikProps: formikProps
  }))) : null)));
};
const Wrapper = styled.div.withConfig({
  displayName: "InsufficientFundsForm__Wrapper",
  componentId: "sc-146vfyi-0"
})(["display:flex;position:relative;"]);
const ContentWrapper = styled.div.withConfig({
  displayName: "InsufficientFundsForm__ContentWrapper",
  componentId: "sc-146vfyi-1"
})(["width:100%;display:flex;flex-direction:column;flex-wrap:nowrap;justify-content:flex-start;align-content:stretch;align-items:stretch;gap:", ";"], _ref2 => {
  let {
    theme
  } = _ref2;
  return theme.spacing.sm;
});
const Content = styled.div.withConfig({
  displayName: "InsufficientFundsForm__Content",
  componentId: "sc-146vfyi-2"
})([""]);
const FormWrapper = styled.div.withConfig({
  displayName: "InsufficientFundsForm__FormWrapper",
  componentId: "sc-146vfyi-3"
})([""]);
const SinglePaymentTitle = styled(Label).withConfig({
  displayName: "InsufficientFundsForm__SinglePaymentTitle",
  componentId: "sc-146vfyi-4"
})(["display:block;margin-bottom:10px;"]);
const SinglePaymentLabel = styled(Paragraph).withConfig({
  displayName: "InsufficientFundsForm__SinglePaymentLabel",
  componentId: "sc-146vfyi-5"
})([""]);
const ErrorLabel = styled.span.withConfig({
  displayName: "InsufficientFundsForm__ErrorLabel",
  componentId: "sc-146vfyi-6"
})(["color:", ";"], props => props.theme.colors.red1);