var __jsx = React.createElement;
import React, { Component } from 'react';
import { BeneficiariesModalView } from './BeneficiariesModalView';
export class BeneficiariesModal extends Component {
  render() {
    let {
      visible,
      hideModal,
      primaryBeneficiary,
      contingentBeneficiary
    } = this.props;
    return __jsx(BeneficiariesModalView, {
      visible: visible,
      hideModal: hideModal,
      primaryBeneficiary: primaryBeneficiary,
      contingentBeneficiary: contingentBeneficiary
    });
  }
}
export default BeneficiariesModal;