import _extends from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/extends.js";
import _Box from "@mui/material/Box";
import _Stack from "@mui/material/Stack";
import _Typography from "@mui/material/Typography";
var __jsx = React.createElement;
import React, { useMemo } from 'react';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Carousel from 'nuka-carousel';
import { PageGrid } from '@yieldstreet/ui-kit';
import { OfferingList } from 'sharedComponents/OfferingList/OfferlingList';
import { AnchorWrapper, ItemBox, MobileParagraph, SLink, StyledAnchor } from './LatestDeals.style';
import { getItems, latestDealsConstants, offeringListConfig, tableHeaderLabels, testIds } from './LatestDeals.model';
export const latestDealItems = (items, isNaiUser) => {
  return items.map(_ref => {
    let {
      text,
      url,
      icon,
      value,
      hasAnchor
    } = _ref;
    return __jsx(SLink, {
      key: text,
      to: url,
      "data-cy": `${testIds.itemsLink.id}-${text}`
    }, __jsx(ItemBox, {
      isNaiUser: isNaiUser
    }, __jsx(_Stack, {
      direction: "row"
    }, __jsx("img", {
      src: icon,
      alt: text,
      "data-cy": `${testIds.itemsImage.id}-${text}`
    }), __jsx(_Box, {
      sx: {
        ml: 4
      }
    }, __jsx(_Typography, {
      variant: "h5",
      "data-cy": `${testIds.itemsHeader.id}-${text}`
    }, value), __jsx(_Stack, {
      direction: "row"
    }, __jsx(_Typography, {
      variant: "body2",
      "data-cy": `${testIds.itemsLabel.id}-${text}`
    }, text), hasAnchor && __jsx(AnchorWrapper, null, __jsx("sup", null, __jsx(StyledAnchor, {
      destination: "#sup6"
    }, "6"))))))));
  });
};
export const LatestDeals = _ref2 => {
  let {
    activeRecentOfferings,
    openAssetClassesLength,
    openOfferingsLength
  } = _ref2;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));
  const items = useMemo(() => getItems({
    openAssetClassesLength,
    openOfferingsLength
  }), [openAssetClassesLength, openOfferingsLength]);
  return __jsx(PageGrid, _extends({
    config: {
      spacing: 20
    }
  }, testIds.base.attr), __jsx(_Typography, _extends({
    variant: "h4"
  }, testIds.heading.attr), latestDealsConstants.heading), __jsx(MobileParagraph, _extends({
    variant: "body1"
  }, testIds.mobileParagraph.attr), latestDealsConstants.mobileParagraph), __jsx(_Stack, {
    direction: "row",
    justifyContent: "space-between",
    sx: {
      mt: 4,
      mb: 5
    }
  }, isMobile || isTablet ? __jsx(Carousel, _extends({
    cellSpacing: 10,
    renderBottomCenterControls: null,
    renderCenterLeftControls: null,
    renderCenterRightControls: null,
    slidesToShow: isMobile ? 1.2 : 1.7
  }, testIds.carousel.attr), latestDealItems(items)) : latestDealItems(items)), __jsx(OfferingList, {
    config: offeringListConfig,
    headings: tableHeaderLabels,
    offerings: activeRecentOfferings
  }));
};