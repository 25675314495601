import { mockUserOptions } from './SAMLFakeCadre.mock';
export const initUser = mockUserOptions[0];
export const inputs = [{
  id: 'legal_first_name',
  label: 'Legal First Name'
}, {
  id: 'legal_last_name',
  label: 'Legal Last Name'
}, {
  id: 'contact_name',
  label: 'Contact Name'
}, {
  id: 'contact_id',
  label: 'Contact ID'
}, {
  id: 'email',
  label: 'Email',
  type: 'email'
}];