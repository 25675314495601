import _extends from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/extends.js";
var __jsx = React.createElement;
import * as React from 'react';
const SvgGoogle = props => __jsx("svg", _extends({
  xmlns: "http://www.w3.org/2000/svg",
  fill: "none",
  viewBox: "0 0 24 24"
}, props), __jsx("path", {
  fill: "#4285F4",
  d: "M20.66 12.689c0-.699-.057-1.209-.18-1.738H12.5v3.154h4.684c-.094.784-.604 1.965-1.737 2.758l-.016.106 2.523 1.955.175.017c1.605-1.483 2.531-3.664 2.531-6.252"
}), __jsx("path", {
  fill: "#34A853",
  d: "M12.5 21c2.295 0 4.222-.756 5.629-2.059l-2.682-2.078c-.718.5-1.681.85-2.947.85-2.248 0-4.155-1.483-4.836-3.532l-.1.009-2.623 2.03-.034.096A8.49 8.49 0 0 0 12.5 21"
}), __jsx("path", {
  fill: "#FBBC05",
  d: "M7.664 14.181a5.2 5.2 0 0 1-.283-1.681c0-.586.104-1.152.274-1.681l-.005-.113-2.656-2.063-.087.041A8.5 8.5 0 0 0 4 12.5c0 1.37.33 2.663.907 3.816z"
}), __jsx("path", {
  fill: "#EB4335",
  d: "M12.5 7.287c1.596 0 2.673.69 3.287 1.265l2.399-2.342C16.712 4.84 14.795 4 12.5 4a8.49 8.49 0 0 0-7.593 4.684l2.748 2.135c.69-2.05 2.597-3.532 4.845-3.532"
}));
export default SvgGoogle;