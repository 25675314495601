import _objectSpread from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
var __jsx = React.createElement;
import React, { useCallback, useMemo } from 'react';
import { InvestorEntityProvider, investorEntityApi } from '@yieldstreet/platform-kit';
import { Heading, Button, PageLoader, Paragraph, Label } from '@yieldstreet/ui-kit';
import iconCheckmark from 'assets/icons/images/check3.svg';
import { ModalContent, SButtonContainer, LoaderWrapper } from '../shared/shared';
import { InvestorAccountsList, InvestorAccountItem, InvestorAccountDetails, InvestorAccountAction, InvestorAccountError, InvestorAccountStatusIcon } from './EditAddressInvestorSync.style';
import { fullAddressPreview } from './EditAddressInvestorSync.utils';
import { defaultError, testIds } from './EditAddressModal.model';
export const EditAddressInvestorSync = _ref => {
  let {
    userAddress = {},
    onBack,
    onDone
  } = _ref;
  const {
    useGetInvestorEntityQuery,
    useUpdateInvestorEntityMutation
  } = investorEntityApi;
  const {
    data: investorEntities,
    isFetching: fetchingInvestorEntities
  } = useGetInvestorEntityQuery();
  const [updateInvestorEntity, {
    isLoading: updatingInvestorEntity,
    originalArgs: latestUpdateParams,
    error: updateInvestorEntityError
  }] = useUpdateInvestorEntityMutation();
  const syncInvestorEntityAddress = useCallback(investorEntityId => {
    updateInvestorEntity({
      address: _objectSpread({}, userAddress),
      investorEntityId
    });
  }, [updateInvestorEntity, userAddress]);
  const isAddressUpdatable = useCallback((addressPreview, provider) => {
    if (!userAddress || !addressPreview) {
      return false;
    }
    const userAddressPreview = fullAddressPreview(userAddress);
    return addressPreview !== userAddressPreview && provider.type !== InvestorEntityProvider.ETC;
  }, [userAddress]);
  const investorAddresses = useMemo(() => {
    return investorEntities ? investorEntities.entities.map(_ref2 => {
      let {
        id,
        name,
        address,
        provider
      } = _ref2;
      const addressPreview = fullAddressPreview(_objectSpread({}, address));
      return {
        id,
        name,
        addressPreview,
        addressSync: isAddressUpdatable(addressPreview, provider)
      };
    }) : null;
  }, [investorEntities, isAddressUpdatable]);
  const hasSyncInvestorAddresses = useMemo(() => investorAddresses === null || investorAddresses === void 0 ? void 0 : investorAddresses.filter(_ref3 => {
    let {
      addressSync
    } = _ref3;
    return addressSync;
  }).length, [investorAddresses]);
  const investorAddressList = useMemo(() => investorAddresses ? __jsx(InvestorAccountsList, null, investorAddresses.map(_ref4 => {
    var _updateInvestorEntity, _updateInvestorEntity2;
    let {
      id,
      name,
      addressPreview,
      addressSync
    } = _ref4;
    return __jsx(InvestorAccountItem, {
      key: id,
      "data-cy": testIds.syncInvestor
    }, __jsx(InvestorAccountDetails, null, __jsx(Paragraph, {
      "data-cy": testIds.syncInvestorName
    }, name), __jsx(Label, {
      "data-cy": testIds.syncInvestorAddress
    }, addressPreview), updateInvestorEntityError && latestUpdateParams.investorEntityId === id && __jsx(InvestorAccountError, {
      small: true,
      "data-cy": testIds.syncInvestorError
    }, (updateInvestorEntityError === null || updateInvestorEntityError === void 0 ? void 0 : (_updateInvestorEntity = updateInvestorEntityError.response) === null || _updateInvestorEntity === void 0 ? void 0 : (_updateInvestorEntity2 = _updateInvestorEntity.data) === null || _updateInvestorEntity2 === void 0 ? void 0 : _updateInvestorEntity2.message) || defaultError)), __jsx(InvestorAccountAction, null, addressSync ? __jsx(Button, {
      small: true,
      reducedPadding: true,
      disabled: fetchingInvestorEntities,
      loading: updatingInvestorEntity && latestUpdateParams.investorEntityId === id,
      onClick: () => syncInvestorEntityAddress(id),
      trackingId: testIds.syncInvestorButton,
      buttonType: "secondary"
    }, "Update") : __jsx(InvestorAccountStatusIcon, {
      src: iconCheckmark,
      "data-cy": testIds.syncInvestorStatus
    })));
  })) : __jsx(LoaderWrapper, null, __jsx(PageLoader, {
    center: true
  })), [investorAddresses, latestUpdateParams, fetchingInvestorEntities, updatingInvestorEntity, updateInvestorEntityError, syncInvestorEntityAddress]);
  return __jsx(ModalContent, {
    "data-cy": testIds.sync
  }, __jsx(Heading, {
    type: 3,
    marginBottom: true
  }, "Update address for investor accounts"), __jsx(Paragraph, {
    marginBottom: true,
    "data-cy": testIds.syncMessage
  }, hasSyncInvestorAddresses ? 'Would you like to update any of your investor account addresses?' : 'All of your investor account addresses match your updated address.'), investorAddressList, __jsx(SButtonContainer, null, __jsx(Button, {
    onClick: onBack,
    trackingId: testIds.syncBack,
    buttonType: "secondary"
  }, "Back"), __jsx(Button, {
    onClick: onDone,
    trackingId: testIds.syncDone,
    type: "submit"
  }, "Done")));
};