import _Box from "@mui/material/Box";
import _Typography from "@mui/material/Typography";
var __jsx = React.createElement;
import React from 'react';
import { useParams } from 'react-router-dom';
import { DepositFundsForm } from './components/DepositFundsForm/DepositFundsForm';
import { testIds } from './DepositFunds.model';
import { PageWrapper } from './DepositFunds.style';
export const DepositFunds = () => {
  const {
    investorEntityId
  } = useParams();
  return __jsx(PageWrapper, {
    testId: testIds.page.id,
    background: "dark"
  }, __jsx(_Typography, {
    variant: "h3",
    mb: 10,
    sx: {
      fontSize: {
        xs: 18,
        md: 34
      },
      fontWeight: {
        xs: 500,
        md: 300
      }
    }
  }, "Fund your", ' ', __jsx(_Typography, {
    variant: "h3",
    component: 'span',
    sx: {
      fontSize: {
        xs: 18,
        md: 34
      },
      fontWeight: 'bold'
    }
  }, "Yieldstreet 360"), ' ', "portfolio"), __jsx(_Box, {
    sx: {
      width: '100%'
    }
  }, __jsx(DepositFundsForm, {
    investorEntityId: investorEntityId
  })));
};