import _Button from "@mui/material/Button";
import _Link from "@mui/material/Link";
import _Typography from "@mui/material/Typography";
var __jsx = React.createElement;
import React from 'react';
import { Modal } from '@yieldstreet/ui-kit';
import { FlexContainer, ModalWrapper } from './BeneficiariesModalView.style';
export const BeneficiariesModalView = _ref => {
  let {
    hideModal,
    primaryBeneficiary,
    contingentBeneficiary,
    visible
  } = _ref;
  if (!visible) {
    return null;
  }
  return __jsx(Modal, {
    visible: visible,
    clickClose: () => hideModal('showModalBeneficiaries')
  }, __jsx(ModalWrapper, null, __jsx(_Typography, {
    variant: "h3",
    sx: {
      mb: 8
    }
  }, "Summary of beneficiaries"), __jsx(FlexContainer, null, primaryBeneficiary && __jsx("div", null, __jsx(_Typography, {
    variant: "h6",
    sx: {
      mb: 2
    }
  }, "Primary beneficiary"), __jsx(_Typography, {
    variant: "subtitle"
  }, primaryBeneficiary.name), __jsx(_Typography, {
    variant: "subtitle2"
  }, primaryBeneficiary.email), __jsx(_Typography, {
    variant: "subtitle2"
  }, primaryBeneficiary.phone)), contingentBeneficiary && __jsx("div", null, __jsx(_Typography, {
    variant: "h6",
    sx: {
      mb: 2
    }
  }, "Contingent beneficiary"), __jsx(_Typography, {
    variant: "subtitle"
  }, contingentBeneficiary.name), __jsx(_Typography, {
    variant: "subtitle2"
  }, contingentBeneficiary.email), __jsx(_Typography, {
    variant: "subtitle2"
  }, contingentBeneficiary.phone))), __jsx(_Typography, {
    variant: "h6",
    sx: {
      mb: 2
    }
  }, "Beneficiary agreement"), __jsx(React.Fragment, null, primaryBeneficiary && __jsx(_Typography, {
    variant: "body1"
  }, "Beneficiary agreement signed as of ", primaryBeneficiary.disclosure, "."), contingentBeneficiary && __jsx(_Typography, {
    variant: "body1"
  }, "Contingent agreement signed as of ", contingentBeneficiary.disclosure, "."), __jsx(_Typography, {
    variant: "body1"
  }, "For more information, see the", ' ', __jsx(_Link, {
    href: "/beneficiary-agreement",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "beneficiary agreement"), ".")), __jsx(_Button, {
    onClick: () => hideModal('showModalBeneficiaries'),
    trackingId: "show-modal-beneficiaries-cancel",
    sx: {
      mt: 8
    }
  }, "Close")));
};