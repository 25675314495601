import styled from 'styled-components';
import { media } from '@yieldstreet/ui-kit';
export const HeaderWrapper = styled.div.withConfig({
  displayName: "StrategyModalHeaderstyle__HeaderWrapper",
  componentId: "sc-1t9uop3-0"
})(["text-align:center;display:flex;flex-direction:column;gap:", ";margin-bottom:", ";padding:", ";"], _ref => {
  let {
    theme
  } = _ref;
  return theme.spacing.xs;
}, _ref2 => {
  let {
    theme
  } = _ref2;
  return theme.spacing.ml;
}, _ref3 => {
  let {
    theme
  } = _ref3;
  return `0 ${theme.spacing.ml}`;
});
export const TitleWrapper = styled.div.withConfig({
  displayName: "StrategyModalHeaderstyle__TitleWrapper",
  componentId: "sc-1t9uop3-1"
})(["max-width:240px;margin:auto;", ""], media.phoneLandscape`
    max-width: 260px;
    `);