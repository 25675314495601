import _extends from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/extends.js";
import React from "react";
var __jsx = React.createElement;
import { useCallback, useMemo } from 'react';
import { Button, Heading, Label, Paragraph, useModalContext } from '@yieldstreet/ui-kit';
import { ManagedPortfolioRisksMap } from '@yieldstreet/platform-kit';
import { HighchartsWrapper } from 'sharedComponents/HighchartsWrapper';
import { testIds } from '../ManagedPortfolioSelection.model';
import { STRATEGY_MODAL } from '../StrategyModal/StrategyModal.model';
import { getChartConfig } from './StrategyCard.utils';
import { investmentStrategy } from './StrategyCard.model';
import { ButtonContainer, Card, ChartContainer, ContentWrapper, FeaturedBadge, FeaturedIcon, Indicator, IndicatorContainer, TitleContainer, IndicatorContent, SAnchorLink, PerformanceWrapper, SCallbackLink } from './StrategyCard.style';
import { fireIcon, RiskIcon } from './icons';
const buildChartData = allocations => allocations === null || allocations === void 0 ? void 0 : allocations.map(alloc => [alloc.assetType, alloc.allocationPercentage]);
export const StrategyCard = _ref => {
  let {
    assetTypeAllocations,
    investmentStrategyType,
    risk,
    featured,
    onClick,
    isMobile
  } = _ref;
  const {
    showModal
  } = useModalContext();
  const showStrategyModal = useCallback(() => showModal(STRATEGY_MODAL, {
    investmentStrategyType
  }), [showModal, investmentStrategyType]);
  const texts = investmentStrategy[investmentStrategyType];
  const chartConfig = useMemo(() => getChartConfig(buildChartData(assetTypeAllocations), featured, isMobile), [assetTypeAllocations, featured, isMobile]);
  return __jsx(Card, _extends({}, testIds.strategyCard.attr, {
    featured: featured
  }), featured && __jsx(FeaturedBadge, testIds.featuredStrategy.attr, __jsx(FeaturedIcon, {
    src: fireIcon
  }), " Recommended for you"), __jsx(TitleContainer, null, __jsx(Heading, {
    inverse: featured,
    type: 4
  }, texts.title), __jsx(Label, {
    small: true,
    inverse: featured
  }, texts.subtitle)), __jsx(ContentWrapper, null, __jsx(ChartContainer, null, __jsx(HighchartsWrapper, {
    options: chartConfig
  })), __jsx(IndicatorContainer, null, __jsx(Indicator, {
    inverse: featured
  }, __jsx(RiskIcon, {
    inverse: featured,
    risk: risk
  }), __jsx(IndicatorContent, null, __jsx(Paragraph, {
    inverse: featured,
    semiBold: true,
    secondary: true
  }, ManagedPortfolioRisksMap[risk]), __jsx(Paragraph, {
    size: "chart",
    inverse: featured,
    secondary: true
  }, "Relative expected return and risk ", __jsx(SAnchorLink, {
    destination: "#sup-a"
  }, "a"))))), __jsx(ButtonContainer, null, __jsx(Button, _extends({}, testIds.selectButton.attr, {
    onClick: onClick,
    inverse: featured,
    buttonType: featured ? 'primary' : 'secondary'
  }), "Select strategy")), __jsx(PerformanceWrapper, null, __jsx(SCallbackLink, {
    inverse: featured,
    callback: showStrategyModal
  }, __jsx(Label, {
    inverse: featured
  }, "View historical performance")))));
};