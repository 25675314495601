import _Typography from "@mui/material/Typography";
var __jsx = React.createElement;
import React, { useEffect, useState } from 'react';
import isEmpty from "lodash/isEmpty";
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { PageLoader } from '@yieldstreet/ui-kit/layouts';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { getMarketplaceRecommendations } from 'store/actions/marketplace';
import { useUserState } from 'utils/hooks';
import useActions from 'utils/hooks/useActions';
import useQuiz from 'utils/hooks/useQuiz';
import { DefaultState } from './DefaultState';
import { OfferingsResults } from './OfferingsResults';
import { Questions } from './Questions';
import { ResultsNotFit } from './ResultsNotFit';
import { GoalsQuizStates, goalsQuiz, TEST_IDS } from './Goals.model';
import { DashboardDesktopWrapper, MobileWrapper } from './Goals.style';
const actions = {
  getMarketplaceRecommendations
};
const getLastAnsweredQuestionIndex = goalsQuiz => goalsQuiz.findIndex(quiz => quiz.answer === undefined);
export const Goals = _ref => {
  let {
    isUserLogged = false,
    page = 'portfolio',
    setMarketplaceQuizCompleted,
    userAudiences,
    userHasInvestments
  } = _ref;
  const {
    saveQuizAnswer,
    getQuizAnswer
  } = useQuiz('goals-quiz');
  const lastAnsweredQuestionIndex = getLastAnsweredQuestionIndex(goalsQuiz(getQuizAnswer));
  const haveQuestions = lastAnsweredQuestionIndex !== -1;
  const {
    0: state,
    1: setState
  } = useState('');
  const {
    0: step,
    1: setStep
  } = useState(0);
  const {
    0: retakeQuiz,
    1: setRetakeQuiz
  } = useState(false);
  const {
    allNAI
  } = userAudiences;
  const history = useHistory();
  const {
    marketplace: {
      recommendedOfferings,
      isFetching,
      haveRecommendedOfferingsFetched,
      fetchError
    }
  } = useSelector(_ref2 => {
    let {
      marketplace
    } = _ref2;
    return {
      marketplace
    };
  });
  const {
    id: userId
  } = useUserState() || {};
  const {
    getMarketplaceRecommendations
  } = useActions(actions);
  const maxOfferingsDisplay = 10;
  const slicedRecommendedOfferings = recommendedOfferings.slice(0, maxOfferingsDisplay);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const showMobileView = isMobile || page === 'portfolio';
  useEffect(() => {
    setState(haveQuestions ? GoalsQuizStates.DEFAULT : GoalsQuizStates.FETCHING_OFFERINGS);
  }, [haveQuestions]);
  useEffect(() => {
    if (state === GoalsQuizStates.FETCHING_OFFERINGS && userId && !isFetching && !fetchError) {
      getMarketplaceRecommendations(userId);
    }
    if (state === GoalsQuizStates.FETCHING_OFFERINGS && haveRecommendedOfferingsFetched) {
      if (page === 'marketplace') {
        setMarketplaceQuizCompleted();
      }
      if (isEmpty(recommendedOfferings)) {
        setState(GoalsQuizStates.RESULTS_NOT_FIT);
      } else {
        setState(GoalsQuizStates.OFFERINGS_RESULTS);
      }
    }
  }, [fetchError, getMarketplaceRecommendations, haveRecommendedOfferingsFetched, isFetching, page, recommendedOfferings, setMarketplaceQuizCompleted, state, userId]);
  useEffect(() => {
    if (retakeQuiz) {
      setState(GoalsQuizStates.QUESTIONS);
      setStep(0);
      setRetakeQuiz(false);
    }
  }, [retakeQuiz]);
  if (!isUserLogged && page === 'marketplace') {
    return __jsx(DefaultState, {
      isMobile: isMobile,
      isUserLogged: isUserLogged,
      page: page,
      showMobileView: showMobileView,
      onClick: () => {
        if (isUserLogged) {
          setStep(lastAnsweredQuestionIndex);
          setState(GoalsQuizStates.QUESTIONS);
        } else {
          history.push('/signup');
        }
      }
    });
  }
  if (isEmpty(userAudiences) || allNAI) {
    return null;
  }
  if (page === 'marketplace' && ![GoalsQuizStates.DEFAULT, GoalsQuizStates.QUESTIONS].includes(state)) {
    return null;
  }
  if (fetchError) {
    const errorMessage = __jsx(_Typography, {
      component: "h4",
      variant: "h4"
    }, "Sorry, something went wrong. Please try again.");
    return showMobileView ? __jsx(MobileWrapper, null, errorMessage) : __jsx(DashboardDesktopWrapper, null, errorMessage);
  }
  if (state === GoalsQuizStates.DEFAULT) {
    return __jsx(DefaultState, {
      isMobile: isMobile,
      isUserLogged: isUserLogged,
      page: page,
      showMobileView: showMobileView,
      userHasInvestments: userHasInvestments,
      onClick: () => {
        setStep(lastAnsweredQuestionIndex);
        setState(GoalsQuizStates.QUESTIONS);
      }
    });
  }
  if (state === GoalsQuizStates.QUESTIONS) {
    return __jsx(Questions, {
      getQuizAnswer: getQuizAnswer,
      page: page,
      saveQuizAnswer: saveQuizAnswer,
      setState: setState,
      setStep: setStep,
      showMobileView: showMobileView,
      step: step
    });
  }
  if (state === GoalsQuizStates.OFFERINGS_RESULTS) {
    return __jsx(OfferingsResults, {
      isMobile: isMobile,
      onClick: () => history.push('/offerings'),
      page: page,
      recommendedOfferings: slicedRecommendedOfferings,
      setRetakeQuiz: setRetakeQuiz,
      showMobileView: showMobileView
    });
  }
  if (state === GoalsQuizStates.RESULTS_NOT_FIT) {
    return __jsx(ResultsNotFit, {
      onClick: () => history.push('/offerings'),
      page: page,
      showMobileView: showMobileView
    });
  }
  if (state === GoalsQuizStates.FETCHING_OFFERINGS) {
    return showMobileView ? __jsx(MobileWrapper, null, __jsx(PageLoader, {
      center: true,
      testId: TEST_IDS.goalsOverviewPageLoader
    })) : __jsx(DashboardDesktopWrapper, null, __jsx(PageLoader, {
      center: true,
      testId: TEST_IDS.goalsDashboardPageLoader
    }));
  }
  return null;
};