var __jsx = React.createElement;
import React from 'react';
import { NumberFormat } from '../../NumberFormat/NumberFormat';
import { OfferingListMobileMeta } from '../OfferingListMobileMeta/OfferingListMobileMeta';
import { testIds } from './OfferingListBody.model';
import { OfferingListBodyImage, OfferingListBodyImageTitleWrapper, OfferingListBodyItemContent, OfferingListBodyItemLink, OfferingListBodyItemRow, OfferingListBodyItemWrapper } from './OfferingListBody.style';
export const OfferingListBody = _ref => {
  let {
    offerings,
    config = {},
    isMobile
  } = _ref;
  return __jsx("div", {
    "data-cy": testIds.offeringListBody
  }, offerings === null || offerings === void 0 ? void 0 : offerings.map(offering => {
    var _config$assetClass, _config$targetReturn, _config$targetTerm, _config$minInvestment;
    return __jsx(OfferingListBodyItemRow, {
      key: offering === null || offering === void 0 ? void 0 : offering.id,
      withBoxShadow: config === null || config === void 0 ? void 0 : config.withBoxShadow,
      "data-cy": `${testIds.offeringListBodyRow}${offering.title}`
    }, __jsx(OfferingListBodyItemLink, {
      "data-cy": testIds.offeringListBodyLink,
      to: offering === null || offering === void 0 ? void 0 : offering.link
    }, __jsx(OfferingListBodyItemWrapper, null, __jsx(OfferingListBodyImageTitleWrapper, {
      "data-cy": testIds.offeringListBodyTitle
    }, __jsx(OfferingListBodyImage, {
      src: offering === null || offering === void 0 ? void 0 : offering.thumbnailImageUrl,
      alt: offering === null || offering === void 0 ? void 0 : offering.title
    }), !isMobile && __jsx(OfferingListBodyItemContent, {
      semiBold: true
    }, offering === null || offering === void 0 ? void 0 : offering.title), isMobile && __jsx(OfferingListMobileMeta, {
      item: offering,
      metaData: config === null || config === void 0 ? void 0 : config.metaData
    })), !isMobile && __jsx(OfferingListBodyItemContent, {
      "data-cy": testIds.offeringListBodyAssetClass,
      rightAlign: config === null || config === void 0 ? void 0 : (_config$assetClass = config.assetClass) === null || _config$assetClass === void 0 ? void 0 : _config$assetClass.rightAlign
    }, offering.assetClass || ''), !isMobile && (offering === null || offering === void 0 ? void 0 : offering.targetReturn) && __jsx(OfferingListBodyItemContent, {
      "data-cy": testIds.offeringListBodyTargetReturn,
      rightAlign: config === null || config === void 0 ? void 0 : (_config$targetReturn = config.targetReturn) === null || _config$targetReturn === void 0 ? void 0 : _config$targetReturn.rightAlign
    }, __jsx(NumberFormat, {
      value: offering.targetReturn,
      displayType: "text",
      suffix: "%",
      decimalScale: 2
    })), !isMobile && (offering === null || offering === void 0 ? void 0 : offering.targetTerm) && __jsx(OfferingListBodyItemContent, {
      "data-cy": testIds.offeringListBodyTargetTerm,
      rightAlign: config === null || config === void 0 ? void 0 : (_config$targetTerm = config.targetTerm) === null || _config$targetTerm === void 0 ? void 0 : _config$targetTerm.rightAlign
    }, offering.targetTerm), !isMobile && (offering === null || offering === void 0 ? void 0 : offering.minInvestment) && __jsx(OfferingListBodyItemContent, {
      "data-cy": testIds.offeringListBodyMinInvestment,
      rightAlign: config === null || config === void 0 ? void 0 : (_config$minInvestment = config.minInvestment) === null || _config$minInvestment === void 0 ? void 0 : _config$minInvestment.rightAlign
    }, __jsx(NumberFormat, {
      value: offering.minInvestment,
      displayType: "text",
      thousandSeparator: true,
      prefix: "$"
    })))));
  }));
};