import { media, Button, ButtonContainer, Paragraph, Heading, Label } from '@yieldstreet/ui-kit';
import styled from 'styled-components';
export const ContentWrapper = styled.div.withConfig({
  displayName: "TenderOfferInputstyle__ContentWrapper",
  componentId: "sc-a9yj8n-0"
})(["margin:40px 0;"]);
export const SHeading = styled(Heading).withConfig({
  displayName: "TenderOfferInputstyle__SHeading",
  componentId: "sc-a9yj8n-1"
})(["margin-bottom:20px;"]);
export const NoteBanner = styled.div.withConfig({
  displayName: "TenderOfferInputstyle__NoteBanner",
  componentId: "sc-a9yj8n-2"
})(["display:flex;flex-direction:row;align-items:flex-start;padding:", " ", ";gap:", ";background:", ";border-radius:4px;"], props => props.theme.spacing.sm, props => props.theme.spacing.s, props => props.theme.spacing.s, props => props.theme.colors.border_default);
export const SLabel = styled(Label).withConfig({
  displayName: "TenderOfferInputstyle__SLabel",
  componentId: "sc-a9yj8n-3"
})(["color:", ";"], props => props.theme.colors.text_label);
export const SubmitButton = styled(Button).withConfig({
  displayName: "TenderOfferInputstyle__SubmitButton",
  componentId: "sc-a9yj8n-4"
})(["", ";"], media.tablet`
    min-width: 90px;
  `);
export const AmountWrapper = styled.div.withConfig({
  displayName: "TenderOfferInputstyle__AmountWrapper",
  componentId: "sc-a9yj8n-5"
})(["margin:", " 0;"], props => props.theme.spacing.m);
export const InputWrapper = styled.div.withConfig({
  displayName: "TenderOfferInputstyle__InputWrapper",
  componentId: "sc-a9yj8n-6"
})(["display:flex;flex-direction:column;", ";"], media.tablet`
    flex-direction: row;
  `);
export const AcknowledgeWrapper = styled.div.withConfig({
  displayName: "TenderOfferInputstyle__AcknowledgeWrapper",
  componentId: "sc-a9yj8n-7"
})([""]);
export const DisclaimerLabel = styled(Label).withConfig({
  displayName: "TenderOfferInputstyle__DisclaimerLabel",
  componentId: "sc-a9yj8n-8"
})(["display:block;margin-bottom:20px;"]);
export const SharesWrapper = styled.div.withConfig({
  displayName: "TenderOfferInputstyle__SharesWrapper",
  componentId: "sc-a9yj8n-9"
})(["display:flex;flex-direction:column;justify-content:space-around;padding:6px 0 6px 10px;margin:10px 0 20px;min-width:205px;height:60px;background:", ";", ";"], props => props.theme.colors.input_disabled_background, media.tablet`
    margin: 0 0 0 10px;
  `);
export const DiscountedAmountWrapper = styled(SharesWrapper).withConfig({
  displayName: "TenderOfferInputstyle__DiscountedAmountWrapper",
  componentId: "sc-a9yj8n-10"
})(["padding:6px 10px;"]);
export const SharesLabel = styled(Label).withConfig({
  displayName: "TenderOfferInputstyle__SharesLabel",
  componentId: "sc-a9yj8n-11"
})(["color:", ";"], props => props.theme.colors.input_disabled_text);
export const DiscountedAmountLabel = styled(SharesLabel).withConfig({
  displayName: "TenderOfferInputstyle__DiscountedAmountLabel",
  componentId: "sc-a9yj8n-12"
})([""]);
export const SharesQty = styled(Paragraph).withConfig({
  displayName: "TenderOfferInputstyle__SharesQty",
  componentId: "sc-a9yj8n-13"
})(["color:", ";"], props => props.theme.colors.input_disabled_text);
export const DiscountedAmountQty = styled(SharesQty).withConfig({
  displayName: "TenderOfferInputstyle__DiscountedAmountQty",
  componentId: "sc-a9yj8n-14"
})([""]);
export const SButtonContainer = styled(ButtonContainer).withConfig({
  displayName: "TenderOfferInputstyle__SButtonContainer",
  componentId: "sc-a9yj8n-15"
})(["flex-direction:column-reverse;button{&:not(:last-child){margin:0;}&:last-child{margin:0 0 20px 0;}}", ";"], media.desktop`
    flex-direction: row;

    button {
      &:not(:last-child) {
        margin-right: 20px;
      }

      &:last-child {
        margin: 0;
      }
    }
  `);