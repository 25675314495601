import React from "react";
var __jsx = React.createElement;
import { Route, Switch, useLocation, useRouteMatch } from 'react-router-dom';
import { useAuthCheck } from 'utils/auth';
import { LinkSocialAccount } from './LinkSocialAccount/LinkSocialAccount';
import { LoginCredentialsForm } from './LoginCredentialsForm/LoginCredentialsForm';
import Login2FA from './Login2FA/Login2FA';
const Login = () => {
  const {
    path
  } = useRouteMatch();
  const location = useLocation();
  useAuthCheck();
  return __jsx(Switch, {
    location: location
  }, __jsx(Route, {
    strict: true,
    path: `${path}/link-identity`,
    render: () => __jsx(LinkSocialAccount, null)
  }), __jsx(Route, {
    strict: true,
    path: `${path}/twofa`,
    render: () => __jsx(Login2FA, null)
  }), __jsx(Route, {
    strict: true,
    path: path,
    render: () => __jsx(LoginCredentialsForm, null)
  }));
};
export default Login;