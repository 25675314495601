import _Box from "@mui/material/Box";
import _Button from "@mui/material/Button";
import _Alert from "@mui/material/Alert";
import _Typography from "@mui/material/Typography";
import _extends from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/extends.js";
var __jsx = React.createElement;
import React, { useCallback, useRef, useState } from 'react';
import LoadingButton from '@mui/lab/LoadingButton';
import { Formik } from 'formik';
import { FormikTextInput } from '@yieldstreet/ui-kit/inputs';
import { PageLoader } from '@yieldstreet/ui-kit';
import { twoFactorAuthApi, User2FAMethod, semiAnonymizePhoneRaw } from '@yieldstreet/platform-kit';
import { BusinessEvent, useApiErrorMessage, useApiEventTracking, useApiFormErrors } from '@yieldstreet/tool-kit';
import { AUTH_ERRORS } from 'utils/auth';
import SecondsCountdown from 'sharedComponents/SecondsCountdown';
import { FieldsContainer, FormContainer } from '../../../Login.style';
import { testIds, phoneRegex } from '../../Login2FA.model';
import { TwoFAFormSchema } from './CodeForm.model';
export const CodeForm = _ref => {
  var _message$match;
  let {
    codeMutation,
    codeMutationResult,
    registerResult
  } = _ref;
  const form = useRef(null);
  const {
    0: countStarted,
    1: setCountStarted
  } = useState(false);
  const {
    data: twoFAStatus
  } = twoFactorAuthApi.useStatusQuery({});
  const {
    method: authMethod,
    phone
  } = (twoFAStatus === null || twoFAStatus === void 0 ? void 0 : twoFAStatus.status) || {};
  const {
    method: registerMethod,
    message
  } = registerResult.data || {};
  const {
    isLoading
  } = codeMutationResult;
  const errorMessage = useApiErrorMessage(codeMutationResult, AUTH_ERRORS.VERIFY_2FA);
  useApiFormErrors(codeMutationResult, form);
  useApiEventTracking(codeMutationResult, BusinessEvent.USER_LOGIN_VERIFY_2FA, {
    authMethod
  }, errorMessage);
  const [twoFAResend] = twoFactorAuthApi.useResendMutation();
  const handleResend = useCallback(() => {
    setCountStarted(true);
    twoFAResend({});
  }, [twoFAResend, setCountStarted]);
  const handleSubmit = useCallback((values, formikActions) => codeMutation({
    token: values.twoFACode
  }).then(() => formikActions.setSubmitting(false)), [codeMutation]);
  if (!twoFAStatus) {
    return __jsx(PageLoader, {
      testId: testIds.loading.id
    });
  }
  const authType = authMethod || registerMethod;
  const authPhone = semiAnonymizePhoneRaw(phone) || (message === null || message === void 0 ? void 0 : (_message$match = message.match(phoneRegex)) === null || _message$match === void 0 ? void 0 : _message$match[0]);
  const authTypeTitle = () => {
    if (authType === User2FAMethod.AUTHY_APP) {
      return 'Open the Authy Authenticator app and enter the Yieldstreet verification code below.';
    }
    return __jsx(React.Fragment, null, "Please enter the verification code we sent to ", authPhone, ".", __jsx("br", null), "It may take a few minutes to receive the code");
  };
  return __jsx(React.Fragment, null, __jsx(_Typography, _extends({
    variant: "h4",
    component: "h1"
  }, testIds.title.attr), "Confirm your verification code"), __jsx(_Typography, {
    variant: "body1",
    sx: {
      mb: 4
    }
  }, authTypeTitle), __jsx(FormContainer, null, __jsx(Formik, {
    ref: form,
    initialValues: {
      twoFACode: ''
    },
    validationSchema: TwoFAFormSchema,
    onSubmit: handleSubmit
  }, formikProps => __jsx(FieldsContainer, null, __jsx(FormikTextInput, _extends({
    autoComplete: "off",
    autoFocus: true,
    formikProps: formikProps,
    label: "Enter 6-digit code",
    name: "twoFACode",
    slotProps: {
      htmlInput: {
        maxLength: 6
      }
    },
    type: "tel"
  }, testIds.codeField.attr)), errorMessage && __jsx(_Alert, _extends({
    severity: "error",
    sx: {
      mt: 2
    }
  }, testIds.errorNotification.attr), errorMessage), __jsx(LoadingButton, _extends({
    fullWidth: true,
    loading: isLoading,
    sx: {
      mt: 2
    },
    type: "submit"
  }, testIds.submit.attr), "Confirm verification code"))), authType === User2FAMethod.TEXT_MESSAGE && __jsx(_Box, null, !countStarted ? __jsx(_Button, _extends({
    variant: "outlined",
    onClick: () => handleResend(),
    fullWidth: true
  }, testIds.resend.attr), "Resend") : __jsx(_Typography, {
    sx: {
      textAlign: 'center'
    }
  }, "Please wait", ' ', __jsx(SecondsCountdown, {
    seconds: 30,
    onComplete: () => setCountStarted(false)
  }), " to try again"))));
};