import { genTestIds } from '@yieldstreet/tool-kit';
const {
  getTestIds
} = genTestIds(['loading-view', 'data-view', 'input-bank-account', 'input-deposit-amount', 'input-sign', 'error-notification-bankAccount', 'error-notification-onboarding', 'cancel-button', 'submit-button']);
export const testIds = getTestIds('mp-deposit-funds-form');
export const DepositFundsLimitDefaults = {
  minInvestment: 25000,
  maxInvestment: 250000,
  stepIncrement: 1000
};
export const DepositFundsInitialValues = {
  depositAmount: undefined,
  bankAccountId: ''
};