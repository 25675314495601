import _Button from "@mui/material/Button";
import _Typography from "@mui/material/Typography";
import _extends from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/extends.js";
var __jsx = React.createElement;
import React from 'react';
import { useHistory } from 'react-router-dom';
import { PageFlow } from '@yieldstreet/ui-kit';
import { RouterLink } from '@yieldstreet/ui-kit/navigation';
import { SignupQuizSteps } from '@yieldstreet/platform-kit';
import { ButtonWrapper, ContentWrapper } from './GetStarted.style';
import { SKIP_QUIZ_KEY, testIds } from './GetStarted.model';
const GetStarted = () => {
  const history = useHistory();
  const onGetStarted = () => {
    localStorage.setItem(SKIP_QUIZ_KEY, false);
    history.push(`/signup-quiz/${SignupQuizSteps.EXPERIENCE_ALTS}`);
  };
  return __jsx(PageFlow, {
    smallHeader: true
  }, __jsx(ContentWrapper, null, __jsx(_Typography, _extends({
    component: "h3",
    variant: "h3"
  }, testIds.title.attr), "Personalize your experience"), __jsx(_Typography, _extends({
    component: "p",
    variant: "body1",
    sx: {
      mt: 4
    }
  }, testIds.subtitle.attr), "We\u2019re going to ask you a few questions to help you through the account setup process and suggest the strategy that best fits your investment goals."), __jsx(ButtonWrapper, null, __jsx(_Button, _extends({
    fullWidth: true,
    onClick: onGetStarted
  }, testIds.letsGetStarted.attr), "Let\u2019s get started")), __jsx(_Typography, {
    component: "span",
    variant: "body1",
    sx: {
      textAlign: 'center'
    }
  }, "Have an account?", ' ', __jsx(RouterLink, _extends({
    to: "/login/"
  }, testIds.loginLink.attr), "Log in"))));
};
export default GetStarted;