var __jsx = React.createElement;
import React, { useRef } from 'react';
import { BusinessEvent, useApiErrorMessage, useApiEventTracking, useApiFormErrors, createYupObjectSchema } from '@yieldstreet/tool-kit';
import { PageContainer } from '@yieldstreet/ui-kit/layouts';
import { resetPasswordApi } from '@yieldstreet/platform-kit';
import { Formik, Form } from 'formik';
import MetaData from 'sharedComponents/MetaData';
import { metaData, successNotificationDescription, emailAddressInput, submitButtonTitle, forgotPassword, testIds } from './ForgotPassword.model';
import { FormikTextInput } from '@yieldstreet/ui-kit/inputs';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import LoadingButton from '@mui/lab/LoadingButton';
import { ForgotPasswordBox } from './ForgotPassword.style';
const ForgotPasswordFormSchema = createYupObjectSchema({
  email: {
    validationType: 'email'
  }
});
export const ForgotPassword = () => {
  const form = useRef();
  const [resetPassword, resetPasswordState] = resetPasswordApi.useResetPasswordMutation();
  const {
    isSuccess,
    isLoading
  } = resetPasswordState;
  const errorMessage = useApiErrorMessage(resetPasswordState);
  useApiFormErrors(resetPasswordState, form);
  useApiEventTracking(resetPasswordState, BusinessEvent.USER_LOGIN_RESET_PASSWORD, {}, errorMessage);
  return __jsx(React.Fragment, null, __jsx(MetaData, {
    metaData: metaData
  }), __jsx(PageContainer, null, __jsx(ForgotPasswordBox, null, __jsx(Box, {
    sx: {
      pt: 25
    }
  }, __jsx(Typography, {
    "data-cy": testIds.screenTitle,
    variant: "h3",
    component: "h1",
    sx: {
      mb: 10
    }
  }, forgotPassword), errorMessage && __jsx(Alert, {
    severity: "error",
    "data-cy": testIds.errorNotification
  }, errorMessage), isSuccess ? __jsx(Alert, {
    severity: "success",
    "data-cy": testIds.successNotification
  }, successNotificationDescription) : __jsx(Formik, {
    ref: form,
    initialValues: {
      email: ''
    },
    validationSchema: ForgotPasswordFormSchema,
    onSubmit: (values, formikActions) => {
      resetPassword(values).then(() => formikActions.setSubmitting(false));
    }
  }, formikProps => {
    return __jsx(Form, null, __jsx(FormikTextInput, {
      "data-cy": testIds.input,
      label: emailAddressInput,
      type: "email",
      name: "email",
      testId: testIds.input,
      formikProps: formikProps,
      fullWidth: true,
      sx: {
        mb: 6
      }
    }), __jsx(LoadingButton, {
      loading: isLoading,
      type: "submit",
      fullWidth: true,
      "data-trackingId": "forgotpassword-reset",
      "data-cy": testIds.submitButton
    }, submitButtonTitle));
  })))));
};