import { isCorrectIncrement } from '@yieldstreet/tool-kit';
export const useDepositFundsValidators = _ref => {
  let {
    depositAmount,
    limits
  } = _ref;
  const {
    minInvestment,
    maxInvestment,
    stepIncrement
  } = limits;
  const isRangeValid = depositAmount ? depositAmount >= minInvestment && depositAmount <= maxInvestment : false;
  const isIncrementValid = !!depositAmount && isCorrectIncrement(depositAmount, stepIncrement);
  return {
    isRangeValid,
    isIncrementValid
  };
};