import _Alert from "@mui/material/Alert";
var __jsx = React.createElement;
import React, { useState, useMemo } from 'react';
import { AccountIncomplete, FormWrapper, SingleOptionLabel, SingleOptionTitle } from './InvestorAccountSelection.style';
import { FormikSelectInput } from '@yieldstreet/ui-kit/inputs';
export const InvestorAccountSelection = _ref => {
  var _availableInvestorAcc;
  let {
    formikProps,
    investorAccounts,
    onInvestorChange
  } = _ref;
  const {
    0: hasIncompleteAccounts,
    1: setHasIncompleteAccounts
  } = useState(false);
  const {
    0: incompleteReason,
    1: setIncompleteReason
  } = useState('');
  const availableInvestorAccountOptions = useMemo(() => {
    const options = investorAccounts === null || investorAccounts === void 0 ? void 0 : investorAccounts.map(account => {
      const isInvalidAccount = !!account.gateData;
      if (isInvalidAccount && !hasIncompleteAccounts && !formikProps.values.investorAccountId) {
        var _account$gateData, _account$urlHashReaso;
        setIncompleteReason((account === null || account === void 0 ? void 0 : (_account$gateData = account.gateData) === null || _account$gateData === void 0 ? void 0 : _account$gateData.gateText) || (account === null || account === void 0 ? void 0 : (_account$urlHashReaso = account.urlHashReason) === null || _account$urlHashReaso === void 0 ? void 0 : _account$urlHashReaso[0]));
        setHasIncompleteAccounts(true);
      }
      return {
        value: account.id || account.investorAccountId,
        label: isInvalidAccount ? `${account.name} *` : account.name,
        disabled: isInvalidAccount
      };
    });
    return options;
  }, [hasIncompleteAccounts, formikProps.values.investorAccountId, investorAccounts]);
  const handleInvestorChange = prop => {
    setHasIncompleteAccounts(false);
    onInvestorChange(prop);
  };
  const hasMoreThanOneInvestor = (availableInvestorAccountOptions === null || availableInvestorAccountOptions === void 0 ? void 0 : availableInvestorAccountOptions.length) > 1;
  return __jsx(React.Fragment, null, hasMoreThanOneInvestor ? __jsx(React.Fragment, null, __jsx(FormWrapper, null, __jsx(FormikSelectInput, {
    id: "account-dropdown",
    name: "investorAccountId",
    "data-cy": "investorAccountId",
    label: "Investor Account",
    placeholder: "Choose Investor Account",
    formikProps: formikProps,
    options: availableInvestorAccountOptions,
    fullWidth: true,
    variant: "outlined",
    onChange: handleInvestorChange
  })), hasIncompleteAccounts && __jsx(_Alert, {
    severity: "warning",
    "data-cy": "investor-account-selection-notification"
  }, __jsx(React.Fragment, null, "You have an account that is not eligible to invest. It is denoted with a (*) mark.", ' ', __jsx(AccountIncomplete, {
    "data-cy": "investor-account-selection-notification-reason"
  }, "\u2022 ", incompleteReason || 'Account set-up Incomplete')))) : __jsx(React.Fragment, null, __jsx(SingleOptionTitle, null, "Investor account"), __jsx(SingleOptionLabel, {
    "data-cy": "investor-account-single-name"
  }, availableInvestorAccountOptions === null || availableInvestorAccountOptions === void 0 ? void 0 : (_availableInvestorAcc = availableInvestorAccountOptions[0]) === null || _availableInvestorAcc === void 0 ? void 0 : _availableInvestorAcc.label)));
};