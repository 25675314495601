import { genTestIds } from '@yieldstreet/tool-kit';
import tractionNumbers from '../../../../../static-files/tractionNumbers.json';
import irrIcon from './assets/irr.svg';
import openAssetClassesIcon from './assets/openAssetClasses.svg';
import openOfferingsIcon from './assets/openOfferings.svg';
const {
  getTestIds
} = genTestIds(['carousel', 'heading', 'items-header', 'items-image', 'items-label', 'items-link', 'mobile-paragraph']);
export const testIds = getTestIds('latest-deals');
export const latestDealsConstants = {
  heading: 'Latest deals on the platform',
  mobileParagraph: 'Access unique offerings across a diverse set of product types and asset classes.'
};
export const latestDealsItems = {
  openOfferings: 'Open offerings',
  openAssetClasses: 'Open asset classes',
  irr: 'Internal rate of return',
  irrValue: tractionNumbers.netIRR,
  aum: 'Total AUM',
  aumValue: tractionNumbers.totalFunded
};
export const tableHeaderLabels = [{
  title: 'Offering'
}, {
  title: 'Asset class'
}, {
  title: 'Target return',
  rightAlign: true
}, {
  title: 'Target term',
  rightAlign: true
}, {
  title: 'Min. Investment',
  rightAlign: true
}];
export const offeringListConfig = {
  targetReturn: {
    rightAlign: true
  },
  targetTerm: {
    rightAlign: true
  },
  minInvestment: {
    rightAlign: true
  },
  metaData: {
    mappedKey: 'assetClass'
  }
};
export const getItems = _ref => {
  let {
    openAssetClassesLength,
    openOfferingsLength
  } = _ref;
  return [{
    text: latestDealsItems.openOfferings,
    value: openOfferingsLength,
    icon: openOfferingsIcon,
    url: marketingUrl
  }, {
    text: latestDealsItems.openAssetClasses,
    value: openAssetClassesLength,
    icon: openAssetClassesIcon,
    url: marketingUrl
  }, {
    text: latestDealsItems.irr,
    value: latestDealsItems.irrValue,
    hasAnchor: true,
    icon: irrIcon,
    url: statisticsUrl
  }, {
    text: latestDealsItems.aum,
    value: latestDealsItems.aumValue,
    icon: irrIcon,
    url: statisticsUrl
  }];
};
export const marketingUrl = '/offerings';
export const statisticsUrl = '/statistics';