import _Box from "@mui/material/Box";
var __jsx = React.createElement;
import React from 'react';
import { ValidationIndicator } from '@yieldstreet/ui-kit';
import { formatCurrency } from '@yieldstreet/tool-kit';
import isUndefined from "lodash/isUndefined";
import { ValidationContainer } from './DepositFundsValidators.style';
import { useDepositFundsValidators } from './hooks/useDepositFundsValidators';
import { testIds } from './DepositFundsValidators.model';
export const DepositFundsValidators = _ref => {
  let {
    depositAmount,
    limits
  } = _ref;
  const {
    minInvestment,
    maxInvestment,
    stepIncrement
  } = limits;
  const {
    isIncrementValid,
    isRangeValid
  } = useDepositFundsValidators({
    depositAmount,
    limits
  });
  const isTouched = !isUndefined(depositAmount);
  return __jsx(ValidationContainer, null, __jsx(_Box, null, __jsx(ValidationIndicator, {
    testId: testIds.stepIncrement.id,
    valid: !isTouched || isIncrementValid,
    touched: isTouched
  }, formatCurrency(stepIncrement), " increments")), __jsx(_Box, null, __jsx(ValidationIndicator, {
    testId: testIds.validationRange.id,
    valid: !isTouched || isRangeValid,
    touched: isTouched
  }, formatCurrency(minInvestment), " - ", formatCurrency(maxInvestment), " investments")));
};