import _Typography from "@mui/material/Typography";
var __jsx = React.createElement;
import React from 'react';
import { CompleteInvestmentCopy as copy, interpolate, useGetACHBusinessDay } from '@yieldstreet/tool-kit';
export const ACHDisclaimer = () => {
  const {
    formattedBusinessDay
  } = useGetACHBusinessDay(new Date(), 0);
  return __jsx(React.Fragment, null, __jsx(_Typography, {
    variant: "body2",
    color: "textSecondary"
  }, interpolate(copy.ACH.footnote1, {
    ACHInitiationDate: formattedBusinessDay
  })), __jsx(_Typography, {
    variant: "body2",
    color: "textSecondary"
  }, copy.ACH.footnote3));
};