import _Link from "@mui/material/Link";
var __jsx = React.createElement;
import React from 'react';
import isEmpty from "lodash/isEmpty";
import { Link as RouterLink } from 'react-router-dom';
import { isFortyActFund } from '@yieldstreet/tool-kit';
import { CompleteOfferingCard } from './CompleteOfferingCard';
import { EmptyOfferingCard } from './EmptyOfferingCard';
export const OfferingsCard = _ref => {
  let {
    accreditationRequired,
    dataCy,
    description,
    external,
    href,
    image,
    imageAlt,
    minInvestment,
    offering,
    title
  } = _ref;
  let card = __jsx(React.Fragment, null);
  const transformTags = function () {
    let tags = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
    if (typeof tags[0] === 'string') {
      return tags.map(tag => ({
        name: tag
      }));
    }
    return tags;
  };
  if (!isEmpty(offering)) {
    var _offering$v5Data;
    const offeringTags = transformTags(offering === null || offering === void 0 ? void 0 : (_offering$v5Data = offering.v5Data) === null || _offering$v5Data === void 0 ? void 0 : _offering$v5Data.offeringTags);
    const isPrismFund = isFortyActFund(offering === null || offering === void 0 ? void 0 : offering.fundType);
    card = __jsx(CompleteOfferingCard, {
      dataCy: dataCy,
      offering: offering,
      offeringTags: offeringTags
    });
    if (isPrismFund) {
      return __jsx(_Link, {
        href: 'https://yieldstreetalternativeincomefund.com',
        target: "_blank",
        id: `${dataCy}-external-link`
      }, card);
    }
    const linkTo = `/offering/${offering.urlHash}/${encodeURI(offering.SEOTitle)}/`;
    return __jsx(RouterLink, {
      to: linkTo,
      "data-cy": `${dataCy}-link`,
      target: "_blank",
      rel: "noopener noreferrer"
    }, card);
  } else {
    card = __jsx(EmptyOfferingCard, {
      accreditationRequired: accreditationRequired,
      dataCy: dataCy,
      description: description,
      image: image,
      imageAlt: imageAlt,
      minInvestment: minInvestment,
      title: title
    });
  }
  if (external) {
    return __jsx(_Link, {
      href: href,
      target: "_blank",
      id: `${dataCy}-external-link`
    }, card);
  }
  if (href) {
    return __jsx(RouterLink, {
      to: href,
      "data-cy": `${dataCy}-link`,
      target: "_blank",
      rel: "noopener noreferrer"
    }, card);
  }
  return card;
};