import styled from 'styled-components';
import { media } from '@yieldstreet/ui-kit';
export const GraphWrapper = styled.div.withConfig({
  displayName: "StrategyModalstyle__GraphWrapper",
  componentId: "sc-39nht7-0"
})(["display:flex;justify-content:center;"]);
export const GraphImage = styled.img.withConfig({
  displayName: "StrategyModalstyle__GraphImage",
  componentId: "sc-39nht7-1"
})(["overflow:scroll;", ""], media.phoneLandscape`
    overflow: unset;
  `);