import _Button from "@mui/material/Button";
import _Grid from "@mui/material/Grid2";
import _Divider from "@mui/material/Divider";
import _Box from "@mui/material/Box";
import _Alert from "@mui/material/Alert";
import _AlertTitle from "@mui/material/AlertTitle";
import _Typography from "@mui/material/Typography";
import _Stack from "@mui/material/Stack";
import _extends from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/extends.js";
import _Skeleton from "@mui/material/Skeleton";
var __jsx = React.createElement;
import { Form, Formik } from 'formik';
import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';
import { AppAction, useInvestorEntity, usePortfolioEligibilityCheck } from '@yieldstreet/tool-kit';
import { FormikCurrencyInput, FormikSelectInput } from '@yieldstreet/ui-kit/inputs';
import { FundingStepTypes, MANAGED_PORTFOLIO_FUNDING_PATH, stepParam } from '../../../../managedPortfolioFunding/ManagedPortfolioFunding.model';
import { DepositFundsValidators } from '../DepositFundsValidators/DepositFundsValidators';
import { useSubmitDepositFundsForm } from './hooks/useSubmitDepositFundsForm';
import { DepositFundsInitialValues, DepositFundsLimitDefaults, testIds } from './DepositFundsForm.model';
import { Card } from './DepositFundsForm.style';
import { DepositFundsFormSchema } from './DepositFundsForm.schema';
import { useBankLinkingModal } from '../../../../bankLinking';
import { useBankAccounts } from '../../../../investorEntity/screens/Management/routes/BankAccounts/hooks/useBankAccounts';
import { useDiscretionaryFlags } from 'hooks';
import { PORTFOLIO_SUMMARY_SCREEN_PATH } from '../../../../portfolio';
export const DepositFundsForm = _ref => {
  let {
    investorEntityId
  } = _ref;
  const {
    investorEntity,
    isLoading: isInvestorEntityLoading
  } = useInvestorEntity(investorEntityId);
  const history = useHistory();
  const form = useRef(null);
  const requiredActions = [AppAction.DEPOSIT_REQUIRED, AppAction.DEPOSIT_RESUBMIT_REQUIRED];
  const {
    currentGate,
    isLoading: isPortfolioEligibilityLoading
  } = usePortfolioEligibilityCheck({
    investorEntityId,
    requiredActions,
    config: {
      skipPollChecks: true
    }
  });
  const {
    confirmedBankAccounts = [],
    isEntityBankAccountsLoading,
    refetchEntityBankAccounts
  } = useBankAccounts(investorEntityId);
  const {
    showManagedOnboarding,
    showManagedDepositResubmitWeb
  } = useDiscretionaryFlags();
  const {
    onSubmitDeposit,
    submitDepositIsSuccess,
    submitDepositIsLoading
  } = useSubmitDepositFundsForm({
    investorEntityId
  });
  useEffect(() => {
    if (submitDepositIsSuccess) {
      history.push({
        pathname: `${MANAGED_PORTFOLIO_FUNDING_PATH.replace(stepParam, FundingStepTypes.Welcome)}`,
        state: {
          investorEntityId
        }
      });
    }
  }, [submitDepositIsSuccess, history, investorEntityId]);
  const bankAccounts = useMemo(() => confirmedBankAccounts === null || confirmedBankAccounts === void 0 ? void 0 : confirmedBankAccounts.map(bankAccount => ({
    label: bankAccount.nickname,
    value: `${bankAccount.id}`
  })), [confirmedBankAccounts]);
  useEffect(() => {
    var _form$current;
    if (bankAccounts.length === 1 && form !== null && form !== void 0 && (_form$current = form.current) !== null && _form$current !== void 0 && _form$current.setFieldValue) {
      var _bankAccounts$;
      form.current.setFieldValue('bankAccountId', `${(_bankAccounts$ = bankAccounts[0]) === null || _bankAccounts$ === void 0 ? void 0 : _bankAccounts$.value}`);
    }
  }, [bankAccounts, form]);
  const handleOnCancel = useCallback(() => {
    history.push(PORTFOLIO_SUMMARY_SCREEN_PATH);
  }, [form]);
  const bankLinkingModal = useBankLinkingModal({
    investorEntityId,
    onSuccess: () => {
      refetchEntityBankAccounts();
    }
  });
  const validationSchema = DepositFundsFormSchema(DepositFundsLimitDefaults);
  const isLoading = isEntityBankAccountsLoading || isPortfolioEligibilityLoading || isInvestorEntityLoading;
  const isDepositResubmitDisabled = currentGate === AppAction.DEPOSIT_RESUBMIT_REQUIRED && !showManagedDepositResubmitWeb;
  const isManagedOnboardingDisabled = currentGate && !requiredActions.includes(currentGate) || !showManagedOnboarding;
  const isFormDisabled = isManagedOnboardingDisabled || isDepositResubmitDisabled;
  if (isLoading) {
    return __jsx(_Stack, _extends({
      spacing: 1
    }, testIds.loadingView.attr), __jsx(_Skeleton, {
      variant: "rounded",
      width: "100%",
      height: 144,
      sx: {
        mb: 4
      }
    }), __jsx(_Skeleton, {
      variant: "rounded",
      width: "100%",
      height: 421,
      sx: {
        mb: 12
      }
    }), __jsx(_Skeleton, {
      variant: "rounded",
      width: "50%",
      height: 22
    }));
  }
  return __jsx(Formik, {
    enableReinitialize: true,
    initialValues: DepositFundsInitialValues,
    validationSchema: validationSchema,
    onSubmit: onSubmitDeposit,
    ref: form
  }, formikProps => {
    return __jsx(Form, testIds.dataView.attr, __jsx(_Stack, null, __jsx(_Stack, {
      spacing: 4,
      direction: "column"
    }, isDepositResubmitDisabled && __jsx(_Alert, _extends({
      severity: "error"
    }, testIds.errorNotificationOnboarding.attr), __jsx(_AlertTitle, null, "Deposit retries are not available at the moment.", __jsx(_Typography, {
      variant: "body1"
    }, "Please try again later."))), isManagedOnboardingDisabled && __jsx(_Alert, _extends({
      severity: "error"
    }, testIds.errorNotificationOnboarding.attr), __jsx(_AlertTitle, null, "You are currently unable to deposit funds into YS360 portfolio"), __jsx(_Typography, {
      variant: "body1"
    }, "An issue during your registration has occurred")), __jsx(Card, null, __jsx(_Stack, {
      spacing: 5,
      sx: theme => ({
        padding: theme.spacing(6)
      })
    }, __jsx(FormikCurrencyInput, _extends({
      name: "depositAmount",
      autoFocus: !!(bankAccounts !== null && bankAccounts !== void 0 && bankAccounts.length),
      placeholder: "Investment amount",
      decimalScale: 2,
      thousandSeparator: true,
      fullWidth: true,
      disabled: isFormDisabled,
      allowNegative: false,
      formikProps: formikProps,
      variant: "outlined"
    }, testIds.inputDepositAmount.attr)), __jsx(DepositFundsValidators, {
      depositAmount: formikProps.values.depositAmount,
      limits: DepositFundsLimitDefaults
    }))), __jsx(Card, null, __jsx(_Stack, {
      direction: {
        xs: 'column',
        md: 'row'
      },
      sx: theme => ({
        padding: theme.spacing(6),
        paddingBottom: 0
      })
    }, __jsx(_Box, {
      sx: {
        flex: 1
      }
    }, __jsx(_Typography, {
      variant: "body2",
      color: "textSecondary"
    }, "Account name"), __jsx(_Typography, {
      variant: "body1"
    }, investorEntity === null || investorEntity === void 0 ? void 0 : investorEntity.name))), __jsx(_Divider, {
      sx: {
        margin: 6
      }
    }), __jsx(_Box, {
      sx: theme => ({
        padding: theme.spacing(6),
        paddingTop: 0
      })
    }, __jsx(FormikSelectInput, _extends({}, testIds.inputBankAccount.attr, {
      id: "pay-method-dropdown",
      label: "Payment method",
      placeholder: "Choose Payment Method",
      name: "bankAccountId",
      options: bankAccounts,
      fullWidth: true,
      variant: "outlined",
      formikProps: formikProps,
      disabled: isFormDisabled || (confirmedBankAccounts === null || confirmedBankAccounts === void 0 ? void 0 : confirmedBankAccounts.length) === 0
    })), !(bankAccounts !== null && bankAccounts !== void 0 && bankAccounts.length) && !isFormDisabled && __jsx(_Alert, _extends({
      sx: {
        mt: 6
      },
      severity: "error"
    }, testIds.errorNotificationBankAccount.attr, {
      onClick: () => bankLinkingModal.open()
    }), __jsx(_Grid, {
      container: true,
      sx: {
        cursor: 'pointer'
      }
    }, __jsx(_Grid, {
      size: 9
    }, __jsx(_AlertTitle, null, "You have no bank accounts linked"), __jsx(_Typography, {
      variant: "body1"
    }, "Link a bank so that you can fund your YS360 portfolio.")), __jsx(_Grid, {
      size: 3,
      textAlign: "right"
    }, __jsx(_Typography, {
      variant: "body1",
      sx: {
        fontWeight: 500
      }
    }, "Link a bank")))), __jsx(_Typography, {
      variant: "body1",
      mt: 6
    }, "Automatic ACH transfer"), __jsx(_Typography, {
      variant: "body2",
      color: "textSecondary",
      sx: {
        mb: 4
      }
    }, "By submitting, you are authorizing Yieldstreet to debit the account indicated for the amount noted on today\u2019s date. Once authorized, there cannot be any changes or corrections, and this transaction is bound by all applicable terms and provisions of the NACHA Rules."), __jsx(_Typography, {
      variant: "body2",
      color: "textSecondary"
    }, "Please ensure that required funds are available in your account. Transactions made after 3:00 PM ET or on a weekend or holiday will be processed the next business day. It is recommended that you print a copy of this authorization and maintain it for your records.")))), __jsx(_Stack, {
      spacing: 4,
      direction: {
        xs: 'column',
        sm: 'row-reverse'
      },
      sx: {
        mt: 12,
        px: {
          xs: 6,
          md: 0
        },
        justifyContent: 'start'
      }
    }, __jsx(LoadingButton, _extends({}, testIds.submitButton.attr, {
      disabled: !formikProps.isValid || isFormDisabled || submitDepositIsLoading,
      type: "submit",
      loading: submitDepositIsLoading
    }), "Fund portfolio"), __jsx(_Button, _extends({
      variant: "outlined"
    }, testIds.cancelButton.attr, {
      onClick: handleOnCancel
    }), "Cancel"))));
  });
};