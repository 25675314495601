export const metaData = {
  title: 'Forget Your Password? - Yieldstreet',
  canonicalUrl: '/password/',
  description: 'Reset your password to Yieldstreet by starting with your email address. Get a new password in seconds.'
};
export const successNotificationDescription = 'Ok, we’ve sent you an email with password reset instructions.';
export const emailAddressInput = 'Email address';
export const submitButtonTitle = 'Reset password';
export const forgotPassword = 'Forgot your password?';
export const testIds = {
  screenTitle: 'screen-title',
  input: 'input-email',
  inputError: 'error',
  submitButton: 'submit-button',
  successNotification: 'success-notification',
  errorNotification: 'error-notification'
};