import _Box from "@mui/material/Box";
import _Button from "@mui/material/Button";
import _Typography from "@mui/material/Typography";
import _Divider from "@mui/material/Divider";
var __jsx = React.createElement;
/* eslint-disable no-nested-ternary */
import React, { useState, useMemo, useCallback, useEffect } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { skipToken } from '@reduxjs/toolkit/query';
import { marketplaceApi, OfferingStatus } from '@yieldstreet/platform-kit';
import { Notification, OfferingTitle, ValidationIndicator } from '@yieldstreet/ui-kit';
import { formatCurrency, interpolate, isCorrectIncrement, Interpolation } from '@yieldstreet/tool-kit';
import info from '@yieldstreet/ui-kit/assets/graphics/icons/info.svg';
import { RouterLink } from '@yieldstreet/ui-kit/navigation';
import LoadingButton from '@mui/lab/LoadingButton';
import { FormikCurrencyInput } from '@yieldstreet/ui-kit/inputs';
import { PricingChips } from './PricingChips';
import { testIds, errorMessages, COPY as copy } from './Invest.model';
import { Container, Progression, ButtonWrapper, InvestContent, FormFieldContainer, Card, CardRow, FormGroup } from './Invest.style';
const Invest = _ref => {
  var _rollover$totals2, _offering$features$;
  let {
    offering,
    rolloverOffering,
    rollover,
    isFetchingRolloverData,
    onInvestClick,
    loggedIn,
    onChangeInput
  } = _ref;
  const OnChangeToken = _ref2 => {
    let {
      formikProps
    } = _ref2;
    useEffect(() => {
      var _formikProps$values;
      onChangeInput && onChangeInput(formikProps === null || formikProps === void 0 ? void 0 : (_formikProps$values = formikProps.values) === null || _formikProps$values === void 0 ? void 0 : _formikProps$values.investmentAmount);
    }, [formikProps]);
    return null;
  };
  const {
    0: wasFocused,
    1: setWasFocused
  } = useState(false);
  const {
    0: value,
    1: setValue
  } = useState(0);
  const {
    useGetProgressQuery
  } = marketplaceApi;
  const {
    increment,
    minimumInvestment,
    maximumInvestment
  } = useMemo(() => {
    return {
      increment: offering.purchaseIncrement,
      minimumInvestment: offering.autoInvestMinimumPurchase || offering.minimumPurchase,
      maximumInvestment: offering.autoInvestMaximumPurchase || offering.maximumPurchase
    };
  }, [offering]);
  const isRolloverReady = !isFetchingRolloverData && !!rollover;
  const maxRolloverAmount = useMemo(() => {
    if (isRolloverReady) {
      var _rollover$totals;
      return rollover === null || rollover === void 0 ? void 0 : (_rollover$totals = rollover.totals) === null || _rollover$totals === void 0 ? void 0 : _rollover$totals.maxRolloverAmount;
    }
  }, [isRolloverReady, rollover === null || rollover === void 0 ? void 0 : (_rollover$totals2 = rollover.totals) === null || _rollover$totals2 === void 0 ? void 0 : _rollover$totals2.maxRolloverAmount]);
  const getProgressConfig = useMemo(() => {
    if (!offering || ![OfferingStatus.open, OfferingStatus.preoffering].includes(offering.status)) {
      return skipToken;
    }
    return {
      key: offering.urlHash,
      noteUrlHashes: [offering.urlHash]
    };
  }, [offering]);
  const {
    data: progress,
    progressIsUninitialized,
    progressIsLoading
  } = useGetProgressQuery(getProgressConfig);
  const {
    remainingInvestment
  } = useMemo(() => {
    if (!progress) {
      return {
        remainingInvestment: null
      };
    }
    const offeringProgress = progress[offering.urlHash];
    return {
      remainingInvestment: offeringProgress === null || offeringProgress === void 0 ? void 0 : offeringProgress.remainingInvestment
    };
  }, [progress, offering]);
  const doInvest = useCallback(investmentAmount => {
    onInvestClick(investmentAmount);
  }, [onInvestClick]);
  const isWaitlisted = remainingInvestment => remainingInvestment <= 0;
  const investorType = ((_offering$features$ = offering.features[0]) === null || _offering$features$ === void 0 ? void 0 : _offering$features$.feature) || 'FTI';
  const InvestSchema = useMemo(() => Yup.object().shape({
    investmentAmount: Yup.number().transform(value => Number.isNaN(value) ? null : value).typeError(interpolate(errorMessages.mustBeNumber), {}, true).min(minimumInvestment, interpolate(errorMessages.betweenMinMax, {
      min: formatCurrency(minimumInvestment),
      max: formatCurrency(maximumInvestment)
    }, true)).max(maximumInvestment, interpolate(errorMessages.betweenMinMax, {
      min: formatCurrency(minimumInvestment),
      max: formatCurrency(maximumInvestment)
    }, true)).test('correctIncrement', interpolate(errorMessages.increment, {
      increment: formatCurrency(increment)
    }, true), value => isCorrectIncrement(value, increment)).required('Required')
  }), [minimumInvestment, maximumInvestment, increment]);
  const doesRolloverFullyFund = useMemo(() => maxRolloverAmount >= value, [maxRolloverAmount, value]);
  const onFocus = useCallback(() => {
    if (!wasFocused) {
      setWasFocused(true);
    }
  }, [wasFocused, setWasFocused]);
  return __jsx(Container, null, rolloverOffering && __jsx(Card, null, __jsx(OfferingTitle, {
    testId: "maturing-investment",
    subtitle: copy.rollover.subtitle,
    title: rolloverOffering === null || rolloverOffering === void 0 ? void 0 : rolloverOffering.title,
    icon: {
      src: rolloverOffering === null || rolloverOffering === void 0 ? void 0 : rolloverOffering.thumbnailImageUrl
    },
    compact: true
  }), __jsx(_Divider, null), __jsx(CardRow, null, __jsx(_Typography, {
    variant: "body2"
  }, copy.rollover.label), __jsx(_Typography, {
    variant: "body1"
  }, formatCurrency(maxRolloverAmount)))), __jsx(Formik, {
    initialValues: {
      investmentAmount: !!rolloverOffering ? maxRolloverAmount : null
    },
    validationSchema: rollover ? null : InvestSchema,
    onSubmit: _ref3 => {
      let {
        investmentAmount
      } = _ref3;
      doInvest(investmentAmount);
    }
  }, formikProps => {
    var _formikProps$touched;
    const investmentAmount = formikProps.values.investmentAmount;
    const isIncrementValid = isCorrectIncrement(investmentAmount, increment);
    const isRangeValid = investmentAmount && investmentAmount >= minimumInvestment && investmentAmount <= maximumInvestment;
    return __jsx(Form, null, __jsx(OnChangeToken, {
      formikProps: formikProps
    }), __jsx(FormGroup, null, __jsx(Card, null, __jsx(OfferingTitle, {
      testId: "next-investment",
      icon: {
        src: offering === null || offering === void 0 ? void 0 : offering.thumbnailImageUrl
      },
      subtitle: copy.nextInvestment,
      title: offering === null || offering === void 0 ? void 0 : offering.title,
      compact: true
    }), __jsx(InvestContent, {
      isFullyAllocated: offering && offering.remainingInvestment === 0
    }, __jsx(FormFieldContainer, {
      id: "offering-value-input-container",
      "data-cy": testIds.formContainer
    }, __jsx(FormikCurrencyInput, {
      id: "offering-valueinput",
      "data-cy": testIds.investInput,
      name: "investmentAmount",
      className: "Invest__StyledNumberFormat",
      label: interpolate(copy.inputLabel, {
        str: offering !== null && offering !== void 0 && offering.capitalCallEnabled ? 'Commitment' : 'Investment'
      }, true),
      placeholder: "XXX,XXX,XXX",
      decimalScale: 2,
      thousandSeparator: true,
      allowNegative: false,
      formikProps: formikProps,
      onFocus: onFocus,
      disabled: !!rolloverOffering
    })), !rolloverOffering && __jsx(PricingChips, {
      minimumInvestment: minimumInvestment,
      maximumInvestment: maximumInvestment,
      investorType: investorType,
      value: value,
      action: investmentValue => {
        formikProps.setFieldValue('investmentAmount', investmentValue);
        setValue(investmentValue);
        onFocus();
      }
    })), offering && offering.statusDisplayName !== 'Fully Subscribed' && __jsx(Progression, {
      "data-cy": testIds.progress
    }, rollover && __jsx(ValidationIndicator, {
      touched: wasFocused,
      valid: doesRolloverFullyFund,
      "data-cy": doesRolloverFullyFund ? testIds.fullyFunded : testIds.additionalPayment,
      config: {
        valid: {
          color: '#043449',
          icon: info
        },
        invalid: {
          color: '#043449',
          icon: info
        }
      }
    }, copy.rollover.amountDescription), !rolloverOffering && __jsx(React.Fragment, null, __jsx(ValidationIndicator, {
      touched: wasFocused,
      valid: isIncrementValid,
      "data-cy": isIncrementValid ? testIds.incrementValid : testIds.incrementInvalid
    }, __jsx(Interpolation, {
      template: copy.increment,
      values: {
        increment: formatCurrency(increment)
      }
    })), __jsx(ValidationIndicator, {
      touched: wasFocused,
      valid: isRangeValid,
      "data-cy": isRangeValid ? testIds.betweenMinMaxValid : testIds.betweenMinMaxInvalid
    }, __jsx(Interpolation, {
      template: copy.range,
      values: {
        min: formatCurrency(minimumInvestment),
        max: formatCurrency(maximumInvestment)
      }
    }))))), __jsx(ButtonWrapper, null, loggedIn ? __jsx(LoadingButton, {
      id: "offering-invest-MainCTA",
      "data-cy": testIds.mainButton,
      loading: progressIsUninitialized || progressIsLoading,
      disabled: (formikProps === null || formikProps === void 0 ? void 0 : (_formikProps$touched = formikProps.touched) === null || _formikProps$touched === void 0 ? void 0 : _formikProps$touched.investmentAmount) && !(formikProps !== null && formikProps !== void 0 && formikProps.isValid),
      "data-trackingId": "offering-invest",
      className: "Invest__StyledButton offering-invest-real",
      type: "submit"
    }, isWaitlisted(remainingInvestment) ? copy.ctaWaitList : copy.cta) : __jsx(RouterLink, {
      to: `/login?redirect=${''}`,
      sx: {
        backgroundImage: 'none'
      }
    }, __jsx(_Button, {
      id: "offering-invest-MainCTA",
      "data-cy": testIds.loginButton,
      trackingId: "offering-invest",
      className: "Invest__StyledButton offering-invest-login",
      fullWidth: true
    }, copy.ctaLogIn))), offering && offering.statusDisplayName === 'Fully Subscribed' && __jsx(_Box, {
      "data-cy": testIds.fullyAllocatedWrapper
    }, __jsx(Notification, {
      testId: "fully-subscribed-notification",
      inline: true,
      description: copy.allocationNotification,
      "data-js-fully-allocated-notification": true
    }))));
  }));
};
export default Invest;