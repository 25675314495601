import { genTestIds } from '@yieldstreet/tool-kit';
import * as Yup from 'yup';
export const INITIAL_VALUES = {
  investorInitials: '',
  desiredAmount: undefined,
  check1: false,
  check2: false,
  check3: false,
  check4: false
};
export const COPY = {
  validationRequired: 'This field is required',
  validationMoreThan: 'Please enter a value greater than 0',
  validationMax: 'Desired amount should not exceed the investment balance.',
  validationLetters: 'This field only accepts letters.'
};
export const VALIDATION_SCHEMA = {
  investorInitials: Yup.string().required(COPY.validationRequired).matches(/^[A-Za-z]+$/, {
    excludeEmptyString: true,
    message: COPY.validationLetters
  })
};
const {
  getTestIds
} = genTestIds(['header', 'nav-amount', 'loading']);
export const testIds = getTestIds('tender-input');