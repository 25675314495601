var __jsx = React.createElement;
import React from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';
import { SignupQuiz } from './SignupQuiz';
const SignupQuizContainer = () => {
  const location = useLocation();
  return __jsx(Switch, {
    location: location
  }, __jsx(Route, {
    path: "/signupQuiz",
    render: props => props.history.push(`/signup-quiz${props.location.search}`)
  }), __jsx(Route, {
    path: ['/signup-quiz', '/signup-invitation'],
    render: props => __jsx(SignupQuiz, props)
  }));
};
export default SignupQuizContainer;