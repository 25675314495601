import _Box from "@mui/material/Box";
import _Alert from "@mui/material/Alert";
import _extends from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/extends.js";
import _Typography from "@mui/material/Typography";
var __jsx = React.createElement;
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Formik } from 'formik';
import { LoadingButton } from '@mui/lab';
import { PageFlow, Paragraph } from '@yieldstreet/ui-kit';
import { PageLoader } from '@yieldstreet/ui-kit/layouts';
import { FormikPhoneInput } from '@yieldstreet/ui-kit/inputs';
import { useApiErrorMessage, useAuth } from '@yieldstreet/tool-kit';
import { userProfileApi } from '@yieldstreet/platform-kit';
import { AUTH_ERRORS, useAuthCompleted } from 'utils/auth';
import { PhoneSchema, testIds } from './PhoneSignup.model';
import { Content, FieldsContainer, FormContainer } from './PhoneSignup.style';
import SecurityLogo from 'sharedComponents/SecurityLogo';
import ToggleTooltip from 'sharedComponents/ToggleTooltip';
const PhoneSignup = () => {
  const history = useHistory();
  const {
    isAuthLoading,
    isPhoneSubmitted
  } = useAuth();
  const completeAuth = useAuthCompleted();
  const {
    0: isPhoneValid,
    1: setIsPhoneValid
  } = useState(false);
  const [updateUserProfilePhone, updateUserProfilePhoneState] = userProfileApi.useUpdateUserProfilePhoneMutation();
  const {
    isLoading: isUpdating,
    isSuccess
  } = updateUserProfilePhoneState;
  const isSubmitting = isUpdating || isAuthLoading && isSuccess;
  const isLoading = isAuthLoading && !isSuccess;
  useEffect(() => {
    if (isPhoneSubmitted && isSuccess) {
      completeAuth();
    }
  }, [isPhoneSubmitted, isSuccess, completeAuth]);
  const errorMessage = useApiErrorMessage(updateUserProfilePhoneState, AUTH_ERRORS.PHONE);
  if (isLoading) {
    return __jsx(PageLoader, testIds.pageLoader.attr);
  }
  return __jsx(PageFlow, {
    showLogo: true
  }, __jsx(Content, null, __jsx(_Typography, {
    component: "h1",
    variant: "h4",
    sx: {
      mb: 4
    }
  }, "Mobile phone number"), __jsx(_Typography, {
    component: "p",
    variant: "body1"
  }, "Please provide a valid U.S. mobile number. Your number will also be used to setup two-factor authorization later."), __jsx(FormContainer, null, __jsx(Formik, {
    validationSchema: PhoneSchema,
    initialValues: {
      phoneNumber: ''
    },
    onSubmit: (values, formikActions) => updateUserProfilePhone(values).then(() => formikActions.setSubmitting(false))
  }, formikProps => __jsx(FieldsContainer, null, __jsx(FormikPhoneInput, _extends({
    name: "phoneNumber",
    label: "Phone number",
    formikProps: formikProps,
    fullWidth: true,
    onUpdate: _ref => {
      let {
        isValid
      } = _ref;
      setIsPhoneValid(isValid);
    },
    required: true
  }, testIds.input.attr)), errorMessage && __jsx(_Alert, _extends({
    severity: "error"
  }, testIds.submitError.attr), errorMessage), __jsx(LoadingButton, _extends({
    disabled: !isPhoneValid || isSubmitting,
    fullWidth: true,
    loading: isSubmitting,
    type: "submit"
  }, testIds.submitButton.attr), "Continue")))), __jsx(_Box, {
    sx: {
      mt: 2
    }
  }, __jsx(ToggleTooltip, {
    stepLabel: "Why do we ask for this information?",
    stepInfo: __jsx(Paragraph, null, "Your phone number will primarily be used for two-factor authentication (which we require after your first investment is made). It\u2019ll also be used by our Investor Relations team who may use it to contact you about important investment issues. Your data is safe and protected with Yieldstreet. We follow digital security best practices, and will never sell your information to a third party")
  }), __jsx(SecurityLogo, null))));
};
export default PhoneSignup;