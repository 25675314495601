import * as Yup from 'yup';
export const COMPLETE_INVESTMENT_VALIDATION_SCHEMA = {
  investorAccountId: Yup.string().required('This field is required.'),
  paymentMethod: Yup.string().required('This field is required.')
};
export const SUBMIT_COMMITMENT_VALIDATION_SCHEMA = {
  investorAccountId: Yup.string().required('This field is required.')
};
export const COMPLETE_INVESTMENT_INITIAL_VALUES = {
  investorAccountId: undefined,
  paymentMethod: undefined,
  additionalFundsPaymentMethod: undefined,
  processOn: false,
  reInvest: true,
  rolloverInvestmentIds: undefined
};
export const COMPLETE_INVESTMENT_TYPE = {
  DISCRETIONARY: 'DISCRETIONARY',
  NON_DISCRETIONARY: 'NON_DISCRETIONARY'
};
export const ERRORS = {
  noInvestoraccount: 'NO_INVESTOR_ACCOUNT'
};
export const COMPLETE_INVESTMENT_STATUS_CODE = {
  LOADING: 'LOADING',
  ERROR: 'ERROR'
};
export const COMPLETE_INVESTMENT_STATUS_META = {
  GATED: 'GATED',
  TIMER_EXPIRED: 'TIMER_EXPIRED'
};
export const COMPLETE_INVESTMENT_STATUS = {
  LOADING: {
    code: COMPLETE_INVESTMENT_STATUS_CODE.LOADING
  },
  ERROR: {
    code: COMPLETE_INVESTMENT_STATUS_CODE.ERROR
  },
  GATED: {
    code: COMPLETE_INVESTMENT_STATUS_CODE.ERROR,
    meta: COMPLETE_INVESTMENT_STATUS_META.GATED
  },
  TIMER_EXPIRED: {
    code: COMPLETE_INVESTMENT_STATUS_CODE.ERROR,
    meta: COMPLETE_INVESTMENT_STATUS_META.TIMER_EXPIRED
  }
};
export const COPY = {
  wireOnly: `Due to temporary delays in ACH processing at our third party payments processor, we are temporarily accepting investment requests funded via wire transfer only. Our teams are working to restore this service as soon as possible.`,
  wireInfo: `Please wire the full amount of your investment request to Yieldstreet within 5 business days. We will email you wire instructions once you submit your request.`,
  wireInfoAlternative: `Please wire the full amount of your investment request to Yieldstreet by the 15th of next month. We will email you wire instructions once you submit your request.`
};