import _Stack from "@mui/material/Stack";
import _Tooltip from "@mui/material/Tooltip";
import _Chip from "@mui/material/Chip";
import _Typography from "@mui/material/Typography";
var __jsx = React.createElement;
import React from 'react';
import abbreviate from 'number-abbreviate';
import marketplaceOfferingDefault from 'assets/images/images/marketplaceOfferingDefault.png';
import { Info, Wrapper } from '../OfferingsCard.style';
import { OfferingImage, Paragraph } from './CompleteOfferingCard.style';
export const CompleteOfferingCard = _ref => {
  let {
    dataCy,
    offering,
    offeringTags
  } = _ref;
  return __jsx(Wrapper, {
    "data-cy": `${dataCy}-not-empty-wrapper`
  }, __jsx(OfferingImage, {
    imageUrl: offering.thumbnailImageUrl || marketplaceOfferingDefault,
    "data-cy": "investment-products-offering-card-image"
  }), __jsx(_Typography, {
    variant: "h5",
    sx: {
      mb: 3
    },
    "data-cy": `${dataCy}-heading`
  }, offering.title), __jsx("div", null, __jsx(Info, null, __jsx(Paragraph, {
    variant: "body2"
  }, "Min. investment"), __jsx(Paragraph, {
    variant: "body2",
    sx: {
      fontWeight: 'medium'
    },
    "data-cy": "investment-products-offering-card-min-investment-paragraph"
  }, "$", abbreviate(offering.minimumPurchase, 2))), __jsx(Info, null, __jsx(Paragraph, {
    variant: "body2"
  }, "Asset class"), __jsx(Paragraph, {
    variant: "body2",
    sx: {
      fontWeight: 'medium'
    },
    "data-cy": "investment-products-offering-card-asset-class-paragraph"
  }, offering.assetClassDisplayName))), (offeringTags === null || offeringTags === void 0 ? void 0 : offeringTags.length) > 0 && __jsx(_Stack, {
    "data-cy": "investment-products-offering-card-detail-tags",
    direction: "row",
    flexWrap: "nowrap",
    gap: 3
  }, offeringTags.slice(0, 2).map(offeringTag => __jsx(_Tooltip, {
    key: `offering-overview-${offering.urlHash}-${offeringTag.name}-tag-wrapper`,
    "data-cy": "investment-products-offering-tag-wrapper",
    title: offeringTag.description
  }, __jsx(_Chip, {
    "data-cy": `offering-overview-${offeringTag.name}-tag`,
    label: offeringTag.name,
    variant: "filled"
  })))));
};