import _Stack from "@mui/material/Stack";
var __jsx = React.createElement;
import React, { useCallback, useEffect } from 'react';
import { FormikCheckboxInput, FormikCurrencyInput } from '@yieldstreet/ui-kit/inputs';
import { FormRadioGroupInput } from '@yieldstreet/ui-kit';
import { RADIO_INPUT, CURRENCY_INPUT, goalsQuiz } from '../Goals.model';
import { DesktopView } from './DesktopView';
import { MobileView } from './MobileView';
import { TOTAL_STEPS, dontKnowLabel, testIds } from './Questions.model';
export const Questions = _ref => {
  let {
    getQuizAnswer,
    saveQuizAnswer,
    setState,
    setStep,
    showMobileView,
    step
  } = _ref;
  const nextStep = step + 1;
  const prevStep = step - 1;
  useEffect(() => {
    if (step === TOTAL_STEPS) {
      setState('fetchingOfferings');
    }
  }, [setState, step]);
  const handleOnClick = useCallback((values, currentStep, saveAnswer) => {
    let submitValue = '';
    switch (currentStep) {
      case 0:
        submitValue = values.quizGoalsRadioGroupInput_0;
        break;
      case 1:
        submitValue = values.quizGoalsRadioGroupInput_1;
        break;
      case 2:
        submitValue = values.quizGoalsRadioGroupInput_2;
        break;
      case 3:
        submitValue = values.quizGoalsCheckboxInput_3 ? 0 : parseFloat(values.quizGoalsCurrencyTextInput_3, 10);
        break;
      case 4:
        submitValue = values.quizGoalsCheckboxInput_4 ? 0 : parseFloat(values.quizGoalsCurrencyTextInput_4, 10);
        break;
      default:
        break;
    }
    saveQuizAnswer(saveAnswer, submitValue);
    setStep(nextStep);
  }, [nextStep, saveQuizAnswer, setStep]);
  const handlePrevQuestion = useCallback(() => {
    setStep(prevStep);
  }, [prevStep, setStep]);

  // set amounts to 0 when I don't know checkbox is checked
  const handleCurrencyInput = useCallback(formikProps => {
    if (formikProps.values.quizGoalsCheckboxInput_3) {
      formikProps.setFieldValue('quizGoalsCurrencyTextInput_3', 0);
    }
    if (formikProps.values.quizGoalsCheckboxInput_4) {
      formikProps.setFieldValue('quizGoalsCurrencyTextInput_4', 0);
    }
  }, []);
  const handleOptions = useCallback(function (currentStep, formikProps) {
    let quiz = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
    switch (quiz.questionType) {
      case CURRENCY_INPUT:
        {
          return __jsx(_Stack, {
            gap: 3
          }, __jsx(FormikCurrencyInput, {
            allowNegative: false,
            "data-cy": `${testIds.questionsCurrencyTextInput}-${currentStep}`,
            decimalScale: 2,
            disabled: currentStep === 3 && formikProps.values.quizGoalsCheckboxInput_3 || currentStep === 4 && formikProps.values.quizGoalsCheckboxInput_4,
            formikProps: formikProps,
            name: `quizGoalsCurrencyTextInput_${currentStep}`,
            thousandSeparator: true,
            variant: "outlined"
          }), __jsx(FormikCheckboxInput, {
            disabled: false,
            formikProps: formikProps,
            indeterminate: false,
            label: dontKnowLabel,
            name: `quizGoalsCheckboxInput_${currentStep}`,
            onChange: () => handleCurrencyInput(formikProps),
            testId: `${testIds.questionsFormCheckboxInput}-${currentStep}`
          }));
        }
      case RADIO_INPUT:
      default:
        return __jsx(FormRadioGroupInput, {
          name: `quizGoalsRadioGroupInput_${currentStep}`,
          options: quiz.values,
          testId: testIds.questionsFormRadioGroupInput,
          formikProps: formikProps
        });
    }
  }, [handleCurrencyInput]);
  const Component = showMobileView ? MobileView : DesktopView;
  return __jsx(Component, {
    getQuizAnswer: getQuizAnswer,
    handleOnClick: handleOnClick,
    handleOptions: handleOptions,
    handlePrevQuestion: handlePrevQuestion,
    nextStep: nextStep,
    quiz: goalsQuiz(getQuizAnswer)[step],
    step: step
  });
};