import _objectSpread from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import _objectWithoutProperties from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/objectWithoutProperties.js";
const _excluded = ["disabled", "name", "formikProps", "helperText"];
import { getIn } from 'formik';
export function fieldToComponent(_ref) {
  let {
      disabled,
      name,
      formikProps: {
        touched,
        errors,
        isSubmitting,
        handleBlur
      },
      helperText
    } = _ref,
    props = _objectWithoutProperties(_ref, _excluded);
  const fieldError = getIn(errors, name);
  const showError = getIn(touched, name) && !!fieldError;

  //https://mui.com/material-ui/react-text-field/#integration-with-3rd-party-input-libraries
  return _objectSpread(_objectSpread({
    name,
    id: props['data-cy'],
    onBlur: handleBlur,
    error: showError,
    disabled: disabled !== null && disabled !== void 0 ? disabled : isSubmitting
  }, props), {}, {
    helperText: showError ? helperText !== null && helperText !== void 0 ? helperText : fieldError : null
  });
}