import _Typography from "@mui/material/Typography";
import _extends from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/extends.js";
var __jsx = React.createElement;
import React from 'react';
import { testIds, UpfrontCapitalCallACHDisclaimer, UpfrontCapitalCallWireDisclaimer } from './UpfrontCapitalCallDisclaimer.model';
export const UpfrontCapitalCallDisclaimer = _ref => {
  let {
    isACHPayment,
    isWirePayment
  } = _ref;
  if (isACHPayment) {
    return __jsx(_Typography, _extends({
      variant: "body2"
    }, testIds.ach.attr, {
      color: "textSecondary"
    }), UpfrontCapitalCallACHDisclaimer);
  }
  if (isWirePayment) {
    return __jsx(_Typography, _extends({
      variant: "body1"
    }, testIds.wire.attr, {
      sx: {
        fontStyle: 'italic',
        maxWidth: 600
      }
    }), UpfrontCapitalCallWireDisclaimer);
  }
  return null;
};