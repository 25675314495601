var __jsx = React.createElement;
import React, { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useFlags } from '@yieldstreet/tool-kit';
import { resolvePresetAudiences } from 'store/actions/audiences';
import { useAdvice } from 'utils/advice';
import { useUserState } from 'utils/hooks';
import useActions from 'utils/hooks/useActions';
import { Dashboard as LegacyDashboard } from './LegacyDashboard/Dashboard';
import { Dashboard } from './Dashboard';
import { DashboardRedirect } from './DashboardRedirect';
import { useDashboardData } from './hooks/useDashboardData';
const actions = {
  resolvePresetAudiences
};
const DashboardContainer = _ref => {
  let {
    history
  } = _ref;
  const userState = useUserState() || {};
  const userAudiences = useSelector(_ref2 => {
    let {
      audiences
    } = _ref2;
    return audiences.data;
  });
  const userAudiencesFetching = useSelector(_ref3 => {
    let {
      audiences
    } = _ref3;
    return audiences.isFetching;
  });
  const advice = useAdvice();
  const dashboardData = useDashboardData({
    userAudiences
  });
  const {
    resolvePresetAudiences
  } = useActions(actions);
  const {
    newPortfolioDashboard: showNewPortfolioDashboard
  } = useFlags({
    newPortfolioDashboard: false
  });
  useEffect(() => {
    resolvePresetAudiences('portfolio');
  }, [resolvePresetAudiences]);
  const loading = useMemo(() => !userAudiences || userAudiencesFetching, [userAudiences, userAudiencesFetching]);
  return __jsx(DashboardRedirect, null, showNewPortfolioDashboard ? __jsx(Dashboard, null) : __jsx(LegacyDashboard, {
    history: history,
    userState: userState,
    userAudiences: userAudiences,
    loading: loading,
    hasAdvice: advice.hasAdvice,
    dashboardData: dashboardData
  }));
};
export default DashboardContainer;