import _Alert from "@mui/material/Alert";
import _Button from "@mui/material/Button";
import _objectSpread from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
var __jsx = React.createElement;
import React, { useRef, useCallback, useMemo, useEffect } from 'react';
import { Formik } from 'formik';
import { parse } from 'query-string';
import { useLocation } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import { authApi } from '@yieldstreet/platform-kit';
import { RouterLink } from '@yieldstreet/ui-kit/navigation';
import { FormikTextInput, FormikPasswordInput } from '@yieldstreet/ui-kit/inputs';
import { AuthType, BusinessEvent, eventManager, useApiErrorMessage, useApiEventTracking, useApiFormErrors } from '@yieldstreet/tool-kit';
import { ReCaptchaDisclaimer } from 'sharedComponents/ReCaptcha';
import { SocialLogin } from 'sharedComponents/SocialLogin';
import { useAnalyticsIds } from 'utils/analytics';
import { useAuthRecaptcha, useAuthMeta, useAuthFlow, AUTH_ERRORS } from 'utils/auth';
import { withFlagsPrefetch } from 'utils/flags';
import SecureImage from 'assets/images/images/secure-ssl-alt.png';
import useAccreditationStatus from '../../hooks/useAccreditationStatus';
import { AlreadyHaveAccount, FieldsContainer, FormContainer, SecureLogo, SecureLogos, SignUpEmailLink } from './PrimarySignupForm.style';
import { buttonText, initialValues, inputLabelText, linkText, PrimarySignupSchema, SignUpFormType } from './PrimarySignupForm.model';
const LinkSwitch = _ref => {
  let {
    isEmbedded,
    url,
    inverse,
    children
  } = _ref;
  return isEmbedded ? __jsx("a", {
    target: "_parent",
    href: url
  }, children) : __jsx(RouterLink, {
    to: url,
    inverse: inverse
  }, children);
};
export const PrimarySignupForm = withFlagsPrefetch(_ref2 => {
  let {
    formType = SignUpFormType.FULL,
    buttonType,
    isOnSidebar
  } = _ref2;
  const {
    search
  } = useLocation();
  const form = useRef();
  const isEmbedded = useMemo(() => {
    const searchParams = parse(search);
    return searchParams === null || searchParams === void 0 ? void 0 : searchParams.embed;
  }, [search]);
  const [passwordRegister, passwordRegisterState] = authApi.usePasswordRegisterMutation();
  const {
    isLoading,
    isSuccess,
    data: authResult
  } = passwordRegisterState;
  const {
    isAuthenticating
  } = useAuthFlow(authResult);
  const {
    anonymousId
  } = useAnalyticsIds();
  const fetchReCaptchaToken = useAuthRecaptcha();
  const getMetaPayload = useAuthMeta();
  const {
    payload: accreditationPayload,
    hasAccreditationStatus,
    isAccredited
  } = useAccreditationStatus();
  const doPasswordRegister = useCallback(async formData => {
    const metaPayload = getMetaPayload();
    const recaptchaV3 = await fetchReCaptchaToken('socialLogin');
    return passwordRegister(_objectSpread(_objectSpread(_objectSpread({
      anonymousId,
      recaptchaV3
    }, formData), metaPayload), accreditationPayload));
  }, [accreditationPayload, anonymousId, fetchReCaptchaToken, getMetaPayload, passwordRegister]);
  const errorMessage = useApiErrorMessage(passwordRegisterState, AUTH_ERRORS.PASSWORD_REGISTER);
  useApiFormErrors(passwordRegisterState, form);
  useApiEventTracking(passwordRegisterState, BusinessEvent.USER_SIGNUP_STARTED, {
    type: AuthType.PASSWORD
  }, errorMessage);
  useEffect(() => {
    if (isSuccess && hasAccreditationStatus) {
      // if accreditation status payload was sent with signup call it
      // means accreditation was submitted so fire accrediation event
      eventManager.track(BusinessEvent.USER_SIGNUP_ACCREDITATION, {
        accredited: isAccredited
      });
    }
  }, [isSuccess, hasAccreditationStatus, isAccredited]);
  const socialSignup = __jsx(SocialLogin, {
    buttonType: buttonType,
    cta: "Sign up",
    isOnSidebar: isOnSidebar
  });
  switch (formType) {
    case SignUpFormType.MINIMAL:
      return __jsx(React.Fragment, null, socialSignup, isOnSidebar ? __jsx(SignUpEmailLink, null, __jsx(LinkSwitch, {
        inverse: true,
        isEmbedded: isEmbedded,
        url: "/signup/email/"
      }, "Sign up with email")) : __jsx(LinkSwitch, {
        isEmbedded: isEmbedded,
        url: "/signup/email/"
      }, __jsx(_Button, {
        "data-cy": "signup-email",
        fullWidth: true
      }, "Sign up with email")), errorMessage && __jsx(_Alert, {
        "data-cy": "primary-signup-error",
        severity: "error"
      }, errorMessage));
    case SignUpFormType.FULL:
    default:
      return __jsx(FormContainer, null, socialSignup, __jsx(Formik, {
        ref: form,
        initialValues: initialValues,
        validationSchema: PrimarySignupSchema,
        onSubmit: (values, formikActions) => doPasswordRegister(values).then(() => formikActions.setSubmitting(false))
      }, formikProps => __jsx(FieldsContainer, null, __jsx(FormikTextInput, {
        "data-cy": "input-firstName",
        formikProps: formikProps,
        label: inputLabelText.firstName,
        maxLength: "30",
        name: "firstName",
        testId: "input-firstName",
        type: "text"
      }), __jsx(FormikTextInput, {
        "data-cy": "input-lastName",
        formikProps: formikProps,
        label: inputLabelText.lastName,
        maxLength: "30",
        name: "lastName",
        testId: "input-lastName",
        type: "text"
      }), __jsx(FormikTextInput, {
        "data-cy": "input-email",
        formikProps: formikProps,
        label: inputLabelText.email,
        maxLength: "30",
        name: "email",
        testId: "input-email",
        type: "email"
      }), __jsx(FormikPasswordInput, {
        "data-cy": "input-password",
        formikProps: formikProps,
        label: inputLabelText.password,
        name: "password",
        testId: "input-password"
      }), errorMessage && __jsx(_Alert, {
        "data-cy": "primary-signup-error",
        severity: "error"
      }, errorMessage), __jsx(LoadingButton, {
        "data-cy": "sign-up-with-email",
        disabled: isLoading || isAuthenticating,
        fullWidth: true,
        loading: isLoading || isAuthenticating,
        startIcon: __jsx(MailOutlineIcon, null),
        sx: {
          mt: 2
        },
        type: "submit"
      }, buttonText.submitPrimary))), __jsx("div", null, __jsx(AlreadyHaveAccount, null, "Already have an account?", ' ', __jsx(LinkSwitch, {
        isEmbedded: isEmbedded,
        url: `/login${search}`
      }, linkText.logIn)), __jsx(ReCaptchaDisclaimer, null), __jsx(SecureLogos, null, __jsx(SecureLogo, {
        src: SecureImage,
        height: 50
      }))));
  }
});