import _extends from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/extends.js";
import _objectSpread from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import _objectWithoutProperties from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/objectWithoutProperties.js";
const _excluded = ["formControl", "formHelperText", "inputLabel", "options"],
  _excluded2 = ["error", "formError", "disabled"],
  _excluded3 = ["children"];
var __jsx = React.createElement;
// https://github.com/stackworx/formik-mui/blob/main/packages/formik-mui/src/Select.tsx

import React from 'react';
import { useTheme } from '@mui/material/styles';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import MuiSelect from '@mui/material/Select';
import { Field } from 'formik';
import { fieldToSelect } from './FormikSelectInput.util';
const FormikSelect = _ref => {
  var _selectFieldProps$var, _theme$components, _theme$components$Mui, _theme$components$Mui2;
  let {
      formControl,
      formHelperText,
      inputLabel,
      options
    } = _ref,
    selectProps = _objectWithoutProperties(_ref, _excluded);
  const _fieldToSelect = fieldToSelect(_objectSpread(_objectSpread({}, selectProps), {}, {
      options
    })),
    {
      error,
      formError,
      disabled
    } = _fieldToSelect,
    selectFieldProps = _objectWithoutProperties(_fieldToSelect, _excluded2);
  const _ref2 = formHelperText || {},
    {
      children: formHelperTextChildren
    } = _ref2,
    formHelperTextProps = _objectWithoutProperties(_ref2, _excluded3);
  const shouldDisplayFormHelperText = error || formHelperTextChildren;
  const theme = useTheme();
  const variantSelected = (_selectFieldProps$var = selectFieldProps.variant) !== null && _selectFieldProps$var !== void 0 ? _selectFieldProps$var : theme === null || theme === void 0 ? void 0 : (_theme$components = theme.components) === null || _theme$components === void 0 ? void 0 : (_theme$components$Mui = _theme$components.MuiSelect) === null || _theme$components$Mui === void 0 ? void 0 : (_theme$components$Mui2 = _theme$components$Mui.defaultProps) === null || _theme$components$Mui2 === void 0 ? void 0 : _theme$components$Mui2.variant;
  return __jsx(FormControl, _extends({
    disabled: disabled,
    error: error
  }, formControl, {
    variant: variantSelected,
    fullWidth: selectProps.fullWidth
  }), __jsx(InputLabel, _extends({
    id: selectFieldProps.labelId
  }, inputLabel), selectFieldProps.label), __jsx(MuiSelect, _extends({}, selectFieldProps, {
    sx: {
      textAlign: 'left'
    }
  }), options.map(option => __jsx(MenuItem, {
    "data-cy": option.value,
    value: option.value,
    disabled: option.disabled
  }, option.label))), shouldDisplayFormHelperText && __jsx(FormHelperText, formHelperTextProps, error ? formError : formHelperTextChildren));
};
export const FormikSelectInput = props => __jsx(Field, _extends({
  component: FormikSelect
}, props));