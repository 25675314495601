import _Stack from "@mui/material/Stack";
import _extends from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/extends.js";
var __jsx = React.createElement;
import React from 'react';
import { useLatestDeals } from '../../hooks/useLatestDeals';
import { testIds } from './LatestDealsListMobile.model';
import { LatestDealsListCard } from './components/LatestDealsListCard';
export const LatestDealsListMobile = () => {
  const {
    offeringData,
    offeringDataIsLoading
  } = useLatestDeals();
  if (offeringDataIsLoading) {
    return __jsx(React.Fragment, null, Array(3).fill('').map((_, idx) => __jsx(_Stack, _extends({
      direction: "column",
      gap: 4
    }, testIds.loadingView.attr), __jsx(LatestDealsListCard, {
      key: idx,
      isLoading: offeringDataIsLoading
    }))));
  }
  return __jsx(_Stack, _extends({
    direction: "column",
    gap: 4
  }, testIds.dataView.attr), offeringData === null || offeringData === void 0 ? void 0 : offeringData.map(offering => __jsx(LatestDealsListCard, {
    key: offering === null || offering === void 0 ? void 0 : offering.noteUrlHash,
    data: offering,
    isLoading: offeringDataIsLoading
  })));
};