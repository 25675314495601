var __jsx = React.createElement;
import React, { useEffect, useRef } from 'react';
import { Formik } from 'formik';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import { FormikTextInput } from '@yieldstreet/ui-kit/inputs/forms';
import { allToken, userApi, userNameApi } from '@yieldstreet/platform-kit';
import { ModalContainer, useModalContext } from '@yieldstreet/ui-kit';
import { useApiErrorMessage, useApiFormErrors } from '@yieldstreet/tool-kit';
import { ModalHeader } from '../shared/shared';
import { testIds, EditNameSchema } from './EditNameModal.model';
import { SButtonContainer, SForm } from '../common/common.style';
export const EditNameModal = () => {
  const {
    hideModal
  } = useModalContext();
  const form = useRef();
  const {
    useGetUserStateQuery
  } = userApi;
  const {
    useUpdateUserNameMutation
  } = userNameApi;
  const {
    data: userState
  } = useGetUserStateQuery(allToken);
  const [updateUserName, updateUserNameState] = useUpdateUserNameMutation();
  const {
    isError,
    isSuccess,
    isLoading
  } = updateUserNameState;
  const errorMessage = useApiErrorMessage(updateUserName);
  useApiFormErrors(updateUserNameState, form);
  const submit = data => updateUserName({
    userId: userState.id,
    data
  });
  useEffect(() => {
    if (isSuccess) {
      hideModal();
    }
  }, [hideModal, isSuccess]);
  return __jsx(ModalContainer, null, __jsx(ModalHeader, {
    type: 2,
    tagType: 3
  }, "Edit name"), __jsx(Formik, {
    ref: form,
    initialValues: {
      firstName: userState.firstName,
      lastName: userState.lastName
    },
    validationSchema: EditNameSchema,
    onSubmit: (values, formikActions) => submit(values).then(() => formikActions.setSubmitting(false))
  }, formikProps => {
    return __jsx(SForm, null, __jsx(FormikTextInput, {
      name: "firstName",
      label: "First name",
      formikProps: formikProps,
      fullWidth: true,
      sx: {
        mb: 4
      }
    }), __jsx(FormikTextInput, {
      name: "lastName",
      label: "Last name",
      formikProps: formikProps,
      fullWidth: true,
      sx: {
        mb: 4
      }
    }), isError && __jsx(Alert, {
      severity: "error",
      sx: {
        mb: 4
      }
    }, errorMessage), __jsx(SButtonContainer, null, __jsx(Button, {
      "data-trackingid": testIds.showEditNameCancel,
      "data-cy": testIds.showEditNameCancel,
      type: "reset",
      variant: "outlined",
      onClick: () => hideModal(),
      fullWidth: true
    }, "Close"), __jsx(LoadingButton, {
      type: "submit",
      "data-cy": testIds.showEditNameUpdate,
      "data-trackingid": testIds.showEditNameUpdate,
      onClick: formikProps.handleSubmit,
      loading: isLoading,
      disabled: isLoading,
      fullWidth: true
    }, "Update name")));
  }));
};