import _objectSpread from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import _objectWithoutProperties from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/objectWithoutProperties.js";
const _excluded = ["name", "disabled", "formikProps", "helperText"];
import { getIn } from 'formik';
export function fieldToCheckbox(_ref) {
  let {
      name,
      disabled,
      formikProps: {
        isSubmitting,
        values,
        handleChange,
        errors,
        touched
      },
      helperText
    } = _ref,
    props = _objectWithoutProperties(_ref, _excluded);
  const checked = getIn(values, name);
  const fieldError = getIn(errors, name);
  const showError = getIn(touched, name) && !!fieldError;
  return _objectSpread(_objectSpread({}, props), {}, {
    name,
    checked,
    onChange: handleChange,
    disabled: disabled !== null && disabled !== void 0 ? disabled : isSubmitting,
    error: showError,
    helperText: showError ? helperText !== null && helperText !== void 0 ? helperText : fieldError : null
  });
}