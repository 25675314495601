import { GoalQuizSteps, GoalQuizPrimaryFinancialValues } from '@yieldstreet/platform-kit';
export const marketplaceCopy = {
  firstTimeInvestor: {
    title: 'The easiest way to get started is for us to learn more about you. Find the right investment for you.',
    subtitle: 'Choosing your first investment'
  },
  experiencedInvestor: {
    title: `We frequently open new offerings. Let's find the right one for you.`,
    subtitle: 'Choosing your next investment'
  }
};
export const GoalsQuizStates = {
  DEFAULT: 'default',
  QUESTIONS: 'questions',
  FETCHING_OFFERINGS: 'fetchingOfferings',
  RESULTS_NOT_FIT: 'resultsNotFit',
  OFFERINGS_RESULTS: 'offeringsResults'
};
export const ButtonText = {
  default: 'Try the offering selector',
  next: 'Next',
  resultsNotFit: 'View open offerings',
  resultsSuccess: 'View my selections'
};
const defaultInfoDescription = "We offer a diverse array of products. Answer a few questions and find the one that's right for you.";
const DefaultInfo = {
  dashboard: {
    title: 'Having trouble finding the right investment? Let us help.',
    subtitle: 'Choosing your first investment',
    description: defaultInfoDescription
  },
  portfolio: {
    title: 'We frequently open new offerings. Find the right one for you.',
    subtitle: 'Getting ready for your next investment',
    description: defaultInfoDescription
  },
  marketplace: {
    title: marketplaceCopy.firstTimeInvestor.title,
    subtitle: marketplaceCopy.firstTimeInvestor.subtitle,
    description: defaultInfoDescription
  }
};
const resultsNotFitQuestion = 'There are currently no open offerings that fit your goals';
const resultsNotFitDescription = 'Check back frequently to see new results as we launch offerings.';
const ResultsNotFit = {
  dashboard: {
    question: resultsNotFitQuestion,
    counter: 'Your selections',
    description: resultsNotFitDescription
  },
  portfolio: {
    question: resultsNotFitQuestion,
    counter: '',
    description: resultsNotFitDescription
  }
};
const resultsSuccessQuestion = 'There are new open offerings that match your goals';
const resultsSuccessDescription = 'Based on the investment goals you’ve provided us with, we’ve selected more open offerings for you to review.';
const ResultsSuccess = {
  dashboard: {
    question: resultsSuccessQuestion,
    counter: '',
    description: resultsSuccessDescription
  },
  portfolio: {
    question: resultsSuccessQuestion,
    counter: '',
    description: resultsSuccessDescription
  }
};
export const RADIO_INPUT = 'RADIO_INPUT';
export const CURRENCY_INPUT = 'CURRENCY_INPUT';
export const goalsQuiz = getQuizAnswer => [{
  question: 'What is the primary financial goal you want to achieve with Yieldstreet?',
  questionType: RADIO_INPUT,
  values: [{
    value: GoalQuizPrimaryFinancialValues.DIVERSIFY_STOCK_MARKET,
    label: 'Diversify out of the stock market'
  }, {
    value: GoalQuizPrimaryFinancialValues.DIVERSIFY_FIXED_INCOME,
    label: 'Diversify my fixed income or cash position'
  }, {
    value: GoalQuizPrimaryFinancialValues.INCREASE_YIELD,
    label: 'Increase yield on my current portfolio'
  }, {
    value: GoalQuizPrimaryFinancialValues.SUPPLEMENT_INCOME,
    label: 'Supplement income'
  }],
  answer: getQuizAnswer(GoalQuizSteps.PRIMARY_FINANCIAL_GOAL),
  saveAnswer: GoalQuizSteps.PRIMARY_FINANCIAL_GOAL
}, {
  question: 'What is your minimum target return for an investment on Yieldstreet?',
  questionType: RADIO_INPUT,
  values: [{
    value: 0.03,
    label: '3%'
  }, {
    value: 0.05,
    label: '5%'
  }, {
    value: 0.07,
    label: '7%'
  }, {
    value: 0.09,
    label: '9%'
  }, {
    value: 0.13,
    label: '13%'
  }],
  answer: getQuizAnswer(GoalQuizSteps.MINIMUM_TARGET),
  saveAnswer: GoalQuizSteps.MINIMUM_TARGET
}, {
  question: 'What is your maximum target term for an investment on Yieldstreet?',
  questionType: RADIO_INPUT,
  values: [{
    value: 6,
    label: '6 months'
  }, {
    value: 12,
    label: '1 year'
  }, {
    value: 36,
    label: '3 years'
  }, {
    value: 60,
    label: '5 years'
  }, {
    value: 999,
    // should we add another layer, i.e. 10+ years, change this back to `84` and assign `999` to 10+
    label: '7+ years'
  }],
  answer: getQuizAnswer(GoalQuizSteps.MAXIMUM_TARGET),
  saveAnswer: GoalQuizSteps.MAXIMUM_TARGET
}, {
  question: 'In the next 90 days, how much do you plan on investing with Yieldstreet?',
  questionType: CURRENCY_INPUT,
  answer: getQuizAnswer(GoalQuizSteps.AMOUNT_90_DAYS),
  saveAnswer: GoalQuizSteps.AMOUNT_90_DAYS
}, {
  question: 'What is the maximum amount you would invest in an individual Yieldstreet offering?',
  questionType: CURRENCY_INPUT,
  answer: getQuizAnswer(GoalQuizSteps.MAX_AMOUNT_PER_OFFERING),
  saveAnswer: GoalQuizSteps.MAX_AMOUNT_PER_OFFERING
}];
export const QuizContent = {
  default: DefaultInfo,
  resultsSuccess: ResultsSuccess,
  resultsNotFit: ResultsNotFit
};
export const TEST_IDS = {
  goalsOverviewPageLoader: 'goals-overview-loader',
  goalsDashboardPageLoader: 'goals-dashboard-loader',
  defaultStateMobileWrapper: 'default-state-mobile-view',
  defaultStateMobileImage: 'default-state-mobile-dashboard-image',
  defaultStateWrapper: 'default-state-desktop-view',
  defaultStateImage: 'default-state-dashboard-image',
  defaultStateMobileMarketplaceWrapper: 'default-state-marketplace-mobile-view',
  defaultStateMarketplaceWrapper: 'default-state-marketplace-view',
  resultsNotFitMobileWrapper: 'results-not-fit-state-mobile-view',
  resultsNotFitWrapper: 'results-not-fit-state-dashboard-view',
  resultsNotFitIcon: 'results-not-fit-dashboard-view-icon',
  offeringResultsWrapper: 'offering-results-state-dashboard-view',
  offeringResultsMobileWrapper: 'offerings-result-view',
  offeringResultsTitle: 'offerings-result-title',
  offeringResultsRetakeButton: 'offerings-result-retake-quiz-button',
  offeringResultsCarousel: 'offerings-result-carousel',
  offeringResultsDashboardCarousel: 'offering-results-state-dashboard-carousel'
};
export const SharedComponentsTestIds = {
  buttonComponent: 'goals-button',
  counterComponent: 'goals-counter',
  titleComponent: 'goals-title',
  descriptionComponent: 'goals-description'
};