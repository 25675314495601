import _Box from "@mui/material/Box";
var __jsx = React.createElement;
import React from 'react';
import { ButtonComponent } from '../components/ButtonComponent';
import { CounterComponent } from '../components/CounterComponent';
import { DescriptionComponent } from '../components/DescriptionComponent';
import { TitleComponent } from '../components/TitleComponent';
import { DashboardDesktopWrapper, DescriptionWrapper, Left, MobileWrapper, QuestionWrapper, Right } from '../Goals.style';
import { ButtonText, marketplaceCopy, QuizContent, TEST_IDS } from '../Goals.model';
import { Image, MobileImage } from './DefaultState.style';
import GoalsModuleImage from './assets/goals-module.svg';
export const DefaultState = _ref => {
  let {
    isMobile,
    isUserLogged = false,
    onClick,
    page,
    showMobileView,
    userHasInvestments
  } = _ref;
  const MobileView = __jsx(React.Fragment, null, __jsx(MobileWrapper, {
    "data-cy": TEST_IDS.defaultStateMobileWrapper,
    isDashboard: page === 'dashboard'
  }, page === 'dashboard' ? __jsx(MobileImage, {
    "data-cy": TEST_IDS.defaultStateMobileImage,
    src: GoalsModuleImage,
    alt: "goals mobile image"
  }) : __jsx(_Box, {
    sx: {
      mb: 3
    }
  }, CounterComponent(QuizContent.default[page].subtitle)), __jsx(QuestionWrapper, null, TitleComponent(page, QuizContent.default[page].title)), __jsx(DescriptionWrapper, null, DescriptionComponent(QuizContent.default[page].description)), ButtonComponent(onClick, ButtonText.default)));
  const DesktopView = __jsx(DashboardDesktopWrapper, {
    "data-cy": TEST_IDS.defaultStateWrapper,
    isDashboard: true
  }, __jsx(Left, {
    state: "default"
  }, __jsx(_Box, {
    sx: {
      mb: 3
    }
  }, CounterComponent(QuizContent.default[page].subtitle)), __jsx(QuestionWrapper, null, TitleComponent(page, QuizContent.default[page].title)), __jsx(DescriptionWrapper, null, DescriptionComponent(QuizContent.default[page].description), ButtonComponent(onClick, ButtonText.default))), __jsx(Right, {
    isDashboard: true
  }, __jsx(Image, {
    "data-cy": TEST_IDS.defaultStateImage,
    src: GoalsModuleImage,
    alt: "goals-image"
  })));
  const getMarketplaceSubtitle = () => {
    if (userHasInvestments) {
      return marketplaceCopy.experiencedInvestor.subtitle;
    }
    if (isUserLogged) {
      return QuizContent.default[page].subtitle;
    }
    return 'Getting ready for your next investment';
  };
  const getMarketplaceTitle = () => {
    if (userHasInvestments) {
      return marketplaceCopy.experiencedInvestor.title;
    }
    if (isUserLogged) {
      return QuizContent.default[page].title;
    }
    return 'The easiest way to get started is for us to learn more about you.';
  };
  const MarketplaceDesktopView = __jsx(DashboardDesktopWrapper, {
    "data-cy": TEST_IDS.defaultStateMarketplaceWrapper,
    dynamicHeight: true
  }, __jsx(Left, {
    state: "default"
  }, __jsx(_Box, {
    sx: {
      mb: 3
    }
  }, CounterComponent(getMarketplaceSubtitle())), __jsx(QuestionWrapper, {
    maxWidth: true
  }, TitleComponent(page, getMarketplaceTitle())), __jsx(DescriptionWrapper, {
    noBottomMargin: true
  }, DescriptionComponent(isUserLogged ? QuizContent.default[page].description : `We offer a diverse array of products. Answer a few questions and find the one that's right for you.`))), __jsx(_Box, {
    sx: {
      mx: 5,
      my: 'auto'
    }
  }, ButtonComponent(onClick, isUserLogged ? ButtonText.default : 'Sign up to try')));
  const MarketplaceMobileView = __jsx(MobileWrapper, {
    "data-cy": TEST_IDS.defaultStateMobileMarketplaceWrapper
  }, __jsx(_Box, {
    sx: {
      mb: 3
    }
  }, CounterComponent(getMarketplaceSubtitle())), __jsx(QuestionWrapper, null, TitleComponent(page, getMarketplaceTitle())), __jsx(DescriptionWrapper, null, DescriptionComponent(isUserLogged ? QuizContent.default[page].description : `We offer a diverse array of products. Answer a few questions and find the one that's right for you.`)), ButtonComponent(onClick, isUserLogged ? ButtonText.default : 'Sign up to try'));

  // if (page === 'marketplace') {
  //   if (viewPort === 'mobile') {
  //     return MarketplaceMobileView;
  //   } else {
  //     return MarketplaceView;
  //   }
  // } else if (viewPort === 'mobile' || page === 'portfolio') {
  //   return MobileView;
  // } else {
  //   return DashboardDesktopView;
  // }

  if (page === 'marketplace') {
    return isMobile ? MarketplaceMobileView : MarketplaceDesktopView;
  }

  // potentially a bug since this will always return the mobile view
  if (showMobileView) {
    return MobileView;
  }
  return DesktopView;
};