var __jsx = React.createElement;
import React from 'react';
import { NewModal } from '@yieldstreet/ui-kit';
import { EditPhoneModal } from './EditPhoneModalView';
import { EDIT_PHONE_MODAL, TEST_IDS } from './EditPhoneModal.model';
export const EditPhoneModalContainer = _ref => {
  let {
    userId,
    userPhone
  } = _ref;
  return __jsx(NewModal, {
    modalId: EDIT_PHONE_MODAL,
    "data-cy": TEST_IDS.modal
  }, __jsx(EditPhoneModal, {
    userId: userId,
    userPhone: userPhone
  }));
};