var __jsx = React.createElement;
import React from 'react';
import { Row, Col, PageGrid } from '@yieldstreet/ui-kit';
import { Goals as GoalsCard } from 'sharedComponents/Goals';
export const Goals = _ref => {
  let {
    userAudiences,
    saveQuizAnswer,
    getQuizAnswer
  } = _ref;
  if (!userAudiences) {
    return null;
  }
  return __jsx(Row, null, __jsx(Col, null, __jsx(PageGrid, {
    config: {
      spacing: 20
    },
    "data-cy": "module-goals"
  }, __jsx(Row, null, __jsx(Col, null, __jsx(GoalsCard, {
    getQuizAnswer: getQuizAnswer,
    page: "dashboard",
    saveQuizAnswer: saveQuizAnswer,
    userAudiences: userAudiences
  }))))));
};