import _extends from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/extends.js";
import _objectSpread from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
var __jsx = React.createElement;
import * as React from 'react';
import MuiTextField from '@mui/material/TextField';
import { fieldToTextField } from '../utils/fieldToTextField/fieldToTextField.util';
export const FormikTextInput = props => {
  const forwardedProps = _objectSpread(_objectSpread({}, props), {}, {
    slotProps: _objectSpread({
      htmlInput: {
        role: 'combobox'
      },
      formHelperText: {
        'data-cy': `${props['data-cy']}-formHelperText`
      }
    }, (props === null || props === void 0 ? void 0 : props.slotProps) || {})
  });
  return __jsx(MuiTextField, _extends({}, fieldToTextField(forwardedProps), {
    variant: props.variant || 'filled'
  }));
};