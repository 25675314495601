import _Stack from "@mui/material/Stack";
import _Button from "@mui/material/Button";
import _extends from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/extends.js";
import _Typography from "@mui/material/Typography";
var __jsx = React.createElement;
import React, { useCallback, useState } from 'react';
import { OnboardButton, OnboardChipStack, OnboardCheckboxStack } from '@yieldstreet/ui-kit/inputs';
import { testIds } from './SignupQuizStep.model';
import { SignupQuizStepContainer, SignupQuizStepContent, SignupQuizStepItems } from './SignupQuizStep.style';
export const SignupQuizStep = _ref => {
  let {
    answer,
    asChip = false,
    multiple = false,
    nextStep,
    options,
    saveQuizAnswer,
    stepKey,
    subtitle,
    testId,
    title
  } = _ref;
  const {
    0: selectedValues,
    1: setSelectedValues
  } = useState(answer);
  const enterFromLeft = JSON.parse(localStorage.getItem('enterFromLeft'));
  const save = useCallback(value => {
    saveQuizAnswer(stepKey, value ? value : selectedValues);
    nextStep();
  }, [saveQuizAnswer, stepKey, selectedValues, nextStep]);
  const MultipleChoiceComponent = asChip ? OnboardChipStack : OnboardCheckboxStack;
  return __jsx(SignupQuizStepContainer, {
    enterFromLeft: enterFromLeft
  }, __jsx(_Typography, {
    component: "h1",
    variant: "h4"
  }, title), subtitle && __jsx(_Typography, {
    component: "p",
    sx: {
      mt: 4
    },
    variant: "body1"
  }, subtitle), multiple && __jsx(_Typography, {
    component: "p",
    sx: {
      mt: 4
    },
    variant: "body1"
  }, "Select one or more options."), __jsx(SignupQuizStepContent, null, __jsx(SignupQuizStepItems, null, multiple ? __jsx(React.Fragment, null, __jsx(MultipleChoiceComponent, {
    onChange: checkedValues => {
      setSelectedValues(checkedValues);
    },
    options: options,
    slotProps: {
      size: 'medium',
      'data-cy': testId
    },
    spacing: 4,
    sx: {
      mb: 2
    },
    values: selectedValues
  }), __jsx(_Button, _extends({
    fullWidth: true,
    onClick: () => {
      save();
    },
    disabled: !(selectedValues !== null && selectedValues !== void 0 && selectedValues.length),
    sx: {
      mt: 5
    }
  }, testIds.continueButton.attr), "Continue")) : __jsx(_Stack, {
    spacing: 4
  }, options.map(option => __jsx(OnboardButton, _extends({}, option, {
    key: option.value,
    onClick: () => {
      save(option.value);
    },
    selected: answer === option.value,
    size: "medium"
  })))))));
};