import _extends from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/extends.js";
import React from "react";
var __jsx = React.createElement;
import { useCallback, useMemo } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import compact from "lodash/compact";
import isEmpty from "lodash/isEmpty";
import remove from "lodash/remove";
import { adviceEngineApi, pdfLinks, PdfLinkKeys } from '@yieldstreet/platform-kit';
import { Button, DesktopOnly, Heading, Label, MobileOnly, PageContainer, PageLoader, Paragraph } from '@yieldstreet/ui-kit';
import { INVESTOR_ENTITY_ID_PARAM, MANAGED_PORTFOLIO_AGREEMENT_PATH, STRATEGY_TYPE_PARAM } from 'pages/managedPortfolio/ManagedPortfolio.model';
import { NumberFormat } from 'sharedComponents/NumberFormat/NumberFormat';
import { SLink, SCardsCarousel, StrategyContainer, TitleContainer, WhiteBox, DisclaimerContainer, Disclaimer, DisclaimerLink, StrategyLabelWrapper } from './ManagedPortfolioSelection.style';
import { testIds, getTexts } from './ManagedPortfolioSelection.model';
import { StrategyCard } from './StrategyCard/StrategyCard';
import { StrategyModal } from './StrategyModal';
export const ManagedPortfolioSelection = () => {
  const history = useHistory();
  const {
    investorEntityId
  } = useParams();
  const {
    useGetSuggestionsQuery
  } = adviceEngineApi;
  const {
    data: {
      availableStrategies = [],
      suggestedStrategies = []
    } = {},
    isLoading: loadingSuggestions
  } = useGetSuggestionsQuery({
    investorEntityId
  });
  const hasSuggestedStrategy = !isEmpty(compact(suggestedStrategies));
  const selectStrategy = useCallback(investmentStrategyType => history.push(MANAGED_PORTFOLIO_AGREEMENT_PATH.replace(INVESTOR_ENTITY_ID_PARAM, investorEntityId).replace(STRATEGY_TYPE_PARAM, investmentStrategyType)), [history, investorEntityId]);
  const sortedStrategies = useMemo(() => {
    const sorted = [...availableStrategies];
    const featured = remove(sorted, strategy => suggestedStrategies.includes(strategy.investmentStrategyType))[0];
    featured && sorted.splice(Math.ceil(sorted.length / 2), 0, featured);
    return sorted;
  }, [suggestedStrategies, availableStrategies]);
  const strategyCards = useCallback(isMobile => {
    return sortedStrategies.map(strategy => __jsx(StrategyCard, {
      key: strategy.investmentStrategyType,
      assetTypeAllocations: strategy === null || strategy === void 0 ? void 0 : strategy.assetTypeAllocations,
      investmentStrategyType: strategy === null || strategy === void 0 ? void 0 : strategy.investmentStrategyType,
      risk: strategy === null || strategy === void 0 ? void 0 : strategy.risk,
      featured: suggestedStrategies.includes(strategy.investmentStrategyType),
      onClick: () => selectStrategy(strategy.investmentStrategyType),
      isMobile: isMobile
    }));
  }, [selectStrategy, sortedStrategies, suggestedStrategies]);
  const minimumDepositAmount = useMemo(() => {
    var _sortedStrategies$;
    return ((_sortedStrategies$ = sortedStrategies[0]) === null || _sortedStrategies$ === void 0 ? void 0 : _sortedStrategies$.minimumDepositAmount) || 0;
  }, [sortedStrategies]);
  const texts = getTexts(hasSuggestedStrategy);
  return loadingSuggestions ? __jsx(PageContainer, null, __jsx(PageLoader, null)) : __jsx(React.Fragment, null, __jsx(StrategyModal, null), __jsx(PageContainer, null, __jsx(TitleContainer, null, __jsx(Heading, _extends({}, testIds.title.attr, {
    type: 4
  }), texts.title), __jsx(Paragraph, {
    secondary: true
  }, texts.subtitle)), __jsx(DesktopOnly, null, __jsx(StrategyContainer, {
    hasSuggestedStrategy: hasSuggestedStrategy
  }, strategyCards(false))), __jsx(MobileOnly, null, __jsx(SCardsCarousel, {
    showMobileControls: true,
    initialSlide: hasSuggestedStrategy ? 1 : 0
  }, strategyCards(true))), __jsx(StrategyLabelWrapper, null, minimumDepositAmount > 0 && __jsx(Label, null, "Each strategy requires a", ' ', __jsx(NumberFormat, {
    displayType: "text",
    value: minimumDepositAmount,
    thousandSeparator: true,
    prefix: "$"
  }), ' ', "minimum investment and has a 1.25% advisory fee.")), __jsx(WhiteBox, null, __jsx("div", null, __jsx(Heading, {
    type: 4
  }, "Need help?"), __jsx(Paragraph, null, "Connect with an Investor Relations professional.")), __jsx(SLink, _extends({}, testIds.helpButton.attr, {
    id: "intercom-link"
  }), __jsx(Button, {
    fullWidth: true,
    buttonType: "basic"
  }, "Schedule a call"))), __jsx(DisclaimerContainer, testIds.disclaimer.attr, __jsx(Disclaimer, {
    small: true,
    id: "sup-a"
  }, "a. Yieldstreet partnered with Wilshire, a global investment firm and institutional advisor, to design the asset allocations for each portfolio option (each, a \u201CStrategy\u201D)."), __jsx(Disclaimer, {
    small: true
  }, "Asset allocations are based on Wilshire\u2019s 10-year return, risk, and correlation forecasts (\u201CCapital Market Assumptions\u201D). Wilshire\u2019s Capital Market Assumptions process derives key inputs to form a 10-year outlook on return and risk for public and private asset classes. This process has been the basis for asset allocation studies across Wilshire\u2019s institutional client base since the 1980s. Historical data is primarily sourced from Wilshire\u2019s proprietary database of historical returns, AUM, representative holdings-level detail and other quantitative performance metrics from a variety of investment managers. Past performance is no guarantee of future returns.", ' ', __jsx(DisclaimerLink, {
    href: pdfLinks[PdfLinkKeys.WILSHIRE],
    target: "_blank"
  }, "Wilshire\u2019s 2025 Capital Market Assumptions can be viewed here"), "."), __jsx(Disclaimer, {
    small: true
  }, "Based on Wilshire\u2019s Capital Market Assumptions, it developed three Strategies designed to offer the highest expected return at three levels of risk: low, medium, and high. Risk levels are relative to the other Strategies and do not contemplate other types of investments (e.g., stocks and bonds). Strategies are limited to the investment options provided by Yieldstreet, defined investment size constraints, and minimum allocations."), __jsx(Disclaimer, {
    small: true
  }, "Wilshire updates its Capital Market Assumptions quarterly and will provide updated allocations for Yieldstreet\u2019s three Strategies on an annual basis.\xA0"), __jsx(Disclaimer, {
    small: true
  }, "Disclaimer: There can be no assurance that any investment process or strategy will achieve its objectives."), __jsx(Disclaimer, {
    small: true
  }, "Please review", ' ', __jsx(DisclaimerLink, {
    href: pdfLinks[PdfLinkKeys.FORM_ADV],
    target: "_blank"
  }, "Yieldstreet's Form ADV Part 2A Brochure"), ' ', "for important information about how we provide investment advice with our algorithm, including limitations and risks of our approach. Please also see our", ' ', __jsx(DisclaimerLink, {
    href: pdfLinks[PdfLinkKeys.FORM_CRS],
    target: "_blank"
  }, "Form CRS (Customer Relationship Summary).")), __jsx(Disclaimer, {
    small: true
  }, "If you select an investment strategy other than the one we recommended (if any), the actual investments selected for your Managed Portfolio account will align with the investment goals and preferences associated with the selected investment strategy and will not necessarily align with investment goals or preferences you indicated in the questionnaire."), __jsx(Disclaimer, {
    small: true
  }, "Investments made on your behalf in a Managed Portfolio are highly speculative and entail substantial risks, including the fact that such investments are illiquid and subject to significant restrictions on transferability and redemption and that all or a substantial amount of the principal invested may be lost. No amount of diversification can eliminate such risks. Investment strategies such as the ones offered for Managed Portfolios may not be suitable for all investors, and potential investors must make an independent assessment of the appropriateness of any investment in light of their own objectives and circumstances."))));
};