import Experienced from '@yieldstreet/ui-kit/assets/graphics/articles/alt_experienced.svg';
import NonExperienced from '@yieldstreet/ui-kit/assets/graphics/articles/non_alt_experienced.svg';
export const RESOURCES = {
  EVALUATING_COLLATERAL_CARD: {
    testId: 'evaluating-collateral-card',
    title: 'Evaluating collateral for asset-based lending opportunities',
    description: 'Collateral is a tangible asset that a borrower offers a lender to secure a loan. At Yieldstreet, we work with a variety of partners across each asset class to bring deals to market...',
    ctaLink: '/resources/article/evaluating-collateral',
    image: Experienced
  },
  ZERO_INTEREST_RATE_MARKET_CARD: {
    testId: 'zero-interest-rate-market-card',
    title: 'Discussing Modern Portfolio Theory',
    description: 'The foundational concepts of risk, return, and diversification need to be thoroughly explored.',
    ctaLink: '/blog/article/modern-portfolio-theory',
    mini: true
  },
  SOURCING_AND_ORIGINATION_PARTNERS_CARD: {
    testId: 'sourcing-and-origination-partners-card',
    title: 'Sourcing Yieldstreet opportunities and the role of origination partners',
    description: 'At Yieldstreet, we work with a variety of partners across each asset class to bring deals to market..',
    ctaLink: '/resources/article/sourcing-and-origination-partners',
    mini: true
  },
  EVALUATING_ALTERNATIVE_INVESTMENT_CARD: {
    testId: 'evaluating-alternative-investment-card',
    title: 'Certain Things to Consider When Evaluating an Alternative Investment',
    description: 'Reviewing an alternative investments opportunity often requires a more specialized approach than vetting their traditional counterparts.',
    ctaLink: '/resources/article/6-things-a-prospective-investor-may-want-to-ask-when-evaluating-an-alternative-investment',
    image: NonExperienced
  },
  WHY_ALTERNATIVES: {
    testId: 'why-alternatives-card',
    title: 'Why invest in private market alternatives?',
    description: 'Previously only accessible to institutions, private market alternatives are rapidly becoming a meaningful part of many individual investor portfolios.',
    ctaLink: '/blog/article/why-invest-in-private-market-alternatives',
    mini: true
  }
};