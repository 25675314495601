import _Alert from "@mui/material/Alert";
import _Typography from "@mui/material/Typography";
import _extends from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/extends.js";
var __jsx = React.createElement;
import React, { useCallback, useRef } from 'react';
import LoadingButton from '@mui/lab/LoadingButton';
import { Formik } from 'formik';
import { FormikTextInput } from '@yieldstreet/ui-kit/inputs';
import { PageLoader } from '@yieldstreet/ui-kit/layouts';
import { twoFactorAuthApi } from '@yieldstreet/platform-kit';
import { useApiErrorMessage, useApiFormErrors } from '@yieldstreet/tool-kit';
import { AUTH_ERRORS } from 'utils/auth';
import { FieldsContainer, FormContainer } from '../../../Login.style';
import { testIds } from '../../Login2FA.model';
import { EmailAuthFormSchema, emailTitle } from './EmailForm.model';
export const EmailForm = _ref => {
  let {
    confirmEmail,
    confirmEmailResult
  } = _ref;
  const form = useRef(null);
  const {
    data: twoFAStatus
  } = twoFactorAuthApi.useStatusQuery({});
  const {
    emailAddress
  } = (twoFAStatus === null || twoFAStatus === void 0 ? void 0 : twoFAStatus.status) || {};
  const {
    isLoading
  } = confirmEmailResult;
  const errorMessage = useApiErrorMessage(confirmEmailResult, AUTH_ERRORS.VERIFY_2FA);
  useApiFormErrors(confirmEmailResult, form);
  const handleSubmit = useCallback((values, formikActions) => confirmEmail({
    token: values.confirmationCode
  }).then(() => formikActions.setSubmitting(false)), [confirmEmail]);
  if (!twoFAStatus) {
    return __jsx(PageLoader, {
      testId: testIds.loading.id
    });
  }
  return __jsx(React.Fragment, null, __jsx(_Typography, _extends({
    variant: "h4",
    component: "h1",
    sx: {
      mb: 4
    }
  }, testIds.title.attr), emailTitle), __jsx(_Typography, {
    variant: "body1"
  }, emailAddress && __jsx(React.Fragment, null, "We emailed a one time code to ", __jsx("b", null, emailAddress), ". Please enter that code below so we can set up your two-factor authentication.")), __jsx(FormContainer, null, __jsx(Formik, {
    ref: form,
    initialValues: {
      confirmationCode: ''
    },
    validationSchema: EmailAuthFormSchema,
    onSubmit: handleSubmit
  }, formikProps => __jsx(FieldsContainer, null, __jsx(FormikTextInput, _extends({
    autoComplete: "off",
    autoFocus: true,
    formikProps: formikProps,
    label: "Enter code",
    name: "confirmationCode",
    slotProps: {
      htmlInput: {
        maxLength: 6
      }
    },
    type: "tel"
  }, testIds.codeField.attr)), errorMessage && __jsx(_Alert, _extends({
    severity: "error"
  }, testIds.errorNotification.attr, {
    sx: {
      mt: 2
    }
  }), errorMessage), __jsx(LoadingButton, _extends({
    type: "submit",
    loading: isLoading,
    fullWidth: true,
    sx: {
      mt: 2
    }
  }, testIds.submit.attr), "Verify your identity")))));
};