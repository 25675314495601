import _Alert from "@mui/material/Alert";
import _Typography from "@mui/material/Typography";
import _extends from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/extends.js";
var __jsx = React.createElement;
import React, { useRef } from 'react';
import { Formik } from 'formik';
import { authApi } from '@yieldstreet/platform-kit';
import { FormikPasswordInput } from '@yieldstreet/ui-kit/inputs';
import { AdaAssistantLink, PageFlow } from '@yieldstreet/ui-kit';
import { LoadingButton } from '@mui/lab';
import { BusinessEvent, useApiErrorMessage, useApiEventTracking, useApiFormErrors } from '@yieldstreet/tool-kit';
import { ReCaptchaDisclaimer } from 'sharedComponents/ReCaptcha';
import { useAuthFlow, AUTH_ERRORS } from 'utils/auth';
import { Content, FieldsContainer, FormContainer } from '../Login.style';
import { PasswordSchema, testIds } from './LinkSocialAccount.model';
export const LinkSocialAccount = () => {
  const form = useRef();
  const [socialIdentityLink, socialIdentityLinkState] = authApi.useSocialIdentityLinkMutation();
  const {
    isLoading,
    data: authResult
  } = socialIdentityLinkState;
  const {
    require2FA
  } = authResult || {};
  const {
    isAuthenticating
  } = useAuthFlow(authResult);
  const errorMessage = useApiErrorMessage(socialIdentityLinkState, AUTH_ERRORS.SOCIAL_LINK_IDENTITY);
  useApiFormErrors(socialIdentityLinkState, form);
  useApiEventTracking(socialIdentityLinkState, BusinessEvent.USER_LOGIN_LINK_SOCIAL, {
    require2FA
  }, errorMessage);
  return __jsx(PageFlow, {
    showLogo: true
  }, __jsx(Content, testIds.login.attr, __jsx(_Typography, _extends({
    component: "h1",
    variant: "h4",
    sx: {
      mb: 4
    }
  }, testIds.title.attr), "Streamline your login experience"), __jsx(_Typography, {
    component: "p",
    variant: "body1",
    sx: {
      mb: 10
    }
  }, "It appears you already have a Yieldstreet account associated with this email address. Link them together by confirming your password below, and next time you log in you can use your email/password or social login button to log in to your account."), __jsx(FormContainer, null, __jsx(Formik, {
    initialValues: {
      password: ''
    },
    ref: form,
    onSubmit: values => socialIdentityLink(values),
    validationSchema: PasswordSchema
  }, formikProps => {
    return __jsx(FieldsContainer, null, __jsx(FormikPasswordInput, _extends({
      autoComplete: "off",
      disabled: isLoading || isAuthenticating,
      formikProps: formikProps,
      label: "Confirm your password",
      name: "password"
    }, testIds.password.attr)), errorMessage && __jsx(_Alert, _extends({
      severity: "error"
    }, testIds.alert.attr), errorMessage), __jsx(LoadingButton, _extends({
      disabled: isLoading || isAuthenticating,
      fullWidth: true,
      loading: isLoading || isAuthenticating,
      sx: {
        mt: 3
      },
      type: "submit"
    }, testIds.submit.attr), "Connect accounts"), __jsx(_Typography, {
      component: "p",
      variant: "body1",
      sx: {
        mt: 10
      }
    }, "Think this is an error? Have questions?", __jsx("br", null), __jsx(AdaAssistantLink, null, "Chat with our investor relations team.")));
  })), __jsx(ReCaptchaDisclaimer, null)));
};