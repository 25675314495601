import { createYupObjectSchema } from '@yieldstreet/tool-kit';
export const buttonText = {
  submitPrimary: 'Sign up with Email'
};
export const initialValues = {
  firstName: '',
  lastName: '',
  email: '',
  password: ''
};
export const inputLabelText = {
  firstName: 'Legal first name',
  lastName: 'Legal last name',
  email: 'Email address',
  password: 'Password'
};
export const linkText = {
  logIn: 'Log in'
};
export const PrimarySignupSchema = createYupObjectSchema({
  firstName: {
    validationType: 'name'
  },
  lastName: {
    validationType: 'name'
  },
  email: {
    validationType: 'email'
  },
  password: {
    validationType: 'password'
  }
});
export const SignUpFormType = {
  FULL: 'full',
  MINIMAL: 'minimal'
};