import _Stack from "@mui/material/Stack";
var __jsx = React.createElement;
import React from 'react';
import { CSSTransition } from 'react-transition-group';
import { DescriptionComponent } from '../components/DescriptionComponent';
import { TitleComponent } from '../components/TitleComponent';
import { QuizContent, TEST_IDS } from '../Goals.model';
import { DashboardDesktopWrapper, DescriptionWrapper, Left, Line, QuestionWrapper, Right } from '../Goals.style';
import { OfferingsCard } from './OfferingsCard';
import { OfferingWrapper, SButton, SCarousel, SMobileWrapper, TitleContainer } from './OfferingsResults.style';
export const DashboardDesktopResults = _ref => {
  let {
    isMobile,
    page,
    recommendedOfferings,
    setRetakeQuiz,
    state
  } = _ref;
  return __jsx(DashboardDesktopWrapper, {
    "data-cy": TEST_IDS.offeringResultsWrapper,
    isDashboard: true
  }, __jsx(Left, {
    state: "default"
  }, __jsx(QuestionWrapper, null, __jsx(TitleContainer, {
    "data-cy": TEST_IDS.offeringResultsTitle
  }, TitleComponent(page, QuizContent[state][page].question, true), __jsx(SButton, {
    dashboard: true,
    "data-cy": TEST_IDS.offeringResultsRetakeButton,
    onClick: () => setRetakeQuiz(true),
    small: true,
    sx: {
      mt: 3
    }
  }, "Retake"))), __jsx(DescriptionWrapper, null, DescriptionComponent(QuizContent[state][page].description))), __jsx(Line, null), __jsx(Right, null, __jsx(SCarousel, {
    "data-cy": TEST_IDS.offeringResultsDashboardCarousel,
    wrapAround: true,
    dragging: true,
    slidesToShow: isMobile ? 1 : 1.3,
    framePadding: "5px",
    cellAlign: "center",
    cellSpacing: 10,
    heightMode: "max",
    autoplay: true,
    height: 300,
    displayDots: true
  }, recommendedOfferings.map(offering => __jsx(OfferingWrapper, null, __jsx(OfferingsCard, {
    offering: offering
  }))))));
};
export const OfferingsResults = _ref2 => {
  let {
    isMobile,
    page,
    recommendedOfferings,
    setRetakeQuiz,
    showMobileView
  } = _ref2;
  if (showMobileView) {
    return __jsx(CSSTransition, {
      in: true,
      classNames: "fade",
      appear: true
    }, __jsx(SMobileWrapper, {
      "data-cy": TEST_IDS.offeringResultsMobileWrapper,
      isDashboard: page === 'dashboard'
    }, __jsx(_Stack, {
      alignItems: "center",
      justifyContent: "space-between",
      "data-cy": TEST_IDS.offeringResultsTitle
    }, TitleComponent(page, page === 'dashboard' ? 'Find the right investment for you' : 'Based on your goals', true), __jsx(SButton, {
      "data-cy": TEST_IDS.offeringResultsRetakeButton,
      onClick: () => setRetakeQuiz(true),
      small: true,
      sx: {
        mt: 3
      }
    }, "Retake")), __jsx(SCarousel, {
      "data-cy": TEST_IDS.offeringResultsCarousel,
      wrapAround: true,
      dragging: true,
      slidesToShow: 1,
      framePadding: "5px",
      cellAlign: "center",
      cellSpacing: 10,
      heightMode: "max",
      autoplay: true,
      height: 300,
      displayDots: true
    }, recommendedOfferings.map(offering => __jsx(OfferingWrapper, null, __jsx(OfferingsCard, {
      offering: offering
    }))))));
  }
  return __jsx(DashboardDesktopResults, {
    isMobile: isMobile,
    page: page,
    recommendedOfferings: recommendedOfferings,
    setRetakeQuiz: setRetakeQuiz,
    state: "resultsSuccess"
  });
};