import React from "react";
var __jsx = React.createElement;
import { Route, Switch } from 'react-router-dom';
import { ActivatePortfolio } from 'pages/newCreateInvestorAccount/screens/NewYS360/ActivatePortfolio';
import { MANAGED_PORTFOLIO_AGREEMENT_PATH, MANAGED_PORTFOLIO_DEPOSIT_PATH, MANAGED_PORTFOLIO_FUND_FULL_PATH, MANAGED_PORTFOLIO_SELECTION_PATH } from './ManagedPortfolio.model';
import { ManagedPortfolioSelection } from './Strategy/Selection/ManagedPortfolioSelection';
import { DepositFunds } from './DepositFunds';
import { Agreement } from './Strategy/Agreement/Agreement';
export const ManagedPortfolio = () => {
  return __jsx(Switch, null, __jsx(Route, {
    path: MANAGED_PORTFOLIO_AGREEMENT_PATH,
    render: () => __jsx(Agreement, null),
    exact: true
  }), __jsx(Route, {
    path: MANAGED_PORTFOLIO_SELECTION_PATH,
    render: () => __jsx(ManagedPortfolioSelection, null),
    exact: true
  }), __jsx(Route, {
    path: MANAGED_PORTFOLIO_FUND_FULL_PATH,
    render: () => __jsx(ActivatePortfolio, null),
    exact: true
  }), __jsx(Route, {
    path: MANAGED_PORTFOLIO_DEPOSIT_PATH,
    render: () => __jsx(DepositFunds, null),
    exact: true
  }));
};