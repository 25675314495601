import _objectSpread from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import { getIn } from 'formik';
import { matchIsValidTel } from 'mui-tel-input';
import { fieldToComponent } from '../utils/fieldToComponent/fieldToComponent.util';
export function fieldToPhoneField(props) {
  const {
    formikProps,
    name
  } = props;
  const value = getIn(formikProps.values, name);

  //https://mui.com/material-ui/react-text-field/#integration-with-3rd-party-input-libraries
  return _objectSpread(_objectSpread({}, fieldToComponent(props)), {}, {
    defaultCountry: 'US',
    focusOnSelectCountry: true,
    forceCallingCode: true,
    onChange: value => {
      var _props$onUpdate;
      const isValid = matchIsValidTel(value);
      props === null || props === void 0 ? void 0 : (_props$onUpdate = props.onUpdate) === null || _props$onUpdate === void 0 ? void 0 : _props$onUpdate.call(props, {
        isValid
      });
      formikProps.setFieldValue(name, value);
    },
    preferredCountries: ['US'],
    value
  });
}