import styled from 'styled-components';
import { Heading, media } from '@yieldstreet/ui-kit';
export const AgreementWrapper = styled.div.withConfig({
  displayName: "Agreementstyle__AgreementWrapper",
  componentId: "sc-wnre75-0"
})(["display:flex;flex-direction:column;margin:0 auto;max-width:900px;"]);
export const AgreementHeadingWrapper = styled.div.withConfig({
  displayName: "Agreementstyle__AgreementHeadingWrapper",
  componentId: "sc-wnre75-1"
})(["align-items:center;margin-bottom:", ";", ""], props => props.theme.spacing.l, media.desktop`
     text-align: center;
  `);
export const AgreementNotificationWrapper = styled.div.withConfig({
  displayName: "Agreementstyle__AgreementNotificationWrapper",
  componentId: "sc-wnre75-2"
})(["margin-bottom:", ";"], props => props.theme.spacing.l);
export const AgreementTitle = styled(Heading).attrs({
  type: 4
}).withConfig({
  displayName: "Agreementstyle__AgreementTitle",
  componentId: "sc-wnre75-3"
})(["margin-bottom:", ";"], props => props.theme.spacing.sm);
export const AgreementDocumentWrapper = styled.div.withConfig({
  displayName: "Agreementstyle__AgreementDocumentWrapper",
  componentId: "sc-wnre75-4"
})(["background:", ";border-radius:", ";padding:", ";", ""], props => props.theme.colors.background_card, props => props.theme.radius.xl, props => props.theme.spacing.m, media.desktop`
    padding: ${props => props.theme.spacing.l};
  `);
export const AgreementInitialsWrapper = styled.div.withConfig({
  displayName: "Agreementstyle__AgreementInitialsWrapper",
  componentId: "sc-wnre75-5"
})(["margin:", " 0;"], props => props.theme.spacing.ml);
export const AgreementConfirmWrapper = styled.div.withConfig({
  displayName: "Agreementstyle__AgreementConfirmWrapper",
  componentId: "sc-wnre75-6"
})(["display:flex;flex-direction:column;margin-bottom:", ";"], props => props.theme.spacing.ml);