var __jsx = React.createElement;
import React from 'react';
import { SignupQuizSteps } from '@yieldstreet/platform-kit';
import { SignupInvestingLabels, SignupInvestingOptions } from '@yieldstreet/tool-kit';
import { SignupQuizStep } from '../../SignupQuizStep';
import { stepTestId } from './WhyStep.model';
export const WhyStep = _ref => {
  let {
    nextStep,
    saveQuizAnswer,
    getQuizAnswer
  } = _ref;
  const answer = getQuizAnswer(SignupQuizSteps.INVESTING_REASONS, true);
  return __jsx(SignupQuizStep, {
    answer: answer,
    getQuizAnswer: getQuizAnswer,
    multiple: true,
    nextStep: nextStep,
    options: SignupInvestingOptions,
    saveQuizAnswer: saveQuizAnswer,
    stepKey: SignupQuizSteps.INVESTING_REASONS,
    testId: stepTestId,
    title: SignupInvestingLabels.title
  });
};