import { useCallback, useMemo } from 'react';
import { paymentsApi, PortfolioStrategyValues } from '@yieldstreet/platform-kit';
import { useInvestorEntity } from '@yieldstreet/tool-kit';
export const useSubmitDepositFundsForm = _ref => {
  let {
    investorEntityId
  } = _ref;
  const {
    investorEntity
  } = useInvestorEntity(investorEntityId);
  const investorAccount = useMemo(() => {
    if (!investorEntity) {
      return null;
    }
    return investorEntity === null || investorEntity === void 0 ? void 0 : investorEntity.investorAccounts.find(investorAccount => (investorAccount === null || investorAccount === void 0 ? void 0 : investorAccount.managementStrategy) === PortfolioStrategyValues.Discretionary);
  }, [investorEntity]);
  const [submitDeposit, {
    isSuccess: submitDepositIsSuccess,
    isError: submitDepositIsError,
    isLoading: submitDepositIsLoading
  }] = paymentsApi.useSubmitManagedPortfolioDepositMutation();
  const onSubmitDeposit = useCallback(_ref2 => {
    let {
      depositAmount,
      bankAccountId
    } = _ref2;
    if (investorEntityId && investorAccount !== null && investorAccount !== void 0 && investorAccount.id && depositAmount && bankAccountId) {
      submitDeposit({
        investorEntityId,
        amount: depositAmount,
        source: {
          investorAccountId: investorAccount === null || investorAccount === void 0 ? void 0 : investorAccount.id,
          bankAccountId
        }
      });
    }
  }, [investorEntityId, investorAccount, submitDeposit]);
  return {
    onSubmitDeposit,
    submitDepositIsError,
    submitDepositIsLoading,
    submitDepositIsSuccess
  };
};