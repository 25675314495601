var __jsx = React.createElement;
import React, { useEffect } from 'react';
import { Formik, Form } from 'formik';
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';
import Typography from '@mui/material/Typography';
import LoadingButton from '@mui/lab/LoadingButton';
import { userAddressApi, investorEntityApi } from '@yieldstreet/platform-kit';
import ENV_CONFIG from 'utils/env/config';
import { ModalContent, FormWrapper, SButtonContainer } from '../shared/shared';
import { useUserState } from 'utils/hooks';
import { testIds, defaultError, EditAddressSchema, emptyAddress } from './EditAddressModal.model';
import { FormikAddressInputGroup } from '@yieldstreet/ui-kit/inputs';
export const EditAddressForm = _ref => {
  let {
    userAddress,
    onCancel,
    onSuccess
  } = _ref;
  const {
    useUpdateUserAddressMutation
  } = userAddressApi;
  const {
    id: userId
  } = useUserState();
  const {
    useGetInvestorEntityQuery
  } = investorEntityApi;
  const {
    data: investorEntities
  } = useGetInvestorEntityQuery();
  const [updateUserAddress, {
    originalArgs,
    isLoading,
    isSuccess,
    isError,
    error
  }] = useUpdateUserAddressMutation();
  useEffect(() => {
    if (isSuccess) {
      var _investorEntities$ent;
      onSuccess(originalArgs.address, !!(investorEntities !== null && investorEntities !== void 0 && (_investorEntities$ent = investorEntities.entities) !== null && _investorEntities$ent !== void 0 && _investorEntities$ent.length));
    }
  }, [isSuccess, onSuccess, originalArgs, investorEntities]);
  return __jsx(ModalContent, {
    "data-cy": testIds.form
  }, __jsx(Typography, {
    variant: "h3",
    sx: {
      mb: 3
    }
  }, "Edit user address"), __jsx(Formik, {
    initialValues: userAddress || emptyAddress,
    validationSchema: EditAddressSchema,
    onSubmit: (address, formikActions) => {
      updateUserAddress({
        userId,
        address
      }).then(formikActions.setSubmitting(false));
    }
  }, formikProps => {
    var _error$response, _error$response$data;
    return __jsx(Form, null, __jsx(FormWrapper, null, __jsx(FormikAddressInputGroup, {
      apiKey: ENV_CONFIG.GOOGLE_PLACES_KEY,
      formikProps: formikProps,
      testId: testIds.form
    })), isError && __jsx(Alert, {
      severity: "error"
    }, (error === null || error === void 0 ? void 0 : (_error$response = error.response) === null || _error$response === void 0 ? void 0 : (_error$response$data = _error$response.data) === null || _error$response$data === void 0 ? void 0 : _error$response$data.message) || defaultError), __jsx(SButtonContainer, null, __jsx(Button, {
      variant: "outlined",
      "data-cy": testIds.formCancel,
      onClick: onCancel,
      "data-trackingid": testIds.formCancel
    }, "Cancel"), __jsx(LoadingButton, {
      "data-cy": testIds.formUpdate,
      loading: isLoading,
      "data-trackingid": testIds.formUpdate,
      onClick: () => {
        formikProps.handleSubmit();
      }
    }, "Update address")));
  }));
};