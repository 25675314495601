import { SegmentSpecEvent, eventManager } from '@yieldstreet/tool-kit';
import forEach from "lodash/forEach";
import { setSegmentTrack } from 'utils/thirdPartyCode';
export const onCompleteInvestments = _ref => {
  let {
    investments,
    context
  } = _ref;
  forEach(Object.values(investments), (_ref2, index) => {
    let {
      id,
      amount,
      noteUrlHash
    } = _ref2;
    //Track First time investment only on first one for bulk
    if (context && context.firstTimeInvestor && index === 0) {
      eventManager.track(SegmentSpecEvent.FIRST_INVESTMENT, {
        investmentId: id,
        total: amount,
        currency: 'USD',
        noteUrlHash
      });
    }
    eventManager.track(SegmentSpecEvent.ORDER_COMPLETED, {
      orderId: id,
      total: amount,
      currency: 'USD',
      noteUrlHash
    });
    setSegmentTrack({
      event: 'investment-completed',
      properties: {
        firstInvestment: context.firstTimeInvestor
      }
    });
  });
};
export const onCompletePrequest = () => {
  setSegmentTrack({
    event: 'investor.investment.prequestcompleted'
  });
};