import _Button from "@mui/material/Button";
import _Typography from "@mui/material/Typography";
import _extends from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/extends.js";
var __jsx = React.createElement;
import React, { useCallback, useState } from 'react';
import { useAuthFlow } from 'utils/auth';
import { SuccessScreen } from '../../../Login.style';
import { testIds } from '../../Login2FA.model';
export const SetupSuccess = () => {
  // Temporary fix to enable ETC users to continue with the flow after 2FA
  const {
    0: authFlowState,
    1: setAuthFlowState
  } = useState();
  useAuthFlow(authFlowState);
  const handleOnClick = useCallback(() => {
    setAuthFlowState({});
  }, []);
  return __jsx(SuccessScreen, null, __jsx(_Typography, _extends({
    component: "h1",
    variant: "h4"
  }, testIds.title.attr), "Two-factor authentication set-up complete"), __jsx("br", null), __jsx(_Typography, {
    component: "p",
    variant: "body1"
  }, "From now on, we'll send you a code when you log in. You will need to enter that code to verify your identity."), __jsx("br", null), __jsx(_Typography, {
    component: "p",
    variant: "body1"
  }, "You can change your verification method at any time via your user profile."), __jsx(_Button, _extends({
    fullWidth: true,
    onClick: handleOnClick,
    sx: {
      mt: 8
    }
  }, testIds.continue.attr), "Continue"));
};