import _objectSpread from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import _objectWithoutProperties from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/objectWithoutProperties.js";
const _excluded = ["info", "contact_name"];
import { v4 as uuidv4 } from 'uuid';
export const mockUsers = [{
  info: 'DEV ($1M Balance)',
  account_number: '205350023',
  contact_name: 'Richard Klam',
  contact_id: '2200044',
  email: 'richardklam@ys.com'
}, {
  info: 'DEV ($1M Balance)',
  account_number: '205350024',
  contact_name: 'Universal Debit',
  contact_id: '2186170',
  email: 'joshuawagner@ys.com'
}, {
  info: 'DEV ($5K Balance)',
  account_number: '205356600',
  contact_name: 'Syndication Last',
  contact_id: '2218383',
  email: 'georgiaclark@ys.com'
}, {
  info: 'QA ($1M Balance)',
  account_number: '205356587',
  contact_name: 'Steven Kelly',
  contact_id: '2208521',
  email: 'stevenkelly@ys.com'
}, {
  info: 'QA ($1M Balance)',
  account_number: '205356588',
  contact_name: 'Mildred Jones',
  contact_id: '2208522',
  email: 'mildredjones@ys.com'
}, {
  info: 'QA ($5K Balance)',
  account_number: '205356591',
  contact_name: 'Jay Salazar',
  contact_id: '2218381',
  email: 'jaysalazar@ys.com'
}];
export const mockUserOptions = mockUsers.map((_ref, value) => {
  let {
      info,
      contact_name
    } = _ref,
    props = _objectWithoutProperties(_ref, _excluded);
  return _objectSpread({
    value: String(value),
    label: `${info} - ${contact_name}`,
    user: 0,
    contact_name
  }, props);
});
export const mockSAMLResponse = function () {
  let {
    account_number = '',
    contact_name = '',
    contact_id = '',
    email = '',
    user_id = ''
  } = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  const id = uuidv4();
  const timestamp = new Date().toISOString();
  return `<?xml version="1.0" encoding="UTF-8"?>
<saml2p:Response xmlns:saml2p="urn:oasis:names:tc:SAML:2.0:protocol"
                Consent="urn:oasis:names:tc:SAML:2.0:consent:unspecified"
                Destination="https://sp.yieldstreet.net/api/auth/saml/callback?client_name=etc"
                ID="unique-id-per-response-${id}" IssueInstant="${timestamp}" Version="2.0">
    <saml2:Issuer xmlns:saml2="urn:oasis:names:tc:SAML:2.0:assertion">http://samldev-yieldstreet.trustetc.com</saml2:Issuer>
    <saml2p:Status>
        <saml2p:StatusCode Value="urn:oasis:names:tc:SAML:2.0:status:Success"/>
    </saml2p:Status>
    <saml2:Assertion xmlns:saml2="urn:oasis:names:tc:SAML:2.0:assertion" ID="assertion-id-per-response-${id}" IssueInstant="${timestamp}" Version="2.0">
        <saml2:Issuer>http://samldev-yieldstreet.trustetc.com</saml2:Issuer>
        <saml2:Subject>
            <saml2:NameID NameQualifier="https://sp.yieldstreet.net">user-with-account-2</saml2:NameID>
            <saml2:SubjectConfirmation Method="urn:oasis:names:tc:SAML:2.0:cm:bearer">
                <saml2:SubjectConfirmationData NotOnOrAfter="${timestamp}" Recipient="https://sp.yieldstreet.net/api/auth/saml/callback?client_name=etc"/>
            </saml2:SubjectConfirmation>
        </saml2:Subject>
        <saml2:Conditions NotBefore="${timestamp}" NotOnOrAfter="${timestamp}">
            <saml2:AudienceRestriction>
                <saml2:Audience>https://sp.yieldstreet.net</saml2:Audience>
            </saml2:AudienceRestriction>
        </saml2:Conditions>
        <saml2:AttributeStatement>
            <saml2:Attribute Name="account_number">
                <saml2:AttributeValue>${account_number}</saml2:AttributeValue>
            </saml2:Attribute>
            <saml2:Attribute Name="contact_name">
                <saml2:AttributeValue>${contact_name}</saml2:AttributeValue>
            </saml2:Attribute>
            <saml2:Attribute Name="contact_id">
                <saml2:AttributeValue>${contact_id}</saml2:AttributeValue>
            </saml2:Attribute>
            <saml2:Attribute Name="email">
                <saml2:AttributeValue>${email}</saml2:AttributeValue>
            </saml2:Attribute>
            <saml2:Attribute Name="user_id">
                <saml2:AttributeValue>${user_id}</saml2:AttributeValue>
            </saml2:Attribute>
        </saml2:AttributeStatement>
        <saml2:AuthnStatement AuthnInstant="${timestamp}">
            <saml2:AuthnContext>
                <saml2:AuthnContextClassRef>urn:oasis:names:tc:SAML:2.0:ac:classes:PasswordProtectedTransport</saml2:AuthnContextClassRef>
            </saml2:AuthnContext>
        </saml2:AuthnStatement>
    </saml2:Assertion>
</saml2p:Response>`;
};