import _Box from "@mui/material/Box";
import _Typography from "@mui/material/Typography";
var __jsx = React.createElement;
import React, { useMemo } from 'react';
import { Markdown } from '@yieldstreet/ui-kit';
import { useRolloverSheet } from '@yieldstreet/tool-kit';
import { replaceCheckboxLink } from 'utils/replaceCheckboxLink';
import { getPrefix } from '../utils/prefix';
import Acknowledge from './Acknowledge';
import DynamicAcknowledge from './DynamicAcknowledge';
import { Container, PaddingContainer } from './AcknowledgeContainer.styles';
import { FormikCheckboxInput } from '@yieldstreet/ui-kit/inputs';
const AcknowledgeContainer = _ref => {
  let {
    formikProps,
    offeringInfo,
    externalCheckboxes,
    prefix,
    title,
    generalCheckboxes,
    isRolloverV2,
    rollover
  } = _ref;
  title = title || 'Acknowledge and sign';
  prefix = getPrefix(prefix);
  const ackType = externalCheckboxes ? 'dynamic' : 'static';
  const {
    rolloverOffering
  } = useRolloverSheet({
    amount: 0,
    urlHash: rollover === null || rollover === void 0 ? void 0 : rollover.urlHash,
    investorId: rollover === null || rollover === void 0 ? void 0 : rollover.investorId
  });
  const commonCheckboxes = useMemo(() => {
    return generalCheckboxes && generalCheckboxes.map(checkbox => {
      return __jsx(_Box, {
        key: checkbox.name,
        "data-cy": "general-checkboxes"
      }, __jsx(FormikCheckboxInput, {
        "data-cy": `general-checkbox-${checkbox.name}`,
        name: checkbox.name,
        label: __jsx(_Typography, {
          variant: "body2",
          className: checkbox.className,
          "data-cy": `checkboxes-paragraph-${checkbox.className}`
        }, typeof checkbox.label === 'string' ? __jsx(Markdown, null, replaceCheckboxLink(checkbox.label, checkbox.links)) : checkbox.label),
        formikProps: formikProps
      }));
    });
  }, [generalCheckboxes, formikProps]);
  return __jsx(Container, {
    id: `${prefix}acknowledge`,
    "data-cy": `${prefix}${ackType}-acknowledge`
  }, __jsx(_Typography, {
    variant: "h4"
  }, title), __jsx(PaddingContainer, null, externalCheckboxes ? __jsx(DynamicAcknowledge, {
    checkboxes: isRolloverV2 ? [...(externalCheckboxes === null || externalCheckboxes === void 0 ? void 0 : externalCheckboxes.checkboxes), {
      name: 'checkRollover',
      label: `For convenience I hereby irrevocably instruct and direct YS ST Notes LLC to apply the full amount of principal repayment of my existing investment in ${rolloverOffering === null || rolloverOffering === void 0 ? void 0 : rolloverOffering.title} towards my subscription in ${offeringInfo === null || offeringInfo === void 0 ? void 0 : offeringInfo.title}.`
    }] : externalCheckboxes === null || externalCheckboxes === void 0 ? void 0 : externalCheckboxes.checkboxes,
    offeringDocuments: (offeringInfo === null || offeringInfo === void 0 ? void 0 : offeringInfo.documents) || [],
    formikProps: formikProps,
    prefix: prefix
  }) : __jsx(Acknowledge, {
    offeringInfo: offeringInfo,
    formikProps: formikProps,
    prefix: prefix,
    isRolloverV2: isRolloverV2,
    rolloverOffering: rolloverOffering
  }), commonCheckboxes));
};
export default AcknowledgeContainer;