import { friendlyMedia, Paragraph } from '@yieldstreet/ui-kit';
import styled from 'styled-components';
import { Link } from 'sharedComponents/Links/Links';
import { OfferingListAlignment, OfferingListAlignmentFirstChild, OfferingListAlignmentLastChild, OfferingListContentRightMixin, OfferingListFlexMixin } from '../OfferingList.style';
import arrowIcon from '@yieldstreet/ui-kit/assets/graphics/icons/arrow-round.svg';
export const OfferingListBodyItemWrapper = styled.div.withConfig({
  displayName: "OfferingListBodystyle__OfferingListBodyItemWrapper",
  componentId: "sc-12mdrlv-0"
})(["", ";filter:invert(1);"], OfferingListFlexMixin);
export const OfferingListBodyItemRow = styled.div.withConfig({
  displayName: "OfferingListBodystyle__OfferingListBodyItemRow",
  componentId: "sc-12mdrlv-1"
})(["filter:invert(1);background-color:black;display:block;border-radius:4px;box-shadow:", ";&:hover{box-shadow:0px 2px 4px rgba(255,255,255,0.16);}", ";"], props => props !== null && props !== void 0 && props.withBoxShadow ? '0px 2px 4px rgba(255, 255, 255, 0.16)' : '', friendlyMedia.desktop`
     background: black url(${arrowIcon}) no-repeat calc(100% - 20px) center;
     background-size: 12px 12px;
  `);
export const OfferingListBodyImage = styled.img.withConfig({
  displayName: "OfferingListBodystyle__OfferingListBodyImage",
  componentId: "sc-12mdrlv-2"
})(["width:38px;height:38px;margin-right:10px;border-radius:4px;"]);
export const OfferingListBodyImageTitleWrapper = styled.div.withConfig({
  displayName: "OfferingListBodystyle__OfferingListBodyImageTitleWrapper",
  componentId: "sc-12mdrlv-3"
})(["display:flex;align-items:center;", ";"], OfferingListAlignmentFirstChild);
export const OfferingListBodyItemLink = styled(Link).withConfig({
  displayName: "OfferingListBodystyle__OfferingListBodyItemLink",
  componentId: "sc-12mdrlv-4"
})(["background:none;"]);
export const OfferingListBodyItemContent = styled(Paragraph).attrs({
  small: true
}).withConfig({
  displayName: "OfferingListBodystyle__OfferingListBodyItemContent",
  componentId: "sc-12mdrlv-5"
})(["display:block;", ";", ";&:last-child{", "}"], props => props !== null && props !== void 0 && props.rightAlign ? OfferingListContentRightMixin : '', OfferingListAlignment, OfferingListAlignmentLastChild);