import _Box from "@mui/material/Box";
import _Stack from "@mui/material/Stack";
var __jsx = React.createElement;
import React, { useCallback } from 'react';
import { Formik, Form } from 'formik';
import isEmpty from "lodash/isEmpty";
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';
import Typography from '@mui/material/Typography';
import LoadingButton from '@mui/lab/LoadingButton';
import { userAddressApi, investorEntityApi } from '@yieldstreet/platform-kit';
import ENV_CONFIG from 'utils/env/config';
import { FormWrapper } from '../shared/shared';
import { useUserState } from 'utils/hooks';
import { testIds, defaultError, EditAddressSchema, emptyAddress } from './EditAddressModal.model';
import { FormikAddressInputGroup } from '@yieldstreet/ui-kit/inputs';
import { BusinessEvent, eventManager } from '@yieldstreet/tool-kit';
export const EditAddressForm = _ref => {
  let {
    isUpdating,
    userAddress,
    onCancel,
    onSuccess
  } = _ref;
  const {
    useUpdateUserAddressMutation
  } = userAddressApi;
  const {
    id: userId
  } = useUserState();
  const {
    useGetInvestorEntityQuery
  } = investorEntityApi;
  const {
    data: investorEntities
  } = useGetInvestorEntityQuery();
  const [updateUserAddress, {
    originalArgs,
    isLoading,
    isError,
    error
  }] = useUpdateUserAddressMutation();
  const onSubmit = useCallback(async (address, formikActions) => {
    try {
      var _investorEntities$ent;
      await updateUserAddress({
        userId,
        address
      }).unwrap();
      eventManager.track(BusinessEvent.USER_ADDRESS_UPDATE);
      formikActions.setSubmitting(false);
      onSuccess(originalArgs === null || originalArgs === void 0 ? void 0 : originalArgs.address, !!(investorEntities !== null && investorEntities !== void 0 && (_investorEntities$ent = investorEntities.entities) !== null && _investorEntities$ent !== void 0 && _investorEntities$ent.length));
    } catch (error) {
      eventManager.trackFailure(BusinessEvent.USER_ADDRESS_UPDATE);
    }
  }, [investorEntities, onSuccess, originalArgs, updateUserAddress, userId]);
  return __jsx(_Box, {
    "data-cy": testIds.form,
    sx: {
      mt: [10, 0]
    }
  }, __jsx(Typography, {
    variant: "h3",
    sx: {
      mb: 3
    }
  }, isEmpty(userAddress) ? 'Add address' : 'Update address'), __jsx(Formik, {
    initialValues: isUpdating ? userAddress : emptyAddress,
    validationSchema: EditAddressSchema,
    onSubmit: onSubmit
  }, formikProps => {
    var _error$response, _error$response$data;
    return __jsx(Form, null, __jsx(FormWrapper, null, __jsx(FormikAddressInputGroup, {
      apiKey: ENV_CONFIG.GOOGLE_PLACES_KEY,
      formikProps: formikProps,
      testId: testIds.form
    })), isError && __jsx(Alert, {
      severity: "error",
      sx: {
        my: 2
      }
    }, (error === null || error === void 0 ? void 0 : (_error$response = error.response) === null || _error$response === void 0 ? void 0 : (_error$response$data = _error$response.data) === null || _error$response$data === void 0 ? void 0 : _error$response$data.message) || defaultError), __jsx(_Stack, {
      spacing: 2,
      direction: {
        xs: 'column-reverse',
        sm: 'row'
      }
    }, __jsx(Button, {
      variant: "outlined",
      "data-cy": testIds.formCancel,
      onClick: onCancel,
      "data-trackingid": testIds.formCancel
    }, "Cancel"), __jsx(LoadingButton, {
      "data-cy": testIds.formUpdate,
      loading: isLoading,
      "data-trackingid": testIds.formUpdate,
      onClick: () => {
        formikProps.handleSubmit();
      }
    }, isEmpty(userAddress) ? 'Add address' : 'Update address')));
  }));
};