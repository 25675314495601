import _FormHelperText from "@mui/material/FormHelperText";
import _FormControlLabel from "@mui/material/FormControlLabel";
import _objectWithoutProperties from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/objectWithoutProperties.js";
const _excluded = ["helperText", "error"];
var __jsx = React.createElement;
import React from 'react';
import MuiCheckbox from '@mui/material/Checkbox';
import { fieldToCheckbox } from '../utils/fieldToCheckbox/fieldToCheckbox.util';
export const FormikCheckboxInput = props => {
  const _fieldToCheckbox = fieldToCheckbox(props),
    {
      helperText,
      error
    } = _fieldToCheckbox,
    checkboxProps = _objectWithoutProperties(_fieldToCheckbox, _excluded);
  return __jsx(React.Fragment, null, __jsx(_FormControlLabel, {
    control: __jsx(MuiCheckbox, checkboxProps),
    label: props.label
  }), error && __jsx(_FormHelperText, {
    error: true
  }, helperText));
};