import _extends from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/extends.js";
import _objectWithoutProperties from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/objectWithoutProperties.js";
const _excluded = ["label", "onChange", "size", "value", "values"];
var __jsx = React.createElement;
import React from 'react';
import CheckIcon from '@mui/icons-material/Check';
import { SCheckbox, SFormControlLabel } from './OnboardCheckbox.style';
export const OnboardCheckbox = _ref => {
  let {
      label,
      onChange,
      size,
      value,
      values
    } = _ref,
    testAttr = _objectWithoutProperties(_ref, _excluded);
  return __jsx(SFormControlLabel, {
    label: label,
    size: size,
    control: __jsx(SCheckbox, _extends({
      checked: values === null || values === void 0 ? void 0 : values.includes(`${value}`),
      checkedIcon: __jsx(CheckIcon, {
        fontSize: "medium"
      }),
      icon: __jsx(React.Fragment, null),
      onChange: onChange,
      value: value
    }, testAttr))
  });
};