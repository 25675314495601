import * as Yup from 'yup';
import Big from 'big.js';
import { formatCurrency } from '@yieldstreet/tool-kit';
export const DepositFundsFormSchema = _ref => {
  let {
    minInvestment,
    maxInvestment,
    stepIncrement
  } = _ref;
  return Yup.object().shape({
    bankAccountId: Yup.string().typeError("You haven't specified a bank account").required("You haven't specified a bank account"),
    depositAmount: Yup.number().transform(value => Number.isNaN(value) ? null : value).typeError("You haven't specified a deposit amount").min(minInvestment, `Your deposit must be between ${formatCurrency(minInvestment)} and ${formatCurrency(maxInvestment)}`).max(maxInvestment, `Your deposit must be between ${formatCurrency(minInvestment)} and ${formatCurrency(maxInvestment)}`).test('stepIncrement', `Only increments of ${formatCurrency(stepIncrement)} are allowed`, amount => amount ? new Big(amount).mod(stepIncrement).eq(0) : true).required("You haven't specified a deposit amount")
  });
};