import { genTestIds } from '@yieldstreet/tool-kit';
const {
  getTestIds
} = genTestIds(['account-type', 'verify-identity', 'first-investment']);
export const testIds = getTestIds('account-progress');
export const accountTypeSelection = {
  title: 'Account type selection',
  subtitle: 'Getting started on Yieldstreet'
};
export const verifyIdentity = {
  title: 'Verify identity',
  subtitle: 'Tell us bit about you'
};
export const firstInvestment = {
  title: 'Make your first investment',
  subtitle: 'Your first step into alternatives'
};