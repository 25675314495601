var __jsx = React.createElement;
import React, { useRef } from 'react';
import { Formik } from 'formik';
import { FormikTextInput } from '@yieldstreet/ui-kit/inputs/forms';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import Alert from '@mui/material/Alert';
import { ModalContainer, useModalContext } from '@yieldstreet/ui-kit';
import { useApiErrorMessage, useApiFormErrors } from '@yieldstreet/tool-kit';
import { userPasswordApi } from '@yieldstreet/platform-kit';
import { ModalHeader } from '../shared/shared';
import { ResetPasswordSchema, checkMatchingPw, initialValues } from './PasswordModal.model';
import { FormWrapper, SButtonContainer, SForm } from '../common/common.style';
export const View = () => {
  const form = useRef();
  const {
    hideModal
  } = useModalContext();
  const [changePassword, changePasswordState] = userPasswordApi.useChangePasswordMutation();
  const {
    isLoading,
    isError,
    isSuccess
  } = changePasswordState;
  const errorMessage = useApiErrorMessage(changePasswordState);
  useApiFormErrors(changePasswordState, form);
  return __jsx(ModalContainer, null, __jsx(ModalHeader, {
    type: 3
  }, "Change password"), __jsx(Formik, {
    ref: form,
    initialValues: initialValues,
    validationSchema: ResetPasswordSchema,
    validate: values => checkMatchingPw(values),
    onSubmit: (_ref, formikActions) => {
      let {
        password,
        newPassword,
        passwordConfirm
      } = _ref;
      return changePassword({
        password,
        new_password: newPassword,
        password_confirm: passwordConfirm
      }).then(() => formikActions.setSubmitting(false));
    }
  }, formikProps => __jsx(SForm, null, isSuccess ? __jsx(Alert, {
    severity: "success"
  }, "Your password has changed!") : __jsx(FormWrapper, null, __jsx(FormikTextInput, {
    type: "password",
    name: "password",
    autoComplete: "password",
    label: "Current password",
    formikProps: formikProps,
    sx: {
      mb: 4
    }
  }), __jsx(FormikTextInput, {
    type: "password",
    name: "newPassword",
    autoComplete: "new-password",
    label: "New password",
    formikProps: formikProps,
    sx: {
      mb: 4
    }
  }), __jsx(FormikTextInput, {
    type: "password",
    name: "passwordConfirm",
    autoComplete: "passwordConfirm",
    label: "Confirm password",
    formikProps: formikProps,
    sx: {
      mb: 4
    }
  }), isError && __jsx(Alert, {
    severity: "error",
    sx: {
      mb: 4
    }
  }, errorMessage), __jsx(SButtonContainer, null, __jsx(Button, {
    variant: "outlined",
    fullWidth: true,
    type: "reset",
    onClick: () => hideModal(),
    "data-trackingid": "password-modal-update-password-cancel",
    disabled: isLoading
  }, "Cancel"), __jsx(LoadingButton, {
    fullWidth: true,
    type: "submit",
    onSubmit: formikProps.onSubmit,
    "data-trackingid": "password-modal-update-password-confirm",
    disabled: isLoading,
    loading: isLoading
  }, "Update password"))))));
};