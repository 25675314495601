var __jsx = React.createElement;
import React, { useEffect } from 'react';
import { useHistory } from 'react-router';
import { audienceApi } from '@yieldstreet/platform-kit';
import { PORTFOLIO_PATH, PORTFOLIO_SUMMARY_SCREEN_PATH } from 'pages/portfolio';
import { usePortfolioBaseConfig } from 'pages/portfolio/hooks/usePortfolioBaseConfig';
import { useDiscretionaryFlags } from 'hooks';
export const DashboardRedirect = _ref => {
  let {
    children
  } = _ref;
  const history = useHistory();
  const portfolioBaseConfig = usePortfolioBaseConfig();
  const {
    showManagedPortfolio,
    showPortfolioSummary
  } = useDiscretionaryFlags();
  const {
    useGetPortfolioSelfAudienceQuery
  } = audienceApi;
  const {
    data,
    isFetching,
    isError
  } = useGetPortfolioSelfAudienceQuery({
    userId: portfolioBaseConfig === null || portfolioBaseConfig === void 0 ? void 0 : portfolioBaseConfig.userId
  }, {
    refetchOnMountOrArgChange: true
  });
  useEffect(() => {
    if (showManagedPortfolio || showPortfolioSummary) {
      var _history$location;
      history.replace({
        pathname: PORTFOLIO_SUMMARY_SCREEN_PATH,
        hash: history === null || history === void 0 ? void 0 : (_history$location = history.location) === null || _history$location === void 0 ? void 0 : _history$location.hash
      });
    }
  }, [showManagedPortfolio, showPortfolioSummary, history]);
  useEffect(() => {
    if (!isFetching && !isError && data !== null && data !== void 0 && data.hasSelfPortfolio) {
      var _history$location2;
      history.push({
        pathname: PORTFOLIO_PATH,
        hash: history === null || history === void 0 ? void 0 : (_history$location2 = history.location) === null || _history$location2 === void 0 ? void 0 : _history$location2.hash
      });
    }
  }, [data, isFetching, isError, history]);
  return __jsx(React.Fragment, null, !isFetching && !isError && __jsx(React.Fragment, null, children));
};