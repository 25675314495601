var __jsx = React.createElement;
import React from 'react';
import { SignupQuizSteps } from '@yieldstreet/platform-kit';
import { SignupUsualInvestingWayLabels, SignupUsualInvestingWayOptions } from '@yieldstreet/tool-kit';
import { SignupQuizStep } from '../../SignupQuizStep';
import { stepTestId } from './HowStep.model';
export const HowStep = _ref => {
  let {
    nextStep,
    saveQuizAnswer,
    getQuizAnswer
  } = _ref;
  const answer = getQuizAnswer(SignupQuizSteps.USUAL_INVESTING_WAY);
  return __jsx(SignupQuizStep, {
    answer: answer,
    getQuizAnswer: getQuizAnswer,
    multiple: true,
    nextStep: nextStep,
    options: SignupUsualInvestingWayOptions,
    saveQuizAnswer: saveQuizAnswer,
    stepKey: SignupQuizSteps.USUAL_INVESTING_WAY,
    testId: stepTestId,
    title: SignupUsualInvestingWayLabels.title
  });
};