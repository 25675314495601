import _objectSpread from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import _objectWithoutProperties from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/objectWithoutProperties.js";
const _excluded = ["label", "values"];
var __jsx = React.createElement;
import * as Yup from 'yup';
import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { Formik, Form } from 'formik';
import { NumericFormat } from 'react-number-format';
import { useHistory } from 'react-router-dom';
import { FormCurrencyTextInput, CheckboxInput, Paragraph, ExternalLink, Notification, Button, Heading, Row, Col } from '@yieldstreet/ui-kit';
import { Interpolation, interpolate } from '@yieldstreet/tool-kit';
import { investmentRequestOfferingsApi } from '@yieldstreet/platform-kit';
import { AmountWrapper, InputWrapper, DiscountedAmountWrapper, DiscountedAmountLabel, DiscountedAmountQty, AcknowledgeWrapper, DisclaimerLabel, SButtonContainer, SubmitButton, SharesWrapper, SharesQty, SharesLabel } from './TenderOfferInput.style';
import AcknowledgeContainer from 'pages/completeInvestment/Acknowledge/AcknowledgeContainer';
import Signature from 'pages/completeInvestment/Signature';
import { COPY as validationCopy, INITIAL_VALUES, VALIDATION_SCHEMA } from './TenderOfferInput.model';
import { isNotBasicTenderOfferCheck } from './shared/TenderOffer.utils';
const {
  useLiquidationMutation
} = investmentRequestOfferingsApi;
export const TenderOfferInputForm = _ref => {
  let {
    urlHash,
    nav,
    fundType,
    liquidationDiscount,
    backUrl,
    desiredAmount,
    investorAccountId,
    tenderId,
    onSubmitCallback,
    copy,
    documents,
    legalEntityType
  } = _ref;
  const {
    0: desiredAmountInput,
    1: setDesiredAmountInput
  } = useState(false);
  const {
    0: errorMessage,
    1: setErrorMessage
  } = useState(false);
  const {
    0: isFullLiquidation,
    1: setFullLiquidation
  } = useState(false);
  const {
    0: discountedAmount,
    1: setDiscountedAmount
  } = useState(0);
  const {
    0: amountShares,
    1: setAmountShares
  } = useState(0);
  const isNotBasicTenderOffer = useMemo(() => isNotBasicTenderOfferCheck({
    fundType,
    legalEntityType
  }), [fundType, legalEntityType]);
  const history = useHistory();
  const {
    checkboxes,
    signatureDisclaimer
  } = copy;
  const interpolatedCheckboxes = useMemo(() => {
    return checkboxes.map(checkbox => {
      const {
          label,
          values = []
        } = checkbox,
        rest = _objectWithoutProperties(checkbox, _excluded);
      const links = Object.entries(values).map(_ref2 => {
        let [key, value] = _ref2;
        const doc = documents === null || documents === void 0 ? void 0 : documents.find(d => key === (d === null || d === void 0 ? void 0 : d.docType));
        return [key, __jsx(ExternalLink, {
          onClick: event => {
            event.stopPropagation();
          },
          key: doc === null || doc === void 0 ? void 0 : doc.id,
          href: doc === null || doc === void 0 ? void 0 : doc.url,
          target: "_blank",
          rel: "noopener noreferrer"
        }, value)];
      });
      return _objectSpread(_objectSpread({}, rest), {}, {
        label: __jsx(Interpolation, {
          template: label,
          values: Object.fromEntries(links)
        })
      });
    });
  }, [checkboxes, documents]);
  const validationSchema = useMemo(() => {
    const checkboxValidation = checkboxes.reduce((acc, checkbox) => {
      acc[checkbox.name] = Yup.boolean().oneOf([true], 'This field is required.');
      return acc;
    }, {});
    return Yup.object().shape(_objectSpread(_objectSpread(_objectSpread({}, VALIDATION_SCHEMA), checkboxValidation), {}, {
      desiredAmount: Yup.number().required(validationCopy.validationRequired).moreThan(0, validationCopy.validationMoreThan).max(desiredAmount, validationCopy.validationMax)
    }));
  }, [checkboxes, desiredAmount]);
  const [submitLiquidation, {
    isLoading: isSubmitting
  }] = useLiquidationMutation();
  const submit = useCallback(async values => {
    var _submitRes$data;
    const {
      investorInitials,
      desiredAmount
    } = values;
    let payload = {
      urlHash,
      disclaimer: interpolate(signatureDisclaimer, {
        terms: 'Terms of Service',
        privacy: 'Privacy Policy'
      }, true),
      tenderId,
      investorAccountId,
      investorInitials
    };
    if (isFullLiquidation) {
      payload = _objectSpread(_objectSpread({}, payload), {}, {
        fullLiquidation: true
      });
    } else {
      payload = _objectSpread(_objectSpread({}, payload), {}, {
        amount: parseFloat(desiredAmount)
      }, isNotBasicTenderOffer && {
        amountShares
      });
    }
    const submitRes = await submitLiquidation(_objectSpread({}, payload));
    if (submitRes !== null && submitRes !== void 0 && (_submitRes$data = submitRes.data) !== null && _submitRes$data !== void 0 && _submitRes$data.success) {
      onSubmitCallback();
    } else {
      var _submitRes$error;
      setErrorMessage(submitRes === null || submitRes === void 0 ? void 0 : (_submitRes$error = submitRes.error) === null || _submitRes$error === void 0 ? void 0 : _submitRes$error.message);
    }
  }, [signatureDisclaimer, urlHash, amountShares, investorAccountId, tenderId, isFullLiquidation, submitLiquidation, onSubmitCallback, isNotBasicTenderOffer]);
  useEffect(() => {
    if (isNotBasicTenderOffer) {
      setAmountShares(desiredAmountInput ? parseFloat((desiredAmountInput / nav).toFixed(10)) : 0);
    } else {
      setDiscountedAmount(desiredAmountInput || typeof liquidationDiscount !== 'number' ? desiredAmountInput - desiredAmountInput * (liquidationDiscount / 100) : 0);
    }
  }, [nav, isNotBasicTenderOffer, desiredAmountInput, liquidationDiscount]);
  const renderSharesOrDiscount = useMemo(() => {
    if (isNotBasicTenderOffer) {
      return nav ? __jsx(SharesWrapper, null, __jsx(SharesLabel, null, "Shares"), __jsx(SharesQty, {
        small: true,
        "data-cy": "tender-input-shares-amount"
      }, amountShares)) : null;
    } else {
      return liquidationDiscount > 0 ? __jsx(DiscountedAmountWrapper, null, __jsx(DiscountedAmountLabel, null, "Estimated amount to be paid after", ' ', __jsx(NumericFormat, {
        value: liquidationDiscount,
        displayType: "text",
        suffix: '%',
        decimalScale: 2,
        allowNegative: false
      }), ' ', "discount"), __jsx(DiscountedAmountQty, {
        small: true,
        "data-cy": "tender-input-discounted-amount"
      }, __jsx(NumericFormat, {
        value: discountedAmount,
        displayType: "text",
        thousandSeparator: true,
        prefix: '$',
        decimalScale: 2,
        fixedDecimalScale: true,
        allowNegative: false
      }))) : null;
    }
  }, [isNotBasicTenderOffer, nav, liquidationDiscount, discountedAmount, amountShares]);
  return __jsx(Formik, {
    enableReinitialize: true,
    initialValues: INITIAL_VALUES,
    validationSchema: validationSchema,
    onSubmit: submit,
    render: formikProps => __jsx(React.Fragment, null, __jsx(Form, {
      noValidate: true
    }, __jsx(Row, null, __jsx(Col, {
      size: {
        mobile: 12,
        tablet: 7,
        desktop: 7
      }
    }, __jsx(AmountWrapper, null, __jsx(Heading, {
      type: 4,
      marginBottom: "s"
    }, copy === null || copy === void 0 ? void 0 : copy.formTitle), (copy === null || copy === void 0 ? void 0 : copy.formInputNote) && __jsx(Paragraph, {
      size: "small",
      marginTop: "s",
      marginBottom: "sm"
    }, copy === null || copy === void 0 ? void 0 : copy.formInputNote), __jsx(InputWrapper, null, __jsx(FormCurrencyTextInput, {
      name: "desiredAmount",
      type: "tel",
      label: "Desired amount",
      placeholder: "XXX,XXX,XXX",
      decimalScale: 2,
      thousandSeparator: true,
      allowNegative: false,
      formikProps: formikProps,
      disabled: isFullLiquidation,
      onChange: value => {
        setDesiredAmountInput(parseFloat(value));
      }
    }), renderSharesOrDiscount), __jsx(CheckboxInput, {
      testId: "tender-input-sell-entire-checkbox",
      name: "sellEntire",
      label: __jsx(Paragraph, {
        size: "small"
      }, copy === null || copy === void 0 ? void 0 : copy.formSellAll),
      onChange: val => {
        formikProps.setFieldValue('desiredAmount', val ? desiredAmount : undefined);
        setFullLiquidation(!!val);
      }
    })))), __jsx(AcknowledgeWrapper, null, interpolatedCheckboxes && __jsx(React.Fragment, null, __jsx(AcknowledgeContainer, {
      externalCheckboxes: {
        checkboxes: interpolatedCheckboxes
      },
      formikProps: formikProps,
      markdown: false
    }), __jsx(Signature, {
      formikProps: formikProps,
      customDisclaimer: __jsx(DisclaimerLabel, null, __jsx(Interpolation, {
        template: copy === null || copy === void 0 ? void 0 : copy.signatureDisclaimer,
        values: {
          terms: __jsx(ExternalLink, {
            target: "_blank",
            rel: "noopener noreferrer",
            href: "/terms"
          }, "Terms of Use"),
          privacy: __jsx(ExternalLink, {
            target: "_blank",
            rel: "noopener noreferrer",
            href: "/privacy"
          }, "Privacy Policy")
        }
      }))
    }))), errorMessage && __jsx(Notification, {
      severity: "error",
      title: errorMessage,
      inline: true
    }), __jsx(SButtonContainer, null, __jsx(Button, {
      type: "button",
      buttonType: "secondary",
      disabled: isSubmitting,
      onClick: () => history.push(backUrl)
    }, "Back to investment"), __jsx(SubmitButton, {
      type: "submit",
      loading: isSubmitting,
      disabled: isSubmitting
    }, copy === null || copy === void 0 ? void 0 : copy.submitCtaText))))
  });
};