import _TextField from "@mui/material/TextField";
import _extends from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/extends.js";
import _objectSpread from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import _objectWithoutProperties from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/objectWithoutProperties.js";
const _excluded = ["apiKey", "label", "allowWorldwide", "onChange", "sx"];
var __jsx = React.createElement;
import React from 'react';
import MuiAddressAutocomplete from 'mui-address-autocomplete';
import { useState } from 'react';
import isEmpty from "lodash/isEmpty";
import trim from "lodash/trim";
import { getGoogleFieldValue } from './FormikAddressInput.utils';
import { fieldToTextField } from '../utils/fieldToTextField/fieldToTextField.util';
export const FormikAddressInput = _ref => {
  let {
      apiKey,
      label,
      allowWorldwide,
      onChange,
      sx
    } = _ref,
    props = _objectWithoutProperties(_ref, _excluded);
  const {
    0: value,
    1: setValue
  } = useState({
    description: '',
    components: {},
    structured_formatting: {
      main_text: '',
      secondary_text: '',
      main_text_matched_substrings: []
    }
  });
  const componentRestrictions = allowWorldwide ? {} : {
    country: 'us'
  };
  return __jsx(MuiAddressAutocomplete
  /* @ts-expect-error [MM]:typings are incorrect for freeSolo */, {
    freeSolo: true,
    apiKey: apiKey,
    id: props['data-cy'],
    label: label,
    value: value,
    getOptionLabel: option => `${getGoogleFieldValue(option, 'street_number')} ${getGoogleFieldValue(option, 'route')}`,
    onChange: (_, value) => {
      setValue(value);
      onChange(value);
    },
    requestOptions: {
      componentRestrictions
    },
    renderInput: inputParams => {
      var _inputParams$inputPro, _inputParams$inputPro2, _inputParams$inputPro3;
      const fieldProps = fieldToTextField(props);
      const inputProps = _objectSpread(_objectSpread({}, inputParams.inputProps), {}, {
        value: isEmpty(trim(inputParams === null || inputParams === void 0 ? void 0 : (_inputParams$inputPro = inputParams.inputProps) === null || _inputParams$inputPro === void 0 ? void 0 : (_inputParams$inputPro2 = _inputParams$inputPro.value) === null || _inputParams$inputPro2 === void 0 ? void 0 : _inputParams$inputPro2.toString())) ? fieldProps === null || fieldProps === void 0 ? void 0 : fieldProps.value : inputParams === null || inputParams === void 0 ? void 0 : (_inputParams$inputPro3 = inputParams.inputProps) === null || _inputParams$inputPro3 === void 0 ? void 0 : _inputParams$inputPro3.value
      });
      const params = _objectSpread(_objectSpread(_objectSpread({}, inputParams), fieldProps), {}, {
        inputProps
      });
      return __jsx(_TextField, _extends({
        label: label
      }, params));
    },
    sx: sx
  });
};