import { SignupQuizSteps } from '@yieldstreet/platform-kit';
import { genTestIds } from '@yieldstreet/tool-kit';
import BenzingaLogo from 'assets/Logos/images/benzinga-gray.svg';
const {
  getTestIds
} = genTestIds(['title', 'subtitle', 'lets-get-started', 'login-link']);
export const testIds = getTestIds('signup-get-started');
export const Benzinga = {
  alignment: 'center',
  rate: 4.5,
  image: BenzingaLogo,
  imageAlt: 'Benzinga logo',
  imageWidth: 87,
  imageHeight: 12,
  content: '“If you’re searching for alternative investments to add to your portfolio, you’ll find a wealth of opportunities”',
  asOfDate: 'Dec 2021'
};
export const options = [{
  value: `/signup-quiz/${SignupQuizSteps.EXPERIENCE_ALTS}`,
  label: 'Personalize my experience',
  dataCy: 'signup-get-started-personalized-dashboard'
}, {
  value: '/signup',
  label: 'Skip to sign up',
  dataCy: 'signup-get-started-ready-to-invest'
}];
export const SKIP_QUIZ_KEY = 'skip_quiz';