import _extends from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/extends.js";
import _objectWithoutProperties from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/objectWithoutProperties.js";
const _excluded = ["label", "onClick", "value", "values"];
var __jsx = React.createElement;
import React from 'react';
import CheckIcon from '@mui/icons-material/Check';
import { OnboardChipContainer } from './OnboardChip.style';
export const OnboardChip = _ref => {
  let {
      label,
      onClick,
      value,
      values
    } = _ref,
    testAttr = _objectWithoutProperties(_ref, _excluded);
  const isSelected = values === null || values === void 0 ? void 0 : values.includes(value);
  return __jsx(OnboardChipContainer, _extends({
    "aria-selected": isSelected,
    icon: isSelected ? __jsx(CheckIcon, {
      fontSize: "small"
    }) : undefined,
    label: label,
    onClick: onClick,
    selected: isSelected
  }, testAttr));
};