import styled from 'styled-components';
import { CardsCarousel, Label, ExternalLink, media } from '@yieldstreet/ui-kit';
import { Link } from 'sharedComponents/Links/Links';
export const TitleContainer = styled.div.withConfig({
  displayName: "ManagedPortfolioSelectionstyle__TitleContainer",
  componentId: "sc-8oqnnh-0"
})(["margin:auto;margin-bottom:", ";text-align:center;display:flex;flex-direction:column;gap:", ";", ""], props => props.theme.spacing.l, props => props.theme.spacing.sm, media.large`
    max-width: 500px;
  `);
export const WhiteBox = styled.div.withConfig({
  displayName: "ManagedPortfolioSelectionstyle__WhiteBox",
  componentId: "sc-8oqnnh-1"
})(["padding:", ";background:", ";border-radius:", ";margin:auto;width:100%;display:flex;flex-direction:column;", ""], props => props.theme.spacing.ml, props => props.theme.colors.background_card, props => props.theme.spacing.s, media.large`
    flex-direction: row;
    justify-content: space-between;
  `);
export const SLink = styled(Link).withConfig({
  displayName: "ManagedPortfolioSelectionstyle__SLink",
  componentId: "sc-8oqnnh-2"
})(["align-self:center;background:none;margin-top:", ";width:100%;", ""], props => props.theme.spacing.m, media.large`
    margin-top: 0;
    width: unset;
  `);
export const StrategyContainer = styled.div.withConfig({
  displayName: "ManagedPortfolioSelectionstyle__StrategyContainer",
  componentId: "sc-8oqnnh-3"
})(["display:flex;flex-direction:row;gap:", ";margin-top:", ";margin-bottom:", ";"], props => props.theme.spacing.ml, props => props.hasSuggestedStrategy ? props.theme.spacing.l : '0', _ref => {
  let {
    theme
  } = _ref;
  return theme.spacing.m;
});
export const StrategyLabelWrapper = styled.div.withConfig({
  displayName: "ManagedPortfolioSelectionstyle__StrategyLabelWrapper",
  componentId: "sc-8oqnnh-4"
})(["margin:", ";"], _ref2 => {
  let {
    theme
  } = _ref2;
  return `0 auto ${theme.spacing.l} auto`;
});
export const SCardsCarousel = styled(CardsCarousel).withConfig({
  displayName: "ManagedPortfolioSelectionstyle__SCardsCarousel",
  componentId: "sc-8oqnnh-5"
})(["margin-bottom:", ";.swiper-cards{max-width:470px;margin-left:auto !important;}"], props => props.theme.spacing['2xl']);
export const DisclaimerContainer = styled.div.withConfig({
  displayName: "ManagedPortfolioSelectionstyle__DisclaimerContainer",
  componentId: "sc-8oqnnh-6"
})(["display:flex;flex-direction:column;margin-top:", ";"], _ref3 => {
  let {
    theme
  } = _ref3;
  return theme.spacing.l;
});
export const Disclaimer = styled(Label).withConfig({
  displayName: "ManagedPortfolioSelectionstyle__Disclaimer",
  componentId: "sc-8oqnnh-7"
})(["margin-top:", ";"], _ref4 => {
  let {
    theme
  } = _ref4;
  return theme.spacing.sm;
});
export const DisclaimerLink = styled(ExternalLink).withConfig({
  displayName: "ManagedPortfolioSelectionstyle__DisclaimerLink",
  componentId: "sc-8oqnnh-8"
})(["color:", " !important;"], _ref5 => {
  let {
    theme
  } = _ref5;
  return theme.colors.text_label;
});
export const SExternalLink = styled(ExternalLink).withConfig({
  displayName: "ManagedPortfolioSelectionstyle__SExternalLink",
  componentId: "sc-8oqnnh-9"
})(["color:", " !important;background-image:", ";"], _ref6 => {
  let {
    theme
  } = _ref6;
  return theme.colors.text_secondary_paragraph;
}, _ref7 => {
  let {
    theme
  } = _ref7;
  return `linear-gradient(to right, ${theme.colors.text_secondary_paragraph} 45%, transparent 45%, transparent 55%, ${theme.colors.text_secondary_paragraph} 55%) !important`;
});