import _Button from "@mui/material/Button";
import _Alert from "@mui/material/Alert";
import _Box from "@mui/material/Box";
import _Typography from "@mui/material/Typography";
import _extends from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/extends.js";
var __jsx = React.createElement;
import React, { useEffect, useRef, useState } from 'react';
import { Formik } from 'formik';
import LoadingButton from '@mui/lab/LoadingButton';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { twoFactorAuthApi, User2FAMethod } from '@yieldstreet/platform-kit';
import { useApiErrorMessage, useApiFormErrors } from '@yieldstreet/tool-kit';
import { FormikTextInput, OnboardButton } from '@yieldstreet/ui-kit/inputs';
import { PageLoader } from '@yieldstreet/ui-kit/layouts';
import { FormPhoneTextInput } from '@yieldstreet/ui-kit';
import { AUTH_ERRORS } from 'utils/auth';
import { ClipboardText } from 'sharedComponents/ClipboardText';
import { QRCode } from 'sharedComponents/QRCode';
import { BackNavigation, FieldsContainer, FormContainer } from '../../../Login.style';
import { testIds, setupAuth } from '../../Login2FA.model';
import { RegisterFormSchema, getMethodText, getTwoFAOptions } from './RegisterForm.model';
export const RegisterForm = _ref => {
  let {
    nextStep,
    register,
    registerResult
  } = _ref;
  const form = useRef(null);
  const {
    data: twoFAStatus
  } = twoFactorAuthApi.useStatusQuery({});
  const [createQRCode, qrCodeResult] = twoFactorAuthApi.useCreateFactorMutation({});
  const {
    data: {
      bindingUri = ''
    } = {},
    isLoading: generatingQRCode
  } = qrCodeResult;
  const {
    status: {
      phone = ''
    } = {},
    availableMethods = [User2FAMethod.AUTHY_APP, User2FAMethod.TEXT_MESSAGE]
  } = twoFAStatus || {};
  const maskedPhone = !!(phone !== null && phone !== void 0 && phone.includes('*'));
  const {
    0: method,
    1: setMethod
  } = useState(null);
  const {
    isLoading
  } = registerResult;
  const errorMessage = useApiErrorMessage(registerResult, AUTH_ERRORS.VERIFY_2FA);
  const qrCodeErrorMessage = useApiErrorMessage(qrCodeResult, AUTH_ERRORS.VERIFY_2FA);
  useApiFormErrors(registerResult, form);
  useEffect(() => {
    if (method === User2FAMethod.OTP_APP) {
      createQRCode({});
    }
  }, [method, createQRCode]);
  if (!twoFAStatus || generatingQRCode) {
    return __jsx(PageLoader, {
      testId: testIds.loading.id
    });
  }
  const {
    title,
    titleDescription
  } = getMethodText(method);
  return __jsx(React.Fragment, null, __jsx(_Box, null, method && __jsx(BackNavigation, _extends({}, testIds.backButton.attr, {
    onClick: () => setMethod(null)
  }), __jsx(ArrowBackIosIcon, null)), __jsx(_Typography, _extends({
    variant: "h4",
    component: "h1",
    sx: {
      mb: 4
    }
  }, testIds.title.attr), title)), __jsx(_Typography, {
    component: "p",
    variant: "body1",
    sx: {
      mb: 10
    }
  }, titleDescription), __jsx(FormContainer, null, !method ? __jsx(React.Fragment, null, getTwoFAOptions(availableMethods).map(option => __jsx(OnboardButton, _extends({}, option, {
    "data-cy": `${testIds.radioButton.id}-option-${option.value}`,
    key: option.value,
    onClick: () => {
      setMethod(option.value);
    },
    selected: method === option.value,
    size: "medium"
  })))) : __jsx(React.Fragment, null, method === User2FAMethod.OTP_APP ? __jsx(React.Fragment, null, __jsx("div", {
    className: "fs-exclude"
  }, __jsx(QRCode, {
    value: bindingUri
  })), qrCodeErrorMessage && __jsx(_Alert, _extends({
    severity: "error"
  }, testIds.errorNotification.attr), qrCodeErrorMessage), __jsx(_Typography, {
    component: "p",
    sx: {
      mb: 2,
      mt: 4
    },
    variant: "body1"
  }, "Trouble scanning? Enter the code below instead."), __jsx("div", {
    className: "fs-mask"
  }, __jsx(ClipboardText, {
    label: "Two-factor text code",
    value: bindingUri,
    testId: testIds.otpCode.id
  })), __jsx(_Button, _extends({
    fullWidth: true,
    onClick: nextStep,
    sx: {
      mt: 3
    }
  }, testIds.submit.attr), "Continue")) : __jsx(Formik, {
    ref: form,
    initialValues: {
      phone: phone || ''
    },
    validationSchema: RegisterFormSchema(maskedPhone),
    onSubmit: values => register({
      phone: maskedPhone ? null : values.phone,
      method
    })
  }, formikProps => __jsx(FieldsContainer, null, maskedPhone ? __jsx(FormikTextInput, _extends({
    name: "phone",
    label: "Mobile phone number",
    formikProps: formikProps
  }, testIds.maskedPhoneField.attr, {
    disabled: true
  })) : __jsx(FormPhoneTextInput, _extends({
    name: "phone",
    label: "Mobile phone number",
    formikProps: formikProps,
    fullWidth: true,
    required: true,
    disabled: !!phone
  }, testIds.phoneField.attr)), errorMessage && __jsx(_Alert, _extends({
    severity: "error",
    sx: {
      mt: 2
    }
  }, testIds.errorNotification.attr), errorMessage), __jsx(LoadingButton, _extends({
    fullWidth: true,
    loading: isLoading,
    sx: {
      mt: 2
    },
    type: "submit"
  }, testIds.submit.attr), setupAuth[method]))))));
};