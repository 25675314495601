var __jsx = React.createElement;
import React from 'react';
import { Route, Switch, useLocation, useRouteMatch } from 'react-router-dom';
import { SAMLFakeProviders } from './SAMLFake.model';
import SAMLFakeCadre from './cadre/SAMLFakeCadre';
import SAMLFakeEtc from './etc/SAMLFakeEtc';
const SAMLFake = () => {
  const {
    path
  } = useRouteMatch();
  const location = useLocation();
  return __jsx(Switch, {
    location: location
  }, __jsx(Route, {
    strict: true,
    path: `${path}/${SAMLFakeProviders.ETC}`,
    render: () => __jsx(SAMLFakeEtc, null)
  }), __jsx(Route, {
    strict: true,
    path: `${path}/${SAMLFakeProviders.CADRE}`,
    render: () => __jsx(SAMLFakeCadre, null)
  }));
};
export default SAMLFake;