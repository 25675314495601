import _Box from "@mui/material/Box";
var __jsx = React.createElement;
import React, { useMemo, useState } from 'react';
import { RouterLink } from '@yieldstreet/ui-kit/navigation';
import { twoFactorAuthApi } from '@yieldstreet/platform-kit';
import { PageFlow } from '@yieldstreet/ui-kit';
import { ReCaptchaDisclaimer } from 'sharedComponents/ReCaptcha';
import { useAuthFlow } from 'utils/auth';
import { ContactSupport, Content } from '../Login.style';
import { CodeForm } from './screens/CodeForm';
import { EmailForm } from './screens/EmailForm';
import { RegisterForm } from './screens/RegisterForm';
import { SetupSuccess } from './screens/SetupSuccess';
import { testIds } from './Login2FA.model';
const Login2FA = function () {
  var _twoFAStatus$data;
  // Temporary fix to enable ETC users to continue with the flow after 2FA
  const {
    0: authFlowState,
    1: setAuthFlowState
  } = useState(null);
  const {
    0: codeStep,
    1: setCodeStep
  } = useState(false);
  useAuthFlow(authFlowState);
  const twoFAStatus = twoFactorAuthApi.useStatusQuery({});
  const {
    method: authMethod,
    emailVerified
  } = (twoFAStatus === null || twoFAStatus === void 0 ? void 0 : (_twoFAStatus$data = twoFAStatus.data) === null || _twoFAStatus$data === void 0 ? void 0 : _twoFAStatus$data.status) || {};
  const [confirmEmail, confirmEmailResult] = twoFactorAuthApi.useConfirmEmailMutation();
  const [register, registerResult] = twoFactorAuthApi.useRegisterMutation();
  const [login, loginResult] = twoFactorAuthApi.useLoginMutation();
  const [confirm, confirmResult] = twoFactorAuthApi.useConfirmMutation();
  const showContactSupport = useMemo(() => {
    if ((loginResult.isSuccess || confirmResult.isSuccess) && registerResult.isSuccess) {
      return false;
    }
    return true;
  }, [confirmResult, loginResult, registerResult]);
  const render2FASetup = useMemo(() => {
    if ((loginResult.isSuccess || confirmResult.isSuccess) && registerResult.isSuccess) {
      return __jsx(SetupSuccess, null);
    }
    if (loginResult.isSuccess || confirmResult.isSuccess) {
      setAuthFlowState(twoFAStatus);
    }
    if (codeStep || registerResult.isSuccess || authMethod) {
      return __jsx(CodeForm, {
        registerResult: registerResult,
        codeMutation: authMethod ? login : confirm,
        codeMutationResult: authMethod ? loginResult : confirmResult
      });
    }
    if (confirmEmailResult.isSuccess || emailVerified) {
      return __jsx(RegisterForm, {
        register: register,
        registerResult: registerResult,
        nextStep: () => setCodeStep(true)
      });
    }
    return __jsx(EmailForm, {
      confirmEmail: confirmEmail,
      confirmEmailResult: confirmEmailResult
    });
  }, [authMethod, codeStep, confirm, confirmEmail, confirmEmailResult, confirmResult, emailVerified, login, loginResult, register, registerResult, twoFAStatus]);
  return __jsx(PageFlow, {
    showLogo: true
  }, __jsx(Content, null, __jsx(_Box, testIds.formArea.attr, render2FASetup), showContactSupport && __jsx(ContactSupport, null, __jsx(RouterLink, {
    id: "intercom-link",
    to: "#"
  }, "Contact support")), __jsx(ReCaptchaDisclaimer, null)));
};
export default Login2FA;