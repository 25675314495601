import _extends from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/extends.js";
var __jsx = React.createElement;
import React, { useMemo } from 'react';
import { formatCurrency, Interpolation, useRolloverSheet } from '@yieldstreet/tool-kit';
import { Heading, Label, Paragraph } from '@yieldstreet/ui-kit';
import { investorEntityApi, ManagementStrategy } from '@yieldstreet/platform-kit';
import { Copy as copy, testIds } from './RolloverSheet.model';
import { Divider, HorizontalTally, Main, VerticalTally, Wrapper } from './RolloverSheet.style';
export const RolloverSheet = _ref => {
  var _investor$investorAcc;
  let {
    amount,
    investorEntityId,
    urlHash,
    destinationUrlHash
  } = _ref;
  const {
    data: investorEntity = {},
    isFetching: investorEntityIsFetching,
    isLoading: investorEntityIsLoading,
    isUninitialized: investorEntityIsUninitialized
  } = investorEntityApi.useGetInvestorEntityQuery();
  const investor = useMemo(() => ((investorEntity === null || investorEntity === void 0 ? void 0 : investorEntity.entities) || []).find(investor => investor.id.toString() === investorEntityId), [investorEntity, investorEntityId]);
  const investorId = investor === null || investor === void 0 ? void 0 : (_investor$investorAcc = investor.investorAccounts) === null || _investor$investorAcc === void 0 ? void 0 : _investor$investorAcc.find(account => account.managementStrategy === ManagementStrategy.Self).id;
  const {
    rolloverOffering: offering,
    rolloverOfferingDestination: offeringDestination,
    rolloverOfferingIsUninitialized,
    rolloverOfferingIsLoading,
    rolloverOfferingError,
    maxRolloverAmount: rolloverAmount,
    additionalFunds
  } = useRolloverSheet({
    amount,
    urlHash,
    investorId,
    destinationUrlHash
  });
  const isInvestorAccountReady = !investorEntityIsFetching && !investorEntityIsLoading && !investorEntityIsUninitialized;
  const isRolloverOfferingReady = !rolloverOfferingIsUninitialized && !rolloverOfferingIsLoading && !rolloverOfferingError;
  return __jsx(Wrapper, testIds.base.attr, __jsx(Heading, _extends({
    type: 4
  }, testIds.title.attr), copy.title), __jsx(Main, null, isInvestorAccountReady && __jsx(VerticalTally, testIds.investorAccount.attr, __jsx(Label, null, copy.investorAccount), __jsx(Paragraph, testIds.investorAccountValue.attr, investor === null || investor === void 0 ? void 0 : investor.name)), isRolloverOfferingReady && __jsx(VerticalTally, testIds.rolloverFrom.attr, __jsx(Label, null, copy.rolloverFrom), __jsx(Paragraph, testIds.rolloverFromValue.attr, offering === null || offering === void 0 ? void 0 : offering.title)), __jsx("div", null, __jsx(HorizontalTally, testIds.investmentAmount.attr, __jsx(Paragraph, {
    size: "small"
  }, copy.investmentAmount), __jsx(Paragraph, _extends({}, testIds.investmentAmountValue.attr, {
    size: "small"
  }), formatCurrency(amount))), __jsx(HorizontalTally, testIds.rolloverAmount.attr, __jsx(Paragraph, {
    size: "small"
  }, copy.rolloverAmount), __jsx(Paragraph, _extends({}, testIds.rolloverAmountValue.attr, {
    size: "small"
  }), formatCurrency(rolloverAmount)))), __jsx(Divider, null), __jsx(HorizontalTally, testIds.additionalFunds.attr, __jsx(Paragraph, {
    size: "small",
    semiBold: true
  }, copy.additionalFunds), __jsx(Paragraph, _extends({}, testIds.additionalFundsValue.attr, {
    size: "small",
    semiBold: true
  }), formatCurrency(additionalFunds)))), __jsx(Paragraph, _extends({
    size: "small",
    secondary: true
  }, testIds.disclaimer.attr), __jsx(Interpolation, {
    template: copy.disclaimer,
    values: {
      note: offeringDestination === null || offeringDestination === void 0 ? void 0 : offeringDestination.title
    }
  })));
};