import forEach from "lodash/forEach";
export const replaceCheckboxLink = (label, links) => {
  const matches = label.match(/::[\w]+/gm);
  forEach(matches, match => {
    const sanitizedMatch = match.replace('::', '');
    const foundLink = links === null || links === void 0 ? void 0 : links[sanitizedMatch];
    if (foundLink) {
      label = label.replace(match, `[${foundLink === null || foundLink === void 0 ? void 0 : foundLink.label}](${foundLink === null || foundLink === void 0 ? void 0 : foundLink.url})`);
    }
  });
  return label;
};