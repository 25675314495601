import _Snackbar from "@mui/material/Snackbar";
import _extends from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/extends.js";
import _Typography from "@mui/material/Typography";
var __jsx = React.createElement;
import React, { Fragment, useMemo, useState } from 'react';
import { PageContainer, PageLoader } from '@yieldstreet/ui-kit/layouts';
import { RouterLink } from '@yieldstreet/ui-kit/navigation';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import { ToggleInput, Tooltip, withModalContext, useModalContext } from '@yieldstreet/ui-kit';
import isEmpty from "lodash/isEmpty";
import { requestAccountDeletionStrings, requestAccountDeletionTestIds, useFlags } from '@yieldstreet/tool-kit';
import { Communication } from 'sharedComponents/Communication/Communication';
import { TwoFAModalContainer } from 'sharedComponents/TwoFaModal/TwoFAModalContainer';
import BeneficiariesModal from './BeneficiariesModal';
import { EditAddressModal, EDIT_ADDRESS_MODAL } from './EditAddressModal';
import { EditNameModalContainer, EDIT_NAME_MODAL } from './EditNameModal';
import { EditPhoneModalContainer, EDIT_PHONE_MODAL } from './EditPhoneModal';
import { PasswordModal, PASSWORD_MODAL_ID } from './PasswordModal';
import RemoveBeneficiaryModal from './RemoveBeneficiaryModal';
import { SetPasswordModal, SET_PASSWORD_MODAL_ID } from './SetPasswordModal';
import { Wrapper, Container, OpenModal, ProfileRight, ProfileLeft, ProfileContainer, VerifiedEmail, UnverifiedEmail, Password, TooltipWrapper, DeleteBeneficiary, InfoContainer } from './UserProfile.style';
import { EditAddressModalNew } from './EditAddressModalNew';
const UserProfile = _ref => {
  let {
    userState,
    resetPw,
    twoFAStatus,
    showModalBeneficiaries,
    toggleSMS,
    isSMSToggleOn,
    showOldModal,
    hideOldModal,
    deleteBeneficiary,
    primaryBeneficiary,
    contingentBeneficiary,
    showModalRemoveBeneficiaries,
    showBeneficiary,
    userAddress,
    userPhone,
    twoFAStatusFetching,
    twoFAStatusError,
    hasEmailVerified,
    hasPassword
  } = _ref;
  const {
    investorAddressUpdate
  } = useFlags({
    investorAddressUpdate: false
  });
  const {
    0: show2FAModal,
    1: setShow2FAModal
  } = useState(false);
  const {
    showModal
  } = useModalContext();
  // TODO: convert to context - as SnackbarContext, and replace legacy Snackbar component
  const {
    0: snackbarProps,
    1: setSnackbarProps
  } = useState({});
  const hasAddress = !isEmpty(userAddress) && !isEmpty(userAddress.addressLine1) && !isEmpty(userAddress.city) && !isEmpty(userAddress.state) && !isEmpty(userAddress.postalCode);
  const passwordBag = useMemo(() => ({
    action: hasPassword ? () => showModal(PASSWORD_MODAL_ID) : () => showModal(SET_PASSWORD_MODAL_ID),
    testId: `user-profile-${hasPassword ? 'reset' : 'set'}-password`,
    actionLabel: hasPassword ? 'Change' : 'Set password',
    // if hasPassword status has been fetched
    showAction: hasPassword !== undefined
  }), [hasPassword, showModal]);
  return __jsx(Wrapper, null, __jsx(Communication, {
    location: "top_navigation"
  }), __jsx(PageContainer, null, __jsx(Box, null, twoFAStatusFetching || !twoFAStatusError && !twoFAStatus ? __jsx(PageLoader, {
    testId: "user-profile-empty-loader"
  }) : __jsx(React.Fragment, null, __jsx(_Typography, {
    variant: "h2"
  }, "User profile"), __jsx(Container, null, resetPw && __jsx(Alert, {
    severity: "success"
  }, "Your password has been reset."), __jsx(ProfileContainer, null, __jsx(ProfileLeft, null, __jsx(InfoContainer, null, __jsx(_Typography, {
    variant: "body1"
  }, "Email address -", ' ', hasEmailVerified ? __jsx(VerifiedEmail, {
    "data-cy": "user-profile-email-verified"
  }, "verified") : __jsx(UnverifiedEmail, {
    "data-cy": "user-profile-email-unverified"
  }, "unverified")), __jsx(_Typography, {
    variant: "body2"
  }, userState.emailAddress)), __jsx(InfoContainer, {
    "data-cy": passwordBag.testId
  }, __jsx(_Typography, {
    variant: "body1"
  }, "Password", passwordBag.showAction && __jsx(Password, {
    "data-cy": `${passwordBag.testId}-button`,
    callback: passwordBag.action
  }, passwordBag.actionLabel)), hasPassword && __jsx(_Typography, {
    variant: "body1"
  }, "\u2022\u2022\u2022\u2022\u2022\u2022\u2022\u2022\u2022")), __jsx(InfoContainer, null, __jsx(_Typography, {
    variant: "body1"
  }, "First name", ' ', __jsx(OpenModal, {
    id: "open-show-edit-name",
    "data-cy": "open-show-edit-name",
    callback: () => showModal(EDIT_NAME_MODAL)
  }, "Edit")), __jsx(_Typography, {
    variant: "body2"
  }, userState.firstName)), twoFAStatus && twoFAStatus.available && __jsx(InfoContainer, {
    className: "profile2FA"
  }, __jsx(_Typography, {
    variant: "body1"
  }, "Two-factor authentication", ' ', __jsx(OpenModal, {
    "data-cy": `${twoFAStatus !== null && twoFAStatus !== void 0 && twoFAStatus.method ? 'edit' : 'add'}-2fa-modal`,
    callback: () => {
      setShow2FAModal(true);
    }
  }, twoFAStatus !== null && twoFAStatus !== void 0 && twoFAStatus.method ? 'Edit' : '+ Add')), (twoFAStatus === null || twoFAStatus === void 0 ? void 0 : twoFAStatus.method) && __jsx(TooltipWrapper, {
    className: "two-factor-authentication-label"
  }, __jsx(_Typography, {
    variant: "body2"
  }, twoFAStatus.method), __jsx(Tooltip, {
    place: "top",
    tooltipKey: "2FA-type",
    testId: "2FA-type"
  }, "You'll be asked for your password and a verification code sent to your mobile device when you log in to Yieldstreet."))), __jsx(InfoContainer, {
    className: "profileLastName"
  }, __jsx(_Typography, {
    variant: "body1"
  }, "Last name", ' ', __jsx(OpenModal, {
    callback: () => showModal(EDIT_NAME_MODAL)
  }, "Edit")), __jsx(_Typography, {
    variant: "body2"
  }, userState.lastName)), __jsx(InfoContainer, {
    className: "profileSMS"
  }, __jsx(_Typography, null, "SMS updates"), __jsx(Box, null, __jsx(ToggleInput, {
    label: "Opt Out / Opt In",
    value: !!isSMSToggleOn,
    onChange: toggleSMS
  }))), __jsx(InfoContainer, {
    className: "profilePhone"
  }, __jsx(_Typography, {
    variant: "body1"
  }, "Phone", ' ', __jsx(OpenModal, {
    callback: () => showModal(EDIT_PHONE_MODAL)
  }, "Edit")), __jsx(_Typography, {
    variant: "body2"
  }, (userPhone === null || userPhone === void 0 ? void 0 : userPhone.phone) || (userPhone === null || userPhone === void 0 ? void 0 : userPhone.raw))), showBeneficiary && __jsx(InfoContainer, {
    className: "profileBeneficiary"
  }, __jsx(_Typography, {
    variant: "body1"
  }, "Account beneficiaries", (primaryBeneficiary || contingentBeneficiary) && __jsx(DeleteBeneficiary, {
    callback: () => showOldModal('showModalRemoveBeneficiaries')
  }, "Delete")), primaryBeneficiary && __jsx(Fragment, null, __jsx(_Typography, {
    variant: "body1"
  }, primaryBeneficiary.name), __jsx(_Typography, {
    variant: "body1"
  }, "Primary")), /*eslint-disable no-nested-ternary*/
  !primaryBeneficiary && hasAddress ? __jsx(RouterLink, {
    to: "/add-new-beneficiary"
  }, "+ Add beneficiary") : !hasAddress ? __jsx(_Typography, {
    variant: "body2"
  }, "Please", ' ', __jsx(OpenModal, {
    callback: () => showModal(EDIT_ADDRESS_MODAL)
  }, "add an address"), ' ', "to add beneficiary for this account") : null
  /*eslint-enable no-nested-ternary*/), __jsx(InfoContainer, {
    className: "profileUserAddress"
  }, hasAddress ? __jsx(React.Fragment, null, __jsx(_Typography, {
    variant: "body1"
  }, "Address", ' ', __jsx(OpenModal, {
    callback: () => showModal(EDIT_ADDRESS_MODAL)
  }, "Edit")), __jsx(_Typography, {
    variant: "body2"
  }, `${userAddress.addressLine1}, ${userAddress.addressLine2 ? userAddress.addressLine2 : ''}`, __jsx("br", null), `${userAddress.city} ${userAddress.state} ${userAddress.postalCode}`)) : __jsx(React.Fragment, null, __jsx(_Typography, {
    variant: "body2"
  }, "Address", __jsx(OpenModal, {
    callback: () => showModal(EDIT_ADDRESS_MODAL)
  }, ' ', "+ Add address")))), showBeneficiary && __jsx(InfoContainer, {
    className: "profileBeneficiarySummary"
  }, contingentBeneficiary && __jsx(React.Fragment, null, __jsx(_Typography, {
    variant: "body1"
  }, contingentBeneficiary.name), __jsx(_Typography, {
    variant: "body1"
  }, "Contingent")), (primaryBeneficiary || contingentBeneficiary) && __jsx(OpenModal, {
    callback: () => showOldModal('showModalBeneficiaries')
  }, "View summary")), __jsx(TwoFAModalContainer, {
    "data-cy": "profile-modal-2fa",
    shouldBeVisible: show2FAModal,
    onComplete: () => {
      setShow2FAModal(false);
    },
    onExit: () => {
      setShow2FAModal(false);
    }
  }), __jsx(PasswordModal, null), __jsx(SetPasswordModal, null), __jsx(EditNameModalContainer, null), __jsx(EditPhoneModalContainer, {
    userId: userState.id,
    userPhone: (userPhone === null || userPhone === void 0 ? void 0 : userPhone.raw) || userPhone.phone
  }), investorAddressUpdate ? __jsx(EditAddressModalNew, {
    setSnackbarProps: setSnackbarProps
  }) : __jsx(EditAddressModal, null), __jsx(BeneficiariesModal, {
    visible: showModalBeneficiaries,
    hideModal: hideOldModal,
    primaryBeneficiary: primaryBeneficiary,
    contingentBeneficiary: contingentBeneficiary
  }), __jsx(RemoveBeneficiaryModal, {
    visible: showModalRemoveBeneficiaries,
    hideModal: hideOldModal,
    deleteBeneficiary: deleteBeneficiary,
    userId: userState.id
  })), __jsx(ProfileRight, null, __jsx(_Typography, {
    variant: "body2"
  }, "If you need to edit your email, please", ' ', __jsx(Link, {
    id: "intercom-link"
  }, "Contact Us.")), __jsx(Box, null, __jsx(Communication, {
    location: "user_profile"
  })), __jsx(Box, null, __jsx(RouterLink, _extends({
    variant: "body2",
    to: "/profile/user/request-account-deletion"
  }, requestAccountDeletionTestIds.initialCta.attr), requestAccountDeletionStrings.initialCta))))))), __jsx(_Snackbar, {
    open: snackbarProps === null || snackbarProps === void 0 ? void 0 : snackbarProps.message,
    anchorOrigin: {
      vertical: 'bottom',
      horizontal: 'right'
    },
    sx: {
      '&.MuiSnackbar-root': {
        bottom: '70px'
      }
    },
    autoHideDuration: 2000,
    onClose: () => setSnackbarProps({})
  }, (snackbarProps === null || snackbarProps === void 0 ? void 0 : snackbarProps.message) && __jsx(Alert, {
    severity: snackbarProps === null || snackbarProps === void 0 ? void 0 : snackbarProps.severity,
    variant: "standard"
  }, snackbarProps === null || snackbarProps === void 0 ? void 0 : snackbarProps.message)), __jsx(_Snackbar, null)));
};
export default withModalContext(UserProfile);