var __jsx = React.createElement;
import React from 'react';
import { testIds } from './OfferingListHeader.model';
import { OfferingListHeaderLabel, OfferingListHeaderLabelWrapper } from './OfferingListHeader.style';
export const OfferingListHeader = _ref => {
  let {
    headings
  } = _ref;
  return __jsx(OfferingListHeaderLabelWrapper, {
    "data-cy": testIds.offeringListHeader
  }, headings === null || headings === void 0 ? void 0 : headings.map((label, index) => __jsx(OfferingListHeaderLabel, {
    key: `${label}-${index}`,
    "data-cy": testIds.offeringListHeaderLabel,
    rightAlign: label === null || label === void 0 ? void 0 : label.rightAlign
  }, label === null || label === void 0 ? void 0 : label.title)));
};