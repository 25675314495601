import { friendlyMedia } from '@yieldstreet/ui-kit';
import styled, { css } from 'styled-components';
export const OfferingListWrapper = styled.div.withConfig({
  displayName: "OfferingListstyle__OfferingListWrapper",
  componentId: "sc-1icbuac-0"
})(["width:100%;"]);
export const OfferingListFlexMixin = css(["display:flex;padding:8px;justify-content:start;align-items:center;margin:10px 0;"]);
export const OfferingListContentRightMixin = css(["text-align:right;"]);
export const OfferingListAlignment = css(["flex:1;"]);
export const OfferingListAlignmentLastChild = css(["padding-right:100px;"]);
export const OfferingListAlignmentFirstChild = css(["flex:1;", ";"], friendlyMedia.desktop`
      flex: 0 0 300px;
  `);