import styled from 'styled-components';
import { CallbackLink, media } from '@yieldstreet/ui-kit';
import { AnchorLink } from 'sharedComponents/AnchorLink';
export const Card = styled.div.withConfig({
  displayName: "StrategyCardstyle__Card",
  componentId: "sc-1ro6wf0-0"
})(["background:", ";border-radius:", ";position:relative;display:flex;flex:1 1 0;flex-direction:column;justify-content:space-between;margin-top:", ";", ""], _ref => {
  let {
    featured,
    theme
  } = _ref;
  return featured ? theme.colors.background_inverse : theme.colors.background_card;
}, _ref2 => {
  let {
    theme,
    featured
  } = _ref2;
  return featured ? `0 0 ${theme.spacing.sm} ${theme.spacing.sm}` : theme.spacing.sm;
}, _ref3 => {
  let {
    theme
  } = _ref3;
  return theme.spacing.l;
}, media.large`
    margin-top: 0px;
  `);
export const TitleContainer = styled.div.withConfig({
  displayName: "StrategyCardstyle__TitleContainer",
  componentId: "sc-1ro6wf0-1"
})(["text-align:center;padding:", ";padding-bottom:", ";display:flex;flex-direction:column;gap:", ";"], _ref4 => {
  let {
    theme
  } = _ref4;
  return theme.spacing.ml;
}, _ref5 => {
  let {
    theme
  } = _ref5;
  return theme.spacing.xs;
}, _ref6 => {
  let {
    theme
  } = _ref6;
  return theme.spacing.xs;
});
export const ContentWrapper = styled.div.withConfig({
  displayName: "StrategyCardstyle__ContentWrapper",
  componentId: "sc-1ro6wf0-2"
})(["display:flex;flex-direction:column;gap:", ";"], _ref7 => {
  let {
    theme
  } = _ref7;
  return theme.spacing.m;
});
export const ChartContainer = styled.div.withConfig({
  displayName: "StrategyCardstyle__ChartContainer",
  componentId: "sc-1ro6wf0-3"
})(["max-height:230px;.highcharts-container{width:100% !important;}.highcharts-root{width:100% !important;}.highcharts-legend-item{cursor:pointer;}"]);
export const IndicatorContainer = styled.div.withConfig({
  displayName: "StrategyCardstyle__IndicatorContainer",
  componentId: "sc-1ro6wf0-4"
})(["padding:0 ", ";margin-top:", ";display:flex;flex-direction:row;justify-content:space-around;"], _ref8 => {
  let {
    theme
  } = _ref8;
  return theme.spacing.ml;
}, _ref9 => {
  let {
    theme
  } = _ref9;
  return theme.spacing.s;
});
export const Indicator = styled.div.withConfig({
  displayName: "StrategyCardstyle__Indicator",
  componentId: "sc-1ro6wf0-5"
})(["display:flex;flex-direction:row;align-items:center;gap:", ";background:", ";border-radius:", ";padding:", ";"], _ref10 => {
  let {
    theme
  } = _ref10;
  return theme.spacing.s;
}, _ref11 => {
  let {
    theme,
    inverse
  } = _ref11;
  return inverse ? theme.colors.border_extra_strong : theme.colors.background_page;
}, _ref12 => {
  let {
    theme
  } = _ref12;
  return theme.radius.lg;
}, _ref13 => {
  let {
    theme
  } = _ref13;
  return `${theme.spacing.sm} ${theme.spacing.m}`;
});
export const IndicatorContent = styled.div.withConfig({
  displayName: "StrategyCardstyle__IndicatorContent",
  componentId: "sc-1ro6wf0-6"
})(["display:flex;flex-direction:column;gap:", ";"], _ref14 => {
  let {
    theme
  } = _ref14;
  return theme.spacing.xxs;
});
export const ButtonContainer = styled.div.withConfig({
  displayName: "StrategyCardstyle__ButtonContainer",
  componentId: "sc-1ro6wf0-7"
})(["text-align:center;padding:", ";display:flex;flex-direction:column;"], _ref15 => {
  let {
    theme
  } = _ref15;
  return `0 ${theme.spacing.ml}`;
});
export const FeaturedBadge = styled.div.withConfig({
  displayName: "StrategyCardstyle__FeaturedBadge",
  componentId: "sc-1ro6wf0-8"
})(["position:absolute;top:-40px;height:40px;width:100%;padding:", " 0;border-radius:", ";background:linear-gradient(283deg,#aef99c 3.93%,#b3f6e6 90.54%);text-align:center;"], _ref16 => {
  let {
    theme
  } = _ref16;
  return theme.spacing.s;
}, _ref17 => {
  let {
    theme
  } = _ref17;
  return `${theme.spacing.xs} ${theme.spacing.xs} 0 0`;
});
export const FeaturedIcon = styled.img.withConfig({
  displayName: "StrategyCardstyle__FeaturedIcon",
  componentId: "sc-1ro6wf0-9"
})(["margin-right:", ";"], _ref18 => {
  let {
    theme
  } = _ref18;
  return theme.spacing.s;
});
export const LabelsContainer = styled.div.withConfig({
  displayName: "StrategyCardstyle__LabelsContainer",
  componentId: "sc-1ro6wf0-10"
})(["display:flex;flex-direction:column;margin-top:", ";gap:", ";"], _ref19 => {
  let {
    theme
  } = _ref19;
  return theme.spacing.sm;
}, _ref20 => {
  let {
    theme
  } = _ref20;
  return theme.spacing.xxs;
});
export const SAnchorLink = styled(AnchorLink).withConfig({
  displayName: "StrategyCardstyle__SAnchorLink",
  componentId: "sc-1ro6wf0-11"
})(["position:absolute;cursor:pointer;line-height:10px;margin-left:3px;font-size:9px !important;background:unset !important;"]);
export const SCallbackLink = styled(CallbackLink).withConfig({
  displayName: "StrategyCardstyle__SCallbackLink",
  componentId: "sc-1ro6wf0-12"
})(["background-image:", ";"], _ref21 => {
  let {
    theme,
    inverse
  } = _ref21;
  return inverse ? `linear-gradient(to right, ${theme.colors.text_label_inverse} 45%, transparent 45%, transparent 55%, ${theme.colors.text_label_inverse} 55%)` : `linear-gradient(to right, ${theme.colors.text_label} 45%, transparent 45%, transparent 55%, ${theme.colors.text_label} 55%)`;
});
export const PerformanceWrapper = styled.div.withConfig({
  displayName: "StrategyCardstyle__PerformanceWrapper",
  componentId: "sc-1ro6wf0-13"
})(["margin:auto;padding-bottom:", ";"], _ref22 => {
  let {
    theme
  } = _ref22;
  return theme.spacing.ml;
});