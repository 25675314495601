import React from "react";
var __jsx = React.createElement;
import { ManagedPortfolioRisks } from '@yieldstreet/platform-kit';
export const RiskIcon = _ref => {
  let {
    inverse,
    risk
  } = _ref;
  return __jsx("svg", {
    width: "48",
    height: "49",
    viewBox: "0 0 48 49",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg"
  }, __jsx("rect", {
    y: "0.123047",
    width: "48",
    height: "48",
    rx: "6",
    fill: inverse ? 'black' : 'white',
    "fill-opacity": inverse ? '0.25' : '0.8'
  }), __jsx("rect", {
    x: "10.25",
    y: "26.0059",
    width: "4.5",
    height: "10.8671",
    fill: inverse ? 'white' : 'black',
    "fill-opacity": "0.15"
  }), __jsx("rect", {
    x: "17.75",
    y: "20.373",
    width: "4.5",
    height: "16.5",
    fill: inverse ? 'white' : 'black',
    "fill-opacity": risk === ManagedPortfolioRisks.LOW ? '1' : '0.15'
  }), __jsx("rect", {
    x: "25.25",
    y: "15.873",
    width: "5",
    height: "21",
    fill: inverse ? 'white' : 'black',
    "fill-opacity": risk === ManagedPortfolioRisks.MEDIUM ? '1' : '0.15'
  }), __jsx("rect", {
    x: "33.25",
    y: "12.873",
    width: "4.5",
    height: "24",
    fill: inverse ? 'white' : 'black',
    "fill-opacity": risk === ManagedPortfolioRisks.HIGH ? '1' : '0.15'
  }));
};