import _Box from "@mui/material/Box";
import _Alert from "@mui/material/Alert";
import _Link from "@mui/material/Link";
import _Typography from "@mui/material/Typography";
import _objectWithoutProperties from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/objectWithoutProperties.js";
import _objectSpread from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
const _excluded = ["reInvest", "primaryPaymentDetails", "investments", "rolloverInvestmentIds", "advisoryRelationshipAcknowledged"];
var __jsx = React.createElement;
import React, { useMemo, useEffect, useState, useCallback } from 'react';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import { offeringApi, PaymentMethodType, investmentRequestOfferingsApi, investmentRequestApi, InvestorAccountProvider, AudiencePresetTypes } from '@yieldstreet/platform-kit';
import { getQueryString, useAudiences, useGetCheckboxes, COMPLETE_INVEST_CHECKBOX_KEYS, useOfferingPromotion, withFlags, useIsUpfrontCapitalCallOffering, getCapitalCallsCalculation } from '@yieldstreet/tool-kit';
import { PageContainer, PageLoader } from '@yieldstreet/ui-kit/layouts';
import isEqual from "lodash/isEqual";
import { Formik, Form } from 'formik';
import { parse } from 'query-string';
import * as Yup from 'yup';
import { PRE_INVEST_ACCOUNT_SETUP_NEEDED_PATH } from 'pages/preInvestAccountSetup';
import { makePreInvestmentState } from 'utils/investmentEligibility';
import AcknowledgeContainer from './Acknowledge/AcknowledgeContainer';
import CompleteInvestment from './CompleteInvestment';
import { COMPLETE_INVESTMENT_VALIDATION_SCHEMA, SUBMIT_COMMITMENT_VALIDATION_SCHEMA, COMPLETE_INVESTMENT_INITIAL_VALUES, COMPLETE_INVESTMENT_STATUS_CODE, COMPLETE_INVESTMENT_STATUS_META } from './CompleteInvestment.model';
import { ButtonContainer, SubmitButton, CancelButton } from './CompleteInvestment.style';
import { SIGNATURE_INITIAL_VALUES, SIGNATURE_VALIDATION_SCHEMA } from './Signature/Signature.model';
import ErrorHandler from './ErrorHandler';
import Signature from './Signature';
import { onCompleteInvestments } from './utils/investmentThirdPartyCode';
import DocusignModal from 'sharedComponents/DocusignModal';
import ENV_CONFIG from 'utils/env/config';
import { useIsMobileApp } from 'utils/mobileApp';
const CompleteInvestmentPage = () => {
  var _offeringDetails$note, _offeringDetails$note2, _offeringDetails$note3, _offeringDetails$note4, _audiencesData$audien5, _audiencesData$audien6;
  const history = useHistory();
  const {
    noteUrlHash,
    amount: unparsedAmount
  } = useParams();
  const {
    search,
    state = {}
  } = useLocation();
  const {
    0: presubmitValues,
    1: setPresubmitValues
  } = useState(null);
  const {
    0: showDocusignModalDetails,
    1: setShowDocusignModalDetails
  } = useState(null);
  const {
    0: submitInvestmentError,
    1: setSubmitInvestmentError
  } = useState(null);
  const {
    0: validation,
    1: setValidation
  } = useState(null);
  const {
    0: accountEligibility,
    1: setAccountEligibility
  } = useState({});
  const {
    audiencesData
  } = useAudiences(AudiencePresetTypes.ANALYTICS);
  const {
    0: isCompleteInvestmentDisabled,
    1: setIsCompleteInvestmentDisabled
  } = useState(null);
  const {
    0: completeInvestmentDisabledReason,
    1: setCompleteInvestmentDisabledReason
  } = useState(null);
  const isMobile = useIsMobileApp();
  const {
    0: isCompleteInvestmentStatus,
    1: setIsCompleteInvestmentStatus
  } = useState(null);
  const rollover = useMemo(() => {
    var _state$rollover, _state$rollover2;
    if (!(state !== null && state !== void 0 && (_state$rollover = state.rollover) !== null && _state$rollover !== void 0 && _state$rollover.urlHash) || !(state !== null && state !== void 0 && (_state$rollover2 = state.rollover) !== null && _state$rollover2 !== void 0 && _state$rollover2.investorId)) {
      return null;
    }
    return state === null || state === void 0 ? void 0 : state.rollover;
  }, [state]);
  const isRolloverV2 = !!rollover;
  const {
    investments,
    noteUrlHashes
  } = useMemo(() => ({
    investments: [{
      urlHash: noteUrlHash,
      amount: parseFloat(unparsedAmount)
    }],
    noteUrlHashes: [noteUrlHash]
  }), [noteUrlHash, unparsedAmount]);

  /**
   * Get Offering Promotion Eligibility
   */

  const promoKey = useMemo(() => {
    const {
      promo
    } = parse(search);
    return promo;
  }, [search]);
  const investmentUrlHashes = useMemo(() => {
    return investments.map(investments => investments.urlHash);
  }, [investments]);
  const promoState = useOfferingPromotion(investmentUrlHashes, promoKey);
  const {
    promoCTA
  } = promoState;

  /**
   * Check if user is gated and redirect to gate handling page
   */
  const isError = (isCompleteInvestmentStatus === null || isCompleteInvestmentStatus === void 0 ? void 0 : isCompleteInvestmentStatus.code) === COMPLETE_INVESTMENT_STATUS_CODE.ERROR;
  if (isError && (isCompleteInvestmentStatus === null || isCompleteInvestmentStatus === void 0 ? void 0 : isCompleteInvestmentStatus.meta) === COMPLETE_INVESTMENT_STATUS_META.GATED) {
    history.push(`${PRE_INVEST_ACCOUNT_SETUP_NEEDED_PATH}${getQueryString({
      promo: promoKey
    })}`, {
      preInvestmentState: makePreInvestmentState({
        investments
      })
    });
  }
  const {
    data: offeringDetails,
    isLoading: isLoadingOffering
  } = offeringApi.useDetailsQuery(noteUrlHashes);
  const {
    isUpfrontCapitalCall
  } = useIsUpfrontCapitalCallOffering({
    urlHash: noteUrlHash
  });
  const upfrontFundingDetails = {
    capitalDrawnPercentage: offeringDetails === null || offeringDetails === void 0 ? void 0 : (_offeringDetails$note = offeringDetails[noteUrlHash]) === null || _offeringDetails$note === void 0 ? void 0 : (_offeringDetails$note2 = _offeringDetails$note.note) === null || _offeringDetails$note2 === void 0 ? void 0 : _offeringDetails$note2.capitalDrawnPercentage,
    committedAmount: unparsedAmount
  };
  const enhancedUpfrontFundingDetails = isUpfrontCapitalCall ? _objectSpread(_objectSpread({}, getCapitalCallsCalculation(upfrontFundingDetails)), upfrontFundingDetails) : null;
  const [submitSign, {
    data: submitSignResult,
    isLoading: submitSignLoading
  }] = investmentRequestOfferingsApi.useSignMutation();
  const [signCommitment, {
    data: signCommitmentResult,
    isLoading: signCommitmentLoading
  }] = investmentRequestApi.useSignCommitmentMutation();
  const {
    externalCheckboxes,
    checkboxValidationSchema,
    generalCheckboxes
  } = useGetCheckboxes(offeringDetails, [noteUrlHash], null, false, accountEligibility, isRolloverV2);
  const isCapitalCall = offeringDetails === null || offeringDetails === void 0 ? void 0 : (_offeringDetails$note3 = offeringDetails[noteUrlHash]) === null || _offeringDetails$note3 === void 0 ? void 0 : (_offeringDetails$note4 = _offeringDetails$note3.note) === null || _offeringDetails$note4 === void 0 ? void 0 : _offeringDetails$note4.capitalCallEnabled;
  const {
    validationSchema,
    initialValues
  } = useMemo(() => {
    if (!checkboxValidationSchema) {
      return {
        validationSchema: null,
        initialValues: null
      };
    }
    const validationSchema = _objectSpread(_objectSpread(_objectSpread({}, SIGNATURE_VALIDATION_SCHEMA), isCapitalCall ? SUBMIT_COMMITMENT_VALIDATION_SCHEMA : COMPLETE_INVESTMENT_VALIDATION_SCHEMA), checkboxValidationSchema === null || checkboxValidationSchema === void 0 ? void 0 : checkboxValidationSchema.validationSchema);
    const initialValues = _objectSpread(_objectSpread(_objectSpread(_objectSpread({}, SIGNATURE_INITIAL_VALUES), COMPLETE_INVESTMENT_INITIAL_VALUES), checkboxValidationSchema === null || checkboxValidationSchema === void 0 ? void 0 : checkboxValidationSchema.initialValues), {}, {
      [COMPLETE_INVEST_CHECKBOX_KEYS.ADVISORY_RELATIONSHIP]: false
    });
    return {
      validationSchema,
      initialValues
    };
  }, [checkboxValidationSchema, isCapitalCall]);
  useEffect(() => {
    const validationKeys = validation && Object.keys(validation);
    const validationSchemaKeys = validationSchema && Object.keys(validationSchema);
    if (validationSchema && !isEqual(validationKeys, validationSchemaKeys)) {
      setValidation(validationSchema);
    }
  }, [validationSchema, setValidation, validation]);
  const isLoading = isLoadingOffering;
  const disableSubmit = isCompleteInvestmentDisabled;
  const submit = useCallback(async values => {
    var _Object$values, _values$dripDataPerOf, _audiencesData$audien, _audiencesData$audien2;
    const {
        reInvest,
        primaryPaymentDetails,
        investments,
        rolloverInvestmentIds,
        advisoryRelationshipAcknowledged
      } = values,
      formData = _objectWithoutProperties(values, _excluded);
    const disclaimerEl = document.getElementById('complete-investment-checkboxes-wrapper');
    const investment = investments ? (_Object$values = Object.values(investments)) === null || _Object$values === void 0 ? void 0 : _Object$values[0] : {};
    const dripOfferingConfig = (_values$dripDataPerOf = values.dripDataPerOffering) === null || _values$dripDataPerOf === void 0 ? void 0 : _values$dripDataPerOf[investment === null || investment === void 0 ? void 0 : investment.noteUrlHash];
    const customVariables = _objectSpread(_objectSpread({}, dripOfferingConfig && !(dripOfferingConfig !== null && dripOfferingConfig !== void 0 && dripOfferingConfig.configPresent) && {
      reInvest
    }), {}, {
      advisoryRelationshipAcknowledged
    });
    const isWirePayment = values.paymentMethod === PaymentMethodType.WIRE;
    const isRollover = values.acceptRollover && (rollover || values.enableRollover) && !isWirePayment;
    const investmentRequestDetails = _objectSpread(_objectSpread(_objectSpread({}, formData), {}, {
      processOn: values.processOn ? 2 : 0,
      // Checkbox do delay the process by 2 days, or 0 days
      paymentMethod: primaryPaymentDetails === null || primaryPaymentDetails === void 0 ? void 0 : primaryPaymentDetails.paymentMethod,
      bankAccountId: values === null || values === void 0 ? void 0 : values.bankAccountId,
      disclaimer: disclaimerEl ? disclaimerEl.innerText : '',
      customVariables
    }, isRollover && {
      rolloverInvestmentIds
    }), {}, {
      noteUrlHash: investment === null || investment === void 0 ? void 0 : investment.noteUrlHash,
      investmentId: investment === null || investment === void 0 ? void 0 : investment.id
    });
    await submitSign(investmentRequestDetails);
    onCompleteInvestments({
      investments,
      context: {
        firstTimeInvestor: audiencesData === null || audiencesData === void 0 ? void 0 : (_audiencesData$audien = audiencesData.audiences) === null || _audiencesData$audien === void 0 ? void 0 : (_audiencesData$audien2 = _audiencesData$audien.FTI_YS) === null || _audiencesData$audien2 === void 0 ? void 0 : _audiencesData$audien2.active
      }
    });
    localStorage.removeItem('preInvestmentPending');
  }, [rollover, submitSign, audiencesData]);
  const submitCommitment = useCallback(async values => {
    var _Object$values2, _audiencesData$audien3, _audiencesData$audien4;
    const {
      investments,
      investorInitials,
      investorAccountId,
      primaryPaymentDetails
    } = values;
    const {
      commitmentId
    } = parse(search);
    const disclaimerEl = document.getElementById('complete-investment-checkboxes-wrapper');
    const investment = investments ? (_Object$values2 = Object.values(investments)) === null || _Object$values2 === void 0 ? void 0 : _Object$values2[0] : {};
    const payload = {
      disclaimer: disclaimerEl ? disclaimerEl.innerText : '',
      noteUrlHash: investment === null || investment === void 0 ? void 0 : investment.noteUrlHash,
      preferredPaymentMethod: primaryPaymentDetails === null || primaryPaymentDetails === void 0 ? void 0 : primaryPaymentDetails.paymentMethod,
      preferredBankAccountId: primaryPaymentDetails === null || primaryPaymentDetails === void 0 ? void 0 : primaryPaymentDetails.bankAccountId,
      commitmentId: commitmentId,
      investorInitials,
      investorAccountId
    };
    await signCommitment(payload);
    onCompleteInvestments({
      investments,
      context: {
        firstTimeInvestor: audiencesData === null || audiencesData === void 0 ? void 0 : (_audiencesData$audien3 = audiencesData.audiences) === null || _audiencesData$audien3 === void 0 ? void 0 : (_audiencesData$audien4 = _audiencesData$audien3.FTI_YS) === null || _audiencesData$audien4 === void 0 ? void 0 : _audiencesData$audien4.active
      }
    });
    localStorage.removeItem('preInvestmentPending');
  }, [search, signCommitment, audiencesData === null || audiencesData === void 0 ? void 0 : (_audiencesData$audien5 = audiencesData.audiences) === null || _audiencesData$audien5 === void 0 ? void 0 : (_audiencesData$audien6 = _audiencesData$audien5.FTI_YS) === null || _audiencesData$audien6 === void 0 ? void 0 : _audiencesData$audien6.active]);
  const handleOnCancel = useCallback(() => {
    if (!!rollover) {
      history.push(`/rollover/${rollover.urlHash}/${rollover.investorId}`);
    } else {
      history.push(`/offering/${noteUrlHash}/`);
    }
  }, [history, rollover]);
  useEffect(() => {
    if (submitSignResult !== null && submitSignResult !== void 0 && submitSignResult.errors || signCommitmentResult !== null && signCommitmentResult !== void 0 && signCommitmentResult.errors) {
      setSubmitInvestmentError(true);
      return;
    }
    if (signCommitmentResult !== null && signCommitmentResult !== void 0 && signCommitmentResult.commitment) {
      const commitment = signCommitmentResult === null || signCommitmentResult === void 0 ? void 0 : signCommitmentResult.commitment;
      history.push(`/capital-calls-confirmation/${commitment.noteUrlHash}/${commitment.commitmentId}`);
    }
    if (submitSignResult !== null && submitSignResult !== void 0 && submitSignResult.investment) {
      const signedInvestment = submitSignResult === null || submitSignResult === void 0 ? void 0 : submitSignResult.investment;
      history.push(`/confirm-investment/${signedInvestment.noteUrlHash}/${signedInvestment.investmentId}`);
    }
  }, [submitSignResult, signCommitmentResult, history]);
  const handlePreSubmit = values => {
    var _accountEligibility$p;
    if ((accountEligibility === null || accountEligibility === void 0 ? void 0 : (_accountEligibility$p = accountEligibility.provider) === null || _accountEligibility$p === void 0 ? void 0 : _accountEligibility$p.type) === InvestorAccountProvider.ETC) {
      var _accountEligibility$p2, _accountEligibility$p3, _offeringDetails$note5, _offeringDetails$note6;
      setPresubmitValues(values);
      setShowDocusignModalDetails({
        accountId: accountEligibility === null || accountEligibility === void 0 ? void 0 : (_accountEligibility$p2 = accountEligibility.provider) === null || _accountEligibility$p2 === void 0 ? void 0 : (_accountEligibility$p3 = _accountEligibility$p2.data) === null || _accountEligibility$p3 === void 0 ? void 0 : _accountEligibility$p3.etc_account_number,
        assetName: offeringDetails === null || offeringDetails === void 0 ? void 0 : (_offeringDetails$note5 = offeringDetails[noteUrlHash]) === null || _offeringDetails$note5 === void 0 ? void 0 : (_offeringDetails$note6 = _offeringDetails$note5.note) === null || _offeringDetails$note6 === void 0 ? void 0 : _offeringDetails$note6.title
      });
    } else {
      if (isCapitalCall) {
        submitCommitment(values);
      } else {
        submit(values);
      }
    }
  };
  const onHandleDocusignAgree = () => {
    setTimeout(() => submit(presubmitValues), 1000);
  };
  const handleValidationChange = value => {
    setValidation(_objectSpread(_objectSpread({}, validation), value));
  };

  // this is used on etc requests, with accountId as clientUserId
  const timestamp = useMemo(() => Date.now(), []);
  const pageTitle = isCapitalCall ? 'Submit your commitment' : 'Complete investment';
  const submitButtonCopy = isCapitalCall ? 'Submit commitment' : 'Submit investment';
  return __jsx(_Box, {
    sx: theme => {
      return {
        backgroundColor: theme.palette.background.default,
        width: '100%'
      };
    }
  }, __jsx(PageContainer, {
    testId: "complete-investment-container"
  }, isLoading ? __jsx(PageLoader, null) : __jsx(React.Fragment, null, !isError && __jsx(_Typography, {
    variant: "h2",
    sx: {
      mb: 2
    }
  }, promoCTA || pageTitle), completeInvestmentDisabledReason && __jsx(_Alert, {
    severity: "error"
  }, __jsx(_Typography, {
    variant: "body1"
  }, completeInvestmentDisabledReason, " Please", ' ', __jsx(_Link, {
    id: "restriction-notification",
    href: "mailto:investments@yieldstreet.com"
  }, "contact our Investor Relations team"), ' ', "if you think this is a mistake.")), __jsx(Formik, {
    initialValues: initialValues,
    validationSchema: Yup.object().shape(validation),
    onSubmit: values => {
      handlePreSubmit(values);
    },
    render: formikProps => {
      var _offeringDetails$note7;
      const {
        isValid,
        dirty
      } = formikProps;
      return __jsx(Form, null, __jsx(CompleteInvestment, {
        isRolloverV2: isRolloverV2,
        rollover: rollover,
        promoState: promoState,
        investments: investments,
        formikProps: formikProps,
        setAccountEligibility: setAccountEligibility,
        setIsDisabled: setIsCompleteInvestmentDisabled,
        disabledReason: completeInvestmentDisabledReason,
        setDisabledReason: setCompleteInvestmentDisabledReason,
        setStatus: setIsCompleteInvestmentStatus,
        status: isCompleteInvestmentStatus,
        updateValidation: handleValidationChange,
        upfrontFundingDetails: enhancedUpfrontFundingDetails
      }), !isError && __jsx(React.Fragment, null, __jsx(AcknowledgeContainer, {
        formikProps: formikProps,
        offeringInfo: offeringDetails === null || offeringDetails === void 0 ? void 0 : (_offeringDetails$note7 = offeringDetails[noteUrlHash]) === null || _offeringDetails$note7 === void 0 ? void 0 : _offeringDetails$note7.note,
        externalCheckboxes: externalCheckboxes === null || externalCheckboxes === void 0 ? void 0 : externalCheckboxes[noteUrlHash],
        generalCheckboxes: generalCheckboxes,
        isRolloverV2: isRolloverV2,
        rollover: rollover
      }), __jsx(Signature, {
        formikProps: formikProps
      }), __jsx(ButtonContainer, null, !isMobile && __jsx(CancelButton, {
        id: "complete-cancelBtn",
        type: "button",
        variant: "outlined",
        "data-cy": "ui-kit-button-container-complete-investment-cancel",
        "data-trackingId": "complete-investment-cancel",
        onClick: handleOnCancel
      }, "Cancel"), __jsx(SubmitButton, {
        "data-cy": "ui-kit-button-container-complete-investment-submit",
        id: "complete-submitBtn",
        "data-trackingId": "complete-investment-submit",
        type: "submit",
        disabled: disableSubmit || !isValid || !dirty,
        loading: submitSignLoading || showDocusignModalDetails || signCommitmentLoading
      }, promoCTA || submitButtonCopy)), submitInvestmentError && __jsx(ErrorHandler, {
        submitInvestmentError: submitInvestmentError
      })));
    }
  })), showDocusignModalDetails && __jsx(DocusignModal, {
    clickwrapId: ENV_CONFIG.DOCUSIGN_CLICKWRAP_ID_COMPLETE_INVEST,
    clientUserId: `${showDocusignModalDetails.accountId}-${timestamp}`,
    documentData: {
      company: showDocusignModalDetails.assetName
    },
    onAgree: onHandleDocusignAgree
  })));
};
export default withFlags(CompleteInvestmentPage);