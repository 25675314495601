import { AccreditationEligibility, marketplaceApi } from '@yieldstreet/platform-kit';
import { getOfferingTerm } from '@yieldstreet/tool-kit';
import defaultOfferingImage from 'assets/images/images/marketplaceOfferingDefault.png';
export const useLatestDeals = () => {
  const {
    data: offeringData,
    isFetching
  } = marketplaceApi.useGetOfferingsQuery({
    offeringType: 'open',
    accreditation: AccreditationEligibility.SELF
  });
  const mapOfferings = offeringData === null || offeringData === void 0 ? void 0 : offeringData.offerings.filter((_offering, index) => index < 4).map(offering => {
    const mappedOfferingTerm = getOfferingTerm(offering);
    return {
      thumbnail: (offering === null || offering === void 0 ? void 0 : offering.thumbnailImageUrl) || defaultOfferingImage,
      title: offering.title,
      assetClass: offering.assetClassDisplayName,
      minimumAmount: offering.minimumPurchase,
      term: `${mappedOfferingTerm.term} ${mappedOfferingTerm.termSuffix}`,
      noteUrlHash: offering.urlHash
    };
  });
  return {
    offeringData: mapOfferings,
    offeringDataIsLoading: isFetching
  };
};