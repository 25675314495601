var __jsx = React.createElement;
import React from 'react';
import { HashLink } from 'react-router-hash-link';
const HEADER_OFFSET = 100;
const scrollIntoView = element => {
  const elementPosition = element.offsetTop - HEADER_OFFSET;
  // eslint-disable-next-line
  window.scroll({
    top: elementPosition,
    left: 0,
    behavior: 'smooth'
  });
};
export const AnchorLink = _ref => {
  let {
    destination,
    children,
    className
  } = _ref;
  return __jsx(HashLink, {
    className: className,
    smooth: true,
    to: destination,
    scroll: el => scrollIntoView(el)
  }, children);
};