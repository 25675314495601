import _Button from "@mui/material/Button";
import _Box from "@mui/material/Box";
import _Stack from "@mui/material/Stack";
var __jsx = React.createElement;
import React, { useCallback } from 'react';
import { Formik } from 'formik';
import { SwitchTransition, CSSTransition } from 'react-transition-group';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { ButtonText, goalsQuiz, SharedComponentsTestIds } from '../../Goals.model';
import { CounterComponent } from '../../components/CounterComponent';
import { FadeInUp } from './DesktopView.style';
import { testIds } from '../Questions.model';
import { DashboardDesktopWrapper, DescriptionWrapper, FadeInRight, Left, Line, QuestionTitle, Right, SButton, SForm } from '../../Goals.style';
import { getInitialFormikProps, isNextButtonDisabled } from './DesktopView.model';
export const DesktopView = _ref => {
  let {
    getQuizAnswer,
    handleOnClick,
    handleOptions,
    handlePrevQuestion,
    nextStep,
    quiz = {},
    step
  } = _ref;
  const initialFormikProps = useCallback(() => getInitialFormikProps(getQuizAnswer), [getQuizAnswer]);
  return __jsx(DashboardDesktopWrapper, {
    "data-cy": testIds.desktopView,
    isDashboard: true
  }, __jsx(Left, {
    state: "dashboard"
  }, __jsx(FadeInUp, null, __jsx(SwitchTransition, {
    mode: "out-in"
  }, __jsx(CSSTransition, {
    key: step,
    classNames: "fade-in-up",
    addEndListener: (node, done) => {
      node.addEventListener('transitionend', done, false);
    }
  }, __jsx("div", null, __jsx(_Stack, {
    direction: "row"
  }, step !== 0 && __jsx(SButton, {
    type: "button",
    onClick: () => handlePrevQuestion(step),
    "data-cy": testIds.prevButton
  }, __jsx(ChevronLeftIcon, null)), CounterComponent(`Question ${nextStep}`)), __jsx(QuestionTitle, {
    type: 3,
    "data-cy": testIds.title,
    dashboard: true
  }, quiz.question)))))), __jsx(Line, null), __jsx(Right, null, __jsx(Formik, {
    initialValues: initialFormikProps,
    onSubmit: values => {
      handleOnClick(values, step, goalsQuiz(getQuizAnswer)[step].saveAnswer);
    }
  }, formikProps => __jsx(SForm, null, __jsx(DescriptionWrapper, null, __jsx(_Box, {
    sx: {
      mt: '15%'
    }
  }, __jsx(FadeInRight, null, __jsx(SwitchTransition, {
    mode: "out-in"
  }, __jsx(CSSTransition, {
    key: step,
    classNames: "fade-in-right",
    addEndListener: (node, done) => {
      node.addEventListener('transitionend', done, false);
    }
  }, __jsx("div", null, handleOptions(step, formikProps, quiz))))))), __jsx(_Button, {
    "data-cy": SharedComponentsTestIds.buttonComponent,
    disabled: isNextButtonDisabled(formikProps.values, step),
    type: "submit"
  }, ButtonText['next'])))));
};