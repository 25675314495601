var __jsx = React.createElement;
import React from 'react';
import { Disclaimer, DisclaimerContainer } from '../../../ManagedPortfolioSelection.style';
import { testIds } from '../../StrategyModal.model';
import { DisclaimerItem } from './StrategyModalFooter.style';
import { spacing } from '@yieldstreet/ui-kit';
export const StrategyModalFooter = () => __jsx(DisclaimerContainer, testIds.footer.attr, __jsx(Disclaimer, {
  small: true
}, "As of March 1, 2024"), __jsx(Disclaimer, {
  small: true
}, "Annualized total returns for each Managed Portfolio option (\u201CStrategy\u201D, \u201CStrategies\u201D) represent a blend of the historical total return of the underlying funds included in a particular Strategy. Returns assume dividends are reinvested."), __jsx(Disclaimer, {
  small: true
}, "Volatility is measured by the standard division in monthly total return."), __jsx(Disclaimer, {
  small: true
}, "\u201CIncep.\u201D or inception represents October 1, 2020, the first month after the last underlying fund included in Managed Portfolios was conceived."), __jsx(Disclaimer, {
  small: true
}, "Current asset allocations for each Strategy are as follows:", __jsx(DisclaimerItem, {
  paddingLeft: spacing.s
}, "Income", __jsx(DisclaimerItem, {
  paddingLeft: spacing.sm
}, "88% Carlyle Tactical Credit Fund (Credit)"), __jsx(DisclaimerItem, {
  paddingLeft: spacing.sm
}, "10% Goldman Sachs Real Estate Diversified Income Fund (Equity)"), __jsx(DisclaimerItem, {
  paddingLeft: spacing.sm
}, "2% Reserve")), __jsx(DisclaimerItem, {
  paddingLeft: spacing.s
}, "Balanced", __jsx(DisclaimerItem, {
  paddingLeft: spacing.sm
}, "43% Carlyle Tactical Credit Fund (Credit)"), __jsx(DisclaimerItem, {
  paddingLeft: spacing.sm
}, "45% StepStone Private Markets (Equity)"), __jsx(DisclaimerItem, {
  paddingLeft: spacing.sm
}, "10% Goldman Sachs Real Estate Diversified Income Fund (Equity)"), __jsx(DisclaimerItem, {
  paddingLeft: spacing.sm
}, "2% Reserve")), __jsx(DisclaimerItem, {
  paddingLeft: spacing.s
}, "Growth", __jsx(DisclaimerItem, {
  paddingLeft: spacing.sm
}, "34% Carlyle Tactical Credit Fund (Credit)"), __jsx(DisclaimerItem, {
  paddingLeft: spacing.sm
}, "54% StepStone Private Markets (Equity)"), __jsx(DisclaimerItem, {
  paddingLeft: spacing.sm
}, "10% Goldman Sachs Real Estate Diversified Income Fund (Equity)"), __jsx(DisclaimerItem, {
  paddingLeft: spacing.sm
}, "2% Reserve")), __jsx(DisclaimerItem, {
  paddingLeft: spacing.s
}, "Traditional Portfolio", __jsx(DisclaimerItem, {
  paddingLeft: spacing.sm
}, "60% stocks, represented by the S&P 500 index"), __jsx(DisclaimerItem, {
  paddingLeft: spacing.sm
}, "40% bonds, represented by the Bloomberg U.S. Aggregate Bond Index"))), __jsx(Disclaimer, {
  small: true
}, "Asset allocations are subject to change over time."), __jsx(Disclaimer, {
  small: true
}, "These figures are based on unaudited reporting received by Yieldstreet from the Funds as of the first of each month. Yieldstreet relies on this third-party reporting and may have limited insight and ability to independently verify the veracity of the figures, valuation process, and general reporting standards employed by the Funds mentioned. An independent accountant has not audited, reviewed, or compiled these performance results."), __jsx(Disclaimer, {
  small: true
}, "Factors affecting the performance of an actual Managed Portfolio that do not affect individual funds may include portfolio rebalancing, distributions, and the timing of cash flows. Further, these performance figures are hypothetical as no investor has exposure to this investment portfolio. There is no assurance that any future investment will be sold at the same value used to calculate the above figures."), __jsx(Disclaimer, {
  small: true
}, "Intended for informational purposes only. Past performance is no guarantee of future returns."));