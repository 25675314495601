import _Box from "@mui/material/Box";
import _Typography from "@mui/material/Typography";
import _extends from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/extends.js";
import _objectWithoutProperties from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/objectWithoutProperties.js";
const _excluded = ["title", "textList"];
var __jsx = React.createElement;
import React, { useMemo } from 'react';
import { useTestIds } from './NotificationMessage.model';
const NotificationMessage = _ref => {
  let {
      title,
      textList
    } = _ref,
    props = _objectWithoutProperties(_ref, _excluded);
  const testIds = useTestIds(props);
  const renderTextList = useMemo(() => (textList === null || textList === void 0 ? void 0 : textList.map((text, idx) => __jsx(_Typography, _extends({
    variant: "body1"
  }, testIds.text.attr, {
    key: idx
  }), text))) || null, [textList, testIds]);
  return __jsx(_Box, _extends({
    sx: {
      m: 10
    }
  }, testIds.base.attr), __jsx(_Typography, _extends({
    variant: "h2"
  }, testIds.title.attr), title), __jsx(_Box, {
    sx: {
      mt: 8
    }
  }, renderTextList));
};
NotificationMessage.defaultProps = {
  title: null,
  textList: [],
  hasHtml: false
};
export default NotificationMessage;