import { createYupObjectSchema } from '@yieldstreet/tool-kit';
export const EDIT_ADDRESS_MODAL = 'edit-address-modal';
export const EditAddressSchema = createYupObjectSchema({
  addressLine1: {
    validationType: 'address'
  },
  addressLine2: {
    validationType: 'address',
    isRequired: false
  },
  countryCode: {
    validationType: 'country'
  },
  city: {
    validationType: 'address'
  },
  state: {
    validationType: 'state'
  },
  postalCode: {
    validationType: 'zip-code'
  }
});
export const emptyAddress = {
  addressLine1: '',
  addressLine2: '',
  city: '',
  state: '',
  postalCode: '',
  countryCode: ''
};
export const EditAddressStep = {
  ADDRESS_FORM: 'edit-address-form',
  ADDRESS_INVESTOR_SYNC: 'edit-address-investor-sync'
};
export const defaultError = 'Something went wrong. Please try again.';
export const testIds = {
  back: 'edit-address-investor-sync-back',
  done: 'edit-address-investor-sync-done',
  form: EditAddressStep.ADDRESS_FORM,
  formCancel: `${EditAddressStep.ADDRESS_FORM}-cancel`,
  formUpdate: `${EditAddressStep.ADDRESS_FORM}-update`,
  checkbox: `${EditAddressStep.ADDRESS_INVESTOR_SYNC}-checkbox`,
  sync: EditAddressStep.ADDRESS_INVESTOR_SYNC,
  syncBack: `${EditAddressStep.ADDRESS_INVESTOR_SYNC}-back`,
  syncDone: `${EditAddressStep.ADDRESS_INVESTOR_SYNC}-done`,
  syncMessage: `${EditAddressStep.ADDRESS_INVESTOR_SYNC}-message`,
  syncInvestor: `${EditAddressStep.ADDRESS_INVESTOR_SYNC}-investor`,
  syncInvestorName: `${EditAddressStep.ADDRESS_INVESTOR_SYNC}-investor-name`,
  syncInvestorAddress: `${EditAddressStep.ADDRESS_INVESTOR_SYNC}-investor-address`,
  syncInvestorButton: `${EditAddressStep.ADDRESS_INVESTOR_SYNC}-investor-button`,
  syncInvestorStatus: `${EditAddressStep.ADDRESS_INVESTOR_SYNC}-investor-status`,
  syncInvestorError: `${EditAddressStep.ADDRESS_INVESTOR_SYNC}-investor-error`,
  submitButton: `${EditAddressStep.ADDRESS_INVESTOR_SYNC}-submitButton`
};