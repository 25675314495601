var __jsx = React.createElement;
import React from 'react';
import { useParams, useHistory } from 'react-router';
import { PageStep, PageStepContentTemplate, PageContainer } from '@yieldstreet/ui-kit';
import { PRE_INVESTMENT_STEPS } from 'pages/newCreateInvestorAccount/CreateInvestorAccount.model';
import { INVESTOR_ENTITY_ID_PARAM, MANAGED_PORTFOLIO_DEPOSIT_PATH } from 'pages/managedPortfolio/ManagedPortfolio.model';
export const ActivatePortfolio = () => {
  const history = useHistory();
  const {
    investorEntityId
  } = useParams();
  return __jsx(PageContainer, null, __jsx(PageStep, null, __jsx(PageStepContentTemplate, {
    title: "You're just one step away",
    description: "Select a source of funding to activate your portfolio.",
    step: {
      current: 4,
      items: PRE_INVESTMENT_STEPS
    },
    cta: {
      type: 'primary',
      text: 'Continue',
      callback: () => history.push(MANAGED_PORTFOLIO_DEPOSIT_PATH.replace(INVESTOR_ENTITY_ID_PARAM, investorEntityId))
    }
  })));
};