import { genTestIds } from '@yieldstreet/tool-kit';
import tractionNumbers from '../../../../../static-files/tractionNumbers.json';
import irrIcon from '../LatestDeals/assets/irr.svg';
import { statisticsUrl } from '../LatestDeals/LatestDeals.model';
export const newProductsConstants = {
  heading: 'Our most successful products for new investors',
  subtitle: 'Access unique offerings across a diverse set of product types and asset classes.'
};
export const newProductsItems = {
  numberOfMembers: 'Number of members',
  numberOfMembersValue: tractionNumbers.numOfMembers,
  irr: 'Internal rate of return',
  irrValue: tractionNumbers.netIRR
};
export const items = [{
  text: newProductsItems.numberOfMembers,
  value: newProductsItems.numberOfMembersValue,
  icon: irrIcon,
  url: statisticsUrl
}, {
  text: newProductsItems.irr,
  value: newProductsItems.irrValue,
  hasAnchor: true,
  icon: irrIcon,
  url: statisticsUrl
}];
const {
  getTestIds
} = genTestIds(['heading', 'items-carousel', 'offerings-card', 'offerings-carousel', 'subtitle']);
export const testIds = getTestIds('new-investors-products');