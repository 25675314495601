import _Stack from "@mui/material/Stack";
import _Typography from "@mui/material/Typography";
import _useMediaQuery from "@mui/material/useMediaQuery";
var __jsx = React.createElement;
import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { testIds } from './LatestDealsList.model';
import { LatestDealsListDesktop } from './components/LatestDealsListDesktop/LatestDealsListDesktop';
import { LatestDealsListMobile } from './components/LatestDealsListMobile/LatestDealsListMobile';
export const LatestDealsList = () => {
  const history = useHistory();
  const theme = useTheme();
  const isDeviceMedium = _useMediaQuery(theme.breakpoints.up('md'));
  const onViewAllClick = useCallback(() => {
    history.push('/offering');
  }, [history]);
  return __jsx(_Stack, testIds.loadingView.attr, __jsx(_Typography, {
    variant: "body1",
    sx: {
      fontWeight: 'medium',
      mb: 11
    },
    color: "text.paragraphPrimary.default"
  }, "Latest Deals"), isDeviceMedium ? __jsx(LatestDealsListDesktop, null) : __jsx(LatestDealsListMobile, null), __jsx(_Stack, {
    direction: "row",
    sx: {
      justifyContent: 'center',
      cursor: 'pointer',
      mt: 7
    },
    onClick: onViewAllClick
  }, __jsx(_Typography, {
    variant: "body1",
    sx: {
      fontWeight: 'medium'
    },
    color: "text.paragraphPrimary.default"
  }, "View all opportunities"), __jsx(ChevronRightIcon, null)));
};