import _Button from "@mui/material/Button";
import _Alert from "@mui/material/Alert";
import _Box from "@mui/material/Box";
import _Chip from "@mui/material/Chip";
import _FormHelperText from "@mui/material/FormHelperText";
import _Typography from "@mui/material/Typography";
import _objectSpread from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
var __jsx = React.createElement;
import React, { useCallback, useMemo } from 'react';
import { InvestorEntityProvider, investorEntityApi } from '@yieldstreet/platform-kit';
import { Form, Formik } from 'formik';
import { LoadingButton } from '@mui/lab';
import { Stack } from '@mui/system';
import { PageLoader } from '@yieldstreet/ui-kit/layouts';
import { FormikCheckboxInput } from '@yieldstreet/ui-kit/inputs';
import { BusinessEvent, eventManager } from '@yieldstreet/tool-kit';
import { InvestorAccountItem } from './EditAddressInvestorSync.style';
import { fullAddressPreview } from './EditAddressInvestorSync.utils';
import { testIds } from './EditAddressModal.model';
export const EditAddressInvestorSync = _ref => {
  let {
    isFetchingAddress,
    userAddress = {},
    onBack,
    onDone
  } = _ref;
  const {
    useGetInvestorEntityQuery,
    useUpdateInvestorEntityMutation
  } = investorEntityApi;
  const {
    data: investorEntities,
    isFetching: fetchingInvestorEntities
  } = useGetInvestorEntityQuery();
  const [updateInvestorEntity, {
    isLoading: updatingInvestorEntity,
    error,
    isError
  }] = useUpdateInvestorEntityMutation();
  const isAddressUpdatable = useCallback((addressPreview, provider) => {
    if (!userAddress || !addressPreview) {
      return false;
    }
    const userAddressPreview = fullAddressPreview(userAddress);
    return addressPreview !== userAddressPreview && provider.type !== InvestorEntityProvider.ETC;
  }, [userAddress]);
  const investorAddresses = useMemo(() => {
    return investorEntities ? investorEntities.entities.map(_ref2 => {
      let {
        id,
        name,
        address,
        provider
      } = _ref2;
      const addressPreview = fullAddressPreview(_objectSpread({}, address));
      return {
        id,
        name,
        addressPreview,
        addressSync: isAddressUpdatable(addressPreview, provider),
        provider
      };
    }) : null;
  }, [investorEntities, isAddressUpdatable]);
  const hasSyncInvestorAddresses = useMemo(() => investorAddresses === null || investorAddresses === void 0 ? void 0 : investorAddresses.filter(_ref3 => {
    let {
      addressSync
    } = _ref3;
    return addressSync;
  }).length, [investorAddresses]);
  const handleSubmit = useCallback(async selectedInvestorAccounts => {
    onDone();
    try {
      // This should be replace once we have a proper BE that can handle multiple updates
      const updatePromises = Object.entries(selectedInvestorAccounts).filter(_ref4 => {
        let [, selected] = _ref4;
        return selected;
      }).map(_ref5 => {
        let [investorEntityId] = _ref5;
        return updateInvestorEntity({
          address: _objectSpread({}, userAddress),
          investorEntityId
        }).unwrap();
      });
      await Promise.all(updatePromises);
      onDone({
        message: 'Address updated successfully',
        severity: 'success'
      });
      eventManager.track(BusinessEvent.USER_ADDRESS_INVESTOR_ACCOUNT_UPDATE);
    } catch (error) {
      eventManager.trackFailure(BusinessEvent.USER_ADDRESS_INVESTOR_ACCOUNT_UPDATE);
    }
  }, [updateInvestorEntity, userAddress, onDone]);
  const investorAddressList = useCallback(formikProps => investorAddresses ? __jsx(_Box, {
    sx: {
      width: 1
    }
  }, investorAddresses.map(_ref6 => {
    let {
      id,
      name,
      addressPreview,
      addressSync,
      provider
    } = _ref6;
    return __jsx(InvestorAccountItem, {
      key: id,
      "data-cy": testIds.syncInvestor
    }, __jsx(_Box, null, __jsx(FormikCheckboxInput, {
      formikProps: formikProps,
      disabled: !addressSync,
      name: id,
      label: __jsx(React.Fragment, null, __jsx(_Typography, {
        "data-cy": testIds.syncInvestorName
      }, name), __jsx(_FormHelperText, {
        "data-cy": testIds.syncInvestorAddress,
        disabled: !addressSync
      }, addressPreview), (provider === null || provider === void 0 ? void 0 : provider.type) === InvestorEntityProvider.ETC && __jsx(_Box, null, __jsx(_Chip, {
        label: "Defined by the custodian",
        size: "small",
        sx: {
          fontSize: '10px',
          mt: 2
        }
      }))),
      "data-cy": `${testIds.checkbox}-${id}`
    })));
  })) : null, [investorAddresses]);
  return __jsx(React.Fragment, null, __jsx(_Box, {
    "data-cy": testIds.sync,
    sx: {
      mt: {
        xs: 10,
        sm: 0
      },
      minHeight: '340px'
    }
  }, isFetchingAddress ? __jsx(PageLoader, {
    center: true
  }) : __jsx(Formik, {
    onSubmit: handleSubmit,
    render: formikProps => {
      const isAccountSelected = Object.values(formikProps.values).includes(true);
      return __jsx(Form, null, __jsx(_Typography, {
        variant: "h2"
      }, "Apply new address to your investor accounts"), __jsx(_Typography, {
        variant: "body1",
        "data-cy": testIds.syncMessage,
        sx: {
          mt: 2,
          mb: 5
        }
      }, hasSyncInvestorAddresses ? 'Select the investor accounts that you would like to use your new address.' : 'All of your investor account addresses match your updated address.'), investorAddressList(formikProps), isError && __jsx(_Alert, {
        severity: "error",
        sx: {
          my: 2
        }
      }, (error === null || error === void 0 ? void 0 : error.message) || 'Error updating investor accounts'), __jsx(Stack, {
        spacing: 2,
        direction: {
          xs: 'column-reverse',
          sm: 'row'
        }
      }, __jsx(_Button, {
        variant: "outlined",
        "data-cy": testIds.syncBack,
        onClick: onBack,
        "data-trackingid": testIds.syncBack,
        sx: {
          width: ['100%', 'auto']
        },
        id: testIds.syncBack
      }, "Back"), __jsx(LoadingButton, {
        "data-cy": testIds.submitButton,
        loading: updatingInvestorEntity,
        "data-trackingid": testIds.submitButton,
        disabled: fetchingInvestorEntities || !isAccountSelected,
        type: "submit",
        sx: {
          width: ['100%', 'auto']
        },
        id: testIds.submitButton
      }, "Apply updates")));
    }
  })));
};