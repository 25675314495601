import _Button from "@mui/material/Button";
import _Divider from "@mui/material/Divider";
import _Typography from "@mui/material/Typography";
var __jsx = React.createElement;
import React, { useCallback, useMemo, useState } from 'react';
import { Formik } from 'formik';
import { PageFlow } from '@yieldstreet/ui-kit';
import { FormikSelectInput, FormikTextInput } from '@yieldstreet/ui-kit/inputs';
import { TEST_ID_ATTR } from '@yieldstreet/tool-kit';
import { Content, FieldsContainer, FormContainer } from 'pages/login/Login.style';
import { ReCaptchaDisclaimer } from 'sharedComponents/ReCaptcha';
import FormikEffect from 'utils/FormikEffect';
import { mockSAMLResponse, mockUserOptions } from './SAMLFakeCadre.mock';
import { initUser, inputs } from './SAMLFakeCadre.model';
const SAMLFakeCadre = () => {
  const {
    0: SAMLData,
    1: setSAMLData
  } = useState(initUser);
  const SAMLResponse = useMemo(() => mockSAMLResponse(SAMLData), [SAMLData]);
  const onFormChange = useCallback(_ref => {
    let {
      values
    } = _ref;
    return setSAMLData(values);
  }, [setSAMLData]);
  return __jsx(PageFlow, {
    showLogo: true
  }, __jsx(Content, null, __jsx(_Typography, {
    component: "h1",
    variant: "h4",
    sx: {
      mb: 4
    }
  }, "Cadre SAML Callback Test"), __jsx(FormContainer, null, __jsx(Formik, {
    initialValues: initUser,
    onSubmit: onFormChange
  }, formikProps => {
    return __jsx(FieldsContainer, null, __jsx(FormikEffect, {
      formik: formikProps,
      onChange: onFormChange
    }), __jsx(FormikSelectInput, {
      formikProps: formikProps,
      label: "Users",
      name: "user",
      onChange: e => {
        const selected = mockUserOptions.find(option => option.value === e.target.value);
        formikProps.setValues(selected || {});
      },
      options: mockUserOptions
    }), __jsx(_Divider, {
      component: "div",
      role: "separator",
      sx: {
        mb: 4
      }
    }, __jsx(_Typography, {
      sx: {
        color: 'text.secondary',
        fontSize: 13
      }
    }, "or")), inputs.map(input => __jsx(FormikTextInput, {
      "data-cy": "",
      formikProps: formikProps,
      fullWidth: true,
      label: input.label,
      name: input.id,
      type: input.type ? input.type : 'text',
      slotProps: {
        htmlInput: {
          [TEST_ID_ATTR]: input.id
        }
      }
    })));
  }), __jsx("form", {
    method: "POST",
    action: "/api/auth/saml/callback?client_name=cadre"
  }, __jsx("input", {
    type: "hidden",
    name: "SAMLResponse",
    value: SAMLResponse
  }), __jsx(_Button, {
    "data-cy": "fake-saml-button",
    fullWidth: true,
    sx: {
      mt: 2
    },
    type: "submit"
  }, "Fake SAML Callback"))), __jsx(ReCaptchaDisclaimer, null)));
};
export default SAMLFakeCadre;