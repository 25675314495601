import _Button from "@mui/material/Button";
import _Typography from "@mui/material/Typography";
var __jsx = React.createElement;
import React from 'react';
import { PageGrid, useModal } from '@yieldstreet/ui-kit';
import { Modals } from 'constants/modal.model';
import SimulateEarningsImage from './assets/SimulateEarnings.svg';
import { ButtonContainer, InnerStack, OuterStack, PromoContainer, TextContainer } from './DashboardPromoCards.style';
export const DashboardPromoCards = _ref => {
  let {
    userAudiences: {
      allNAI
    }
  } = _ref;
  const portfolioSimulatorModal = useModal(Modals.PortfolioSimulator);
  const handleOnClick = () => {
    portfolioSimulatorModal.open();
  };
  if (allNAI) {
    return null;
  }
  return __jsx(PageGrid, {
    "data-cy": "dashboard-promo-cards"
  }, __jsx(PromoContainer, {
    "data-cy": "dashboard-simulate-earnings-card",
    elevation: 0
  }, __jsx(OuterStack, null, __jsx("img", {
    src: SimulateEarningsImage
  }), __jsx(InnerStack, null, __jsx(TextContainer, null, __jsx(_Typography, {
    variant: "h5"
  }, "Simulate a portfolio of alternatives"), __jsx(_Typography, {
    variant: "body2",
    sx: {
      mt: 2
    }
  }, "With our simulator you can demo how a diversified set of alternatives can provide stable income as well as long term growth for your portfolio.")), __jsx(ButtonContainer, null, __jsx(_Button, {
    onClick: handleOnClick,
    fullWidth: true
  }, "Simulate earnings"))))));
};