import { SignupAccreditationMethods } from '@yieldstreet/platform-kit';
import { genTestIds } from '@yieldstreet/tool-kit';
const {
  getTestIds
} = genTestIds(['description', 'error', 'questions', 'submit', 'title']);
export const testIds = getTestIds('accreditation-signup');
export const accreditationOptions = [{
  value: SignupAccreditationMethods.INDIVIDUAL_JOINT,
  label: 'I earn $200K+ yearly, or $300K+ with my spousal equivalent'
}, {
  value: SignupAccreditationMethods.NETWORTH,
  label: 'I have $1M+ in assets, excluding my primary residence'
}, {
  value: SignupAccreditationMethods.ENTITY,
  label: 'I own an entity (ie. family office) with $5M+ in assets'
}, {
  value: SignupAccreditationMethods.LICENSED_BROKER,
  label: 'I hold a Series 7, 65 or 82 license currently in good standing'
}, {
  value: SignupAccreditationMethods.NONE,
  label: 'None of the above'
}];