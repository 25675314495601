var __jsx = React.createElement;
import React from 'react';
import styled from 'styled-components';
import { Paragraph } from '@yieldstreet/ui-kit';
import { AVAILABLE_METHODS, NO_AVAILABLE_METHODS } from './InsufficientFunds.const';
import { InsufficientFundsForm } from './InsufficientFundsForm';
export const InsufficientFunds = _ref => {
  let {
    hasAdditionalFundsPaymentMethodChoice,
    additionalFundsPaymentMethods,
    additionalFundsPaymentDetails,
    isConfirmInvestmentScreen,
    formikProps,
    showTitle = true
  } = _ref;
  const hasPaymentMethods = !!additionalFundsPaymentMethods.length;
  return __jsx(React.Fragment, null, showTitle && __jsx(Paragraph, {
    "data-cy": "insufficient-funds-copy"
  }, hasPaymentMethods ? AVAILABLE_METHODS : NO_AVAILABLE_METHODS), hasPaymentMethods && __jsx(InnerCard, {
    "data-cy": "insufficient-funds-form"
  }, __jsx(InsufficientFundsForm, {
    hasAdditionalFundsPaymentMethodChoice: hasAdditionalFundsPaymentMethodChoice,
    additionalFundsPaymentDetails: additionalFundsPaymentDetails,
    additionalFundsPaymentMethods: additionalFundsPaymentMethods,
    isConfirmInvestmentScreen: isConfirmInvestmentScreen,
    formikProps: formikProps
  })));
};
const InnerCard = styled.div.withConfig({
  displayName: "InsufficientFunds__InnerCard",
  componentId: "sc-1obgvw1-0"
})(["padding-top:20px;"]);