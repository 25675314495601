import _Stack from "@mui/material/Stack";
import _Divider from "@mui/material/Divider";
import _Box from "@mui/material/Box";
import _extends from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/extends.js";
import _Typography from "@mui/material/Typography";
var __jsx = React.createElement;
import React from 'react';
import { NumberFormat } from 'sharedComponents/NumberFormat/NumberFormat';
import { testIds } from './UpfrontCommitmentBreakdown.model';
export const UpfrontCommitmentBreakdown = _ref => {
  let {
    upfrontFundingDetails
  } = _ref;
  const {
    capitalDrawnPercentage,
    fundedNow,
    fundedLater,
    percentFundedLater,
    committedAmount
  } = upfrontFundingDetails;
  return __jsx(_Stack, {
    spacing: 2,
    sx: theme => ({
      padding: theme.spacing(6),
      marginBottom: theme.spacing(8),
      borderRadius: theme.shape.radius(4),
      backgroundColor: theme.palette.background.card.default,
      maxWidth: '600px'
    })
  }, __jsx(_Typography, {
    variant: "h5",
    sx: {
      mb: 2,
      fontWeight: 'medium'
    }
  }, "Capital call breakdown"), __jsx(_Box, {
    sx: {
      display: 'flex',
      justifyContent: 'space-between'
    }
  }, __jsx(_Typography, _extends({
    variant: "body2"
  }, testIds.upfrontFundingPercentage.attr), "Upfront funding (", capitalDrawnPercentage, "%)"), __jsx(_Typography, _extends({
    variant: "body2"
  }, testIds.upfrontFundingAmount.attr), __jsx(NumberFormat, {
    decimalScale: 2,
    displayType: "text",
    fixedDecimalScale: true,
    prefix: "$",
    thousandSeparator: true,
    value: fundedNow
  }))), __jsx(_Box, {
    sx: {
      display: 'flex',
      justifyContent: 'space-between'
    }
  }, __jsx(_Typography, _extends({
    variant: "body2",
    color: "textTertiary"
  }, testIds.fundingRemainingPercentage.attr), "Funding remaining (", percentFundedLater, "%)"), __jsx(_Typography, _extends({
    variant: "body2",
    color: "textTertiary"
  }, testIds.fundingRemainingAmount.attr), __jsx(NumberFormat, {
    decimalScale: 2,
    displayType: "text",
    fixedDecimalScale: true,
    prefix: "$",
    thousandSeparator: true,
    value: fundedLater
  }))), __jsx(_Divider, null), __jsx(_Box, {
    sx: {
      display: 'flex',
      justifyContent: 'space-between'
    }
  }, __jsx(_Typography, {
    variant: "subtitle1",
    sx: {
      fontWeight: 'medium'
    }
  }, "Funding required"), __jsx(_Typography, _extends({
    variant: "subtitle1",
    sx: {
      fontWeight: 'medium'
    }
  }, testIds.fundingRequiredAmount.attr), __jsx(NumberFormat, {
    decimalScale: 2,
    displayType: "text",
    fixedDecimalScale: true,
    prefix: "$",
    thousandSeparator: true,
    value: committedAmount
  }))));
};