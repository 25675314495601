var __jsx = React.createElement;
import React, { useRef, useEffect } from 'react';
import { Formik } from 'formik';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import { FormikPhoneInput } from '@yieldstreet/ui-kit/inputs/forms';
import { ModalContainer, useModalContext } from '@yieldstreet/ui-kit';
import { userProfileApi } from '@yieldstreet/platform-kit';
import { useApiErrorMessage, useApiFormErrors } from '@yieldstreet/tool-kit';
import { ModalHeader } from '../shared/shared';
import { SButtonContainer, SForm } from '../common/common.style';
import { modalTitle, phoneInput, closeButton, submitButton, TEST_IDS, EditPhoneSchema } from './EditPhoneModal.model';
export const EditPhoneModal = _ref => {
  let {
    userId,
    userPhone
  } = _ref;
  const form = useRef();
  const {
    hideModal
  } = useModalContext();
  const [updateUserProfilePhone, updateUserProfilePhoneState] = userProfileApi.useUpdateUserProfilePhoneMutation();
  const {
    isLoading: updatePhoneIsLoading,
    isSuccess: updatePhoneSuccess,
    isError
  } = updateUserProfilePhoneState;
  const errorMessage = useApiErrorMessage(updateUserProfilePhoneState);
  useApiFormErrors(updateUserProfilePhoneState, form);
  useEffect(() => {
    if (updatePhoneSuccess) {
      hideModal();
    }
  }, [hideModal, updatePhoneSuccess]);
  return __jsx(ModalContainer, null, __jsx(ModalHeader, {
    "data-cy": TEST_IDS.modalHeader,
    type: 2,
    tagType: 3
  }, modalTitle), __jsx(Formik, {
    ref: form,
    initialValues: {
      phoneNumber: userPhone || ''
    },
    validationSchema: EditPhoneSchema,
    onSubmit: (_ref2, formikActions) => {
      let {
        phoneNumber
      } = _ref2;
      updateUserProfilePhone({
        phoneNumber,
        userId
      }).then(() => formikActions.setSubmitting(false));
    },
    enableReinitialize: true
  }, formikProps => __jsx(SForm, null, __jsx(FormikPhoneInput, {
    name: "phoneNumber",
    label: phoneInput,
    formikProps: formikProps,
    min: 2,
    fullWidth: true,
    sx: {
      mb: 4
    }
  }), isError && __jsx(Alert, {
    severity: "error",
    sx: {
      mb: 4
    }
  }, errorMessage), __jsx(SButtonContainer, null, __jsx(Button, {
    type: "reset",
    onClick: () => hideModal(),
    "data-trackingid": "show-edit-phone-cancel",
    disabled: updatePhoneIsLoading,
    variant: "outlined",
    fullWidth: true
  }, closeButton), __jsx(LoadingButton, {
    type: "submit",
    "data-trackingid": "show-edit-phone-update",
    onClick: formikProps.handleSubmit,
    "data-cy": TEST_IDS.submitButton,
    loading: updatePhoneIsLoading,
    disabled: updatePhoneIsLoading,
    fullWidth: true
  }, submitButton)))));
};