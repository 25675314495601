var __jsx = React.createElement;
import React, { useEffect, useMemo } from 'react';
import { FormCheckboxInput, Heading, Paragraph, Label, media } from '@yieldstreet/ui-kit';
import map from 'lodash/map';
import styled from 'styled-components';
const DistributionReinvestment = _ref => {
  let {
    formikProps,
    data = []
  } = _ref;
  useEffect(() => {
    data.forEach(data => {
      formikProps.setFieldValue(`${data.key || ''}reInvest`, true);
    });

    /* eslint-disable react-hooks/exhaustive-deps */
  }, [data]);
  const reinvestments = useMemo(() => {
    const text = data.length > 1 ? 'boxes' : 'box';
    return __jsx(React.Fragment, null, __jsx(SParagraph, {
      "data-cy": "distribution-reinvestment-explanation"
    }, "You will be enrolled in our distribution reinvestment plan, which allows you to automatically reinvest your distributions in the Fund and compound your investment's growth. If you would like to opt out of this plan, uncheck the ", text, " below."), map(data, _ref2 => {
      let {
        investment,
        noteTitle,
        key
      } = _ref2;
      return !investment.configPresent ? __jsx(React.Fragment, null, __jsx(SFormCheckboxInput, {
        className: "distribution-reinvestment-checkbox",
        name: `${key || ''}reInvest`,
        label: __jsx(Paragraph, {
          small: true,
          "data-cy": "distribution-reinvestment-checkbox-label"
        }, "Enable automated reinvestment of my distributions from the ", noteTitle, "."),
        formikProps: formikProps
      }), __jsx(LabelWrapper, null, __jsx(Label, null, "You can adjust this setting at any time by visiting your Portfolio."))) : __jsx(React.Fragment, null, __jsx(DistributionTitle, null, investment.reInvest ? 'Enrolled' : 'Not Enrolled'), __jsx(DistributionDescription, {
        secondary: true,
        "data-cy": "distribution-reinvestment-description"
      }, investment.reInvest ? `Automated distribution reinvestment is currently turned on for your investments in the ${noteTitle}. You can adjust this setting in your Portfolio following your investment.` : `Automated distribution reinvestment is currently turned off for your investments in the ${noteTitle}. You can adjust this setting in your Portfolio following your investment.`));
    }));
  }, [data, formikProps]);
  if (!(data !== null && data !== void 0 && data.length)) {
    return null;
  }
  return __jsx(Wrapper, {
    className: "distribution-reinvestment-wrapper",
    "data-cy": "distribution-reinvestment-wrapper"
  }, __jsx(Heading, {
    type: 4
  }, "Distribution reinvestment"), __jsx(InnerWrapper, null, reinvestments));
};
const Wrapper = styled.div.withConfig({
  displayName: "DistributionReinvestment__Wrapper",
  componentId: "sc-1ks1ye-0"
})(["border-top:2px solid ", ";padding-top:30px;margin-top:40px;"], props => props.theme.colors.border_default);
const InnerWrapper = styled.div.withConfig({
  displayName: "DistributionReinvestment__InnerWrapper",
  componentId: "sc-1ks1ye-1"
})(["", ";"], media.tablet`
    max-width: 780px;
  `);
const SFormCheckboxInput = styled(FormCheckboxInput).withConfig({
  displayName: "DistributionReinvestment__SFormCheckboxInput",
  componentId: "sc-1ks1ye-2"
})(["margin-bottom:0 !important;"]);
const SParagraph = styled(Paragraph).withConfig({
  displayName: "DistributionReinvestment__SParagraph",
  componentId: "sc-1ks1ye-3"
})(["margin:", ";"], props => props.semiBold ? '20px 0 10px' : '10px 0');
const LabelWrapper = styled.div.withConfig({
  displayName: "DistributionReinvestment__LabelWrapper",
  componentId: "sc-1ks1ye-4"
})(["padding-left:32px;"]);
const DistributionTitle = styled(SParagraph).withConfig({
  displayName: "DistributionReinvestment__DistributionTitle",
  componentId: "sc-1ks1ye-5"
})([""]);
const DistributionDescription = styled(SParagraph).withConfig({
  displayName: "DistributionReinvestment__DistributionDescription",
  componentId: "sc-1ks1ye-6"
})([""]);
export default DistributionReinvestment;