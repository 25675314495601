export const GoogleSDKCodes = {
  street_number: {
    key: 'street_number',
    type: 'short_name'
  },
  route: {
    key: 'route',
    type: 'long_name'
  },
  locality: {
    key: 'locality',
    type: 'long_name'
  },
  sublocality_level_1: {
    key: 'sublocality_level_1',
    type: 'long_name'
  },
  administrative_area_level_2: {
    key: 'administrative_area_level_2',
    type: 'short_name'
  },
  country: {
    key: 'country',
    type: 'short_name'
  },
  postal_code: {
    key: 'postal_code',
    type: 'short_name'
  },
  administrative_area_level_1: {
    key: 'administrative_area_level_1',
    type: 'short_name'
  }
};