import _Divider from "@mui/material/Divider";
import _Box from "@mui/material/Box";
import _Typography from "@mui/material/Typography";
import _Stack from "@mui/material/Stack";
import _Skeleton from "@mui/material/Skeleton";
var __jsx = React.createElement;
import React, { useCallback } from 'react';
import { NumericFormat } from 'react-number-format';
import { useHistory } from 'react-router-dom';
import { Table, TableCell, TBody, Th, THead, Tr } from '../../../../../portfolio/common/Table';
import { useLatestDeals } from '../../hooks/useLatestDeals';
import { testIds } from './LatestDealsListDesktop.model';
import { LatestDealsListHeadings } from '../../LatestDealsList.model';
export const LatestDealsListDesktop = () => {
  const history = useHistory();
  const {
    offeringData,
    offeringDataIsLoading
  } = useLatestDeals();
  const onItemClick = useCallback(offering => {
    history.push(`/offering/${offering.noteUrlHash}`);
  }, [history]);
  if (offeringDataIsLoading) {
    return __jsx(_Stack, null, __jsx(_Skeleton, {
      variant: "rounded",
      width: "100%",
      height: 200
    }));
  }
  return __jsx(React.Fragment, null, __jsx(Table, testIds.base.attr, __jsx(THead, null, __jsx(Th, {
    padding: 0
  }, __jsx(_Typography, {
    variant: "body2",
    sx: {
      fontWeight: 'medium'
    },
    color: "text.paragraphPrimary.default"
  }, LatestDealsListHeadings.OfferingTitle)), __jsx(Th, null, __jsx(_Typography, {
    variant: "body2",
    sx: {
      fontWeight: 'medium'
    },
    color: "text.paragraphPrimary.default"
  }, LatestDealsListHeadings.AssetClass)), __jsx(Th, null, __jsx(_Typography, {
    variant: "body2",
    sx: {
      fontWeight: 'medium'
    },
    color: "text.paragraphPrimary.default"
  }, LatestDealsListHeadings.MinimumInvestment)), __jsx(Th, null, __jsx(_Typography, {
    variant: "body2",
    sx: {
      fontWeight: 'medium'
    },
    color: "text.paragraphPrimary.default"
  }, LatestDealsListHeadings.Term))), __jsx(TBody, null, offeringData === null || offeringData === void 0 ? void 0 : offeringData.map(offering => __jsx(Tr, {
    key: offering.noteUrlHash,
    sx: {
      cursor: 'pointer'
    },
    onClick: () => onItemClick(offering)
  }, __jsx(TableCell, {
    padding: 0
  }, __jsx(_Stack, {
    direction: "row",
    sx: {
      alignItems: 'center'
    }
  }, __jsx(_Box, {
    component: "img",
    src: offering.thumbnail,
    sx: {
      borderRadius: 1,
      mr: 4,
      height: 48
    }
  }), __jsx(_Typography, {
    variant: "body2",
    color: "text.paragraphPrimary.default"
  }, offering.title))), __jsx(TableCell, null, __jsx(_Typography, {
    variant: "body2",
    color: "text.paragraphPrimary.default"
  }, offering.assetClass)), __jsx(TableCell, null, __jsx(_Typography, {
    variant: "body2",
    color: "text.paragraphPrimary.default"
  }, __jsx(NumericFormat, {
    value: offering.minimumAmount,
    displayType: 'text',
    thousandSeparator: true,
    prefix: '$'
  }))), __jsx(TableCell, null, __jsx(_Typography, {
    variant: "body2",
    color: "text.paragraphPrimary.default"
  }, offering.term)))))), __jsx(_Divider, null));
};