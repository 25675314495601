import _extends from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/extends.js";
var __jsx = React.createElement;
import React from 'react';
import { Label, Paragraph } from '@yieldstreet/ui-kit';
import isEmpty from "lodash/isEmpty";
import { NumberFormat } from '../../NumberFormat/NumberFormat';
import { testIds } from './OfferingListMobileMeta.model';
import { OfferingListItemMobileWrapper } from './OfferingListMobileMeta.style';
export const OfferingListMobileMeta = _ref => {
  let {
    metaData = {},
    item
  } = _ref;
  const {
    mappedKey = '',
    content = '',
    format = {}
  } = metaData;
  return __jsx(OfferingListItemMobileWrapper, {
    "data-cy": testIds.offeringListMobile
  }, __jsx(Paragraph, {
    small: true,
    semiBold: true
  }, item === null || item === void 0 ? void 0 : item.title), __jsx(Label, null, !isEmpty(format) ? __jsx(NumberFormat, _extends({
    value: item === null || item === void 0 ? void 0 : item[mappedKey],
    displayType: "text"
  }, format)) : item === null || item === void 0 ? void 0 : item[mappedKey], content));
};