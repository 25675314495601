var __jsx = React.createElement;
import React, { useEffect } from 'react';
import { parse } from 'query-string';
import { Route, Switch, useLocation, useRouteMatch, useHistory } from 'react-router-dom';
import BaseLayout from 'sharedComponents/BaseLayout';
import MetaData from 'sharedComponents/MetaData';
import AccreditationSignup from './accreditationSignup/AccreditationSignup';
import PhoneSignup from './phoneSignup';
import EmbeddedSignup from './primarySignup/EmbeddedSignup';
import PrimarySignup from './primarySignup/PrimarySignup';
import { useManagedPortfolioUtmTagging } from './hooks/useManagedPortfolioTagging/useManagedPortfolioTagging';
const NewSignupContainer = () => {
  const {
    path
  } = useRouteMatch();
  const {
    search
  } = useLocation();
  const history = useHistory();
  const searchParams = parse(search);
  const {
    redirectURI
  } = useManagedPortfolioUtmTagging();
  useEffect(() => {
    if (redirectURI) {
      history.replace(redirectURI);
    }
  }, [history, redirectURI]);
  return __jsx(React.Fragment, null, __jsx(MetaData, {
    metaData: {
      title: 'Sign up to access Alternative Investments - Yieldstreet',
      description: 'Sign up now to Yieldstreet to access real estate, legal, marine, art and diversified investment funds.',
      canonicalUrl: '/signup/',
      url: '/signup/'
    }
  }), __jsx(Switch, null, __jsx(Route, {
    strict: true,
    path: `${path}/accreditation`,
    render: () => __jsx(AccreditationSignup, null)
  }), __jsx(Route, {
    strict: true,
    path: `${path}/phone`,
    render: () => __jsx(PhoneSignup, null)
  }), __jsx(Route, {
    strict: true,
    path: `${path}/embedded`,
    render: () => __jsx(BaseLayout, null, __jsx(EmbeddedSignup, {
      formType: searchParams === null || searchParams === void 0 ? void 0 : searchParams.type
    }))
  }), __jsx(Route, {
    strict: true,
    path: path,
    render: () => __jsx(PrimarySignup, null)
  })));
};
export default NewSignupContainer;