var __jsx = React.createElement;
import React from 'react';
import { PrimarySignupForm } from '../PrimarySignupForm';
import { SignUpFormType } from '../PrimarySignupForm/PrimarySignupForm.model';
const EmbeddedSignup = _ref => {
  let {
    isOnSidebar,
    formType = SignUpFormType.MINIMAL
  } = _ref;
  return __jsx(PrimarySignupForm, {
    buttonType: "basic",
    formType: formType,
    isOnSidebar: isOnSidebar
  });
};
export default EmbeddedSignup;