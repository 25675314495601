import _objectSpread from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import { useState, useMemo, useCallback } from 'react';
import isEmpty from "lodash/isEmpty";
import { SignupAccreditationMethods, SignupQuizSteps } from '@yieldstreet/platform-kit';
import { useSignupQuiz } from 'pages/signup/signupQuiz/hooks/useSignupQuiz';
const initPayload = {
  indvIncome200k: false,
  indvNetWorth1m: false,
  enttAssets5m: false,
  licensedBroker: false
};
const accreditationKeyMap = {
  [SignupAccreditationMethods.NETWORTH]: 'indvNetWorth1m',
  [SignupAccreditationMethods.ENTITY]: 'enttAssets5m',
  [SignupAccreditationMethods.LICENSED_BROKER]: 'licensedBroker',
  [SignupAccreditationMethods.INDIVIDUAL_JOINT]: 'indvIncome200k'
};
const useAccreditationStatus = () => {
  const {
    getQuizAnswer
  } = useSignupQuiz();
  const quizAnswer = getQuizAnswer(SignupQuizSteps.ACCREDITATION, true);
  const {
    0: selectedValues,
    1: setSelectedValues
  } = useState([]);
  const handleChange = useCallback(checkedValues => {
    const noneSelected = checkedValues.includes(SignupAccreditationMethods.NONE) && !selectedValues.includes(SignupAccreditationMethods.NONE);
    setSelectedValues(noneSelected ? [SignupAccreditationMethods.NONE] : checkedValues.filter(option => option !== SignupAccreditationMethods.NONE));
  }, [selectedValues]);
  const quizValues = useMemo(() => {
    if (quizAnswer) {
      if (quizAnswer.length === 0) {
        return [SignupAccreditationMethods.NONE];
      } else {
        return quizAnswer;
      }
    }
  }, [quizAnswer]);
  const values = isEmpty(selectedValues) ? quizValues : selectedValues;
  const hasAccreditationStatus = !isEmpty(values);
  const isAccredited = hasAccreditationStatus && !values.includes(SignupAccreditationMethods.NONE);
  const payload = useMemo(() => {
    if (values) {
      return values.reduce((payload, option) => {
        const optionKey = accreditationKeyMap[option];
        return optionKey ? _objectSpread(_objectSpread({}, payload), {}, {
          [optionKey]: true
        }) : payload;
      }, initPayload);
    }
    return {};
  }, [values]);
  return {
    isAccredited,
    hasAccreditationStatus,
    payload,
    selectedValues,
    handleChange
  };
};
export default useAccreditationStatus;