import { createYupObjectSchema } from '@yieldstreet/tool-kit';
export const PASSWORD_MODAL_ID = 'password_modal';
export const ResetPasswordSchema = createYupObjectSchema({
  password: {
    validationType: 'default-string'
  },
  newPassword: {
    validationType: 'password'
  },
  passwordConfirm: {
    validationType: 'default-string'
  }
});
export const checkMatchingPw = values => {
  return values.newPassword !== values.passwordConfirm ? {
    passwordConfirm: 'Your passwords must match'
  } : {};
};
export const initialValues = {
  password: '',
  newPassword: '',
  passwordConfirm: '',
  reason: '',
  message: '',
  type: ''
};