import _extends from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/extends.js";
import _InputAdornment from "@mui/material/InputAdornment";
import _IconButton from "@mui/material/IconButton";
import _objectDestructuringEmpty from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/objectDestructuringEmpty.js";
var __jsx = React.createElement;
import * as React from 'react';
import MuiTextField from '@mui/material/TextField';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import { fieldToTextField } from '../utils/fieldToTextField/fieldToTextField.util';
export const FormikPasswordInput = _ref => {
  let props = Object.assign({}, (_objectDestructuringEmpty(_ref), _ref));
  const [showPassword, setShowPassword] = React.useState(false);
  const handleClickShowPassword = () => setShowPassword(show => !show);
  const handleMouseDownPassword = event => {
    event.preventDefault();
  };
  return __jsx(MuiTextField, _extends({}, fieldToTextField(props), {
    variant: props.variant || 'filled',
    type: showPassword ? 'text' : 'password',
    slotProps: {
      input: {
        endAdornment: __jsx(_InputAdornment, {
          position: "end"
        }, __jsx(_IconButton, {
          "aria-label": "toggle password visibility",
          onClick: handleClickShowPassword,
          onMouseDown: handleMouseDownPassword,
          "data-cy": `${props['data-cy']}-toggle`,
          edge: "end"
        }, showPassword ? __jsx(VisibilityOffOutlinedIcon, null) : __jsx(VisibilityOutlinedIcon, null)))
      }
    }
  }));
};