var __jsx = React.createElement;
import React, { useMemo, useState } from 'react';
import { ModalContainer, useModalContext } from '@yieldstreet/ui-kit';
import { EditAddressForm } from './EditAddressForm';
import { EditAddressInvestorSync } from './EditAddressInvestorSync';
import { EditAddressStep } from './EditAddressModal.model';
export const EditAddressContainer = () => {
  const {
    0: userAddress,
    1: setUserAddress
  } = useState();
  const {
    0: step,
    1: setStep
  } = useState(EditAddressStep.ADDRESS_FORM);
  const {
    hideModal
  } = useModalContext();
  const renderStep = useMemo(() => {
    switch (step) {
      case EditAddressStep.ADDRESS_FORM:
        return __jsx(EditAddressForm, {
          userAddress: userAddress,
          onCancel: () => hideModal(),
          onSuccess: (userAddress, hasInvestorAccounts) => {
            if (hasInvestorAccounts) {
              setUserAddress(userAddress);
              setStep(EditAddressStep.ADDRESS_INVESTOR_SYNC);
            } else {
              hideModal();
            }
          }
        });
      case EditAddressStep.ADDRESS_INVESTOR_SYNC:
        return __jsx(EditAddressInvestorSync, {
          userAddress: userAddress,
          onBack: () => setStep(EditAddressStep.ADDRESS_FORM),
          onDone: () => hideModal()
        });
      default:
        return null;
    }
  }, [step, userAddress, hideModal]);
  return __jsx(ModalContainer, null, renderStep);
};