var __jsx = React.createElement;
import React, { useRef, useEffect } from 'react';
import { Form, Formik } from 'formik';
import { Button, ModalContainer, Notification } from '@yieldstreet/ui-kit';
import { userPasswordApi } from '@yieldstreet/platform-kit';
import { useApiErrorMessage, useApiFormErrors, eventManager, BusinessEvent } from '@yieldstreet/tool-kit';
import { ModalHeader, SButton } from '../shared/shared';
import { INITIAL_VALUES, SetPasswordSchema, validateMatchingPassword } from './SetPasswordModal.model';
import { FormWrapper, SetPasswordButtonContainer, SFormPasswordTextInput } from './SetPassword.styles';
export const SetPasswordForm = _ref => {
  let {
    hideModal
  } = _ref;
  const form = useRef();
  const [setPassword, setPasswordState] = userPasswordApi.useSetPasswordMutation();
  const {
    isLoading,
    isError,
    isSuccess
  } = setPasswordState;
  const errorMessage = useApiErrorMessage(setPasswordState);
  useApiFormErrors(setPasswordState, form);
  useEffect(() => {
    if (isSuccess) {
      eventManager.track(BusinessEvent.USER_LOGIN_SET_PASSWORD, {});
      hideModal();
    }
    if (isError) {
      eventManager.trackFailure(BusinessEvent.USER_LOGIN_SET_PASSWORD, {
        errorMessage
      });
    }
  }, [isSuccess, isError, hideModal, errorMessage]);
  return __jsx(ModalContainer, null, __jsx(ModalHeader, {
    type: 3
  }, "Create password"), __jsx(Formik, {
    ref: form,
    initialValues: INITIAL_VALUES,
    validationSchema: SetPasswordSchema,
    validate: validateMatchingPassword,
    onSubmit: _ref2 => {
      let {
        password,
        passwordConfirm
      } = _ref2;
      setPassword({
        password,
        passwordConfirm
      });
    },
    enableReinitialize: true
  }, formikProps => __jsx(Form, null, __jsx(FormWrapper, null, __jsx(SFormPasswordTextInput, {
    name: "password",
    autoComplete: "password",
    label: "Password",
    testId: "set-password-password-input",
    formikProps: formikProps
  }), __jsx(SFormPasswordTextInput, {
    name: "passwordConfirm",
    autoComplete: "passwordConfirm",
    label: "Confirm password",
    testId: "set-password-password-confirm-input",
    formikProps: formikProps
  }), __jsx(SetPasswordButtonContainer, null, __jsx(Button, {
    type: "reset",
    onClick: () => hideModal(),
    buttonType: "secondary",
    trackingId: "password-modal-set-password-cancel"
  }, "Cancel"), __jsx(SButton, {
    type: "button",
    onClick: formikProps.handleSubmit,
    trackingId: "password-modal-set-password-confirm",
    "data-cy": "set-password-submit-button",
    disabled: isLoading,
    loading: isLoading,
    left: true
  }, "Set password")), isError && __jsx(Notification, {
    severity: "error",
    description: errorMessage,
    inline: true
  })))));
};