import _extends from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/extends.js";
var __jsx = React.createElement;
import React from 'react';
import capitalize from "lodash/capitalize";
import { Heading, Paragraph } from '@yieldstreet/ui-kit';
import { interpolate } from '@yieldstreet/tool-kit';
import { testIds, investmentStrategyCopy } from '../../StrategyModal.model';
import { HeaderWrapper, TitleWrapper } from './StrategyModalHeader.style';
export const StrategyModalHeader = _ref => {
  let {
    investmentStrategyType
  } = _ref;
  return __jsx(HeaderWrapper, null, __jsx(TitleWrapper, null, __jsx(Heading, _extends({
    type: 4
  }, testIds.title.attr), interpolate(investmentStrategyCopy.title, {
    strategy: capitalize(investmentStrategyType)
  }))), __jsx(Paragraph, _extends({}, testIds.subtitle.attr, {
    secondary: true,
    size: "small"
  }), interpolate(investmentStrategyCopy.subtitle, {
    strategy: capitalize(investmentStrategyType)
  })));
};