import _Button from "@mui/material/Button";
import _Stack from "@mui/material/Stack";
var __jsx = React.createElement;
import React, { useCallback } from 'react';
import { SwitchTransition, CSSTransition } from 'react-transition-group';
import { Formik } from 'formik';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { testIds } from '../Questions.model';
import { ButtonText, goalsQuiz, SharedComponentsTestIds } from '../../Goals.model';
import { CounterComponent } from '../../components/CounterComponent';
import { FadeInRight, MobileWrapper, QuestionTitle, SButton, SForm } from '../../Goals.style';
import { getInitialFormikProps } from './MobileView.model';
export const MobileView = _ref => {
  let {
    getQuizAnswer,
    handleOnClick,
    handleOptions,
    handlePrevQuestion,
    nextStep,
    quiz = {},
    step
  } = _ref;
  const initialFormikProps = useCallback(() => getInitialFormikProps(getQuizAnswer), [getQuizAnswer]);
  return __jsx(MobileWrapper, {
    "data-cy": testIds.mobileView
  }, __jsx(Formik, {
    initialValues: initialFormikProps,
    onSubmit: values => {
      handleOnClick(values, step, goalsQuiz(getQuizAnswer)[step].saveAnswer);
    }
  }, formikProps => __jsx(SForm, null, __jsx("div", null, __jsx(_Stack, {
    direction: "row"
  }, step !== 0 && __jsx(SButton, {
    type: "button",
    onClick: () => handlePrevQuestion(step),
    "data-cy": testIds.prevButton
  }, __jsx(ChevronLeftIcon, null)), CounterComponent(`Question ${nextStep}`)), __jsx(FadeInRight, null, __jsx(SwitchTransition, {
    mode: "out-in"
  }, __jsx(CSSTransition, {
    classNames: "fade-in-right",
    key: step,
    addEndListener: (node, done) => {
      node.addEventListener('transitionend', done, false);
    }
  }, __jsx("div", null, __jsx(QuestionTitle, {
    "data-cy": testIds.title,
    type: 4
  }, quiz.question), handleOptions(step, formikProps, quiz)))))), __jsx(_Button, {
    "data-cy": SharedComponentsTestIds.buttonComponent,
    type: "submit",
    disabled: formikProps.values.quizGoalsRadioGroupInput_0 || formikProps.values.quizGoalsRadioGroupInput_1 || formikProps.values.quizGoalsRadioGroupInput_2 || formikProps.values.quizGoalsCurrencyTextInput_3 || formikProps.values.quizGoalsCurrencyTextInput_4 ? false : true
  }, ButtonText['next']))));
};