import _Box from "@mui/material/Box";
import _Typography from "@mui/material/Typography";
var __jsx = React.createElement;
import React, { useCallback } from 'react';
import { Markdown } from '@yieldstreet/ui-kit';
import map from "lodash/map";
import { replaceCheckboxLink } from 'utils/replaceCheckboxLink';
import AcknowledgeContainer from '../Acknowledge/AcknowledgeContainer';
import { Container, PaddingContainer } from './BulkAcknowledge.styles';
import { FormikCheckboxInput } from '@yieldstreet/ui-kit/inputs';
const BulkAcknowledge = _ref => {
  let {
    formikProps,
    offeringDetails,
    externalCheckboxes,
    generalCheckboxes
  } = _ref;
  const generalRequirements = useCallback(() => {
    if (!generalCheckboxes) {
      return null;
    }
    return __jsx(React.Fragment, null, generalCheckboxes.map(checkbox => {
      return __jsx(_Box, {
        key: checkbox.name
      }, __jsx(FormikCheckboxInput, {
        "data-cy": `general-checkbox-${checkbox.name}`,
        name: checkbox.name,
        label: __jsx(_Typography, {
          variant: "body2",
          className: checkbox.className,
          "data-cy": `checkboxes-paragraph-${checkbox.className}`
        }, __jsx(Markdown, null, replaceCheckboxLink(checkbox.label, checkbox.links))),
        formikProps: formikProps
      }));
    }));
  }, [generalCheckboxes, formikProps]);
  const checkboxes = useCallback(() => {
    return map(offeringDetails, function () {
      let {
        note
      } = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return __jsx(_Box, {
        sx: {
          mb: 4
        }
      }, __jsx(AcknowledgeContainer, {
        formikProps: formikProps,
        offeringInfo: note,
        externalCheckboxes: externalCheckboxes === null || externalCheckboxes === void 0 ? void 0 : externalCheckboxes[note === null || note === void 0 ? void 0 : note.urlHash],
        prefix: note === null || note === void 0 ? void 0 : note.urlHash,
        title: note === null || note === void 0 ? void 0 : note.title
      }));
    });
  }, [formikProps, offeringDetails, externalCheckboxes]);
  return __jsx(Container, {
    id: "general-agreement-acknowledge",
    "data-cy": "static-acknowledge"
  }, __jsx(_Typography, {
    variant: "h4"
  }, "General Agreements"), __jsx(PaddingContainer, null, generalRequirements()), checkboxes());
};
export default BulkAcknowledge;