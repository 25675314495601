var __jsx = React.createElement;
import React, { useCallback, useMemo, useState } from 'react';
import { useModalContext } from '@yieldstreet/ui-kit';
import { userAddressApi } from '@yieldstreet/platform-kit';
import { EditAddressForm } from './EditAddressForm';
import { EditAddressInvestorSync } from './EditAddressInvestorSync';
import { EditAddressStep } from './EditAddressModal.model';
import { useUserState } from 'utils/hooks';
import { StyledModalContainer } from './EditAddressContainer.styles';
export const EditAddressContainer = _ref => {
  let {
    setSnackbarProps
  } = _ref;
  const {
    0: isUpdating,
    1: setIsUpdating
  } = useState(false);
  const {
    id: userId = null
  } = useUserState();
  const {
    useGetUserAddressQuery
  } = userAddressApi;
  const {
    data: {
      address: userAddress = {}
    } = {},
    isFetching: isFetchingAddress
  } = useGetUserAddressQuery({
    userId
  });
  const {
    0: step,
    1: setStep
  } = useState(EditAddressStep.ADDRESS_FORM);
  const {
    hideModal
  } = useModalContext();
  const onDone = useCallback(snackbarProps => {
    if ((snackbarProps === null || snackbarProps === void 0 ? void 0 : snackbarProps.severity) === 'success') {
      hideModal();
    }
    setSnackbarProps(snackbarProps);
  }, [hideModal, setSnackbarProps]);
  const renderStep = useMemo(() => {
    switch (step) {
      case EditAddressStep.ADDRESS_FORM:
        return __jsx(EditAddressForm, {
          isUpdating: isUpdating,
          userAddress: userAddress,
          onCancel: () => hideModal(),
          onSuccess: (userAddress, hasInvestorAccounts) => {
            if (hasInvestorAccounts) {
              setStep(EditAddressStep.ADDRESS_INVESTOR_SYNC);
            } else {
              hideModal();
            }
            setIsUpdating(true);
          }
        });
      case EditAddressStep.ADDRESS_INVESTOR_SYNC:
        return __jsx(EditAddressInvestorSync, {
          isFetchingAddress: isFetchingAddress,
          userAddress: userAddress,
          onBack: () => {
            setStep(EditAddressStep.ADDRESS_FORM);
          },
          onDone: onDone
        });
      default:
        return null;
    }
  }, [step, userAddress, hideModal, onDone, isFetchingAddress, isUpdating]);
  return __jsx(React.Fragment, null, __jsx(StyledModalContainer, null, renderStep));
};