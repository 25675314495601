import _extends from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/extends.js";
import _objectSpread from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
var __jsx = React.createElement;
import React from 'react';
import AppleSignin from 'react-apple-signin-auth';
import AppleIcon from '@mui/icons-material/Apple';
import ENV_CONFIG from 'utils/env/config';
import { testIds } from '../SocialLogin.model';
import { SocialLoginButton } from '../components/SocialLoginButton.style';
export const AppleAuth = _ref => {
  let {
    cta,
    loading,
    onSuccess
  } = _ref;
  return __jsx(AppleSignin, {
    authOptions: {
      clientId: ENV_CONFIG.APPLE_CLIENT_ID,
      scope: 'name email',
      redirectURI: `https://${ENV_CONFIG.HOST}/callback/apple`,
      usePopup: true
    },
    uiType: "light",
    onSuccess: _ref2 => {
      let {
        authorization,
        user
      } = _ref2;
      if (authorization) {
        const {
          id_token
        } = authorization;
        const {
          name = {}
        } = user || {};
        return onSuccess(id_token, _objectSpread({}, name));
      }
    },
    onError: () => null,
    render: _ref3 => {
      let {
        onClick
      } = _ref3;
      return __jsx(SocialLoginButton, _extends({
        color: "tertiary",
        disabled: loading,
        fullWidth: true,
        loading: loading,
        onClick: onClick,
        startIcon: __jsx(AppleIcon, {
          sx: {
            fontSize: 30
          }
        })
      }, testIds.apple.attr), cta, " with Apple");
    }
  });
};