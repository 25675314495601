import _extends from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/extends.js";
var __jsx = React.createElement;
import * as React from 'react';
import { getIn } from 'formik';
import { MuiTelInput } from 'mui-tel-input';
import { fieldToPhoneField } from './fieldToPhoneInput.util';
export const FormikPhoneInput = props => {
  const {
    formikProps,
    name
  } = props;
  const maskedInput = {
    disabled: true,
    slotProps: {
      input: {
        value: getIn(formikProps.values, name)
      }
    }
  };
  const additionalProps = props.masked ? maskedInput : {};
  return __jsx(MuiTelInput, _extends({}, fieldToPhoneField(props), additionalProps));
};