export const testIds = {
  investInput: 'offering-invest-currency-input',
  formContainer: 'offering-invest-form-container',
  mainButton: 'offering-invest-MainCTA',
  loginButton: 'offering-invest-login-button',
  fullyAllocatedWrapper: 'offering-invest-fully-allocated',
  capitalCallWrapper: 'offering-invest-capital-call-wrapper',
  progress: 'offering-invest-progress',
  betweenMinMaxValid: 'offering-invest-progress-item-min-max-valid',
  betweenMinMaxInvalid: 'offering-invest-progress-item-min-max-invalid',
  incrementValid: 'offering-invest-progress-item-increment-valid',
  incrementInvalid: 'offering-invest-progress-item-increment-invalid',
  fullyFunded: 'offering-invest-progress-item-fully-funded',
  additionalPayment: 'offering-invest-progress-item-additional-payment'
};
export const errorMessages = {
  increment: `Your investment must be in increments of {{increment}}`,
  betweenMinMax: `Your investment must be between {{min}} and {{max}}`,
  mustBeNumber: `You haven't specified an investment amount`
};
export const COPY = {
  rollover: {
    subtitle: `Maturing investment`,
    label: `Available rollover amount`,
    amountDescription: `Rollover amount must match your maturing investment. For any additional funding, please submit a separate investment request.`
  },
  nextInvestment: `Your next investment`,
  inputLabel: `{{str}} amount`,
  cta: `Next`,
  ctaWaitList: `Join waitlist`,
  ctaLogIn: `Log in`,
  allocationNotification: `This offering is fully allocated, and the waitlist has been closed.`,
  increment: `Increments: {{increment}}`,
  range: `Investment range: {{min}} - {{max}}`
};