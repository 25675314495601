import { createYupObjectSchema } from '@yieldstreet/tool-kit';
export const EDIT_NAME_MODAL = 'edit-name-modal';
export const testIds = {
  firstName: 'firstName',
  lastName: 'lastName',
  showEditNameUpdate: 'show-edit-name-update',
  showEditNameCancel: 'show-edit-name-cancel'
};
export const EditNameSchema = createYupObjectSchema({
  firstName: {
    validationType: 'name'
  },
  lastName: {
    validationType: 'name'
  }
});