import { useEffect, useRef } from 'react';
import { connect } from 'formik';
const Effect = _ref => {
  let {
    formik,
    onChange
  } = _ref;
  const ref = useRef(null);
  useEffect(() => {
    onChange(formik, ref.current);
    ref.current = formik;
  }, [formik, onChange]);
  return null;
};
const FormikEffect = connect(Effect);
export default FormikEffect;