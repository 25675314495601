import { User2FAMethod } from '@yieldstreet/platform-kit';
import { genTestIds } from '@yieldstreet/tool-kit';
export const setupAuth = {
  [User2FAMethod.TEXT_MESSAGE]: 'Send verification code',
  [User2FAMethod.AUTHY_APP]: 'Send text'
};
const {
  getTestIds
} = genTestIds(['formArea', 'title', 'codeField', 'phoneField', 'maskedPhoneField', 'errorNotification', 'radioButton', 'submit', 'continue', 'resend', 'backButton', 'loading', 'otpCode']);
export const testIds = getTestIds('2fa');
export const phoneRegex = /\*{3}-\*{3}-\d{4}/gi;