var __jsx = React.createElement;
import React from 'react';
import { SignupQuizSteps } from '@yieldstreet/platform-kit';
import { SignupExperienceAltsLabels, SignupExperienceAltsOptions } from '@yieldstreet/tool-kit';
import { SignupQuizStep } from '../../SignupQuizStep';
export const ExperienceStep = _ref => {
  let {
    nextStep,
    saveQuizAnswer,
    getQuizAnswer
  } = _ref;
  const answer = getQuizAnswer(SignupQuizSteps.EXPERIENCE_ALTS);
  return __jsx(SignupQuizStep, {
    answer: answer,
    getQuizAnswer: getQuizAnswer,
    nextStep: nextStep,
    options: SignupExperienceAltsOptions,
    saveQuizAnswer: saveQuizAnswer,
    stepKey: SignupQuizSteps.EXPERIENCE_ALTS,
    subtitle: SignupExperienceAltsLabels.subtitle,
    testId: "quiz-experience-radio",
    title: SignupExperienceAltsLabels.title
  });
};