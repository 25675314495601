import _extends from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/extends.js";
import _SvgIcon from "@mui/material/SvgIcon";
var __jsx = React.createElement;
import React, { useCallback, useEffect, useState } from 'react';
import { useGoogleLogin } from 'react-google-login';
import { Google } from '@yieldstreet/ui-kit/icons';
import ENV_CONFIG from 'utils/env/config';
import { SocialLoginButton } from '../components/SocialLoginButton.style';
import { testIds } from '../SocialLogin.model';
export const GoogleAuth = _ref => {
  let {
    cta,
    loading,
    onSuccess
  } = _ref;
  const {
    0: loginClicked,
    1: setLoginClicked
  } = useState(false);
  const {
    signIn,
    loaded
  } = useGoogleLogin({
    onSuccess: response => onSuccess(response.tokenId),
    clientId: ENV_CONFIG.GOOGLE_CLIENT_ID,
    cookiePolicy: 'single_host_origin',
    scope: 'openid profile email',
    responseType: 'id_token'
  });
  const handleOnClick = useCallback(e => {
    e.preventDefault();
    setLoginClicked(true);
  }, []);
  useEffect(() => {
    if (loginClicked && loaded) {
      signIn();
      setLoginClicked(false);
    }
  }, [loaded, loginClicked, signIn]);
  return __jsx(SocialLoginButton, _extends({
    color: "tertiary",
    disabled: loading,
    fullWidth: true,
    loading: loading,
    onClick: handleOnClick,
    startIcon: __jsx(_SvgIcon, {
      component: Google,
      inheritViewBox: true,
      sx: {
        fontSize: 30
      }
    })
  }, testIds.google.attr), cta, " with Google");
};