import _Box from "@mui/material/Box";
import _Button from "@mui/material/Button";
import _Typography from "@mui/material/Typography";
var __jsx = React.createElement;
import React from 'react';
import { Modal } from '@yieldstreet/ui-kit';
const RemoveBeneficiaryModal = _ref => {
  let {
    hideModal,
    deleteBeneficiary,
    userId,
    visible
  } = _ref;
  if (!visible) {
    return null;
  }
  return __jsx(Modal, {
    visible: visible,
    clickClose: () => hideModal('showModalRemoveBeneficiaries')
  }, __jsx(_Box, {
    sx: theme => ({
      p: `0 ${theme.spacing(3)}`,
      [theme.breakpoints.up('lg')]: {
        p: `0 ${theme.spacing(6)} ${theme.spacing(4)} ${theme.spacing(6)}`
      }
    })
  }, __jsx(_Typography, {
    component: "h1",
    variant: "h3",
    sx: {
      pb: 3
    }
  }, "Confirm removal of beneficiaries"), __jsx(_Typography, {
    component: "p",
    variant: "body1",
    sx: {
      mb: 10
    }
  }, "The primary and contingent beneficiaries will be removed from your account."), __jsx(_Box, {
    component: "div",
    sx: {
      display: 'flex',
      gap: 3
    }
  }, __jsx(_Button, {
    variant: "outlined",
    onClick: () => hideModal('showModalRemoveBeneficiaries')
  }, "Cancel"), __jsx(_Button, {
    "data-cy": "remove-beneficiary-confirm",
    onClick: () => deleteBeneficiary(userId).then(() => hideModal('showModalRemoveBeneficiaries'))
  }, "Delete beneficiaries"))));
};
export default RemoveBeneficiaryModal;