var __jsx = React.createElement;
import React from 'react';
import { SignupQuizSteps } from '@yieldstreet/platform-kit';
import { SignupPortfolioLabels, SignupPortfolioOptions } from '@yieldstreet/tool-kit';
import { SignupQuizStep } from '../../SignupQuizStep';
import { stepTestId } from './PortfolioStep.model';
export const PortfolioStep = _ref => {
  let {
    nextStep,
    saveQuizAnswer,
    getQuizAnswer
  } = _ref;
  const answer = getQuizAnswer(SignupQuizSteps.PORTFOLIO);
  return __jsx(SignupQuizStep, {
    answer: answer,
    getQuizAnswer: getQuizAnswer,
    nextStep: nextStep,
    options: SignupPortfolioOptions,
    saveQuizAnswer: saveQuizAnswer,
    stepKey: SignupQuizSteps.PORTFOLIO,
    subtitle: SignupPortfolioLabels.subtitle,
    testId: stepTestId,
    title: SignupPortfolioLabels.title
  });
};