import _Box from "@mui/material/Box";
import _Typography from "@mui/material/Typography";
var __jsx = React.createElement;
import React from 'react';
import { Info, Wrapper } from '../OfferingsCard.style';
import { OfferingIcon, Paragraph } from './EmptyOfferingCard.style';
export const EmptyOfferingCard = _ref => {
  let {
    accreditationRequired,
    dataCy,
    description,
    image,
    imageAlt,
    minInvestment,
    title
  } = _ref;
  return __jsx(Wrapper, {
    "data-cy": `${dataCy}-wrapper`
  }, __jsx(OfferingIcon, {
    alt: imageAlt,
    src: image,
    "data-cy": "investment-products-offering-card-image"
  }), __jsx(_Typography, {
    variant: "h5",
    sx: {
      mb: 3
    },
    "data-cy": `${dataCy}-heading`
  }, title), __jsx(_Box, {
    sx: {
      mb: 5
    }
  }, __jsx(Paragraph, {
    variant: "body2",
    "data-cy": `${dataCy}-description-paragraph`
  }, description)), __jsx(Info, null, __jsx(Paragraph, {
    variant: "body2"
  }, "Accreditation"), __jsx(Paragraph, {
    variant: "body2",
    sx: {
      fontWeight: 'medium'
    },
    "data-cy": `${dataCy}-accreditation-paragraph`
  }, accreditationRequired ? 'Required' : 'Not required')), __jsx(Info, null, __jsx(Paragraph, {
    variant: "body2"
  }, "Min. investment"), __jsx(Paragraph, {
    variant: "body2",
    sx: {
      fontWeight: 'medium'
    },
    "data-cy": `${dataCy}-min-investment-paragraph`
  }, minInvestment)));
};