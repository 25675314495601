export const rule501Url = 'https://www.ecfr.gov/current/title-17/section-230.501';
export const ACCREDITATION_RULES = {
  // accredited investor
  rule501: {
    url: 'https://www.ecfr.gov/current/title-17/section-230.501',
    label: 'Rule 501'
  },
  // qualified purchaser
  'section2.a.51': {
    url: 'https://www.law.cornell.edu/uscode/text/15/80a-2',
    label: 'Section 2(a)(51)'
  },
  // qualified client
  'rule205-3.d.1': {
    url: 'https://www.law.cornell.edu/cfr/text/17/275.205-3',
    label: 'Rule 205-3(d)(1)'
  }
};