import _Typography from "@mui/material/Typography";
import _Alert from "@mui/material/Alert";
import _AlertTitle from "@mui/material/AlertTitle";
import _extends from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/extends.js";
var __jsx = React.createElement;
import React from 'react';
import { useFlags } from '@yieldstreet/tool-kit';
import { ACHDisclaimer } from './ACHDisclaimer/ACHDisclaimer';
import PaymentMethod from './PaymentMethod';
import WalletBalance from './WalletBalance/WalletBalance';
import { WalletDisclaimer } from './WalletBalance/WalletDisclaimer';
import { InsufficientFunds } from './InsufficientFunds';
import { InsufficientContainer, PaymentCard, WireDetails } from './CompleteInvestment.style';
import { COPY as copy } from './CompleteInvestment.model';
import { NumberFormat } from 'sharedComponents/NumberFormat/NumberFormat';
import { UpfrontCapitalCallDisclaimer } from './UpfrontCapitalCallDisclaimer';
export const CompleteInvestmentInner = _ref => {
  let {
    investorAccountId,
    paymentInfoLoading,
    paymentInfo,
    isWirePayment,
    isACHPayment,
    // for PaymentMethod
    promoState,
    primaryPaymentMethods,
    hasPaymentMethodChoice,
    formikProps,
    // for Rollover
    totalAmount,
    selectedAccountEligible,
    // for wire
    isExternalIRA,
    iraFundingInstructions,
    // for wallet
    isYSWallet,
    showWalletBalance,
    hasInsufficientFunds,
    additionalRolloverFundsDetails,
    rolloverAmount,
    totalCommittedAmount,
    singleCapitalCallDetails,
    // for InsufficientFunds
    isFreeInvestmentPromo,
    hasAdditionalFundsPaymentMethodChoice,
    additionalFundsPaymentDetails,
    additionalFundsPaymentMethods,
    rolloverNeedsAdditionalFunds,
    isRollover,
    shouldShowAlternativeCopy,
    upfrontFundingDetails
  } = _ref;
  const {
    wireOnly
  } = useFlags({
    wireOnly: false
  });
  return __jsx(React.Fragment, null, investorAccountId && !paymentInfoLoading && __jsx(React.Fragment, null, !isRollover ? __jsx(PaymentMethod, {
    promoState: promoState,
    paymentMethodState: {
      paymentInfo,
      primaryPaymentMethods,
      hasPaymentMethodChoice
    },
    formikProps: formikProps,
    investorAccountId: investorAccountId
  }) : null, paymentInfo && isYSWallet && rolloverNeedsAdditionalFunds && selectedAccountEligible && __jsx(React.Fragment, null, __jsx(PaymentCard, null, showWalletBalance && __jsx(WalletBalance, _extends({}, paymentInfo.walletBalance, {
    investmentAmount: totalAmount,
    usingRolloverFunds: isRollover,
    hasInsufficientFunds: hasInsufficientFunds,
    rolloverWalletFundsRequired: additionalRolloverFundsDetails !== null && additionalRolloverFundsDetails !== void 0 && additionalRolloverFundsDetails.fundsNeeded ? additionalRolloverFundsDetails === null || additionalRolloverFundsDetails === void 0 ? void 0 : additionalRolloverFundsDetails.walletFundsRequired : 0,
    rolloverAdditionalFundsRequired: additionalRolloverFundsDetails !== null && additionalRolloverFundsDetails !== void 0 && additionalRolloverFundsDetails.fundsNeeded ? additionalRolloverFundsDetails === null || additionalRolloverFundsDetails === void 0 ? void 0 : additionalRolloverFundsDetails.additionalFundsRequired : 0,
    rolloverTotalFundsRequired: additionalRolloverFundsDetails !== null && additionalRolloverFundsDetails !== void 0 && additionalRolloverFundsDetails.fundsNeeded ? totalAmount - rolloverAmount : null,
    committedAmount: totalCommittedAmount,
    capitalDrawnPercentage: singleCapitalCallDetails === null || singleCapitalCallDetails === void 0 ? void 0 : singleCapitalCallDetails.capitalDrawnPercentage,
    promoState: promoState,
    showDisclaimer: !isRollover
  })), hasInsufficientFunds && (additionalRolloverFundsDetails === null || additionalRolloverFundsDetails === void 0 ? void 0 : additionalRolloverFundsDetails.additionalFundsRequired) > 0 && !isFreeInvestmentPromo && __jsx(InsufficientContainer, null, __jsx(InsufficientFunds, {
    hasAdditionalFundsPaymentMethodChoice: hasAdditionalFundsPaymentMethodChoice,
    additionalFundsPaymentMethods: additionalFundsPaymentMethods,
    additionalFundsPaymentDetails: additionalFundsPaymentDetails,
    hasPaymentMethods: !!additionalFundsPaymentMethods.length,
    formikProps: formikProps,
    showTitle: !isRollover
  }), isRollover && __jsx(WalletDisclaimer, null)))), selectedAccountEligible && !!singleCapitalCallDetails && (upfrontFundingDetails ? __jsx(_Alert, {
    severity: "warning",
    sx: {
      maxWidth: '600px'
    }
  }, __jsx(_AlertTitle, null, "Funding required"), "As noted above,", ' ', __jsx(NumberFormat, {
    decimalScale: 2,
    displayType: "text",
    fixedDecimalScale: true,
    prefix: "$",
    thousandSeparator: true,
    value: upfrontFundingDetails.fundedLater
  }), ' ', "will need to be funded via capital calls at a later date.") : __jsx(_Alert, {
    severity: "warning",
    title: "Capital call breakdown"
  }, "You do not need to fund your commitment at this time. We will notify you as your commitment is called to be funded.", __jsx("br", null), "After submitting your initial commitment, you can increase it incrementally by submitting a new commitment request."))), !isRollover && paymentInfo && __jsx(React.Fragment, null, upfrontFundingDetails ? __jsx(UpfrontCapitalCallDisclaimer, {
    isACHPayment: isACHPayment,
    isWirePayment: isWirePayment
  }) : __jsx(React.Fragment, null, isACHPayment && __jsx(ACHDisclaimer, null), isWirePayment && __jsx(WireDetails, null, isExternalIRA ? __jsx(_Typography, {
    variant: "body1"
  }, iraFundingInstructions) : __jsx(React.Fragment, null, wireOnly && __jsx(React.Fragment, null, __jsx(_Typography, {
    variant: "body1"
  }, copy.wireOnly), __jsx("br", null)), __jsx(_Typography, {
    variant: "body1"
  }, shouldShowAlternativeCopy ? copy.wireInfoAlternative : copy.wireInfo))))));
};