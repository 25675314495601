import { H4, ParagraphRegular } from '@yieldstreet/ui-kit';
import styled from 'styled-components';
export const AccountIncomplete = styled.div.withConfig({
  displayName: "InvestorAccountSelectionstyle__AccountIncomplete",
  componentId: "sc-19iqu81-0"
})(["display:inline-block;width:100%;padding:5px 10px 0;"]);
export const FormWrapper = styled.div.withConfig({
  displayName: "InvestorAccountSelectionstyle__FormWrapper",
  componentId: "sc-19iqu81-1"
})(["max-width:350px;"]);
export const SingleOptionLabel = styled.div.withConfig({
  displayName: "InvestorAccountSelectionstyle__SingleOptionLabel",
  componentId: "sc-19iqu81-2"
})(["", ";margin-bottom:10px;"], ParagraphRegular);
export const SingleOptionTitle = styled.div.withConfig({
  displayName: "InvestorAccountSelectionstyle__SingleOptionTitle",
  componentId: "sc-19iqu81-3"
})(["", ";display:block;margin-bottom:10px;"], H4);