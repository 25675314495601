import _objectSpread from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import _objectWithoutProperties from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/objectWithoutProperties.js";
const _excluded = ["legal_first_name", "legal_last_name", "contact_name", "contact_id"];
import { v4 as uuidv4 } from 'uuid';
export const mockUsers = [{
  legal_first_name: 'Perit',
  legal_last_name: 'Toff',
  email: 'ptoff@cadre.com',
  contact_name: 'P Toff',
  contact_id: '2200080'
}, {
  legal_first_name: 'Nard',
  legal_last_name: 'Greek',
  email: 'ngreek@cadre.com',
  contact_name: 'N Greek',
  contact_id: '2200081'
}, {
  legal_first_name: 'Jo',
  legal_last_name: 'Cat',
  email: 'jcat@cadre.com',
  contact_name: 'J Cat',
  contact_id: '2200082'
}, {
  legal_first_name: 'Arn',
  legal_last_name: 'Ola',
  email: 'aola@cadre.com',
  contact_name: 'A Ola',
  contact_id: '2200083'
}];
export const mockUserOptions = mockUsers.map((_ref, value) => {
  let {
      legal_first_name,
      legal_last_name,
      contact_name,
      contact_id
    } = _ref,
    props = _objectWithoutProperties(_ref, _excluded);
  return _objectSpread({
    value: String(value),
    label: `${legal_first_name} - ${legal_last_name}`,
    user: 0,
    legal_first_name,
    legal_last_name,
    contact_name,
    contact_id
  }, props);
});
export const mockSAMLResponse = function () {
  let {
    legal_first_name = '',
    legal_last_name = '',
    email = '',
    contact_name = '',
    contact_id = ''
  } = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  const id = uuidv4();
  const timestamp = new Date().toISOString();
  return `<?xml version="1.0" encoding="UTF-8"?>
<saml2p:Response xmlns:saml2p="urn:oasis:names:tc:SAML:2.0:protocol"
                Consent="urn:oasis:names:tc:SAML:2.0:consent:unspecified"
                Destination="https://www.yieldstreet.com/api/auth/saml/callback?client_name=cadre"
                ID="unique-id-per-response-${id}" IssueInstant="${timestamp}" Version="2.0">
    <saml2:Issuer xmlns:saml2="urn:oasis:names:tc:SAML:2.0:assertion">http://saml-yieldstreet.cadre.com</saml2:Issuer>
    <saml2p:Status>
        <saml2p:StatusCode Value="urn:oasis:names:tc:SAML:2.0:status:Success"/>
    </saml2p:Status>
    <saml2:Assertion xmlns:saml2="urn:oasis:names:tc:SAML:2.0:assertion" ID="assertion-id-per-response-${id}" IssueInstant="${timestamp}" Version="2.0">
        <saml2:Issuer>http://saml-yieldstreet.cadre.com</saml2:Issuer>
        <saml2:Subject>
            <saml2:NameID NameQualifier="https://www.yieldstreet.com">user-with-account-2</saml2:NameID>
            <saml2:SubjectConfirmation Method="urn:oasis:names:tc:SAML:2.0:cm:bearer">
                <saml2:SubjectConfirmationData NotOnOrAfter="${timestamp}" Recipient="https://www.yieldstreet.com/api/auth/saml/callback?client_name=cadre"/>
            </saml2:SubjectConfirmation>
        </saml2:Subject>
        <saml2:Conditions NotBefore="${timestamp}" NotOnOrAfter="${timestamp}">
            <saml2:AudienceRestriction>
                <saml2:Audience>https://sp.yieldstreet.net</saml2:Audience>
            </saml2:AudienceRestriction>
        </saml2:Conditions>
        <saml2:AttributeStatement>
            <saml2:Attribute Name="legal_first_name">
                <saml2:AttributeValue>${legal_first_name}</saml2:AttributeValue>
            </saml2:Attribute>
            <saml2:Attribute Name="legal_last_name">
                <saml2:AttributeValue>${legal_last_name}</saml2:AttributeValue>
            </saml2:Attribute>
            <saml2:Attribute Name="email">
                <saml2:AttributeValue>${email}</saml2:AttributeValue>
            </saml2:Attribute>
            <saml2:Attribute Name="contact_id">
                <saml2:AttributeValue>${contact_id}</saml2:AttributeValue>
            </saml2:Attribute>
            <saml2:Attribute Name="user_id">
                <saml2:AttributeValue>${contact_name}</saml2:AttributeValue>
            </saml2:Attribute>
        </saml2:AttributeStatement>
        <saml2:AuthnStatement AuthnInstant="${timestamp}">
            <saml2:AuthnContext>
                <saml2:AuthnContextClassRef>urn:oasis:names:tc:SAML:2.0:ac:classes:PasswordProtectedTransport</saml2:AuthnContextClassRef>
            </saml2:AuthnContext>
        </saml2:AuthnStatement>
    </saml2:Assertion>
</saml2p:Response>`;
};