var __jsx = React.createElement;
import React from 'react';
import { PricingChipsContainer, PricingChip, PricingChipMobile, PricingChipDesktop } from './PricingChips.style';
import { formatCurrency } from '@yieldstreet/tool-kit';
import { useInvestmentChips, TypeOfInvestors } from '@yieldstreet/tool-kit';
import { testIds } from './PricingChips.model';
export const PricingChips = _ref => {
  let {
    minimumInvestment,
    maximumInvestment,
    investorType,
    value,
    action
  } = _ref;
  const investor = investorType === 'RI' ? TypeOfInvestors.Repeat : TypeOfInvestors.FirstTime;
  const chips = useInvestmentChips({
    minimumInvestment,
    maximumInvestment,
    investorType: investor
  });
  return chips.length ? __jsx(PricingChipsContainer, {
    "data-cy": testIds.container
  }, chips.map((chip, index) => __jsx(PricingChip, {
    key: index,
    "data-cy": `${testIds.item}${chip === parseInt(`${value}`) ? ` ${testIds.item_selected}` : ''}`,
    selected: chip === parseInt(`${value}`),
    onClick: () => action(chip)
  }, __jsx(PricingChipDesktop, {
    "data-cy": testIds.item_desktop
  }, formatCurrency(chip)), __jsx(PricingChipMobile, {
    "data-cy": testIds.item_mobile
  }, formatCurrency(chip, {
    abbreviate: true
  }))))) : __jsx(React.Fragment, null);
};